import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const ViewContainer = styled.div`
    width: ${props => props.viewWidth}px;
    height: ${props => props.height}px;
    overflow: hidden;
`;

const SlideWrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: start;
    width: ${props => props.contentWidth}px;
    height: ${props => props.height}px;
    transform: ${props => `translateX(${props.translateX}px)`};
    transition: transform 0.5s ease-out;
`;

const ScrollSlide = ({ viewWidth = 1440, contentWidth = 1880, height = 516, children }) => {
    const [translateX, setTranslateX] = useState(0);
    const distance = contentWidth - viewWidth;
    const containerRef = useRef(null);

    const handleScroll = () => {
        const rect = containerRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        // 기준을 컨테이너의 절반 위치로 변경
        const containerMid = rect.top + rect.height / 2;

        if (containerMid < viewportHeight && rect.bottom > 0) {
            const scrollY = window.scrollY + containerMid;
            const scrollRange = Math.min(viewportHeight, rect.height);
            const scrollFraction = (window.scrollY - scrollY + viewportHeight) / scrollRange;
            const newTranslateX = Math.min(Math.max(-scrollFraction * distance, -distance), 0);
            setTranslateX(newTranslateX);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initialize position on mount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [distance]);

    return (
        <ViewContainer ref={containerRef} viewWidth={viewWidth} height={height}>
            <SlideWrapper contentWidth={contentWidth} height={height} translateX={translateX}>
                {children}
            </SlideWrapper>
        </ViewContainer>
    );
};

export default ScrollSlide;
