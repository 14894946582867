import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_MODAL, ALERT_MODAL_OFF, setAlertModal, setModal } from './api/action';

export const useAlertModal = props => {
    const { title, content, buttonText, buttonFunction } = props;
    const dispatch = useDispatch();

    const showModal = () => {
        dispatch(
            setAlertModal({
                type: ALERT_MODAL,
                data: {
                    status: true,
                    title: title,
                    content: content,
                    buttonText: buttonText,
                    buttonFunction: buttonFunction,
                },
            })
        );
    };

    return { showModal };
};

export const useOffAlertModal = type => {
    const dispatch = useDispatch();

    const hideModal = () => {
        dispatch(
            setModal({
                type: type,
            })
        );
    };

    return { hideModal };
};

useAlertModal.defaultProps = {
    title: 'Congratulations!',
    content: 'Successfully sent the offer to the model.',
    buttonText: 'confirm',
    buttonFunction: () => {},
};

const AlertModal = () => {
    const AlertState = useSelector(state => state.modal.alert);
    const { hideModal } = useOffAlertModal(ALERT_MODAL_OFF);
    //const dispatch = useDispatch();
    //const hideModal = () => {
    //    dispatch(
    //        setModal({
    //            type: ALERT_MODAL_OFF,
    //        })
    //    );
    //};

    return AlertState.status ? (
        <div className="modal">
            <div className="modal-dialog modal-sm">
                <h3>{AlertState.title}</h3>
                <i
                    onClick={() => {
                        hideModal();
                    }}
                    className="icon-cancel"
                ></i>
                <div className="modal-content">{AlertState.content}</div>
                <div className="btn-group">
                    <button type="button" onClick={() => AlertState.buttonFunction()} className="btn btn-primary">
                        {AlertState.buttonText}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default AlertModal;
