import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';

import user from '../modules/auth/api/state';
import modal from '../modules/modal/api/state';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'],
};

const appReducer = persistReducer(
    persistConfig,
    combineReducers({
        user,
        modal,
    })
);

const rootReducer = (state, action) => {
    if (action.type === 'user/logout') {
        storage.removeItem('persist:root');
        state = undefined;
    }

    return appReducer(state, action);
};

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);
