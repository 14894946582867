import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { createContract } from '../../queries/CreateContract';
import {
    ALERT_MODAL,
    ALERT_MODAL_OFF,
    CREATE_OFFER_DETAIL_MODAL,
    CREATE_OFFER_MODAL,
    setAlertModal,
    setModal,
    setOfferModal,
} from './api/action';
import { getFileName } from './CreateOffer';
import { getDisplayName } from './ExposureMedias';

const CreateOfferDetail = () => {
    const CreateOfferDetailState = useSelector(state => state.modal.createOfferDetail);
    const CreateOfferState = useSelector(state => state.modal.createOffer);
    const ExposureMediasCode = useSelector(state => state.modal.exposureMediasCode);
    const userData = useSelector(state => state.user);
    const [displayName, setDisplayName] = useState([]);

    const showAlertModal = () => {
        dispatch(
            setAlertModal({
                type: ALERT_MODAL,
                data: {
                    status: true,
                    title: 'Congratulations!',
                    content: 'Successfully sent the offer to the model.',
                    buttonText: 'Confirm',
                    buttonFunction: () => {
                        dispatch(
                            setModal({
                                type: ALERT_MODAL_OFF,
                            })
                        );
                    },
                },
            })
        );
    };

    const showErrorModal = () => {
        dispatch(
            setAlertModal({
                type: ALERT_MODAL,
                data: {
                    status: true,
                    title: 'Fail',
                    content: 'Contract is already in progress.',
                    buttonText: 'Confirm',
                    buttonFunction: () => {
                        dispatch(
                            setModal({
                                type: ALERT_MODAL_OFF,
                            })
                        );
                    },
                },
            })
        );
    };

    const mutation = useMutation(async body => createContract({ userId: userData.id, data: body }), {
        onSuccess: async res => {
            hideModal();
            showAlertModal();
        },
        onError: err => {
            switch (err.response.data.status) {
                case 'fail':
                    showErrorModal();
                    return;
                default:
                    showErrorModal();
                    return;
            }
        },
    });

    useEffect(() => {
        const selectedArray = CreateOfferDetailState.body.exposure_medias;
        let ret = '';
        if (selectedArray) {
            for (let index = 0; index < selectedArray.length; index++) {
                ret += getDisplayName(ExposureMediasCode, selectedArray[index]);
                if (index < selectedArray.length - 1) {
                    ret += ', ';
                }
            }
        }
        setDisplayName(ret);
    }, [CreateOfferDetailState.body]);

    const onSubmit = () => {
        mutation.mutate(CreateOfferDetailState.body);
    };

    const dispatch = useDispatch();

    const backToEdit = () => {
        const beforeData = { ...CreateOfferState };
        dispatch(
            setOfferModal({
                type: CREATE_OFFER_MODAL,
                data: {
                    ...beforeData,
                    status: true,
                },
            })
        );
        hideModal();
    };

    const hideModal = () => {
        const beforeData = { ...CreateOfferDetailState };
        dispatch(
            setOfferModal({
                type: CREATE_OFFER_DETAIL_MODAL,
                data: {
                    ...beforeData,
                    status: false,
                },
            })
        );
    };

    const getAdPostingCountryString = array => {
        let ret = '';

        if (array) {
            for (let index = 0; index < array.length; index++) {
                ret += array[index].label;
                if (index < array.length - 1) {
                    ret += ', ';
                }
            }
            return ret;
        }
    };

    return CreateOfferDetailState.status ? (
        <div className="modal">
            <div className="modal-dialog">
                <div className="header">
                    <h4>Create Offer</h4>
                </div>
                <i onClick={hideModal} className="icon-cancel"></i>
                <div className="input-box pd-b-0">
                    <h5>Basic information</h5>
                    <div className="sel-photo col-4 fl">
                        <h4>Selected photo</h4>
                        <div style={{ textAlign: 'center' }} className="img">
                            <img src={CreateOfferState.portfolio.file} />
                            <p>{decodeURI(getFileName(CreateOfferState.portfolio.file))}</p>
                        </div>
                    </div>
                    <ul className="col-7 fl">
                        <li>
                            <b className="label-b">Project title </b>
                            {CreateOfferDetailState.data.title}
                        </li>
                        <li>
                            <b className="label-b">Posting Date </b> {CreateOfferDetailState.body.posting_start}
                            {'  '}-{'  '}
                            {CreateOfferDetailState.body.posting_end}
                        </li>
                        <li>
                            <b className="label-b">Product </b> {CreateOfferDetailState.data.product}
                        </li>
                        <li>
                            <b className="label-b">Ad posting country </b>
                            {getAdPostingCountryString(CreateOfferDetailState.data.ad_posting_countries)}
                        </li>
                    </ul>
                </div>
                <div className="input-box pd-b-0">
                    <h5>Posting Ad & Estimating cost</h5>
                    <div className="detail-group col-4 fl">
                        <label>
                            Exposure number <span>{CreateOfferDetailState.body.exposure_number}</span>
                        </label>
                    </div>
                    <div className="detail-group col-8  fl">
                        <label>
                            CPM <span>${CreateOfferDetailState.body.cpm}</span>
                        </label>
                    </div>
                    <div className="detail-group col-4 fl">
                        <label>
                            Estimated revenue{' '}
                            <span>
                                $
                                {(
                                    (CreateOfferDetailState.body.cpm / 1000) *
                                    CreateOfferDetailState.body.exposure_number
                                ).toLocaleString()}
                            </span>
                        </label>
                    </div>
                    <div className="detail-group col-8 fl">
                        <label>
                            Exposure Media <span>{displayName}</span>
                        </label>
                    </div>
                </div>
                <div className="input-box">
                    <h5>Special condition</h5>
                    <div style={{ whiteSpace: 'pre-wrap' }} className="detail-group scroll col-12">
                        {CreateOfferDetailState.body.special_condition}
                    </div>
                    <div className="btn-group">
                        <button type="button" onClick={backToEdit} className="btn btn-default">
                            Back to Edit
                        </button>
                        <button type="button" onClick={onSubmit} className="btn btn-primary">
                            Send Offer to Model
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default CreateOfferDetail;
