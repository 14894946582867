import React, { useRef } from 'react';
import styled from 'styled-components';

const ScrollContainer = styled.div`
    width: ${props => props.width}px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: #e5e5e5;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ddc4fd;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #860cf9;
    }
`;

const CustomScrollbar = ({ width, children, ...options }) => {
    const containerRef = useRef(null);

    const handleScroll = e => {
        const container = containerRef.current;
        container.scrollLeft = e.target.scrollLeft;
    };

    return (
        <ScrollContainer width={width} onScroll={handleScroll} ref={containerRef} {...options}>
            {children}
        </ScrollContainer>
    );
};

export default CustomScrollbar;
