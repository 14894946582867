import React, { useState } from 'react';
import Section from '../layout/Section';
import styled from 'styled-components';
import Fade from '../styled-components/Fade';
import { useGetDemoCelebList, useGetDemoResult, useGetProductList } from '../../hooks/useDemo';
import MaterialIcons from '../styled-components/MaterialIcons';

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 174px;
    color: var(--white);
    font-size: 3.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;*/
    color: var(--gray-900, #252525);
    font-family: Larken;
    font-size: 6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 7.2rem; /* 72px */
`;

const Description = styled.div`
    color: var(--gray-700);
    text-align: center;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem; /* 105.263% */
    margin-bottom: 60px;
    .bold {
        font-weight: 700;
    }
`;

const ProgressBarContainer = styled.ul`
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    /*width: 1080px;*/
    padding: 18px 36px;
    background-color: var(--purple-050);
    border-radius: 100px;
    li {
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            &.selected {
                border: 1px solid var(--purple-500);
                background-color: var(--purple-500);
                color: var(--white);
            }
            &.done {
                border: 1px solid var(--purple-500);
                background-color: var(--purple-500);
                color: transparent;
                background-image: url('/svg/check.svg');
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
            }
            border: 1px solid var(--gray-500);
            color: var(--gray-500);
        }
    }
`;

const BottomButtonArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 90px;
    gap: 24px;
    .page-nav-button {
        margin-bottom: 90px;
        width: 140px;
        height: 48px;
        border: 1px solid var(--gray-500);
        color: var(--gray-500);
        border-radius: 48px;
        text-align: center;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem; /* 125% */
        &:hover {
            background-color: var(--purple-500);
            color: var(--white);
            border: 1px solid var(--purple-500);
        }
    }
    .download-button {
        width: 312px;
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        background: var(--purple-500);
        color: var(--gray-050);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem; /* 125% */
    }
    .refresh-button {
        width: 112px;
        height: 48px;
        border: 1px solid var(--gray-500);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        background-color: var(--white);
        color: var(--gray-500);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem; /* 125% */
    }
`;

const stepStatus = [
    {
        step: 1,
        title: 'Select Product',
    },
    {
        step: 2,
        title: 'Select Model',
    },
    {
        step: 3,
        title: 'Done',
    },
];

const ProgressBar = ({ currentStep }) => {
    return (
        <ProgressBarContainer>
            {stepStatus.map((step, index) => {
                let className = '';

                if (step.step < currentStep) {
                    className = 'done';
                } else if (step.step === currentStep) {
                    className = 'selected';
                }
                return (
                    <React.Fragment key={step.step + '_progress'}>
                        <li>
                            <span className={`number ${className}`}>{step.step}</span>
                            <span>{step.title}</span>
                        </li>
                        {stepStatus.length - 1 === index ? (
                            <></>
                        ) : (
                            <li>
                                <MaterialIcons name="arrow_forward_ios" color="var(--gray-400)" size="32px" />
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </ProgressBarContainer>
    );
};

const ResultContainer = styled.div`
    margin: 0 auto;
    text-align: center;
    .img-container {
        margin: 0 auto 32px;
        border: 1px solid var(--gray-400);
        background: linear-gradient(153deg, #f6f6f6 13.58%, #dcdbdb 90.72%);
        width: 448px;
        height: 448px;
        border-radius: 8px;
        overflow: hidden;
        img {
            width: 448px;
            height: 448px;
            object-fit: cover;
        }
    }
`;

const ImageContainer = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    li {
        cursor: pointer;
        width: 280px;
        height: 280px;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid var(--gray-400);
        background: linear-gradient(153deg, #f6f6f6 13.58%, #dcdbdb 90.72%);
        img {
            width: 280px;
            height: 280px;
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: radial-gradient(50% 50% at 50% 50%, rgba(198, 161, 255, 0.7) 0%, rgba(96, 0, 247, 0.7) 100%);
            /*background: radial-gradient(circle, var(--purple-050), var(--purple-500) 50%, var(--purple-900) 70%);*/
            opacity: 0;
            border-radius: 8px;
            transition: opacity 0.3s ease; /* 부드러운 효과를 위한 전환 */
        }
        &:hover::after {
            opacity: 0.9;
        }
        &.selected::after {
            opacity: 0.9;
        }
    }
`;

const ImagePickArea = props => {
    const { imgList, state, setState } = props;

    if (!imgList) return;

    return (
        <ImageContainer>
            {imgList.map((img, index) => {
                const className = state === img.id ? 'selected' : '';
                return (
                    <li onClick={() => setState(img.id)} className={className} key={'image_' + index}>
                        <img src={img.image} />
                    </li>
                );
            })}
        </ImageContainer>
    );
};

const SelectWrapper = styled.div`
    top: 0;
`;

const ImageSelect = ({ currentStep, setCurrentStep }) => {
    const [productId, setProductId] = useState(undefined);
    const [celebId, setCelebId] = useState(undefined);

    const reset = () => {
        setProductId(undefined);
        setCelebId(undefined);
        setCurrentStep(1);
    };

    if (currentStep === 1) {
        return (
            <ProductSelect
                productId={productId}
                setProductId={setProductId}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            />
        );
    } else if (currentStep === 2) {
        return (
            <CelebSelect
                productId={productId}
                celebId={celebId}
                setCelebId={setCelebId}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            />
        );
    } else if (currentStep === 3) {
        return (
            <Result
                productId={productId}
                celebId={celebId}
                reset={reset}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            />
        );
    }
};

const ProductSelect = props => {
    const { productId, setProductId, currentStep, setCurrentStep } = props;
    const { data } = useGetProductList();

    const onClickNext = () => {
        if (!productId) {
            return;
        }
        const nextStep = currentStep + 1;

        if (nextStep <= stepStatus.length) {
            setCurrentStep(nextStep);
        }
    };

    return (
        <SelectWrapper>
            <Fade status={currentStep === 1} time={1000}>
                <Description>
                    <span>Choose a</span> <span className="bold">product</span>
                    <span> you would like to advertise.</span>
                </Description>
                <ImagePickArea imgList={data} state={productId} setState={setProductId} />
                <BottomButtonArea>
                    <button className="page-nav-button" onClick={onClickNext}>
                        Next{' >'}
                    </button>
                </BottomButtonArea>
            </Fade>
        </SelectWrapper>
    );
};

const CelebSelect = props => {
    const { productId, celebId, setCelebId, currentStep, setCurrentStep } = props;
    const { data } = useGetDemoCelebList(productId);

    const onClickNext = () => {
        if (!celebId) {
            return;
        }
        const nextStep = currentStep + 1;

        if (nextStep <= stepStatus.length) {
            setCurrentStep(nextStep);
        }
    };

    const onClickPrev = () => {
        const prevStep = currentStep - 1;

        if (prevStep > 0) {
            setCelebId(undefined);
            setCurrentStep(prevStep);
        }
    };

    return (
        <SelectWrapper>
            <Fade status={currentStep === 2} time={1000}>
                <Description>
                    <span>Pick a recommended </span>
                    <span className="bold">celebrity</span>
                    <span> for your advertisement.</span>
                </Description>
                <ImagePickArea imgList={data} state={celebId} setState={setCelebId} />
                <BottomButtonArea>
                    <button className="page-nav-button" onClick={onClickPrev}>
                        {'< '}Prev
                    </button>
                    <button className="page-nav-button" onClick={onClickNext}>
                        Next{' >'}
                    </button>
                </BottomButtonArea>
            </Fade>
        </SelectWrapper>
    );
};

const Result = props => {
    const { productId, celebId, reset, currentStep } = props;
    const { data } = useGetDemoResult(productId, celebId);

    return (
        <SelectWrapper>
            <ResultContainer>
                <Fade status={currentStep === 3} time={1000}>
                    <div className="img-container">
                        <img src={data.image} />
                    </div>
                    <BottomButtonArea>
                        <a target="_blank" href={data.image} download="CeleVu_Demo">
                            <button className="download-button">
                                Download Image
                                <MaterialIcons color={'var(--white)'} size="18px" name="download" cursor="pointer" />
                            </button>
                        </a>
                        <button className="refresh-button" onClick={reset}>
                            Reset
                            <MaterialIcons color={'var(--gray-500)'} size={20} name="refresh" cursor="pointer" />
                        </button>
                    </BottomButtonArea>
                </Fade>
            </ResultContainer>
        </SelectWrapper>
    );
};

const Demo = () => {
    const [currentStep, setCurrentStep] = useState(1);

    return (
        <>
            <Section mt={60} mb={60}>
                <Title>CeleVu Demo</Title>
            </Section>
            <Section>
                <ProgressBar currentStep={currentStep} />
            </Section>
            <Section mt={60}>
                <ImageSelect currentStep={currentStep} setCurrentStep={setCurrentStep} />
            </Section>
        </>
    );
};

export default Demo;
