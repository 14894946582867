import React, { useState } from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import ListPagination from '../../components/nav/ListPagination';
import { getMyModelsContractList } from '../../queries/GetMyModelsContract';
import { OFFER_DETAIL_MODAL, setOfferModal } from '../modal/api/action';

function ContractList(props) {
    const { celebId } = props;
    const [currentData, setCurrentData] = useState([]);
    const [number, setNumber] = useState(0);
    const dispatch = useDispatch();

    const showOfferDetailModal = contractId => {
        dispatch(
            setOfferModal({
                type: OFFER_DETAIL_MODAL,
                data: {
                    status: true,
                    contract_id: contractId,
                },
            })
        );
    };
    const { data, isLoading, isError, error } = useQuery(['getMyModelsContractList', celebId], () =>
        getMyModelsContractList({ celebId: celebId })
    );

    useEffect(() => {
        if (data) {
            setNumber(data.length);
        }
    }, []);

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <>
            <h3 className="grid-title">Advertisement List</h3>
            <div className="pagination right">
                <ListPagination
                    data={data}
                    postsPerPage={10}
                    pageCount={10}
                    returnData={setCurrentData}
                    number={number}
                    setNumber={setNumber}
                />
                <select className="sort-select">
                    <option>Latest</option>
                    <option>Popularity</option>
                </select>
            </div>
            <table>
                <colgroup>
                    <col width="5%" />
                    <col width="25%" />
                    <col width="15%" />
                    <col width="12%" />
                    <col width="18%" />
                    <col width="25%" />
                </colgroup>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Project</th>
                        <th>Status</th>
                        <th>Revenue</th>
                        <th>Messages</th>
                        <th>Contract</th>
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((contract, index) => (
                        <tr key={index}>
                            <td>{number - index}</td>
                            <td>{contract.project.title}</td>
                            <td>{contract.status}</td>
                            <td>
                                0
                                {/*<button onClick={() => showOfferDetailModal(contract.project.contract)}>$300</button>*/}
                            </td>
                            <td>{contract.new_message_count}</td>
                            <td>
                                <button type="button" onClick={() => showOfferDetailModal(contract.project.contract)}>
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default ContractList;
