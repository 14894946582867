import React from 'react';
import CalendlyWidget from './Calendly';
import Logo from '../layout/Logo';
import Section from '../layout/Section';
import { useQuery } from 'react-query';
import { getContact } from '../../queries/Contact';

const ContactSchedule = () => {
    const { data, isError, error, isLoading } = useQuery(['getContact'], () => getContact(), {});

    if (!data) return <></>;
    return (
        <Section bgUrl="/images/bg.png" pb={180}>
            <Logo text="Set a schedule" />
            <CalendlyWidget url={data.calendly} />
        </Section>
    );
};

export default ContactSchedule;
