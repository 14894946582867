import axios from 'axios';
import { privateClient } from '../utils/client';

export const PostFacebookAccount = async (pageId, accessToken) => {
    const response = await privateClient.post('/api/v1/celeb/insight/', {
        page_id: pageId,
        access_token: accessToken,
    });

    return response;
};
export const PostAccessToken = async (pageId, accessToken) => {
    const response = await privateClient.post('/api/v1/celeb/accesstoken/', {
        page_id: pageId,
        token: accessToken,
    });

    return response;
};
