import { privateClient } from '../utils/client';

export const patchContractStatus = async ({ contractId, status }) => {
    const body = {
        status: { status },
    };
    const response = await privateClient.patch(`/contract/${contractId}/`, body);

    return response.data;
};
