import React from 'react';
import MainContainer from '../../components/MainContainer';
import Layout from '../../modules/common/Layout';
import MyCelebs from '../../modules/model/MyCelebs';

const MyModelsDetail = () => {
    return (
        <>
            <Layout>
                <MainContainer>
                    <MyCelebs />
                </MainContainer>
            </Layout>
            {/* <ModalCreateProject />
            <LargeModal />
            <ConfirmModal />
            <OfferDetail /> */}
        </>
    );
};

export default MyModelsDetail;
