import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MaterialIcons from './MaterialIcons';

const SelectWrapper = styled.div`
    display: inline-block;
    position: relative;
    overflow: hidden;
    font-size: 0;
    .material-icons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        pointer-events: none;
    }
`;

const SelectTag = styled.select`
    ${props => sizeStyles[props.size] || sizeStyles.default}
    ${props => colorStyles[props.color] || colorStyles.default}
    option {
        pointer-events: none;
    }
    cursor: pointer;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    ::ms-expand {
        display: none;
    }
`;

const sizeStyles = {
    default: {
        fontSize: '1.2rem',
        fontWeight: '500',
    },
    sm: {
        fontWeight: '500',
        fontSize: '1rem',
        lineHeight: '1rem',
        width: '100px',
        height: '16px',
        padding: '2px 6px',
    },
    md: {
        fontWeight: '500',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        width: '152px',
        height: '36px',
        padding: '10px 17px',
    },
    lg: {
        fontWeight: '500',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        width: '520px',
        height: '60px',
        padding: '10px 17px',
    },
};

const colorStyles = {
    default: {
        backgroundColor: 'var(--gray-200)',
        border: 'none',
        borderRadius: '2px',
        color: 'var(--gray-800)',
    },
    gray: {
        backgroundColor: 'var(--gray-200)',
        border: 'none',
        borderRadius: '2px',
        color: 'var(--gray-800)',
    },
    primary: {
        backgroundColor: 'var(--gray-050)',
        border: '1px solid var(--purple-500)',
        borderRadius: '2px',
    },
    gray2: {
        borderRadius: '2px',
        border: '1px solid var(--gray-400, #C2C2C2)',
        backgroundColor: 'var(--white)',
    },
};

const iconSize = {
    sm: '16px',
    md: '20px',
    lg: '24px',
};

export const useSelect = () => {
    const [selectState, setSelectState] = useState({
        id: undefined,
        value: undefined,
        label: undefined,
        order: undefined,
    });

    const setSelectStateHandler = value => {
        setSelectState(value);
    };

    return {
        state: selectState,
        setState: setSelectStateHandler,
    };
};

const Select = props => {
    const { optionsList, state, setState, size, color, initValue, ...options } = props;

    const handleChange = event => {
        const selectedValue = optionsList.find(item => item.value === event.target.value);
        setState(selectedValue);
    };

    useEffect(() => {
        if (initValue) {
            const selectedValue = optionsList.find(item => item.value === initValue);
            setState(selectedValue);
        }
    }, [initValue, optionsList]);

    return (
        <SelectWrapper>
            <MaterialIcons name="arrow_drop_down" size={iconSize[size]} />
            <SelectTag
                onChange={event => handleChange(event)}
                value={state?.value}
                size={size}
                color={color}
                {...options}
            >
                <option value={''} disabled>
                    {state?.value ? optionsList.find(item => item.value === state?.value)?.label : 'Select'}
                </option>
                {optionsList.map(option => {
                    return (
                        <option key={'select' + option?.id} value={option?.value}>
                            {option?.label}
                        </option>
                    );
                })}
            </SelectTag>
        </SelectWrapper>
    );
};
Select.defaultProps = {
    optionsList: [],
    setState: null,
    value: '',
};
export default Select;
