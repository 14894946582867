import React, { useEffect, useState } from 'react';
import Section from '../layout/Section';
import styled from 'styled-components';
import { useUserData } from '../../hooks/useUserData';
import { useMutation } from 'react-query';
import { PatchUserSelf } from '../../queries/UserSelf';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div`
    .title {
        width: 864px;
        margin: 108px auto 20px;
        color: var(--gray-800);
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 24px */
        padding-left: 4px;
    }
    .edit {
        display: inline-block;
        text-align: center;
        width: 100px;
        margin-top: 12px;
        margin-left: 1080px;
        color: var(--gray-500, #a4a4a4);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        text-decoration-line: underline;
        cursor: pointer;
    }
`;

const Content = styled.div`
    input {
        height: 19.2px;
        /*margin-top: -3px;*/
        font-size: 1.6rem;
        outline: none;
        border: none;
        color: var(--gray-800);
        &:disabled {
            color: var(--gray-500);
        }
    }
    margin: 0 auto;
    width: 864px;
    height: 304px;
    padding: 40px;
    border-radius: 15px;
    border: 1px solid var(--gray-300);
    background: var(--white);
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 32px;
    color: var(--gray-800);

    /* Body1 / Regular */
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.92rem; /* 19.2px */
    .category {
        font-weight: 700;
        line-height: 1.92rem; /* 19.2px */
    }
`;

const UserPage = () => {
    const { data, isLoading } = useUserData();
    const [isDisabled, setIsDisabled] = useState(true);
    const { register, handleSubmit, setValue } = useForm({});
    const mutation = useMutation(async data => PatchUserSelf(data), {
        onSuccess: res => {
            setIsDisabled(!isDisabled);
        },
    });
    useEffect(() => {
        if (!isLoading && data) {
            // setValue를 사용하여 초기값 설정
            setValue('username', data.username);
            setValue('first_name', data.first_name);
            setValue('last_name', data.last_name);
            setValue('mobile', data.mobile);
            setValue('website', data.website);
        }
    }, [isLoading, data, setValue]);
    if (isLoading) return <></>;

    const onSubmit = result => {
        const registerBody = {
            last_name: result.last_name,
            first_name: result.first_name,
            mobile: result.mobile,
        };
        if (result.website) {
            registerBody['website'] = result.website;
        }
        mutation.mutate(registerBody);
        setIsDisabled(!isDisabled);
    };

    return (
        <Section padding={100}>
            <Wrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h1 className="title">My Profile</h1>
                    <Content>
                        <span className="category">Username (Email)</span>
                        <input type="text" defaultValue={data.username} disabled></input>
                        <span className="category">First name</span>
                        <input
                            type="text"
                            {...register('first_name')}
                            defaultValue={data.first_name}
                            disabled={isDisabled}
                        ></input>
                        <span className="category">Last name</span>
                        <input
                            type="text"
                            {...register('last_name')}
                            defaultValue={data.last_name}
                            disabled={isDisabled}
                        ></input>
                        <span className="category">Mobile</span>
                        <input
                            type="text"
                            {...register('mobile')}
                            defaultValue={data.mobile}
                            disabled={isDisabled}
                        ></input>
                        <span className="category">Website</span>
                        <input
                            type="text"
                            {...register('website')}
                            defaultValue={data.website}
                            disabled={isDisabled}
                        ></input>
                    </Content>
                    {isDisabled ? (
                        <p type="button" className="edit" onClick={() => setIsDisabled(!isDisabled)}>
                            {/*<button type="button" className="edit" onClick={() => handleEditClick()}>*/}
                            edit
                        </p>
                    ) : (
                        <button type="submit" className="edit">
                            {/*<button type="submit" className="edit" onClick={() => handleEditClick()}>*/}
                            save
                        </button>
                    )}
                </form>
            </Wrapper>
        </Section>
    );
};

export default UserPage;
