import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/contents/Loading';
import { getProjectDetail } from '../../queries/GetProjectList';
import { CREATE_OFFER_DETAIL_MODAL, CREATE_OFFER_MODAL, setOfferModal } from './api/action';
import ExposureMedias from './ExposureMedias';
import { useModal } from './ModalUtils';

export const getFileName = url => {
    if (url) {
        let splited = url.split('/');
        return splited.at(-1);
    }
    return '';
};

const CreateOffer = () => {
    const CreateOfferState = useSelector(state => state.modal.createOffer);
    const { showOfferModal, hideOfferModal } = useModal(CREATE_OFFER_MODAL);
    const userData = useSelector(state => state.user);
    const [mediaArray, setMediaArray] = useState([]);
    const [exposureNumber, setExposureNumber] = useState(0);

    const [cpm, setCpm] = useState(0);
    const { data, refetch, isFetched, isLoading } = useQuery(
        ['getProjectDetail', CreateOfferState.projectId],
        () => getProjectDetail({ userId: userData.id, projectId: CreateOfferState.projectId }),
        { enabled: false }
    );

    useEffect(() => {
        if (CreateOfferState.status) {
            refetch();
        }
    }, [CreateOfferState]);

    const appendMedia = event => {
        let array = [...mediaArray];

        if (!array.find(element => element === parseInt(event.target.value)) && event.target.value !== 0) {
            array.push(parseInt(event.target.value));
            setMediaArray(() => array);
        }
    };

    const removeMedia = event => {
        let array = [...mediaArray];
        let filtered = array.filter(element => element !== parseInt(event.target.value));
        setMediaArray(filtered);
    };

    const dispatch = useDispatch();

    const hideModal = () => {
        const beforeData = { ...CreateOfferState };
        dispatch(
            setOfferModal({
                type: CREATE_OFFER_MODAL,
                data: {
                    ...beforeData,
                    status: false,
                },
            })
        );
    };

    const closeModal = () => {
        dispatch(
            setOfferModal({
                type: CREATE_OFFER_MODAL,
                data: {
                    status: false,
                    projectId: 0,
                    portfolio: {},
                },
            })
        );
    };

    const showModal = body => {
        dispatch(
            setOfferModal({
                type: CREATE_OFFER_DETAIL_MODAL,
                data: {
                    status: true,
                    body: body,
                    data: data,
                },
            })
        );
        hideModal();
    };

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        /* test 기본값 */
        defaultValues: {},
    });

    const getAdPostingCountryString = array => {
        let ret = '';

        if (array) {
            for (let index = 0; index < array.length; index++) {
                ret += array[index].label;
                if (index < array.length - 1) {
                    ret += ', ';
                }
            }
            return ret;
        }
    };
    const onSubmit = result => {
        if (mediaArray.length === 0) {
            alert('미디어를 선택해주세요.');
            return;
        }

        const body = {
            project: CreateOfferState.projectId,
            posting_start: result.posting_start,
            posting_end: result.posting_end,
            portfolio: CreateOfferState.portfolio.id,
            exposure_number: result.exposure_number,
            cpm: result.cpm,
            exposure_medias: mediaArray,
            special_condition: result.special_condition,
        };
        showModal(body);
    };

    if (isLoading) <Loading />;

    return CreateOfferState.status ? (
        <div className="modal">
            <div className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="header">
                        <h4>Create offer</h4>
                    </div>
                    <i onClick={hideModal} className="icon-cancel"></i>
                    <div className="input-box">
                        <h5>Basic information</h5>
                        <div className="input-group ">
                            <label>Project title</label>
                            <input type="text" defaultValue={data?.title} disabled />
                        </div>
                        <div className="input-group ">
                            <label>Selected photo</label>
                            <input
                                type="text"
                                className="fileUp"
                                placeholder={decodeURI(getFileName(CreateOfferState.portfolio.file))}
                                disabled
                            />
                            <img
                                style={{
                                    display: 'block',
                                    float: 'right',
                                    width: '60px',
                                    color: '#a6adb4',
                                    marginTop: '-10px',
                                    cursor: 'pointer',
                                }}
                                src={CreateOfferState.portfolio.file}
                            />
                            {/*<i className="icon-picture"></i>*/}
                        </div>
                        <div className="input-group">
                            <label>Posting date (from)</label>
                            <input {...register('posting_start')} type="date" />
                            {/*<div className="selectGroup">
                                <SelectYear formName="fromYear" register={register} endYear={10} addYear={0} />
                                
                                <SelectMonth formName="fromMonth" register={register} />
                                
                                <SelectDay formName="fromDay" register={register} />
                            </div>*/}
                        </div>
                        <div className="input-group">
                            <label>Date (to)</label>
                            <input {...register('posting_end')} type="date" />
                            {/*<div className="selectGroup">
                                <SelectYear formName="toYear" register={register} endYear={10} addYear={1} />

                                <SelectMonth formName="toMonth" register={register} addMonth={1} />

                                <SelectDay formName="toDay" register={register} />
                            </div>*/}
                        </div>
                        <div className="input-group-full">
                            <label>Ad posting country</label>
                            <input
                                type="text"
                                placeholder={getAdPostingCountryString(data?.ad_posting_countries)}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="input-box">
                        <h5>Posting ad & Estimating cost</h5>
                        <div className="input-group ">
                            <label>
                                Exposure number
                                <input
                                    {...register('exposure_number', { valueAsNumber: true, required: true })}
                                    onChange={event => setExposureNumber(event.target.value)}
                                    required="required"
                                    type="number"
                                    placeholder=""
                                />
                            </label>
                        </div>
                        <div className="input-group ">
                            <label>CPM</label>
                            <input
                                {...register('cpm', { valueAsNumber: true, required: true })}
                                onChange={event => setCpm(event.target.value)}
                                required="required"
                                type="number"
                                placeholder=""
                            />
                        </div>
                        <ExposureMedias mediaArray={mediaArray} appendMedia={appendMedia} removeMedia={removeMedia} />
                        <div className="input-group ">
                            <label>
                                Estimated cost <span>(CPM/1,000 X Exposure number)</span>
                                {/*Estimated revenue <span>(CPM/1,000 X Exposure number)</span>*/}
                            </label>
                            <input
                                type="text"
                                className="calculate"
                                placeholder={
                                    cpm && exposureNumber
                                        ? ((cpm / 1000) * exposureNumber).toLocaleString()
                                        : '* Calculates automatically'
                                }
                                disabled
                            />
                            {/* <input type="text" className="calculate" placeholder="* Calculates automatically" disabled /> */}
                        </div>
                    </div>
                    <div className="input-box">
                        <h5>Special condition</h5>
                        <div className="input-group-full">
                            <textarea {...register('special_condition')}></textarea>
                            <button type="submit" className="save">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default React.memo(CreateOffer);
