import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import { confirmOffer, getOfferImage } from '../../queries/ConfirmOffer';
import { sendMessage } from '../../queries/MessageList';
import { patchContractStatus } from '../../queries/PatchContractStatus';
import Carousel from '../model/Carousel';

function ConfirmContents(props) {
    const { userId, contractId } = props;

    const [imgCount, setImgCount] = useState(0);
    const [current, setCurrent] = useState(0);
    const [style, setStyle] = useState({
        marginLeft: `-${current * 616}px`,
    });

    const mutation = useMutation(async body => confirmOffer({ mediaId: data[current].id, body: body }), {
        onSuccess: async res => {
            if (res.status === 'Confirmed') {
                sendMessage({
                    contractId: contractId,
                    userId: userId,
                    data: {
                        content: 'Content has accepted',
                    },
                });
                patchContractStatus({ contractId: contractId, status: 'Posting' });
            } else if (res.status === 'Rejected') {
                sendMessage({
                    contractId: contractId,
                    userId: userId,
                    data: {
                        content: 'Content has rejected',
                    },
                });
            }
        },
        onError: err => {
            switch (err.response.status) {
                case 401:
                    console.log(err);
                    return;
                case 301:
                    console.log(err);
                    return;
                default:
                    console.log(err);
                    return;
            }
        },
    });

    const { data, isLoading, isError, error } = useQuery(['getOfferImage'], () =>
        getOfferImage({ contractId: contractId })
    );

    const confirmed = () => {
        const body = {
            status: 'Confirmed',
        };
        mutation.mutate(body);
    };

    const rejected = () => {
        const body = {
            status: 'Rejected',
        };
        mutation.mutate(body);
    };

    useEffect(() => {
        setStyle({ marginLeft: `-${current * 616}px` });
    }, [current]);

    useEffect(() => {
        if (data) {
            setImgCount(data.length);
        }
    }, [data]);

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <>
            <div className="modal-tab-container">
                <div className="modal-tab-content" id="confirmTab">
                    <Carousel images={data} imageCount={3} mainWidth={600} mainHeight={400} />
                </div>
            </div>
            <div className="btn-group-modal">
                <button onClick={() => confirmed()} className="btn btn-alt-primary mt-30 mr-30">
                    Confirm content
                </button>
                <button onClick={() => rejected()} className="btn btn-outline-primary">
                    {/*Reject content*/}
                    Reject All
                </button>
            </div>
        </>
        //<>
        //    <div className="modal-tab-container">
        //        <div className="modal-tab-content" id="confirmTab">
        //            <div className="upload-content">
        //                <img
        //                    className="arrow-left"
        //                    src="/images/icon/arrow-white-left.svg"
        //                    alt=""
        //                    onClick={() => setCurrent(current - 1)}
        //                    style={{ display: current === 0 ? 'none' : 'block' }}
        //                />
        //                <img
        //                    className="arrow-right"
        //                    src="/images/icon/arrow-white-right.svg"
        //                    alt=""
        //                    onClick={() => setCurrent(current + 1)}
        //                    style={{ display: current === imgCount - 1 ? 'none' : 'block' }}
        //                />
        //                <div className="flexbox" style={style}>
        //                    {data.map((content, index) => (
        //                        <img key={index} src={content.portfolio.file} />
        //                        // <div
        //                        //     className="img"
        //                        //     key={index}
        //                        //     style={{ backgroundImage: `url("${content.portfolio.file}")` }}
        //                        // ></div>
        //                    ))}
        //                </div>
        //                {/*{data.portfolio.file ? <img style={{ width: '300px' }} src={data.portfolio.file}></img> : <></>}*/}
        //            </div>
        //        </div>
        //    </div>
        //</>
    );
}

export default ConfirmContents;
