import React from 'react';
import ContractProjectModal from '../modal/ContractProjectModal';
//import ModalCreateProject from '../modal/ModalCreateProject';
//import TestButton from '../../components/styled-components/TestButton';
import ContractOfferModal from '../modal/ContractOfferModal';
import ForgotPassword from '../modal/ForgotPassword';
import Alert from '../modal/Alert';

const Modals = () => {
    return (
        <>
            <ContractProjectModal />
            <ContractOfferModal />
            {/*<ModalCreateProject />*/}
            <ForgotPassword />
            <Alert />
            {/*<TestButton />*/}
        </>
    );
};

export default Modals;
