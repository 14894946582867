import React from 'react';
import styled from 'styled-components';

const CelebDetailSectionStyle = styled.section`
    background-color: ${props => props.backgroundColor};
    padding: 100px;
    h1 {
        color: ${props => props.fontColor};
        font-family: Inter;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.5rem;
        margin-bottom: 68px;
    }
`;

const CelebDetailSection = props => {
    const { fontColor, backgroundColor, title, children } = props;
    return (
        <CelebDetailSectionStyle fontColor={fontColor} backgroundColor={backgroundColor}>
            <h1>{title}</h1>
            {children}
        </CelebDetailSectionStyle>
    );
};

export default CelebDetailSection;
