import React from 'react';
import Breadcrumb from '../header/Breadcrumb';

const RegisterModelPage = () => {
    const onClick = () => {
        window.location.href = '/my-celebs/create-celeb';
    };

    return (
        <>
            <div className="row px-60">
                <Breadcrumb value={['Celebs', 'My celebs']} />
                <h1 className="border-bottom pb-20">My celebs</h1>
            </div>
            <div className="row pt-100">
                <h3 className="font-size-h5 text-center">Register celeb and get profit!</h3>
                <div className="input-group pt-30 text-center">
                    <button type="button" onClick={onClick} className="btn-primary btn-create-project">
                        Register Celeb
                    </button>
                </div>
            </div>
        </>
    );
};

export default RegisterModelPage;
