import React from 'react';
import CelebDetailSection from './CelebDetailSection';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

//const data = {
//    highlight: {
//        text: `## TV Series
//\n-   **Dr. Romantic 3** (role of “Cha Eun-jae”) 2023 - *Disney*
//\n-   **Call it Love** (role of “Shim Woo-joo”) 2023 - Disney
//\n-   **Dr. Romantic 2** (role of “Cha Eun-jae”) 2022 - Netflix
//\n-   **Dr. Romantic 2** (role of “Cha Eun-jae”) 2022 - Netflix
//\n## Film
//\n-   **Dr. Romantic 3** (role of “Cha Eun-jae”) 2023
//\n-   **Call it Love** (role of “Shim Woo-joo”) 2023
//\n-   **Dr. Romantic 2** (role of “Cha Eun-jae”) 2022
//\n-   **Dr. Romantic 2** (role of “Cha Eun-jae”) 2022
//\n## Web / Music Video / Host
//\n-   **Dr. Romantic 3** (role of “Cha Eun-jae”) 2023
//\n-   **Call it Love** (role of “Shim Woo-joo”) 2023`,
//        images: [
//            'https://aligo-acp.s3.amazonaws.com/dev/celeb/portfolio/2.jpeg',
//            'https://aligo-acp.s3.amazonaws.com/dev/celeb/portfolio/2.jpeg',
//            'https://aligo-acp.s3.amazonaws.com/dev/celeb/portfolio/2.jpeg',
//            'https://aligo-acp.s3.amazonaws.com/dev/celeb/portfolio/2.jpeg',
//            'https://aligo-acp.s3.amazonaws.com/dev/celeb/portfolio/2.jpeg',
//            'https://aligo-acp.s3.amazonaws.com/dev/celeb/portfolio/2.jpeg',
//        ],
//    },
//};

const HighlightsStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .markdown {
        h3 {
            margin: 32px 0 16px;
            color: var(--gray-900, #252525);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 100% */
        }
        ul {
            li {
                margin-left: 20px;
                color: var(--gray-900, #252525);
                font-family: Inter;
                font-size: 1.5rem;
                font-style: normal;
                line-height: 3rem; /* 200% */
            }
        }
    }
    .youtube-video {
        width: 700px;
        position: relative;
        top: -100px;
    }
    .images {
        gap: 28px 32px;
        display: grid;
        grid-template-columns: repeat(3, 180px);
        grid-template-rows: repeat(2, 240px);
        .image {
            width: 180px;
            height: 240px;
            overflow: hidden;
            border-radius: 15px;
            box-shadow: 0px 0px 10px 0px #d9d9d9;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;

const Highlights = props => {
    const { highlightsData, youtubeUrl } = props;

    return (
        <CelebDetailSection fontColor="var(--gray-900)" backgroundColor="var(--gray-50)" title="Highlights">
            <HighlightsStyle>
                <div className="markdown">
                    <ReactMarkdown>{highlightsData.text}</ReactMarkdown>
                </div>
                {youtubeUrl ? (
                    <div className="youtube-video">
                        <iframe
                            width="700"
                            height="400"
                            src={youtubeUrl}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                    </div>
                ) : (
                    <></>
                )}
                {/*<div className="images">*/}
                {/*{highlightsData.images.map((image, index) => (
                        <div key={index} className="image">
                            <img src={image} />
                        </div>
                    ))}*/}
                {/*<div className="image">a</div>
                    <div className="image">a</div>
                    <div className="image">a</div>
                    <div className="image">a</div>
                    <div className="image">a</div>
                    <div className="image">a</div>*/}
                {/*</div>*/}
            </HighlightsStyle>
        </CelebDetailSection>
    );
};

export default Highlights;
