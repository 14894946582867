import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

function ModelImg(props) {
    const { modelPhotos, imgIndex, modelId, showAlertModal } = props;
    const userData = useSelector(state => state.user);

    const sliderRef = useRef(null);
    useEffect(() => {
        sliderRef.current.style.transition = 'all 0.5s ease-in-out';
        sliderRef.current.style.transform = `translateX(-${imgIndex}00%)`;
    }, [imgIndex]);

    const onClick = () => {
        if (userData.id) {
            window.location.href = `/celebs/${modelId}`;
        } else {
            showAlertModal();
        }
    };

    return (
        <div ref={sliderRef} className="imgList">
            <div id="slider-container" className="slider-container-transition">
                {modelPhotos.map((photo, index) => (
                    <img key={index} src={photo.file} onClick={onClick} style={{ cursor: 'pointer' }} />
                ))}
            </div>
        </div>
    );
}

export default ModelImg;
