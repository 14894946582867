import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';

function Charts(props) {
    const [state, setState] = useState();

    useEffect(() => {
        setState({
            series: [
                {
                    name: 'Series 1',
                    data: [
                        props.data[0].value,
                        props.data[1].value,
                        props.data[5].value,
                        props.data[4].value,
                        props.data[3].value,
                        props.data[2].value,
                    ],
                },
            ],
            options: {
                xaxis: {
                    categories: [
                        props.data[0].name,
                        props.data[1].name,
                        props.data[5].name,
                        props.data[4].name,
                        props.data[3].name,
                        props.data[2].name,
                        //props.data[8].name,
                        //props.data[5].name,
                        //'feature 6',
                        //'feature 5',
                        //'feature 4',
                        //props.data[4].name,
                    ],
                    labels: {
                        style: {
                            colors: ['#000', '#000', '#000', '#000', '#000', '#000'],
                            fontSize: props.fontSize,
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        formatter: function (val, i) {
                            return '';
                        },
                    },
                },
                plotOptions: {
                    radar: {
                        size: props.size,
                        polygons: {
                            strokeWidth: props.strokeWidth,
                            strokeColors: '#202124',
                            connectorColors: '#202124',
                            fill: {
                                colors: ['#fff', '#fff'],
                            },
                        },
                    },
                },
                colors: props.fillColor,
                stroke: {
                    show: true,
                    curve: 'stepline',
                    lineCap: 'butt',
                    colors: props.strokeColor,
                    width: 1,
                    dashArray: 0,
                },
                markers: {
                    size: 0,
                },
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [],
                        },
                    },
                },
            },
        });
    }, [props]);

    if (!state) return <></>;
    return (
        <div className="chart">
            <Chart options={state.options} series={state.series} type="radar" width={'100%'} height={props.height} />
        </div>
    );
}

export default Charts;
