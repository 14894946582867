import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    position: relative;
    user-select: none;
    overflow: hidden;
    .arrow-box {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .contents {
        display: flex;
        justify-content: left;
        width: ${props => props.length * 100 + '%'};
        transition: all 0.4s ease-in-out;
        transform: translateX(${props => props.index * (-100 / props.length) + '%'});
    }
`;

const IndicatorWrapper = styled.div`
    position: absolute;
    right: 32px;
    bottom: 32px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    .indicator {
        cursor: pointer;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: var(--gray-700);
        &:hover {
            background-color: var(--purple-300);
        }
        &.selected {
            background-color: var(--purple-300);
        }
    }
`;

const ArrowIcon = styled.button`
    font-size: 6rem;
    color: ${props => (props.disabled ? 'var(--gray-500)' : 'var(--gray-050)')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    z-index: 10;
`;

export const CarouselTimer = props => {
    const { children, width, height, time = 3000 } = props;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [arrowStatus, setArrowStatus] = useState({
        leftArrow: false,
        rightArrow: false,
    });
    const length = children.length;

    useEffect(() => {
        setArrowStatus(prevArrowStatus => ({
            ...prevArrowStatus,
            leftArrow: currentIndex === 0,
            rightArrow: currentIndex === length - 1,
        }));

        // 클릭 이벤트에 따라 인터벌 잠시 멈춤
        const intervalId = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % length);
        }, time);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentIndex, length]);

    const onClickLeftArrow = e => {
        const nextIndex = currentIndex - 1;

        if (nextIndex >= 0) {
            setCurrentIndex(nextIndex);
        }
        e.stopPropagation();
    };

    const onClickRightArrow = e => {
        const nextIndex = currentIndex + 1;

        if (nextIndex < length) {
            setCurrentIndex(nextIndex);
        }
        e.stopPropagation();
    };

    return (
        <Container width={width} height={height} length={length} index={currentIndex}>
            <ul className="contents">{children}</ul>
            <div className="arrow-box">
                <ArrowIcon
                    type="button"
                    className="arrow material-icons"
                    onClick={e => onClickLeftArrow(e)}
                    disabled={arrowStatus.leftArrow}
                >
                    navigate_before
                </ArrowIcon>
                <ArrowIcon
                    type="button"
                    className="arrow material-icons"
                    onClick={e => onClickRightArrow(e)}
                    disabled={arrowStatus.rightArrow}
                >
                    navigate_next
                </ArrowIcon>
            </div>
            <IndicatorWrapper>
                {children.map((_, index) => (
                    <span
                        key={'indicator_' + index}
                        onClick={() => setCurrentIndex(index)}
                        className={`indicator ${index === currentIndex ? 'selected' : ''}`}
                    />
                ))}
            </IndicatorWrapper>
        </Container>
    );
};

const Carousel = props => {
    const { children, width, height } = props;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [arrowStatus, setArrowStatus] = useState({
        leftArrow: false,
        rightArrow: false,
    });
    const length = children.length;

    useEffect(() => {
        setArrowStatus(prevArrowStatus => ({
            ...prevArrowStatus,
            leftArrow: currentIndex === 0,
            rightArrow: currentIndex === length - 1,
        }));
    }, [currentIndex]);

    const onClickLeftArrow = e => {
        const nextIndex = currentIndex - 1;

        if (nextIndex >= 0) {
            setCurrentIndex(nextIndex);
        }
        e.stopPropagation();
    };

    const onClickRightArrow = e => {
        const nextIndex = currentIndex + 1;

        if (nextIndex < length) {
            setCurrentIndex(nextIndex);
        }
        e.stopPropagation();
    };

    return (
        <Container width={width} height={height} length={length} index={currentIndex}>
            <ul className="contents">{children}</ul>
            <div className="arrow-box">
                <ArrowIcon
                    type="button"
                    className="arrow material-icons"
                    onClick={e => onClickLeftArrow(e)}
                    disabled={arrowStatus.leftArrow}
                >
                    navigate_before
                </ArrowIcon>
                <ArrowIcon
                    type="button"
                    className="arrow material-icons"
                    onClick={e => onClickRightArrow(e)}
                    disabled={arrowStatus.rightArrow}
                >
                    navigate_next
                </ArrowIcon>
            </div>
            <IndicatorWrapper>
                {children.map((_, index) => (
                    <span
                        key={'indicator_' + index}
                        onClick={() => setCurrentIndex(index)}
                        className={`indicator ${index === currentIndex ? 'selected' : ''}`}
                    />
                ))}
            </IndicatorWrapper>
        </Container>
    );
};

export default Carousel;
