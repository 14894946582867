import React from 'react';
import { LargeContainer } from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import Contact from '../components/contact/Contact';

const ContactPage = () => {
    return (
        <Layout>
            <LargeContainer>
                <Contact />
            </LargeContainer>
        </Layout>
    );
};

export default ContactPage;
