import axios from 'axios';
import { privateClient, publicClient } from '../utils/client';

export const getNews = async () => {
    const response = await privateClient.get(`/api/v1/landing/news/`);

    return response.data.data;
};

export const getRanking = async () => {
    const response = await privateClient.get(`/api/v1/landing/ranking/`);

    return response.data.data;
};

export const getHottest = async () => {
    const response = await privateClient.get(`/api/v1/landing/hottest/`);

    return response.data.data;
};

export const getLabs = async () => {
    const response = await privateClient.get(`/api/v1/landing/labs/`);

    return response.data.data;
};

export const getBanner = async () => {
    const response = await publicClient.get(`/api/v1/landing/banner/`);

    return response.data.data;
};

export const getReference = async () => {
    const response = await publicClient.get(`/api/v1/landing/reference/`);

    return response.data.data;
};
