import React from 'react';

const Breadcrumb = props => {
    const arrLink = props.value;

    return (
        <ul className="breadcrumb">
            <li>
                <i className="icon-home">&#xe80a;</i>
            </li>
            <li>{arrLink.join(' / ')}</li>
        </ul>
    );
};

Breadcrumb.defaultProps = {
    value: ['Service'],
};
export default Breadcrumb;
