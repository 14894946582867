import axios from 'axios';
import { publicClient } from '../utils/client';

export const cutImage = async data => {
    const response = await publicClient.post('/api/v1/samples/cutout/', data);

    return response.data;
};

export const createSample = async data => {
    const response = await publicClient.post('/api/v1/samples/results/', data);

    return response.data;
};

export const recommendCeleb = async data => {
    const response = await publicClient.post('/api/v1/samples/celebs/', data);

    return response.data;
};

export const registerUser = async data => {
    const response = await publicClient.post('/api/v1/users/email/', data);

    return response.data;
};
