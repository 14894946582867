import axios from 'axios';
import { privateClient } from '../utils/client';

let result = [
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 11,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 4,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 5,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 6,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 7,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 8,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 9,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 10,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 11,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 12,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 13,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 14,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 15,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 16,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 17,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 18,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 11,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 19,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 20,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 21,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 22,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 23,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
    {
        id: 4,
        user: 9,
        portfolio: {
            id: 24,
            is_main: false,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            file_w256: 'str',
            file_w512: 'str',
            type: 'str',
            heart_count: 3,
            heart_id_of_login_user: 3,
            click: 3,
            celeb: 3,
            ctr: 0.3,
            features: [
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
                {
                    id: 0,
                    name: 'str',
                    value: 0.3,
                },
            ],
        },
    },
];

export const getHeartList = async () => {
    //const response = await privateClient.get('/celeb/hearts/');
    //return response.data;
    return [];
    //return result;
};

export const addHeartList = async ({ userId, portfolioId }) => {
    //const body = {
    //    user: userId,
    //    portfolio: portfolioId,
    //};

    //const response = await privateClient.post('/celeb/heart/', body);
    //return response.data;
    return result;
};

export const removeHeartList = async ({ userId, portfolioId }) => {
    console.log(portfolioId);
    result = result.filter(element => element.portfolio.id != portfolioId);
    //const body = {
    //    user: userId,
    //    portfolio: portfolioId,
    //};
    //const response = await privateClient.delete('/celeb/heart/', body);
    //return response.data;
    return result;
};
