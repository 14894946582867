import React from 'react';
import styled from 'styled-components';
import Section from './layout/Section';
import { InputGroup } from './input/Input';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { AddMailing } from '../queries/Register';
import { useModal } from '../modules/modal/ModalUtils';
import { ALERT_MODAL } from '../modules/modal/api/action';

const schema = yup
    .object({
        name: yup.string().label('Name').required(),
        email: yup.string().label('Email').required(),
    })
    .required();

const Title = styled.h2`
    text-align: center;
    color: var(--gray-900);
    margin-bottom: 20px;
    font-family: Larken;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4.8rem;
`;

const Description = styled.p`
    color: var(--gray-600, #7a7a7a);
    text-align: center;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem; /* 150% */
    margin-bottom: 32px;
`;

const InputArea = styled.span`
    display: flex;
    justify-content: center;
    gap: 24px;
`;

const Button = styled.button`
    display: block;
    width: 864px;
    height: 60px;
    background: var(--gradient-06);
    color: var(--gray-050);
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem; /* 19.2px */
    border-radius: 4px;
    margin: 0 auto;
`;

const Mailing = () => {
    const { showModal, hideModal } = useModal(ALERT_MODAL);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const mutate = useMutation(async params => AddMailing(params), {
        onSuccess: res => {
            showModal({
                title: 'Thank you',
                content: 'The form has been submitted successfully and\na representative will get in touch with you',
                buttonText: 'Close',
                buttonFunction: hideModal,
            });
            reset();
        },
        onError: err => {
            console.log(err);
        },
    });

    const onSubmit = data => {
        mutate.mutate(data);
    };

    return (
        <Section pt={120} pb={120} bgColor="var(--gray-100)">
            <Title>Join our Mailing List</Title>
            <Description>
                Please provide us with your work email, if you would like to learn more about how
                <br />
                celevu's photo stock services can elevate your advertising campaigns.
            </Description>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputArea>
                    <InputGroup
                        register={register}
                        type="text"
                        color="primary"
                        size="base"
                        placeholder="name"
                        name="name"
                        label="Name"
                        error={errors.name ? errors.name : undefined}
                        option={{ required: true }}
                    />
                    <InputGroup
                        register={register}
                        type="text"
                        color="primary"
                        size="base"
                        placeholder="email@aligo.ai"
                        name="email"
                        label="Email"
                        error={errors.email ? errors.email : undefined}
                        option={{ required: true }}
                        style={{ textTransform: 'lowercase' }}
                    />
                </InputArea>
                <Button type="submit">Submit</Button>
            </form>
        </Section>
    );
};

export default Mailing;
