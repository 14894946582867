import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import * as yup from 'yup';
import { deletePortfolio } from '../../queries/CreatePortfolio';
import { getModelsDetail } from '../../queries/GetModelsDetail';
import Breadcrumb from '../header/Breadcrumb';
import ContractList from './ContractList';
import ImageList from './ImageList';
import PopularPhotos from './PopularPhotos';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Filmography from './Filmography';
import { getYears } from '../../utils/common';
import { PatchCeleb } from '../../queries/CreateCeleb';

const schema = yup
    .object({
        //pen_name: yup.string().required('pen name은 필수입니다'),
        //family_name: yup.string().required(''),
        //given_name: yup.string().required(''),
        //gender: yup.number().required(''),
        //birth_year: yup.number().required(''),
        //contract_term: yup.string().required(''),
        //email: yup.string().required(''),
        //cpm: yup.number().required(''),
        //minimum_amount: yup.number().required(''),
        //description: yup.string().required(''),
        //performance: yup.string().required(''),
    })
    .required();

function ModelsDetail() {
    const celebId = useParams().id;
    const userData = useSelector(state => state.user);
    const [date, setDate] = useState('');
    const [photoListIndex, setPhotoListIndex] = useState(0);
    const { data, isLoading, isError, error } = useQuery(
        ['getModelsDetail'],
        () => getModelsDetail({ userId: userData.id, celebId: celebId }),
        {
            onSuccess: data => {
                setDate(data.contract_term);
            },
            refetchOnMount: true,
        }
    );

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            //gender: 0,
            //birth_year: 1990,
        },
    });

    const mutation = useMutation(async data => PatchCeleb({ celebId: celebId, data: data }), {
        onSuccess: res => {
            window.location.href = '/my-celebs';
        },
        onError: err => {
            switch (err.response.status) {
                case 400:
                    console.log('400', err);
                    return;
                case 301:
                    console.log('301', err);
                    return;
                case 201:
                    console.log('201', err);
                    return;
                default:
                    console.log('default', err);
                    return;
            }
        },
    });

    const onSubmit = result => {
        const registerBody = {
            pen_name: result.pen_name,
            family_name: result.family_name,
            given_name: result.given_name,
            //gender: result.gender,
            birth_year: result.birth_year,
            contract_term: result.contract_term,
            email: result.email,
            cpm: result.cpm,
            minimum_amount: result.minimum_amount,
            social_media: {
                youtube: result.youtube,
                instagram: result.instagram,
                twitter: result.twitter,
                tiktok: result.tiktok,
                etc: result.etc,
            },
            description: result.description,
            filmography: result.filmography,
            performance: '',
        };
        // performance: result.performance,
        //if (window.confirm('모델을 등록 하시겠습니까?')) {
        //}
        //mutation.mutate(registerBody);
    };

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    const removePhoto = portfolioId => {
        //deletePortfolio(portfolioId);
    };

    return (
        <div className="row px-60">
            <Breadcrumb value={['My celebs', 'Register celeb']} />
            <h1 className="fl mb-20">{data.pen_name}</h1>
            <div className="grid-container border-top-black">
                <ContractList celebId={celebId} />
                {/*<PopularPhotos />*/}
                <div className="infomation border-top">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="info">
                            <h4>Basic information</h4>
                            <span className="right mr-10">
                                View less
                                <i className="icon-right-open"></i>
                            </span>
                            <div className="row-col">
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Pen name</label>
                                        <input {...register('pen_name')} type="text" defaultValue={data.pen_name} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Last name</label>
                                        <input
                                            {...register('family_name ')}
                                            type="text"
                                            defaultValue={data.family_name}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>First name</label>
                                        <input {...register('given_name')} type="text" defaultValue={data.given_name} />
                                    </div>
                                </div>
                            </div>
                            <div className="row-col">
                                <div className="col-2">
                                    <div className="input-group sm">
                                        <label>Gender</label>
                                        <select
                                            defaultValue={data.gender.id}
                                            {...register('gender', { valueAsNumber: true })}
                                            className="lg"
                                        >
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Nonbinary</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="input-group">
                                        <label>Year of birth</label>
                                        <select
                                            defaultValue={data.birth_year}
                                            {...register('birth_year', { valueAsNumber: true })}
                                        >
                                            {getYears().map((year, index) => (
                                                <option key={index} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Contract term</label>
                                        <input type="date" onChange={e => setDate(e.target.value)} value={date} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Email</label>
                                        <input {...register('email')} type="text" defaultValue={data.email} />
                                    </div>
                                </div>
                            </div>
                            <div className="row-col">
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>CPM</label>
                                        <input
                                            {...register('cpm', { valueAsNumber: true })}
                                            type="number"
                                            step={0.1}
                                            defaultValue={data.cpm}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Minimum amount</label>
                                        <input
                                            {...register('minimum_amount', { valueAsNumber: true })}
                                            type="number"
                                            defaultValue={data.minimum_amount}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info border-top">
                            <h4>Social media</h4>
                            <span className="right  mr-10">
                                View less
                                <i className="icon-right-open"></i>
                            </span>
                            <div className="row-col">
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Youtube</label>
                                        <input
                                            {...register('youtube')}
                                            type="text"
                                            defaultValue={data.social_media?.youtube}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Instagram</label>
                                        <input
                                            {...register('instagram')}
                                            type="text"
                                            defaultValue={data.social_media?.instagram}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>Twitter</label>
                                        <input
                                            {...register('twitter')}
                                            type="text"
                                            defaultValue={data.social_media?.twitter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row-col">
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>TikTok</label>
                                        <input
                                            {...register('tiktok')}
                                            type="text"
                                            defaultValue={data.social_media?.tiktok}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <label>etc</label>
                                        <input {...register('etc')} type="text" defaultValue={data.social_media?.etc} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info border-top">
                            <h4>Portfolio</h4>
                            <span className="right  mr-10">
                                View less
                                <i className="icon-right-open"></i>
                            </span>
                            <div className="row-col">
                                <div className="col-2">
                                    <div className="input-group-image">
                                        <label>
                                            Main photo<span>jpg, png, jpeg</span>
                                        </label>
                                        <div className="image-box">
                                            <button type="button" className="btn-upload">
                                                Upload image
                                            </button>
                                            <i className="icon-cancel"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-col col-10">
                                    <div className="col-1 text-right arrow-center">
                                        <i className="icon-left-open i-lg"></i>
                                    </div>
                                    <div className="col-10">
                                        <div className="input-group-image">
                                            <div className="text-right">
                                                <button type="button" className="btn-inner-upload">
                                                    Upload image
                                                </button>
                                            </div>
                                            <div className="image-list">
                                                <ImageList
                                                    list={data.photos}
                                                    currentIndex={photoListIndex}
                                                    removeItem={removePhoto}
                                                />
                                                {/*<div className="image-box">
                                                <i className="icon-cancel"></i>
                                            </div>
                                            <div className="image-box">
                                                <i className="icon-cancel"></i>
                                            </div>
                                            <div className="image-box">
                                                <i className="icon-cancel"></i>
                                            </div>
                                            <div className="image-box">
                                                <i className="icon-cancel"></i>
                                            </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 text-left arrow-center">
                                        <i className="icon-right-open i-lg"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="row-col">
                                <div className="col-12">
                                    <div className="input-group">
                                        <label>
                                            Description
                                            <span className="sub-title">Max 100 characters</span>
                                        </label>
                                        <textarea
                                            {...register('description')}
                                            rows={3}
                                            defaultValue={data.description}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="info border-top border-bottom">
                                <h4>Filmography</h4>
                                <Filmography register={register} filmographys={data.filmography} />
                            </div>
                        </div>
                        {/*<div className="info border-top">
                            <h4>Cost per mille</h4>
                            <span className="ml-10">Cost per 1,000 exposure of your portrait</span>

                            <div className="row-col">
                                <div className="col-2">
                                    <div className="input-group">
                                        <label>Minimum</label>
                                        <input
                                            className="sm"
                                            type="number"
                                            placeholder="$ 3"
                                            defaultValue={data.cpm_min}
                                        />
                                        <span className="to">to</span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group">
                                        <label>Maximum</label>
                                        <input
                                            className="sm"
                                            type="number"
                                            placeholder="$ 3"
                                            defaultValue={data.cpm_max}
                                        />
                                        <span className="bold">X 1,000 = </span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group left">
                                        <label className="expect">Expection</label>
                                        <span className="sub-label">Minimum</span>
                                        <span className="money">
                                            $ 3,000<span>to</span>
                                        </span>
                                    </div>
                                    <div className="input-group left">
                                        <label className="expect"> &nbsp;</label>
                                        <span className="sub-label">Minimum</span>
                                        <span className="money">$ 3,000</span>
                                    </div>
                                </div>
                                <div className="col-3 pl-30 pt-10 border-left-black">
                                    <ul>
                                        <li>Average(1yr)</li>
                                        <li>No experience: Max $800</li>
                                        <li>Less 5 portfolio: Max $1,500</li>
                                    </ul>
                                </div>
                            </div>
                        </div>*/}
                        {/*<div className="info border-top">
                        <h4>Signature</h4>
                        <span className="right">
                            View less
                            <i className="icon-right-open"></i>
                        </span>
                    </div>*/}
                        <div className="btn-group clearfix mt-50">
                            <button type="submit" className="btn btn-alt-primary w-400">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModelsDetail;
