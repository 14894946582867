import React from 'react';
import styled from 'styled-components';

const SectionStyle = styled.section`
    ${props => (props.bgColor ? `background-color: ${props.bgColor}` : 'none')};
    ${props => (props.bgUrl ? `background-image: url(${props.bgUrl})` : '')};
    background-repeat: no-repeat;
    background-position: center top;
    margin: ${props => props.margin};
    margin-left: ${props => props.ml}px;
    margin-top: ${props => props.mt}px;
    margin-right: ${props => props.mr}px;
    margin-bottom: ${props => props.mb}px;
    padding: ${props => props.padding};
    padding-left: ${props => props.pl}px;
    padding-top: ${props => props.pt}px;
    padding-right: ${props => props.pr}px;
    padding-bottom: ${props => props.pb}px;
`;

const Contents = styled.div`
    width: ${props => (props.width ? props.width : 1440)}px;
    /*overflow: hidden;*/
    margin: 0 auto;
`;

const Section = props => {
    const {
        bgColor,
        bgUrl,
        width = 1440,
        margin,
        ml,
        mt,
        mr,
        mb,
        padding,
        pl,
        pt,
        pr,
        pb,
        children,
        ...options
    } = props;

    return (
        <SectionStyle
            bgColor={bgColor}
            bgUrl={bgUrl}
            margin={margin}
            ml={ml}
            mt={mt}
            mr={mr}
            mb={mb}
            padding={padding}
            pl={pl}
            pt={pt}
            pr={pr}
            pb={pb}
            {...options}
        >
            <Contents width={width}>{children}</Contents>
        </SectionStyle>
    );
};

export default Section;
