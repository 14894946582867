import React from 'react';
import Button from '../styled-components/Button';
import styled from 'styled-components';
import Facebook from '../api/Facebook';

const Wrapper = styled.div`
    margin-top: 180px;
    text-align: center;
    h2 {
        color: var(--grey-grey-900, #252525);
        text-align: center;

        /* Headline1/Larken */
        font-family: Larken;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 48px */
        margin-bottom: 20px;
    }
    h4 {
        color: var(--grey-grey600, #7a7a7a);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 40px;
    }
`;

const Instagram = () => {
    return (
        <Wrapper>
            <img src="/svg/instagram-link.svg" height="62px"></img>
            <h2>Link your Instagram business account.</h2>
            <h4>
                Please link your Instagram business account.
                <br />
                You can connect with more advertisers through various data analyses.
            </h4>
            <Facebook />
        </Wrapper>
    );
};

export default Instagram;
