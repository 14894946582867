import { SET_USER } from './action';

export const userInitialState = {
    id: null,
    username: '',
    role: '',
    company: null,
    //account_manager: null,
    //bank_account: null,
};

const user = (state = userInitialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                id: action.user.id,
                username: action.user.username,
                role: action.user.role,
                company: {
                    name: action.user.company?.name,
                    website: action.user.company?.website,
                },
                //account_manager: {
                //    first_name: action.user.account_manager?.first_name,
                //    last_name: action.user.account_manager?.last_name,
                //    email: action.user.account_manager?.email,
                //    mobile: action.user.account_manager?.mobile,
                //},
                //bank_account: {
                //    bank_name: action.user.bank_account?.bank_name,
                //    bic: action.user.bank_account?.bic,
                //    mobile: action.user.bank_account?.mobile,
                //    number: action.user.bank_account?.number,
                //    holder_name: action.user.bank_account?.holder_name,
                //    address: action.user.bank_account?.address,
                //},
            };
        default:
            return state;
    }
};

export default user;
