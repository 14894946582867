import { useEffect } from 'react';

const CLIENT_ID = 'SUUpRCsKmZ1R-wGPh3awHEaRiPbsvPoiVWEkanX7g6k';
const CLIENT_SECRET = 'MBouk_ETIIwJHZFHQw_NYl7RfOQtw3JMtTuWK6vbi3c';
const REDIRECT_URI = 'http://localhost:3000/contact';
const WEB_HOOK_KEY = '8WjpKz2-LCI4_R6MwFC1lM_DsQIfcBOPwyGjttbpjhE';
const ACCESS_TOKEN =
    'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzAyNDMwMDM5LCJqdGkiOiIyMDU5NWVhZi05ZGY5LTQ2ODMtYTAxOC1mNzEwY2E5N2IyMjMiLCJ1c2VyX3V1aWQiOiIwNWNjNTJjYy0yMTM4LTRmNTQtYTYzYi02ZjIyZTE1OTA5ZDYifQ.30JpYdr9wG5cdXTe8N9EDvi4GxZfVh8ZFp2XaNoPnhGMyEhYG21UOkRU_cSybFuqQyV3LVOG75lpbBZ-okfYdg';

const axios = require('axios');

// Calendly API 엔드포인트
const apiUrl = 'https://api.calendly.com/event_types';

// Calendly API에 액세스하기 위한 헤더 설정
const headers = {
    Authorization: 'Bearer ' + ACCESS_TOKEN, // 여기에 실제 액세스 토큰을 넣어야 합니다.
    'Content-Type': 'application/json',
};

// 이벤트 유형을 가져오는 함수
export async function getEventTypes() {
    try {
        const response = await axios.get(apiUrl, { headers });

        // 가져온 이벤트 유형 출력
        console.log('이벤트 유형:');
        console.log(response.data.data);

        return response.data.data;
    } catch (error) {
        console.error('이벤트 유형을 가져오는 중 오류 발생:', error.response ? error.response.data : error.message);
        throw error;
    }
}

export const CalendlyWidget = props => {
    const { url } = props;
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            className="calendly-inline-widget"
            data-url={url + '?background_color=fbfbfb&text_color=474747&primary_color=860cf9&hide_gdpr_banner=1'}
            //data-url="https://calendly.com/junghwan_aligoai"
            style={{ minWidth: 320, height: 700 }}
            //style={{ minWidth: 320, height: 700 }}
        />
    );
};
export default CalendlyWidget;
