import React from 'react';

const InstagramIcon = props => {
    const { width, height, color } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill={color}
        >
            <mask
                id="mask0_2481_16235"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width}
                height={height}
            >
                <rect width={width} height={height} fill="white" />
            </mask>
            <g mask="url(#mask0_2481_16235)">
                <path
                    d="M13.0955 17.9156H6.90446C5.73677 17.9142 4.6173 17.4497 3.79162 16.624C2.96593 15.7983 2.50143 14.6788 2.5 13.5112V6.90446C2.50143 5.73677 2.96593 4.6173 3.79162 3.79161C4.6173 2.96593 5.73677 2.50143 6.90446 2.5H13.0955C14.2632 2.50143 15.3827 2.96593 16.2084 3.79161C17.0341 4.6173 17.4986 5.73677 17.5 6.90446V13.5112C17.4986 14.6788 17.0341 15.7983 16.2084 16.624C15.3827 17.4497 14.2632 17.9142 13.0955 17.9156ZM6.90446 3.6308C6.03621 3.63152 5.20367 3.97656 4.58947 4.59026C3.97526 5.20396 3.62953 6.03621 3.6281 6.90446V13.5112C3.62953 14.3797 3.97518 15.2122 4.5893 15.8263C5.20343 16.4404 6.03596 16.7861 6.90446 16.7875H13.0955C13.9638 16.7861 14.796 16.4404 15.4097 15.8262C16.0234 15.2119 16.3685 14.3794 16.3692 13.5112V6.90446C16.3685 6.03645 16.0234 5.2042 15.4096 4.59043C14.7958 3.97665 13.9635 3.63152 13.0955 3.6308H6.90446Z"
                    fill={color}
                />
                <path
                    d="M10.0001 14.283C8.91422 14.2815 7.87329 13.8494 7.10573 13.0813C6.33817 12.3132 5.90668 11.272 5.90597 10.1862C5.90725 9.63275 6.01889 9.08514 6.23438 8.5754C6.44986 8.06565 6.76485 7.60401 7.16091 7.21748C7.537 6.84731 7.98406 6.55702 8.47522 6.36408C8.96638 6.17114 9.49148 6.07954 10.019 6.09477C11.2955 6.13255 12.3534 6.56167 13.0794 7.33623C13.4293 7.72393 13.6991 8.17699 13.8732 8.66933C14.0474 9.16168 14.1224 9.68359 14.0942 10.2051C14.0783 11.288 13.644 12.3228 12.8824 13.0928C12.5045 13.4719 12.0552 13.7723 11.5604 13.9765C11.0657 14.1808 10.5353 14.285 10.0001 14.283ZM9.90561 7.46846C9.23735 7.47059 8.59669 7.7352 8.12169 8.20524C7.85743 8.46314 7.64731 8.77119 7.50365 9.11135C7.35999 9.4515 7.28567 9.81692 7.28506 10.1862C7.28595 10.5427 7.35705 10.8956 7.49431 11.2246C7.63157 11.5537 7.8323 11.8525 8.08504 12.104C8.33778 12.3555 8.63757 12.5547 8.96731 12.6903C9.29705 12.826 9.65027 12.8953 10.0068 12.8944C10.3634 12.8935 10.7162 12.8224 11.0453 12.6852C11.3743 12.5479 11.6732 12.3472 11.9246 12.0944C12.1761 11.8417 12.3754 11.5419 12.511 11.2122C12.6466 10.8824 12.716 10.5292 12.7151 10.1727C12.7588 9.48285 12.5279 8.80373 12.0728 8.2835C11.6032 7.78152 10.8799 7.50355 9.97848 7.47386L9.90561 7.46846Z"
                    fill={color}
                />
                <path
                    d="M14.194 6.93152C14.7216 6.93152 15.1494 6.50378 15.1494 5.97613C15.1494 5.44849 14.7216 5.02075 14.194 5.02075C13.6664 5.02075 13.2386 5.44849 13.2386 5.97613C13.2386 6.50378 13.6664 6.93152 14.194 6.93152Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

InstagramIcon.defaultProps = {
    width: 20,
    height: 20,
    color: 'var(--gray-500)',
};

export default InstagramIcon;
