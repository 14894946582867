import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import { offerAgencyConfirm, patchContract } from '../../queries/ConfirmOffer';
import { getMyModelsContractDetail } from '../../queries/GetMyModelsContract';
import { getDisplayNameString } from '../../utils/common';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ALERT_MODAL, OFFER_DETAIL_MODAL, setAlertModal, setOfferModal } from '../modal/api/action';
import { getFileName } from '../modal/CreateOffer';

const schema = yup
    .object({
        posting_start: yup.string(),
        posting_end: yup.string(),
        exposure_number: yup.string(),
        cpm: yup.string(),
        special_condition: yup.string(),
    })
    .required();

const ContractDetail = props => {
    const dispatch = useDispatch();
    const { contractId } = props;
    const OfferDetailState = useSelector(state => state.modal.offerDetail);
    const userData = useSelector(state => state.user);
    const [editOrSaveStatus, setEditOrSaveStatus] = useState(true);
    const { data, isLoading, error, isError, refetch } = useQuery(
        ['getMyModelsContractDetail', userData.id, contractId],
        //() => GetContract({ contractId: contractId }),
        () => getMyModelsContractDetail({ contractId: contractId }),
        { enabled: false }
    );

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            //title: data.project.title,
            //posting_start: data.posting_start,
            //posting_end: data.posting_end,
            //product: data.product,
            //exposure_number: data.exposure_number,
            //cpm: data.project.product,
            //special_condition: data.special_condition,
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (OfferDetailState.status) {
            refetch();
        }
    }, [OfferDetailState]);

    const showAlertModal = props => {
        const { buttonFunction } = props;
        dispatch(
            setAlertModal({
                type: ALERT_MODAL,
                data: {
                    status: true,
                    title: 'test',
                    content: 'test\ntest',
                    buttonText: 'Send offer',
                    buttonFunction: buttonFunction,
                },
            })
        );
    };

    const onClickEdit = e => {
        //setEditOrSaveStatus(!editOrSaveStatus);
        e.preventDefault();
        setEditOrSaveStatus(false);
    };

    const acceptContract = body => {
        patchMutation.mutate(body);
    };

    const onClickConfirm = e => {
        e.preventDefault();
        const body = {
            ['status_' + userData.role]: 'Accepted',
        };
        showAlertModal({ buttonFunction: () => acceptContract(body) });
    };

    const onClickCancel = e => {
        e.preventDefault();
        setEditOrSaveStatus(true);
        reset();
    };

    const checkStatus = () => {
        if (data.status_ad_agency === 'Accepted' && data.status_celeb_agency === 'Accepted') {
            return <></>;
        }
        if (data['status_' + userData.role] === 'Pending') {
            return editOrSaveStatus ? (
                <>
                    {editButton}
                    {confirmButton}
                </>
            ) : (
                <>
                    {saveButton}
                    {cancelButton}
                </>
            );
        } else if (data['status_' + userData.role] === 'Accepted') {
            return editOrSaveStatus ? (
                editButton
            ) : (
                <>
                    {saveButton}
                    {cancelButton}
                </>
            );
        }
    };

    const editButton = (
        <button
            type="button"
            className="btn btn-alt-primary mt-30 ml-10 mr-10"
            style={{ width: '150px' }}
            onClick={e => onClickEdit(e)}
        >
            Edit offer
        </button>
    );

    const confirmButton = (
        <button
            type="button"
            onClick={e => onClickConfirm(e)}
            className="btn btn-alt-black mt-30 ml-10 mr-10"
            style={{ width: '150px' }}
        >
            Confirm offer
        </button>
    );

    const saveButton = (
        <button type="submit" className="btn btn-alt-primary mt-30 ml-10 mr-10" style={{ width: '150px' }}>
            Send offer
        </button>
    );

    const cancelButton = (
        <button
            type="button"
            className="btn btn-alt-black mt-30 ml-10 mr-10"
            style={{ width: '150px' }}
            onClick={e => onClickCancel(e)}
        >
            Cancel edit
        </button>
    );

    const mutation = useMutation(async () => offerAgencyConfirm({ contractId: contractId }), {
        onSuccess: async res => {
            if (res.status === 'complete') {
                dispatch(
                    setOfferModal({
                        type: OFFER_DETAIL_MODAL,
                        data: {
                            status: false,
                            contract_id: 0,
                        },
                    })
                );
            }
        },
    });

    const patchMutation = useMutation(async result => patchContract(contractId, result), {
        onSuccess: async res => {
            refetch();
            setEditOrSaveStatus(true);
        },
        //onError: err => {
        //    switch (err.response.status) {
        //        default:
        //            return;
        //    }
        //},
    });

    const onSubmit = result => {
        const body = {
            posting_start: result.posting_start,
            posting_end: result.posting_end,
            exposure_number: result.exposure_number,
            cpm: result.cpm,
            ['status_' + userData.role]: 'Accepted',
            status_ad_agency: userData.role === 'ad_agency' ? 'Accepted' : 'Pending',
            status_celeb_agency: userData.role === 'celeb_agency' ? 'Accepted' : 'Pending',
            //special_condition: result.special_condition ? '' : result.special_condition,
        };
        //patchMutation.mutate(body);
        showAlertModal({ buttonFunction: () => acceptContract(body) });
    };

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-tab-container">
                <div className="modal-tab-content" id="detailTab">
                    <div className="info border-bottom">
                        <h4>Basic information</h4>
                        <div style={{ textAlign: 'center' }} className="photo">
                            <h4>Selected photo</h4>
                            <img src={data.portfolio.file} />
                            <br />
                            <span>{decodeURI(getFileName(data.portfolio.file))}</span>
                        </div>
                        <div className="info-content">
                            <ul>
                                <li>
                                    <div className="modify-input-group">
                                        <span className="title">Project title</span>
                                        <input
                                            //{...register('project')}
                                            type="text"
                                            disabled
                                            defaultValue={data.project.title}
                                            //className="changed"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="modify-input-group">
                                        <span className="title">Product</span>
                                        <input type="text" disabled defaultValue={data.project.product} />
                                    </div>
                                </li>
                                <li>
                                    <div className="modify-input-group">
                                        <span className="title">Ad posting country</span>
                                        <input
                                            type="text"
                                            disabled
                                            defaultValue={getDisplayNameString(data.project.ad_posting_countries)}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="modify-input-group">
                                        <span className="title">Posting start date</span>
                                        <input
                                            {...register('posting_start')}
                                            type="date"
                                            disabled={editOrSaveStatus}
                                            defaultValue={data.posting_start}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="modify-input-group">
                                        <span className="title">Posting end date</span>
                                        <input
                                            {...register('posting_end')}
                                            type="date"
                                            disabled={editOrSaveStatus}
                                            defaultValue={data.posting_end}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="info border-bottom">
                        <h4>Posting Ad & Estimating cost</h4>
                        <ul>
                            <li>
                                <div className="modify-input-group">
                                    <span className="title">Exposure number</span>
                                    <input
                                        {...register('exposure_number', { valueAsNumber: true })}
                                        type="number"
                                        disabled={editOrSaveStatus}
                                        defaultValue={data.exposure_number}
                                    />
                                </div>
                            </li>
                            <li>
                                <span className="title">Exposure media</span>
                                <span className="content">{getDisplayNameString(data.exposure_medias)}</span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="modify-input-group">
                                    <span className="title">CPM</span>
                                    <input
                                        {...register('cpm', { valueAsNumber: true })}
                                        type="number"
                                        disabled={editOrSaveStatus}
                                        defaultValue={data.cpm}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="info">
                        <h4>Special condition</h4>
                        <div className="contents" style={{ whiteSpace: 'pre-wrap' }}>
                            {data.special_condition}
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-group-modal">{checkStatus()}</div>
        </form>
    );
};

export default ContractDetail;
//export default React.memo(Detail);
