import React from 'react';
import { privateClient } from '../utils/client';

export const getMyModelsContractList = async ({ celebId }) => {
    const response = await privateClient.get(`/api/v1/contract/celeb/${celebId}/`);

    return response.data;
};

export const getMyModelsContractDetail = async ({ contractId }) => {
    if (contractId) {
        const response = await privateClient.get(`/api/v1/contracts/${contractId}/`);

        return response.data.data;
    }
};
