import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import { getExposureMedias } from '../../queries/GetExposureMedias';
import { EXPOSURE_MEDIAS_CODE, setOfferModal } from './api/action';

export const getDisplayName = (array, id) => {
    for (let index = 0; index < array.length; index++) {
        if (array[index].id === id) {
            return array[index].label;
        }
    }
    return '';
};

const ExposureMedias = props => {
    const dispatch = useDispatch();
    const { mediaArray, appendMedia, removeMedia } = props;
    const { data, isLoading, isError, error } = useQuery(['getExposureMedias'], getExposureMedias, {
        onSuccess: res => {
            dispatch(
                setOfferModal({
                    type: EXPOSURE_MEDIAS_CODE,
                    data: res,
                })
            );
        },
    });

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <>
            <div className="input-group ">
                <label>Exposure media</label>
                <div className="selectGroup">
                    <select defaultValue="0" onChange={appendMedia}>
                        <option value="0" hidden disabled>
                            Select
                        </option>
                        {data.map((media, index) => (
                            <option key={index} value={media.id}>
                                {media.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="input-group selected">
                <label className="h6">Selected media :</label>
                {mediaArray.map((media, index) => (
                    <button type="button" onClick={removeMedia} key={index} value={media}>
                        {getDisplayName(data, media)}
                    </button>
                ))}
            </div>
        </>
    );
};

export default ExposureMedias;
