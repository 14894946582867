import React from 'react';
import { publicClient, privateClient } from '../utils/client';

//const axiosInstance = axios.create({
//    baseURL: BASE_URL,
//});

export const UserSelf = async () => {
    const response = await privateClient.get('/api/v1/users/self/');

    return response.data.data;
};

export const PatchUserSelf = async data => {
    const response = await privateClient.patch('/api/v1/users/self/', data);

    return response.data;
};

export const putForgotPassword = async data => {
    const response = await publicClient.patch('/api/v1/users/password/', data);

    return response.data;
};
