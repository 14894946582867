import React from 'react';

function FilmographyForm(props) {
    const { index, register, filmography, removeComponent } = props;

    return (
        <div className="row-col col-12">
            <div className="col-7">
                <div className="input-group">
                    <label>Title</label>
                    <input
                        {...register(`filmography[${index}].title`)}
                        type="text"
                        style={{ width: '100%' }}
                        defaultValue={filmography?.title}
                    />
                </div>
            </div>
            <div className="col-5">
                <div className="input-group">
                    <label>URL</label>
                    <input {...register(`filmography[${index}].url`)} type="text" defaultValue={filmography?.url} />
                    {/* <i className="icon-cancel" onClick={index => removeComponent(index)} /> */}
                </div>
            </div>
        </div>
    );
}

export default FilmographyForm;
