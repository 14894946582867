import React, { useState } from 'react';
import { useEffect } from 'react';
import { array } from 'yup';

const createArray = num => {
    let arr = [];
    for (let i = 0; i < num; i++) {
        arr.push(i + 1);
    }
    return arr;
};

// data : 전체 데이터
// setCurrentPage : 현재 페이지를 변경하는 함수
// currentPage : 현재 페이지
// postsPerPage : 한 페이지에 보여줄 데이터의 개수
// pageCount : 한 페이지 그룹에 보여줄 페이지의 넘버링 개수

const Pagination = props => {
    const { data, page, blockNum, postsPerPage, pageCount, setPage, setBlockNum } = props;
    const totalData = data?.length; //전체 데이터의 갯수

    const totalPages = Math.ceil(totalData / postsPerPage); //전체 페이지의 갯수
    const pageGroup = Math.ceil(blockNum * pageCount); //페이지 그룹

    const nArr = createArray(totalPages);
    let pArr = nArr?.slice(pageGroup, pageGroup + pageCount);

    //console.log('totalData', pArr);
    const prevPage = () => {
        if (page <= 1) {
            return;
        }

        if (page - 1 <= blockNum * pageCount) {
            setBlockNum(() => blockNum - 1);
        }
        setPage(() => page - 1);
    };

    const nextPage = () => {
        if (page >= totalPages) {
            return;
        }
        if (pageCount * (blockNum + 1) < page + 1) {
            setBlockNum(() => blockNum + 1);
        }
        setPage(() => page + 1);
    };

    return (
        <ul>
            <li onClick={() => prevPage()} className={page === 1 ? 'disabled' : ''}>
                <i className="icon-left-open"></i>
            </li>
            {pArr.map((item, index) => (
                <li key={index} onClick={() => setPage(item)} className={page === item ? 'selected' : ''}>
                    {item}
                </li>
            ))}
            <li onClick={() => nextPage()} className={page === totalPages ? 'disabled' : ''}>
                <i className="icon-right-open"></i>
            </li>
        </ul>
    );
};

export default Pagination;
