import React from 'react';
import { useQuery } from 'react-query';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import MainContainer from '../../components/MainContainer';
import Layout from '../../modules/common/Layout';
import AgencySetting from '../../modules/mypage/AgencySetting';
import CelebSetting from '../../modules/mypage/CelebSetting';
import SelectedHeart from '../../modules/mypage/SelectedHeart';
import { UserSelf } from '../../queries/UserSelf';

const Setting = () => {
    const { data, isLoading, error, isError } = useQuery(['getUserData'], () => UserSelf());

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error} />;

    console.log(data);
    const adAgency = data.role === 'ad_agency' ? <AgencySetting data={data} /> : <></>;
    const celebAgency = data.role === 'celeb_agency' ? <CelebSetting data={data} /> : <></>;

    return (
        <>
            <Layout>
                <MainContainer>
                    {adAgency}
                    {celebAgency}
                </MainContainer>
            </Layout>
            {/* <ModalCreateProject />
            <LargeModal />
            <ConfirmModal />
            <OfferDetail /> */}
        </>
    );
};

export default Setting;
