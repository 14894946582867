import React from 'react';
import Select from '../input/SortSelect';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getCountriesCode, getGenderCode } from '../../queries/GetList';
import Error from '../contents/Error';

const SortAreaStyle = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin: 40px 100px;
`;

//const LeftArea = styled.div`
//    margin-top: -15px;
//`;

//const RightArea = styled.div`
//    display: flex;
//    align-items: center;
//    select {
//        margin-left: 12px;
//    }
//`;

const CountriesSelectWrapper = props => {
    const { country, setCountry } = props;
    const { data, isLoading, isError, error } = useQuery(['getCountriesCode'], () => getCountriesCode());
    if (isLoading) return <></>;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;
    return <Select value={country} setValue={setCountry} options={data} />;
};

const QuerySelectWrapper = props => {
    const { value, setValue } = props;
    const data = [
        {
            id: 1,
            value: 'popularity',
            label: 'Popularity',
        },
        //{
        //    id: 2,
        //    value: 'created',
        //    label: 'Created',
        //},
        {
            id: 3,
            value: 'instagram_followers',
            label: 'Followers',
        },
    ];
    //const { country, setCountry } = props;
    //const { data, isLoading, isError, error } = useQuery(['getCountriesCode'], () => getCountriesCode());
    //if (isLoading) return <></>;
    //if (!data) return <></>;
    //if (isError) return <Error errMsg={error.message} />;
    return <Select value={value} setValue={setValue} options={data} />;
};

const SortArea = props => {
    const { query, setQuery } = props;
    const selectCountry = newCountry => {
        setQuery({ ...query, o: newCountry, country: newCountry });
    };

    const selectOption = newOption => {
        setQuery({ ...query, o: newOption, option: newOption });
    };

    return (
        <SortAreaStyle>
            {/*<LeftArea>
                <GradientText fontOption="normal normal 700 4rem/4rem inter">Popular Celebs</GradientText>
            </LeftArea>*/}
            {/*<RightArea>*/}
            {/*<CheckBox label="Female" setValue={setFemale} value={query.female} size="md" />
            <CheckBox label="Male" setValue={setMale} value={query.male} size="md" />*/}
            <CountriesSelectWrapper country={query.country} setCountry={selectCountry} />
            <QuerySelectWrapper value={query.option} setValue={selectOption} />
            {/*<GenderSelectWrapper />*/}
            {/*</RightArea>*/}
        </SortAreaStyle>
    );
};

export default SortArea;

const GenderSelectWrapper = () => {
    const { data, isLoading, isError, error } = useQuery(['getGenderCode'], () => getGenderCode());
    if (isLoading) return <></>;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;
    return <Select value="Select gender" options={data} />;
};
