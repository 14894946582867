import React from 'react';

function PopularPhotos() {
    const data = [
        {
            id: 1,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            click: 0,
            file_w512: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            heart_count: 0,
        },
        {
            id: 2,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            click: 0,
            file_w512: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            heart_count: 0,
        },
        {
            id: 3,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            click: 0,
            file_w512: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            heart_count: 0,
        },
        {
            id: 4,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            click: 0,
            file_w512: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            heart_count: 0,
        },
        {
            id: 5,
            file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            click: 0,
            file_w512: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%B2%E1%84%85%E1%85%B5_olqp7JM.jpg',
            heart_count: 0,
        },
    ];
    return (
        <div className="photo-list mt-30">
            <h3 className="grid-title">Popular Photos</h3>
            <div className="arrow-box">
                <i className="icon-left-open"></i>
                <i className="icon-right-open"></i>
            </div>
            <div className="img-list-container">
                {data.map((photo, index) => (
                    <div key={index} className="model-card" style={{ marginRight: '20px' }}>
                        <div className="card-content" key={index}>
                            <div className="img">
                                <img style={{ width: '100%' }} src={photo.file} alt="" />
                            </div>
                            <div className="card-header">
                                <span>{photo.click}</span>
                                <span className="right">
                                    {photo.heart_count}
                                    <i className="icon-heart" />
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                {/*{data.map((photo, index) => (
                    <div className="img-box" key={index}>
                        <img src={photo.file} alt="" />
                        <span>{photo.click}</span>
                        <span className="right">
                            {photo.heart_count}
                            <i className="icon-heart" />
                        </span>
                    </div>
                ))}*/}
            </div>
        </div>
    );
}

export default PopularPhotos;
