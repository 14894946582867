import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import InputGroup from '../../components/input/InputGroup';
import ApplyButton from '../../components/buttons/ApplyButton';
import { REGISTER_MODAL, setModal } from './api/action';
import { useModal } from './ModalUtils';
import { useMutation } from 'react-query';
import { registerUser } from '../../queries/SampleService';

export const useRegisterModal = () => {
    const dispatch = useDispatch();

    const showModal = () => {
        dispatch(
            setModal({
                type: REGISTER_MODAL,
                data: {
                    status: true,
                },
            })
        );
    };

    return { showModal };
};

const Modal = styled.div`
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    width: 100%;
    height: 100%;
    background: linear-gradient(181.2deg, #5900e5 2.06%, #860cf9 33.02%, rgba(243, 231, 254, 0) 97.94%);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    animation: fadein 300ms;
    .modal-contents {
        border-radius: 15px;
        width: 864px;
        height: 600px;
        background-color: #fbfbfb;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #f3e7fe;
        box-shadow: 0px 0px 20px rgba(124, 0, 172, 0.3);
        backdrop-filter: blur(2px);
        .material-icons {
            position: absolute;
            color: #666666;
            width: 28px;
            height: 28px;
            right: 32px;
            top: 32px;
            cursor: pointer;
        }
    }
    .title {
        margin-top: 80px;
        font: normal normal 700 5rem/5rem inter;
        color: #474747;
    }
    .sub-text {
        margin: 28px auto;
        font: normal normal 400 2rem/2.5rem inter;
        color: #474747;
    }
    button {
        margin: 92px auto 0;
    }
`;

const RegisterModal = () => {
    const { hideModal } = useModal(REGISTER_MODAL);
    //const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [textMsg, setTextMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const RegisterState = useSelector(state => state.modal.register);
    const modalRef = useRef(null);

    const mutation = useMutation(async data => registerUser(data), {
        onSuccess: async res => {
            setTextMsg('Registration successful');
        },
        onError: async err => {
            setErrMsg(err.response.data.data.username[0]);
        },
    });

    const registerButtonFunction = () => {
        const data = {
            username: email,
        };
        mutation.mutate(data);
    };

    const handleClickBackground = e => {
        if (e.target === modalRef.current) {
            hideModal();
        }
    };

    const setEmailHandler = e => {
        setEmail(e.target.value);
    };

    //if (isLoading)
    //    return RegisterState.status ? (
    //        <Modal>
    //            <div className="modal-contents">
    //                <span className="material-icons" onClick={hideModal}>
    //                    close
    //                </span>
    //            </div>
    //        </Modal>
    //    ) : (
    //        <></>
    //    );

    return RegisterState.status ? (
        <Modal ref={modalRef} onClick={handleClickBackground}>
            <div className="modal-contents">
                <span className="material-icons" onClick={hideModal}>
                    close
                </span>
                <p className="title">Join our service</p>
                <p className="sub-text">
                    Register your email to have an access to our celebrities.
                    <br />
                    You will receive a password for the access. It is highly
                    <br />
                    recommended to put professional email address.
                </p>
                <InputGroup
                    onChange={e => setEmailHandler(e)}
                    size="lg"
                    placeholder="Email address"
                    type="email"
                    text={textMsg}
                    errorMsg={errMsg}
                    required
                />
                <ApplyButton onClick={() => registerButtonFunction()}>Register here</ApplyButton>
                <img src="/svg/CeleVu_logo_b.svg" style={{ width: '200px', margin: '32px auto' }} />
            </div>
        </Modal>
    ) : (
        <></>
    );
};

export default RegisterModal;
