import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Pager from '../common/Pager';
import Breadcrumb from '../header/Breadcrumb';
import LeftMenu from './LeftMenu';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { CreateCeleb } from '../../queries/CreateCeleb';
import { useSelector } from 'react-redux';
import UploadFile from './UploadFile';
import ImageList from './ImageList';
import VideoList from './VideoList';
import { useEffect } from 'react';
import { RegisterInput } from '../auth/RegisterInput';
import Filmography from './Filmography';

const schema = yup
    .object({
        pen_name: yup.string().required('pen name은 필수입니다'),
        family_name: yup.string().required(''),
        given_name: yup.string().required(''),
        gender: yup.number().required(''),
        birth_year: yup.number().required(''),
        contract_term: yup.string().required(''),
        email: yup.string().required(''),
        cpm: yup.number().required(''),
        minimum_amount: yup.number().required(''),
        description: yup.string().required(''),
        //performance: yup.string().required(''),
    })
    .required();

const RegisterModel = () => {
    const userData = useSelector(state => state.user);
    const [photoListIndex, setPhotoListIndex] = useState(0);
    const [photoList, setPhotoList] = useState([]);
    const [videoListIndex, setVideoListIndex] = useState(0);
    const [videoList, setVideoList] = useState([]);
    const [mainPhoto, setMainPhoto] = useState({});
    const [mainVideo, setMainVideo] = useState({});

    const addPhoto = object => {
        setPhotoList([...photoList, object]);
    };

    const removePhoto = index => {
        let removedPhotoList = [...photoList];

        removedPhotoList.splice(index, 1);
        setPhotoList(removedPhotoList);
    };

    const addPhotoListIndex = () => {
        let changedIndex = photoListIndex + 1;
        if (changedIndex <= 0) {
            setPhotoListIndex(changedIndex);
        }
    };

    const subPhotoListIndex = () => {
        let changedIndex = photoListIndex - 1;
        if (changedIndex >= photoList.length * -1 && changedIndex >= (photoList.length - 4) * -1) {
            setPhotoListIndex(changedIndex);
        }
    };

    const addVideo = object => {
        setVideoList([...videoList, object]);
    };

    const removeVideo = index => {
        let removedVideoList = [...videoList];

        removedVideoList.splice(index, 1);
        setVideoList(removedVideoList);
    };

    const addVideoListIndex = () => {
        let changedIndex = videoListIndex + 1;
        if (changedIndex <= 0) {
            setVideoListIndex(changedIndex);
        }
    };

    const subVideoListIndex = () => {
        let changedIndex = videoListIndex - 1;
        if (changedIndex >= videoList.length * -1 && changedIndex >= (videoList.length - 4) * -1) {
            setVideoListIndex(changedIndex);
        }
    };

    useEffect(() => {
        if (photoList.length > 4) {
            setPhotoListIndex((photoList.length - 4) * -1);
        }
    }, [photoList]);

    useEffect(() => {
        if (videoList.length > 4) {
            setVideoListIndex((videoList.length - 4) * -1);
        }
    }, [videoList]);

    const setMainPhotoHandler = photo => {
        setMainPhoto(photo);
    };

    const setMainVideoHandler = video => {
        setMainVideo(video);
    };

    const mutation = useMutation(async data => CreateCeleb(data), {
        onSuccess: res => {
            window.location.href = '/my-celebs';
        },
        onError: err => {
            switch (err.response.status) {
                case 400:
                    console.log('400', err);
                    return;
                case 301:
                    console.log('301', err);
                    return;
                case 201:
                    console.log('201', err);
                    return;
                default:
                    console.log('default', err);
                    return;
            }
        },
    });
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            gender: 0,
            birth_year: 1990,
        },
    });

    const setYears = () => {
        const years = [];

        for (let index = 1923; index < 2023; index++) {
            years.push(index);
        }
        return years;
    };

    const onSubmit = result => {
        //if (!mainPhoto.id) alert('main사진이 필요합니다');
        //if (!mainVideo.id) alert('main비디오가 필요합니다');
        const photos = photoList.map(element => element.id);
        photos.unshift(mainPhoto.id);
        const videos = videoList.map(element => element.id);
        if (mainVideo.id) {
            videos.unshift(mainVideo.id);
        }
        const referee = result.referee_email ? { email: result.referee_email } : null;
        //main image 확인
        //main video 확인
        //이미지 비디오 숫자 확인
        //5개이하면 validation
        const registerBody = {
            pen_name: result.pen_name,
            family_name: result.family_name,
            given_name: result.given_name,
            gender: result.gender,
            birth_year: result.birth_year,
            contract_term: result.contract_term,
            email: result.email,
            cpm: result.cpm,
            minimum_amount: result.minimum_amount,
            social_media: {
                youtube: result.youtube,
                instagram: result.instagram,
                twitter: result.twitter,
                tiktok: result.tiktok,
                etc: result.etc,
            },
            photos: photos,
            videos: videos,
            referee: referee,
            description: result.description,
            filmography: result.filmography,
            performance: '',
            // performance: result.performance,
        };
        //if (window.confirm('모델을 등록 하시겠습니까?')) {
        //}
        mutation.mutate(registerBody);
    };

    if (!userData.id) {
        window.location.href = '/login';
    }

    return (
        <div className="page-container">
            <LeftMenu />

            <div className="row px-60">
                <Breadcrumb value={['My Models', 'Register Celeb']} />
                <h1 className="fl mb-20">Register Celeb</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid-container ">
                        <div className="infomation">
                            <div className="info border-top-black">
                                <h4>Basic information</h4>
                                {/*<span className="right  mr-10">
                                    View less
                                    <i className="icon-right-open"></i>
                                </span>*/}
                                <div className="row-col">
                                    <div className="col-4">
                                        <div className="input-group">
                                            {/*<RegisterInput
                                                register={register}
                                                type="text"
                                                label="Pen name"
                                                name="pen_name"
                                                errorMsg={errors.pen_name?.message}
                                                defaultMsg=""
                                            />*/}
                                            <label>Pen name</label>
                                            <input {...register('pen_name')} type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Last name</label>
                                            <input {...register('family_name')} type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>First name</label>
                                            <input {...register('given_name')} type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row-col">
                                    <div className="col-2">
                                        <div className="input-group sm">
                                            <label>Gender</label>
                                            <select {...register('gender', { valueAsNumber: true })} className="lg">
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Nonbinary</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="input-group">
                                            <label>Year of birth</label>
                                            <select {...register('birth_year', { valueAsNumber: true })}>
                                                {setYears().map((year, index) => (
                                                    <option key={index} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                            {/*<input type="text" placeholder="YYYY" />*/}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Contract term</label>
                                            <input {...register('contract_term')} type="date" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Email</label>
                                            <input {...register('email')} type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row-col">
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>CPM</label>
                                            <input
                                                {...register('cpm', { valueAsNumber: true })}
                                                type="number"
                                                step={0.1}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Minimum amount</label>
                                            <input
                                                {...register('minimum_amount', { valueAsNumber: true })}
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="info border-top-black">
                                <h4>Contact</h4>
                                <div className="row-col">
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Mobile</label>
                                            <input {...register('youtube')} type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Email</label>
                                            <input {...register('instagram')} type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="info border-top-black">
                                <h4>Social media (optional)</h4>
                                {/*<span className="right  mr-10">
                                    View less
                                    <i className="icon-right-open"></i>
                                </span>*/}
                                <div className="row-col">
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Youtube</label>
                                            <input {...register('youtube')} type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Instagram</label>
                                            <input {...register('instagram')} type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>Twitter</label>
                                            <input {...register('twitter')} type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row-col">
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>TikTok</label>
                                            <input {...register('tiktok')} type="text" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>etc</label>
                                            <input {...register('etc')} type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="info border-top-black">
                                <h4>Portfolio</h4>
                                <span className="right  mr-10">
                                    View less
                                    <i className="icon-right-open"></i>
                                </span>
                                <div className="row-col">
                                    <div className="col-2">
                                        <div className="input-group-image">
                                            <label>
                                                Main photo<span>jpg, png, jpeg</span>
                                            </label>
                                            <div className="image-box">
                                                {mainPhoto.file ? (
                                                    <>
                                                        <img src={mainPhoto.file}></img>
                                                        <i onClick={() => setMainPhoto({})} className="icon-cancel"></i>
                                                    </>
                                                ) : (
                                                    <>
                                                        <UploadFile
                                                            isMain={true}
                                                            setMain={setMainPhotoHandler}
                                                            title="Upload image"
                                                            id="mainPhotoInput"
                                                        />
                                                        <i className="icon-cancel"></i>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-col col-10">
                                        <div className="col-1 text-right arrow-center">
                                            <i onClick={addPhotoListIndex} className="icon-left-open i-lg"></i>
                                        </div>
                                        <div className="col-10">
                                            <div className="input-group-image">
                                                <div className="text-right">
                                                    <UploadFile
                                                        addList={addPhoto}
                                                        isMain={false}
                                                        title="Upload image"
                                                        id="photosInput"
                                                    />
                                                </div>
                                                <ImageList
                                                    list={photoList}
                                                    currentIndex={photoListIndex}
                                                    removeItem={removePhoto}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1 text-left arrow-center">
                                            <i onClick={subPhotoListIndex} className="icon-right-open i-lg"></i>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="input-group-image">
                                            <label>
                                                Main video<span>mp4, mov, avi</span>
                                            </label>
                                            <div className="image-box">
                                                {mainVideo.file ? (
                                                    <>
                                                        <video controls width="100%">
                                                            <source src={mainVideo.file} />
                                                        </video>
                                                        <i onClick={() => setMainVideo({})} className="icon-cancel"></i>
                                                    </>
                                                ) : (
                                                    <>
                                                        <UploadFile
                                                            isMain={true}
                                                            setMain={setMainVideoHandler}
                                                            title="Upload video"
                                                            id="mainVideoInput"
                                                        />
                                                        <i className="icon-cancel"></i>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-col col-10">
                                        <div className="col-1 text-right arrow-center">
                                            <i onClick={addVideoListIndex} className="icon-left-open i-lg"></i>
                                        </div>
                                        <div className="col-10">
                                            <div className="input-group-image">
                                                <div className="text-right">
                                                    <UploadFile
                                                        addList={addVideo}
                                                        isMain={false}
                                                        title="Upload video"
                                                        id="videosInput"
                                                    />
                                                </div>
                                                <VideoList
                                                    list={videoList}
                                                    currentIndex={videoListIndex}
                                                    removeItem={removeVideo}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1 text-left arrow-center">
                                            <i onClick={subVideoListIndex} className="icon-right-open i-lg"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-col">
                                    <div className="col-12">
                                        <div className="input-group">
                                            <label>
                                                Description<span className="sub-title">Max 100 characters</span>
                                            </label>
                                            <textarea
                                                {...register('description')}
                                                rows={3}
                                                // placeholder="Introduction (text)"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="row-col">
                                    <div className="col-12">
                                        <div className="input-group">
                                            <label>Filmography</label>
                                            <textarea
                                                {...register('performance')}
                                                rows={4}
                                                placeholder="Text, URL, Images"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                            {/*<div className="info border-top-black">
                                <h4>Cost per mille</h4>
                                <span className="ml-10">Cost per 1,000 exposure of your portrait</span>

                                <div className="row-col">
                                    <div className="col-2">
                                        <div className="input-group">
                                            <label>Minimum</label>
                                            <input
                                                {...register('cpm_min', { valueAsNumber: true })}
                                                onChange={event => setCpmMin(event.target.value)}
                                                className="sm"
                                                type="number"
                                                step={0.1}
                                                placeholder="$ 0.1"
                                            />
                                            <span className="to">to</span>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="input-group">
                                            <label>Maximum</label>
                                            <input
                                                {...register('cpm_max', { valueAsNumber: true })}
                                                onChange={event => setCpmMax(event.target.value)}
                                                className="sm"
                                                type="number"
                                                step={0.1}
                                                placeholder="$ 1"
                                            />
                                            <span className="bold">X 1,000 = </span>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="input-group left">
                                            <label className="expect">Expection</label>
                                            <span className="sub-label">Minimum</span>
                                            <span className="money">
                                                $ {(cpmMin * 1000).toLocaleString()}
                                                <span>to</span>
                                            </span>
                                        </div>
                                        <div className="input-group left">
                                            <label className="expect"> &nbsp;</label>
                                            <span className="sub-label">Maximum</span>
                                            <span className="money">$ {(cpmMax * 1000).toLocaleString()}</span>
                                        </div>
                                    </div>
                                    <div className="col-3 pl-30 pt-10 border-left-black">
                                        <ul>
                                            <li>Average(1yr)</li>
                                            <li>No experience: Max $800</li>
                                            <li>Less 5 portfolio: Max $1,500</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="info border-top-black">
                                <h4>Who referred you? (optional)</h4>
                                <div className="row-col">
                                    {/*<div className="col-4">
                                        <div className="input-group">
                                            <label>Youtube</label>
                                            <input {...register('youtube')} type="text" />
                                        </div>
                                    </div>*/}
                                    <div className="col-4">
                                        <div className="input-group">
                                            <label>email</label>
                                            <input {...register('referee_email')} type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="info border-top-black border-bottom-black">
                                <h4>Filmography</h4>
                                <Filmography register={register} />
                            </div>
                        </div>
                        <div className="btn-group clearfix mt-50">
                            <button type="submit" className="btn btn-alt-primary w-400">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterModel;
