import React from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { ALERT_MODAL_OFF, setModal } from './api/action';
import { useAlertModal } from './AlertModal';

import styled from 'styled-components';

const CookieModal = styled.div`
    .modal-white {
        width: 100%;
        height: 100%;
        background-color: #ffffff80;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 500;
        overflow-y: auto;

        .cookie-notice-container {
            //background: rgb(79, 102, 124);
            //color: #fff;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 3px #00000029;
            color: var(--gray-900);
            display: block;
            left: 0;
            height: 320px;
            position: fixed;
            width: 100%;
            box-sizing: content-box;
            z-index: 110;
            bottom: 0;
            //max-width: 100vw;
            animation-duration: 1.5s;
            animation-name: slidein;
            @keyframes slidein {
                from {
                    bottom: -100px;
                    background: rgba(255, 255, 255, 0.1);
                }
                to {
                    bottom: 0;
                    background: rgba(255, 255, 255, 1);
                }
            }
            .container {
                overflow: hidden;
                padding: 10px 0 !important;
                .cookie-text {
                    margin-top: 72px;
                    //width: 100%;
                    //display: inline-block;
                    font: normal normal normal 2.6rem/3.2rem Roboto;
                    text-align: center;
                    //margin: auto;
                    a {
                        color: var(--purple-800);
                    }
                }
                .cookie-btn-container {
                    text-align: center;
                    margin-top: 52px;
                    //width: 100%;
                    //margin: auto;
                    //display: inline-block;
                    .accept-btn {
                        border: var(--purple-500) 1px solid;
                        background-color: var(--purple-500);
                        color: white;
                        width: 280px;
                        margin: 12px;
                        padding: 17px 27px;
                        font: normal normal normal 1.8rem/2rem Roboto;
                        border-radius: 2px;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                    }
                    .reject-btn {
                        border: var(--purple-500) 1px solid;
                        background-color: #ffffff;
                        color: var(--purple-500);
                        width: 280px;
                        margin: 12px;
                        padding: 17px 27px;
                        font: normal normal normal 1.8rem/2rem Roboto;
                        border-radius: 2px;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                    }
                    .cookie-btn:hover {
                        color: rgb(255, 77, 77);
                        background-color: white;
                    }
                }
            }
        }
        //filter: blur(5px);
    }
`;

const CookieNotice = () => {
    const [cookies, setCookie] = useCookies(['webAcceptsCookies']);
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 365);

    const acceptNecessaryCookie = () => {
        setCookie('webAcceptsCookies', 1, { expires: expirationDate });
    };

    const acceptAllCookie = () => {
        setCookie('webAcceptsCookies', 2, { expires: expirationDate });
    };
    const dispatch = useDispatch();
    const { showModal } = useAlertModal({
        title: 'Cookie policy',
        content:
            'Cookies are stored on your device, and by continuing to use our website, you agree to the use of these cookies. For more information or to manage cookie settings, please refer to our Privacy Policy.',
        buttonText: 'Accept',
        buttonFunction: () => hideModal(),
    });

    const hideModal = () => {
        dispatch(
            setModal({
                type: ALERT_MODAL_OFF,
            })
        );
    };

    const privacyPolicy = () => {
        acceptAllCookie();
        showModal();
    };

    return cookies.webAcceptsCookies ? null : (
        <CookieModal>
            <div className="modal-white">
                <div className="cookie-notice-container">
                    <div className="container">
                        <div className="cookie-text">
                            We use only essential <a href="/">cookies</a> to improve your experience on
                            <br></br>
                            CeleVu such as service login.
                        </div>
                        <div className="cookie-btn-container">
                            <button className="accept-btn" type="button" onClick={() => acceptNecessaryCookie()}>
                                Accept essential cookies
                            </button>
                            <button className="reject-btn" type="button" onClick={() => privacyPolicy()}>
                                Reject all
                            </button>
                            {/*<button className="cookie-btn" type="button" onClick={() => acceptAllCookie()}>
                        Accept all
                    </button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </CookieModal>
    );
};

export default CookieNotice;
