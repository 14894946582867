import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import { getMessageList, sendMessage } from '../../queries/MessageList';

const Message = props => {
    const { userId, contractId } = props;
    const { data, isLoading, isError, error, refetch } = useQuery(['getMessageList'], () =>
        getMessageList({ userId: userId, contractId: contractId })
    );
    const [messageInput, setMessageInput] = useState('');

    const typeMessage = event => {
        setMessageInput(event.target.value);
    };

    const mutation = useMutation(async body => sendMessage({ userId: userId, contractId: contractId, data: body }), {
        onSuccess: async res => {
            setMessageInput('');
            refetch();
        },
        onError: err => {
            switch (err.response.status) {
                case 401:
                    console.log(err);
                    return;
                case 301:
                    console.log(err);
                    return;
                default:
                    console.log(err);
                    return;
            }
        },
    });

    const submitMessage = () => {
        const text = messageInput;
        const body = {
            content: text,
        };
        if (body.content !== '') {
            mutation.mutate(body);
        }
    };

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;
    return (
        <>
            <div className="modal-tab-container">
                <div className="modal-tab-content" id="messageTab">
                    <ul>
                        {data.map((message, index) => (
                            <li key={index}>
                                <div className="message-name">{message.sender.name}</div>
                                <div className="message-content">
                                    <span>{message.created}</span>
                                    <br />
                                    <span>{message.content}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="input-group-modal">
                <div className="input-message">
                    <textarea value={messageInput} onChange={typeMessage} placeholder="Type a message here"></textarea>
                    <button type="button" onClick={submitMessage} className="btn btn-outline-send right">
                        Send
                    </button>
                </div>
            </div>
        </>
    );
};

export default Message;
