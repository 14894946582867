import React from 'react';
import LeftMenu from './LeftMenu';
import ModelsDetail from './ModelsDetail';

function MyCelebs() {
    return (
        <div className="page-container">
            <LeftMenu />
            <ModelsDetail />
            {/*{id ? projectDetail : createProject}*/}
        </div>
    );
}

export default MyCelebs;
