//import React, { useEffect, useState, useRef } from 'react';

//const CarouseNonSelect = props => {
//    const { images, imageCount, mainWidth, mainHeight } = props;
//    const [currentImageIndex, setCurrentImageIndex] = useState(0);
//    const [leftArrow, setLeftArrow] = useState(false);
//    const [rightArrow, setRightArrow] = useState(true);
//    const listRef = useRef(null);
//    const width = mainWidth / imageCount;
//    const height = mainHeight / imageCount;

//    useEffect(() => {
//        if (images.length) {
//            listRef.current.style.transition = 'all 0.5s ease-in-out';
//            listRef.current.style.transform = `translateX(${currentImageIndex * -width}px)`;
//            if (currentImageIndex === 0) {
//                setLeftArrow(false);
//            } else {
//                setLeftArrow(true);
//            }
//            if (currentImageIndex >= images.length - imageCount) {
//                setRightArrow(false);
//            } else {
//                setRightArrow(true);
//            }
//        }
//        //console.log('count', imageCount);
//    }, [currentImageIndex, images.length]);

//    useEffect(() => {
//        setCurrentImageIndex(images.length - 1);
//    }, [images]);
//    //length 3 current 1 left true right true
//    if (!images.length || !images) return <></>;

//    const leftButtonIndex = () => {
//        //if (leftArrow) {
//        const nextIndex = currentImageIndex - 1;

//        if (nextIndex >= 0) {
//            setCurrentImageIndex(nextIndex);
//        }
//        //}
//    };

//    const rightButtonIndex = () => {
//        //if (rightArrow) {
//        const nextIndex = currentImageIndex + 1;

//        if (nextIndex >= 0 && nextIndex <= images.length - imageCount) {
//            setCurrentImageIndex(nextIndex);
//        }
//        //}
//    };

//    return (
//        <div style={{ overflow: 'hidden', width: `${mainWidth}px`, position: 'relative' }}>
//            <div className="nav-arrow-box">
//                {leftArrow ? (
//                    <i
//                        onClick={leftButtonIndex}
//                        className={'icon-left-open'}
//                        style={{
//                            position: 'absolute',
//                            left: '0',
//                            top: `${height / 2}px`,
//                            zIndex: '1',
//                            cursor: 'pointer',
//                        }}
//                    />
//                ) : null}
//                {rightArrow ? (
//                    <i
//                        onClick={rightButtonIndex}
//                        className={'icon-right-open'}
//                        style={{
//                            position: 'absolute',
//                            right: '0',
//                            top: `${height / 2}px`,
//                            zIndex: '1',
//                            cursor: 'pointer',
//                        }}
//                    />
//                ) : null}
//            </div>
//            <div
//                className="img-container"
//                ref={listRef}
//                style={{
//                    display: 'flex',
//                    justifyContent: 'left',
//                    alignItems: 'center',
//                    position: 'relative',
//                    width: `${width * images.length}px`,
//                }}
//            >
//                {images.map((image, index) => {
//                    return (
//                        <img
//                            key={index}
//                            style={{ width: `${width}px`, height: `${height}px`, objectFit: 'contain' }}
//                            src={image.portfolio.file}
//                            alt={index}
//                        />
//                    );
//                })}
//            </div>
//        </div>
//    );
//};

//export default CarouseNonSelect;

import React, { useEffect, useState, useRef } from 'react';

const CarouseNonSelect = props => {
    const { images, imageCount, width, height, idHandler, children } = props;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [leftArrow, setLeftArrow] = useState(false);
    const [rightArrow, setRightArrow] = useState(true);
    const listRef = useRef(null);

    useEffect(() => {
        idHandler(images[currentImageIndex]?.id);
        if (images.length) {
            listRef.current.style.transition = 'all 0.5s ease-in-out';
            listRef.current.style.transform = `translateX(${currentImageIndex * -width}px)`;
            if (currentImageIndex === 0) {
                setLeftArrow(false);
            } else {
                setLeftArrow(true);
            }
            if (currentImageIndex >= images.length - imageCount) {
                setRightArrow(false);
            } else {
                setRightArrow(true);
            }
        }
    }, [currentImageIndex, images]);

    useEffect(() => {
        setCurrentImageIndex(images.length - 1);
    }, [images]);

    if (!images.length || !images) return <></>;

    const leftButtonIndex = () => {
        const nextIndex = currentImageIndex - 1;

        if (nextIndex >= 0) {
            setCurrentImageIndex(nextIndex);
            //idHandler(images[currentImageIndex]?.id);
        }
    };

    const rightButtonIndex = () => {
        const nextIndex = currentImageIndex + 1;

        if (nextIndex >= 0 && nextIndex <= images.length - imageCount) {
            setCurrentImageIndex(nextIndex);
            //idHandler(images[currentImageIndex]?.id);
        }
    };

    return (
        <>
            <div style={{ overflow: 'hidden', width: `${width * imageCount}px`, position: 'relative' }}>
                <div className="nav-arrow-box">
                    <i
                        onClick={leftButtonIndex}
                        className="icon-left-open"
                        style={{
                            position: 'absolute',
                            left: '0',
                            top: `${height / 2.2}px`,
                            fontSize: '25px',
                            zIndex: '1',
                            cursor: 'pointer',
                            display: leftArrow ? 'block' : 'none',
                        }}
                    />
                    <i
                        onClick={rightButtonIndex}
                        className="icon-right-open"
                        style={{
                            position: 'absolute',
                            right: '0',
                            top: `${height / 2.2}px`,
                            fontSize: '25px',
                            zIndex: '1',
                            cursor: 'pointer',
                            display: rightArrow ? 'block' : 'none',
                        }}
                    />
                </div>
                <div
                    className="img-container"
                    ref={listRef}
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        position: 'relative',
                        width: `${width * images.length}px`,
                    }}
                >
                    {images.map((image, index) => {
                        return (
                            <div>
                                <img
                                    key={index}
                                    style={{ width: `${width}px`, height: `${height}px`, objectFit: 'contain' }}
                                    src={image.portfolio.file}
                                    alt={index}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {children ? children : null}
        </>
    );
};

export default CarouseNonSelect;
