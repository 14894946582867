import React from 'react';
import styled from 'styled-components';
import Button from '../../components/styled-components/Button';
import { useUrl } from '../../hooks/useUrl';

const PageTitle = styled.div`
    margin: 100px auto 40px;
    text-align: center;
    .logo {
        display: inline-block;
        width: 200px;
    }
    .description {
        margin-left: 12px;
        color: var(--gray-900);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
    }
`;

const Contents = styled.div`
    width: 620px;
    border-radius: 15px;
    background-color: var(--white);
    margin: 40px auto 100px;
    padding: 64px 50px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    .title {
        margin-bottom: 32px;
        color: var(--gray-900);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 100% */
    }
    .description {
        color: var(--gray-900);
        margin-bottom: 60px;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
    }
`;

const WelcomePage = () => {
    const { navigateToPage } = useUrl();
    return (
        <div>
            <PageTitle>
                <div className="logo">
                    <a href="/">
                        <img src="/svg/CeleVu_logo_b.svg" alt="logo" />
                    </a>
                </div>
                <div className="description">Sign up for CeleVu account.</div>
            </PageTitle>
            <Contents>
                <div className="title">Congratulations</div>
                <div className="description">Sign up is complete.</div>
                <Button type="button" variant="primary" size="lg" onClick={() => navigateToPage('/login')}>
                    Log in
                </Button>
            </Contents>
        </div>
    );
};

export default WelcomePage;
