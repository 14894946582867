import React, { useState } from 'react';
import LeftMenu from './LeftMenu';
import RegisterModelPage from './RegisterModel';
import RegisterModelDetail from './RegisterModelDetail';

const RegisterModels = () => {
    return (
        <div className="page-container">
            <LeftMenu />
            <RegisterModelPage />
            {/*<RegisterModelDetail />*/}
        </div>
    );
};

export default RegisterModels;
