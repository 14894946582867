import axios from 'axios';
import { BASE_URL } from '../configs/common';

const axiosInstance = axios.create({ baseURL: BASE_URL });

export const Login = async userCredentials => {
    if (!userCredentials) {
        throw new Error('Not user credentials');
    }

    const response = await axiosInstance.post('/api/v1/token/', userCredentials);
    return response.data;
};

export const login = async userCredentials => {
    if (!userCredentials) {
        throw new Error('Not user credentials');
    }

    const response = await axiosInstance.post('/api/v1/token/', userCredentials);
    return response.data;
};
