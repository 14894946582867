import React from 'react';
import { ModalFrame } from './ModalUtils';
import { ALERT_MODAL } from './api/action';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../components/styled-components/Button';

const AlertModalContent = styled.div`
    margin: 0 auto;
    text-align: center;
    .title {
        margin-top: 40px;
        color: var(--gray-900);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem;
    }
    .text {
        margin-top: 16px;
        margin-bottom: 40px;
        color: var(--gray-700);
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.2rem; /* 157.143% */
        white-space: pre-wrap;
    }
`;

const Alert = () => {
    const alertState = useSelector(state => state.modal.alert);

    return (
        <ModalFrame width="456px" height="auto" modalType={ALERT_MODAL} modalName="alert">
            <AlertModalContent>
                <h1 className="title">{alertState.title}</h1>
                <div className="text">{alertState.content}</div>
                <Button type="button" variant="primary" size="sm" onClick={alertState.buttonFunction}>
                    {alertState.buttonText}
                </Button>
            </AlertModalContent>
        </ModalFrame>
    );
};

export default Alert;
