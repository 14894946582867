import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ImageViewContainer = styled.div`
    position: relative;
    user-select: none;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    overflow: hidden;
    .arrow-box {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    justify-content: left;
    width: ${props => props.imagesLength * 100 + '%'};
    transition: all 0.4s ease-in-out;
    transform: translateX(${props => props.imageIndex * (-100 / props.imagesLength) + '%'});
    img {
        width: ${props => props.width}px;
        height: ${props => props.height}px;
        object-fit: cover;
    }
`;

const ArrowIcon = styled.button`
    font-size: 6rem;
    color: ${props => (props.disabled ? '#d0d0d0EE' : '#FBFBFB')};
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    z-index: 10;
`;

const ImageSlider = props => {
    const { images, width, height, ...options } = props;
    const [imageIndex, setImageIndex] = useState(0);
    const [arrowStatus, setArrowStatus] = useState({
        leftArrow: false,
        rightArrow: false,
    });
    const imagesLength = images.length;

    useEffect(() => {
        setArrowStatus(prevArrowStatus => ({
            ...prevArrowStatus,
            leftArrow: imageIndex === 0,
            rightArrow: imageIndex === imagesLength - 1,
        }));
    }, [imageIndex]);

    const onClickLeftArrow = e => {
        const nextIndex = imageIndex - 1;

        if (nextIndex >= 0) {
            setImageIndex(nextIndex);
        }
        e.stopPropagation();
    };

    const onClickRightArrow = e => {
        const nextIndex = imageIndex + 1;

        if (nextIndex < imagesLength) {
            setImageIndex(nextIndex);
        }
        e.stopPropagation();
    };

    return (
        <ImageViewContainer width={width} height={height} {...options}>
            <ImagesContainer imagesLength={imagesLength} imageIndex={imageIndex}>
                {images.map((image, index) => (
                    <img key={index} src={image} width={width} height={height} />
                ))}
            </ImagesContainer>
            <div className="arrow-box">
                <ArrowIcon
                    type="button"
                    className="arrow material-icons"
                    onClick={e => onClickLeftArrow(e)}
                    disabled={arrowStatus.leftArrow}
                >
                    navigate_before
                </ArrowIcon>
                <ArrowIcon
                    type="button"
                    className="arrow material-icons"
                    onClick={e => onClickRightArrow(e)}
                    disabled={arrowStatus.rightArrow}
                >
                    navigate_next
                </ArrowIcon>
            </div>
        </ImageViewContainer>
    );
};

export default ImageSlider;
