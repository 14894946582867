import React, { useState } from 'react';
import Breadcrumb from '../header/Breadcrumb';
import ProjectInfo from './ProjectInfo';
import ProjectModelList from '../../components/models/ProjectModelList';
import { useQueries, useQuery } from 'react-query';
import { getProjectDetail } from '../../queries/GetProjectList';
import Loading from '../../components/contents/Loading';
import Error from '../../components/contents/Error';
import ProjectProgress from './ProjectProgress';
import { getMyModelsContractDetail } from '../../queries/GetMyModelsContract';
import { useEffect } from 'react';

const ProjectDetail = props => {
    const { selectProjectId, userId } = props;

    const [viewState, setViewState] = useState(false);
    const [status, setStatus] = useState('Casting');
    const [contractId, setContractId] = useState(0);

    const project = useQuery(
        ['getProjectDetail', userId, selectProjectId],
        () => getProjectDetail({ userId: userId, projectId: selectProjectId }),
        {
            onSuccess: data => {
                setContractId(data.contract);
            },
        }
    );
    const contract = useQuery(['getMyModelsContractDetail'], () =>
        getMyModelsContractDetail({ contractId: contractId }, { enabled: !!contractId })
    );
    useEffect(() => {
        contract.refetch();
    }, [selectProjectId]);

    //const [projectDetail, setProjectDetail] = useState({});
    //const [contractDetail, setContractDetail] = useState({});
    //const response = useQueries([
    //    {
    //        queryKey: ['getProjectDetail', userId, selectProjectId],
    //        queryFn: () => getProjectDetail({ userId: userId, projectId: selectProjectId }),
    //        onSuccess: data => {
    //            setProjectDetail(data);
    //        },
    //    },
    //    {
    //        queryKey: ['getMyModelsContractDetail'],
    //        queryFn: () => getMyModelsContractDetail({ contractId: projectDetail.contract }),
    //        onSuccess: data => {
    //            setContractDetail(data);
    //        },
    //    },
    //]);

    const setViewStateHandler = () => {
        setViewState(!viewState);
    };

    if (project.isLoading) {
        return <Loading />;
    }
    if (!project.data) return <></>;
    if (project.isError) return <Error errMsg={project.error.message} />;

    return (
        <div className="row px-60">
            <div className="row px-60 mb-10">
                <Breadcrumb value={['Project', 'Current project']} />
                <h1 className="fl">{project.data.title}</h1>
                <ProjectProgress contractId={project.data.contract} status={status} setStatus={setStatus} />
                {/*<span className="project-date">Sep 1 2022 ~ Sep 7 2022 (7 days)</span>*/}
            </div>
            <div className="row px-60 info">
                <ProjectInfo detail={project.data} viewState={viewState} setViewStateHandler={setViewStateHandler} />

                <ProjectModelList
                    //features={project.data.features}
                    viewState={viewState}
                    projectId={project.data?.id}
                    productStatus={status}
                    contractId={project.data.contract}
                    //productStatus={projectDetailData.data?.status}
                />
            </div>
        </div>
    );
};

ProjectDetail.defaultProps = {
    title: '3CE Lipstick Advertisement in France',
};
export default ProjectDetail;
