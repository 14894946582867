import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Input from '../../components/input/Input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../components/styled-components/Button';
import { useMutation } from 'react-query';
import { setAuthTokens } from 'axios-jwt';
import { setUser } from './api/action';
import { UserSelf } from '../../queries/UserSelf';
import { login } from '../../queries/Auth';
import { useDispatch } from 'react-redux';
import { useUrl } from '../../hooks/useUrl';
import { useModal } from '../modal/ModalUtils';
import { FORGOT_PASSWORD_MODAL } from '../modal/api/action';

const schema = yup
    .object({
        username: yup.string().label('ID').required(),
        password: yup.string().label('Password').required(),
    })
    .required();

const Wrapper = styled.div`
    margin: 100px;
`;

const PageTitle = styled.div`
    margin: 100px auto 40px;
    text-align: center;
    .logo {
        display: inline-block;
        width: 200px;
    }
    .description {
        margin-left: 12px;
        color: var(--gray-900);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
    }
`;

const LoginForm = styled.div`
    width: 616px;
    border-radius: 15px;
    background-color: var(--white);
    margin: 0 auto;
    padding: 48px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    .button-area {
        margin-top: 28px;
        .forgot-password {
            cursor: pointer;
            text-align: center;
            color: var(--blue-900);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2rem; /* 100% */
            margin: 16px auto 36px;
        }
        .horizontal-line {
            text-align: center;
            position: relative;
            color: var(--gray-400);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2rem; /* 100% */
            margin-bottom: 36px;
            .text {
                margin: auto;
                position: relative;
                background-color: var(--white);
                padding: 0 8px;
                z-index: 1;
                width: 15px;
            }
        }
        .horizontal-line::before {
            content: '';
            position: absolute;
            top: 50%; /* 엘리먼트 중간에 배치 */
            left: 0;
            right: 0;
            width: 100%;
            height: 1px; /* 가로선의 높이 */
            background-color: var(--gray-400); /* 가로선의 색상 설정 */
        }
    }
`;

const InputGroup = styled.div`
    width: 520px;
    margin: auto;
    label.require {
        ::after {
            content: ' *';
            color: var(--crimson-500);
        }
    }
    label.optional {
        ::after {
            content: ' (optional)';
            font-weight: 350;
        }
    }
`;

const LabelStyle = styled.label`
    /*display: flex;
    align-items: center;
    justify-content: flex-start;*/
    display: inline-block;
    color: var(--gray-800);

    margin-left: 1px;
    margin-bottom: 16px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem;
`;

const ErrorLabel = styled.label`
    margin-left: 1px;
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: top;
    justify-content: flex-start;
    color: var(--crimson-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const Login = () => {
    const dispatch = useDispatch();
    const { navigateToPage } = useUrl();
    const { showModal } = useModal(FORGOT_PASSWORD_MODAL);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const mutation = useMutation(async data => login(data), {
        onSuccess: async res => {
            setAuthTokens({
                accessToken: res.access,
                refreshToken: res.refresh,
            });
            const userData = await UserSelf();
            dispatch(setUser(userData));
            navigateToPage('/');
        },
        onError: err => {
            switch (err.response.status) {
                case 400:
                    alert('No active account found with the given credentials');
                    break;
                case 401:
                    setError('username', { type: 'username', message: '일치하는 정보가 없습니다' });
                    setError('password', { type: 'password', message: '일치하는 정보가 없습니다' });
                    alert('로그인에 실패 하였습니다.');
                    break;
                case 403:
                    alert('권한이 없습니다');
                    break;
                default:
                    console.log(err.response.data.detail);
                    console.log('default');
                    break;
            }
        },
    });
    const onSubmit = data => {
        mutation.mutate(data);
    };

    return (
        <Wrapper>
            <PageTitle>
                <div className="logo">
                    <a href="/">
                        <img src="/svg/CeleVu_logo_b.svg" alt="logo" />
                    </a>
                </div>
                <div className="description">Log in for CeleVu account.</div>
            </PageTitle>
            <LoginForm>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                        <LabelStyle className="require">Email</LabelStyle>
                        <Input
                            type="text"
                            name="username"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.username ? errors.username : null}
                        />
                        {errors.username ? <ErrorLabel>{errors.username.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <InputGroup>
                        <LabelStyle className="require">Password</LabelStyle>
                        <Input
                            type="password"
                            name="password"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.password ? errors.password : undefined}
                        />
                        {errors.password ? <ErrorLabel>{errors.password.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <div className="button-area">
                        <Button type="submit" variant="primary" size="lg">
                            Log in
                        </Button>
                        <div className="forgot-password" onClick={showModal}>
                            Forgot Password?
                        </div>
                        <div className="horizontal-line">
                            <span className="text">or</span>
                        </div>
                        <Button type="button" variant="gray" size="lg" onClick={() => navigateToPage('/register')}>
                            Sign up
                        </Button>
                    </div>
                </form>
            </LoginForm>
        </Wrapper>
    );
};

export default Login;
