import React from 'react';

const Pager = ({ data, query, setQueryPageHandler }) => {
    const getPagination = () => {
        let minPage = query.page - ((query.page - 1) % 10);
        let maxPage = Math.ceil(query.page / 10) * 10;
        if (maxPage >= data.count / data.page_size) maxPage = Math.ceil(data.count / data.page_size);
        const pageArray = [];
        for (let i = minPage; i <= maxPage; i++) {
            pageArray.push(i);
        }
        return pageArray;
    };
    if (!data) {
        return (
            <ul>
                <li>
                    <a href="#">
                        <i
                            className="icon-left-open"
                            onClick={() => {
                                setQueryPageHandler(query.page - 1);
                            }}
                        ></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i
                            className="icon-right-open"
                            onClick={() => {
                                setQueryPageHandler(query.page + 1);
                            }}
                        ></i>
                    </a>
                </li>
            </ul>
        );
    }
    return (
        <ul>
            <li>
                <a href="#">
                    <i
                        className="icon-left-open"
                        onClick={() => {
                            setQueryPageHandler(query.page - 1);
                        }}
                    ></i>
                </a>
            </li>
            {getPagination().map((pages, index) =>
                query.page === pages ? (
                    <li
                        className="selected"
                        key={pages}
                        onClick={() => {
                            setQueryPageHandler(pages);
                        }}
                    >
                        {pages}
                    </li>
                ) : (
                    <li
                        key={pages}
                        onClick={() => {
                            setQueryPageHandler(pages);
                        }}
                    >
                        {pages}
                    </li>
                )
            )}
            <li>
                <a href="#">
                    <i
                        className="icon-right-open"
                        onClick={() => {
                            setQueryPageHandler(query.page + 1);
                        }}
                    ></i>
                </a>
            </li>
        </ul>
    );
};

export default Pager;
