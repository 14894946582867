import React from 'react';
import Layout from '../modules/common/Layout';
import MainContainer from '../components/MainContainer';
import Instagram from '../components/celebs/Instagram';

const InstagramPage = () => {
    return (
        <Layout>
            <MainContainer>
                <Instagram />
            </MainContainer>
        </Layout>
    );
};

export default InstagramPage;
