import React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getProjectFeatures } from '../../queries/GetProjectList';
import { getAdPostingCountries, getMBTIString } from '../../utils/common';
import { OFFER_DETAIL_MODAL, setOfferModal } from '../modal/api/action';
import ProductChart from './ProductChart';

const ProjectInfo = props => {
    const { detail, viewState, setViewStateHandler } = props;
    const contractId = detail.contract ? detail.contract : 0;

    const data = [
        {
            id: 3006,
            name: 'feature9',
            value: 8.0,
        },
        {
            id: 3005,
            name: 'feature8',
            value: 3.5,
        },
        {
            id: 3004,
            name: 'feature7',
            value: 8.4,
        },
        {
            id: 3003,
            name: 'feature6',
            value: 0.4,
        },
        {
            id: 3002,
            name: 'Smart',
            value: 4.8,
        },
    ];
    const dispatch = useDispatch();
    const showOfferDetailModal = () => {
        dispatch(
            setOfferModal({
                type: OFFER_DETAIL_MODAL,
                data: {
                    status: true,
                    contract_id: contractId,
                },
            })
        );
    };

    const viewStateOn = (
        <span onClick={setViewStateHandler} className="view-link">
            <i>View less </i>
            <img src="/images/arrow-up-icon.svg" alt="" />
        </span>
    );

    const viewStateOff = (
        <span onClick={setViewStateHandler} className="view-link">
            <i>View more </i>
            <img src="/images/arrow-down-icon.svg" alt="" />
        </span>
    );

    const adPostingCountriesString = getAdPostingCountries(detail.ad_posting_countries);

    return (
        <>
            <table>
                <colgroup>
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    {/*<col style={{ width: '20%' }} />
                    <col style={{ width: '25%' }} />*/}
                </colgroup>
                <tbody>
                    <tr>
                        <td colSpan={1}>
                            <span className="title">Product</span>
                        </td>
                        <td colSpan={2}>
                            <span>{detail.product}</span>
                        </td>
                        {/*<td className="border-left-black" colSpan={2} rowSpan={6}>
                            <ProductChart data={detail.feature} />
                        </td>*/}
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <span className="title">Brand</span>
                        </td>
                        <td colSpan={2}>
                            <span>{detail.brand_name}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <span className="title">Title</span>
                        </td>
                        <td colSpan={2}>
                            <span>{detail.title}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <span className="title">Ad posting countury</span>
                        </td>
                        <td colSpan={2}>
                            <span>{adPostingCountriesString}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <span className="title">Contract information</span>
                        </td>
                        <td colSpan={2}>
                            {!contractId ? (
                                <span className="round">N / A</span>
                            ) : (
                                <span
                                    onClick={showOfferDetailModal}
                                    className="view-link"
                                    //style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                >
                                    View
                                    {/*<span className="num">{detail.newMessageCount}</span>*/}
                                </span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <span className="title">Celeb recommendation</span>
                        </td>
                        <td colSpan={2}>{viewState ? viewStateOn : viewStateOff}</td>
                    </tr>
                </tbody>
            </table>

            {/* <table>
                <colgroup>
                    <col width="25%" />
                    <col width="45%" />
                    <col width="30%" />
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            <span className="title">Product</span>
                            <span>{detail.product}</span>
                        </td>
                        <td>
                            <span className="title">Product Name</span>
                            <span>{detail.title}</span>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <span className="title">Ad posting countury</span>
                            <span>{adPostingCountriesString}</span>
                        </td>
                        <td>
                            <span className="title">Offer</span>
                            {!contractId ? (
                                <span className="round">N / A</span>
                            ) : (
                                <span onClick={showOfferDetailModal} className="view-link">
                                    View
                                    <span className="num">{detail.newMessageCount}</span>
                                </span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <span className="title">Matching Celebrities</span>
                            <span>Set the feature weight and get different Celeb recommendation</span>
                        </td>
                        <td>{viewState ? viewStateOn : viewStateOff}</td>
                    </tr>
                </tbody>
            </table> */}
        </>
    );
};

export default ProjectInfo;
