import axios from 'axios';
import { applyAuthTokenInterceptor, clearAuthTokens, getAccessToken } from 'axios-jwt';
import { BASE_URL } from '../configs/common';

export const publicClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const privateClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const sampleClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'text/plain',
    },
});

const requestRefresh = async refreshToken => {
    const response = await axios.post(
        '/api/v1/token/refresh/',
        {
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
        },
        {
            body: {
                refresh: refreshToken,
            },
        }
    );

    return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
    };
};

applyAuthTokenInterceptor(privateClient, { requestRefresh });
