import axios from 'axios';
import { BASE_URL } from '../configs/common';

const axiosInstance = axios.create({ baseURL: BASE_URL });

export const Register = async userCredentials => {
    if (!userCredentials) {
        throw new Error('Not user credentials');
    }
    const response = await axiosInstance.post('/api/v1/users/', userCredentials);
    //const response = await axiosInstance.post('/user/ad-agency/', userCredentials);
    return response.data;
};

export const registerUser = async userCredentials => {
    if (!userCredentials) {
        throw new Error('Not user credentials');
    }
    const response = await axiosInstance.post('/api/v1/users/', userCredentials);
    //const response = await axiosInstance.post('/user/ad-agency/', userCredentials);
    return response.data;
};

export const AddMailing = async data => {
    if (!data) {
        throw new Error('Not user credentials');
    }
    const response = await axiosInstance.post('/api/v1/mailing/', data);
    return response.data.data;
};

//export const register = async userCredentials => {
//    if (!userCredentials) {
//        throw new Error('Not user credentials');
//    }
//    const response = await axiosInstance.post('/user/celeb-agency/', userCredentials);
//    return response.data;
//};
