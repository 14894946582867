import React from 'react';
import styled, { css } from 'styled-components';

const sizeStyles = {
    default: {
        width: '420px',
        height: '60px',
        font: 'normal normal 700 2rem/2rem inter',
    },
    sm: {
        fontSize: '1.2rem',
        padding: '6px 10px',
        fontWeight: '500',
    },
    md: {
        width: '164px',
        height: '40px',
        font: 'normal normal 700 1.5rem/1.8rem inter',
    },
    lg: {
        width: '392px',
        height: '78px',
        font: 'normal normal 700 2.5rem/2.5rem inter',
    },
    instagram: {
        width: '420px',
        height: '60px',
        font: 'normal normal 700 1.5rem/1.5rem inter',
    },
    instagram_sm: {
        width: '224px',
        height: '40px',
        font: 'normal normal 400 1.3rem/1.3rem inter',
    },
};

const colorStyles = {
    default: {
        background: 'linear-gradient(240.64deg, #fef3c7 0%, #860cf9 62.4%, #5900e5 100%)',
        color: 'var(--gray-050)',
    },
    contained: {
        background: 'linear-gradient(240.64deg, #fef3c7 0%, #860cf9 62.4%, #5900e5 100%)',
        color: 'var(--gray-050)',
    },
    outlined: {
        background: 'var(--gray-050)',
        border: '1px solid #860CF9',
        color: '#860CF9',
    },
    instagram: {
        background:
            'linear-gradient(315deg, #FBE18A 0%, #FCBB45 21.00%, #F75274 38.00%, #D53692 52.00%, #8F39CE 74.00%, #5B4FE9 100%)',
        boxShadow: '0px 0px 5px 0px #D9D9D9',
        color: 'var(--gray-050)',
    },
    deactivation: {
        background: '#FFF',
        boxShadow: '0px 0px 5px 0px #D9D9D9',
        color: '#A4A4A4',
    },
    instagram_sm: {
        borderRadius: '4px',
        background: 'var(--gray-050)',
        boxShadow: '0px 0px 10px 0px rgba(37, 37, 37, 0.20)',
        color: '#EC0E5D',
    },
};

const ApplyButtonStyle = styled.button`
    display: block;
    /*background: linear-gradient(240.64deg, #fef3c7 0%, #860cf9 62.4%, #5900e5 100%);*/
    /*color: var(--gray-050);*/
    border-radius: 10px;
    &:disabled,
    &[disabled] {
        background: #c2c2c2;
        cursor: default;
    }
    ${props => sizeStyles[props.size] || sizeStyles.default}
    ${props => colorStyles[props.variant] || colorStyles.default}
`;

const GradientText = styled.p`
    background: linear-gradient(45deg, #5900e5, #860cf9, #fef3c7);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: normal normal 900 3.6rem/4.6rem Roboto;
`;

const ApplyButton = props => {
    const { children, isDisabled, onClick, size, variant, ...options } = props;
    return (
        <ApplyButtonStyle
            type="button"
            {...options}
            variant={variant}
            onClick={onClick}
            disabled={isDisabled}
            size={size}
        >
            {children}
        </ApplyButtonStyle>
    );
};

export default ApplyButton;
