import React from 'react';
import TermsOfUseDetail from '../components/contents/TermsOfUseDetail';
import MainContainer from '../components/MainContainer';
import Layout from '../modules/common/Layout';

const TermsOfUse = () => {
    return (
        <Layout>
            <MainContainer>
                <TermsOfUseDetail />
            </MainContainer>
        </Layout>
    );
};

export default TermsOfUse;
