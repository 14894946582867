import React, { useState } from 'react';
import styled from 'styled-components';
import { ModalFrame, useModal } from './ModalUtils';
import { CONTRACT_OFFER_MODAL, CONTRACT_PROJECT_MODAL, CREATE_PROJECT_MODAL } from './api/action';
import Scrollbar from '../../components/styled-components/Scrollbar';
import CustomScrollbar from '../../components/styled-components/Scrollbar';
import Button from '../../components/styled-components/Button';
import { useUrl } from '../../hooks/useUrl';
import { useSelector } from 'react-redux';
import { useProjectList } from '../../hooks/getCode';

const Contents = styled.div`
    margin: 24px;
    height: 100%;
    .button-area {
        overflow: hidden;

        margin-top: 48px;
    }
`;
const ModalTitle = styled.div`
    h1 {
        font: normal normal 700 2.4rem/2.4rem inter;
        color: var(--gray-gray-800, #474747);
    }
    h4 {
        margin-top: 12px;
        color: var(--gray-gray-800, #474747);
        font: normal normal 400 1.4rem/1.8rem inter;
    }
    p {
        margin-top: 36px;
        color: #474747;
        font: normal normal 700 1.8rem/2.4rem inter;
    }
`;

const SubText = styled.p`
    font: normal normal 400 1.4rem/1.8rem inter;
    color: var(--gray-gray-500, #a4a4a4);
`;

const CreateProject = styled.div`
    text-align: center;
    img {
        width: 67px;
        height: 68px;
    }
`;

const EmptyProject = styled.div`
    height: 180px;
    text-align: center;
    position: relative;
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
            width: 66px;
            height: 60px;
            user-drag: none;
            cursor: pointer;
        }
    }
    p {
        font: normal normal 400 1.4rem/1.8rem inter;
        color: var(--gray-gray-500, #a4a4a4);
    }
`;

const ProjectListContainer = styled.div`
    display: flex;
    margin: 28px auto;
`;

const CreateProjectBox = styled.div`
    margin: 6px;
    width: 140px;
    height: 180px;
    position: relative;
    div {
        width: 180px;
        img {
            width: 67px;
            height: 68px;
            user-drag: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }
`;

const ProjectBox = styled.div`
    margin: 6px;
    width: 140px;
    height: 180px;
    border-radius: 8px;
    background-color: #fbfbfb;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.3);
    padding: 16px;
    p.title {
        width: 112px;
        height: 3.6rem;
        margin: 8px 0 24px 0;
        font: normal normal 700 1.4rem/1.8rem inter;
        color: var(--gray-gray-800, #474747);
        /*white-space: wrap;*/
        overflow: hidden;
        /*text-overflow: ellipsis;*/
    }
    p.text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 4px;
        width: 100%;
        font: normal normal 400 1.3rem/1.4rem inter;
        color: var(--gray-gray-800, #474747);
    }
    &:hover {
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px #af6efd;
    }
    &.selected {
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px #af6efd;
    }
`;

export const getLabelString = array => {
    let ret = '';

    if (!array) return ret;

    array.forEach(element => {
        ret += element.label + ', ';
    });

    return ret.substring(0, ret.length - 2);
};

const ContractProjectModal = () => {
    const { hideModal } = useModal(CONTRACT_PROJECT_MODAL);
    const { showModal } = useModal(CONTRACT_OFFER_MODAL);
    //const showCreateProjectModal = useModal(CREATE_PROJECT_MODAL).showModal;
    const { navigateToPage } = useUrl();
    const { data } = useProjectList();
    const [projectIndex, setProjectIndex] = useState(null);
    const celebPhoto = useSelector(state => state.modal.contractProjectModal.photo);
    const celebId = useSelector(state => state.modal.contractProjectModal.celebId);

    const onClickCreateProject = () => {
        hideModal();
        navigateToPage('/project/create/');
    };

    const onClickConfirm = () => {
        hideModal();
        showModal({ ...data[projectIndex], photo: celebPhoto, celebId: celebId });
    };

    const ProjectList = (
        <ProjectListContainer>
            <CreateProjectBox>
                <div onClick={onClickCreateProject}>
                    <img src="/images/create-project.png" />
                </div>
            </CreateProjectBox>
            {data.map((project, index) => (
                <ProjectBox
                    key={project.id}
                    onClick={() => setProjectIndex(index)}
                    className={index === projectIndex ? 'selected' : ''}
                >
                    <p className="title">{project.title}</p>
                    <p className="text">{project.detail}</p>
                    <p className="text">{project.budget ? project.budget.label : ' '}</p>
                    <p className="text">{project.schedule ? project.schedule.label : ' '}</p>
                    <p className="text">{getLabelString(project.countries)}</p>
                </ProjectBox>
            ))}
        </ProjectListContainer>
    );

    return (
        <ModalFrame width="864px" modalType={CONTRACT_PROJECT_MODAL} modalName="contractProjectModal">
            <Contents>
                <ModalTitle>
                    <h1>Current Project</h1>
                    <h4>Choose your project for this contract</h4>
                </ModalTitle>
                <CustomScrollbar width={768}>{ProjectList}</CustomScrollbar>
                {/*<CreateProject>
                        <img src="/svg/create-project.svg" />
                        <SubText>Create Project!</SubText>
                    </CreateProject>*/}
                {/*<ModalTitle>
                    <p>Previous Project</p>
                </ModalTitle>
                <EmptyProject>
                    <div>
                        <img src="/images/empty-project.png" />
                        <SubText>Empty!</SubText>
                    </div>
                </EmptyProject>*/}
                <div className="button-area">
                    <Button
                        size="sm"
                        type="button"
                        variant="primary"
                        style={{ float: 'right' }}
                        onClick={onClickConfirm}
                    >
                        Confirm
                    </Button>
                </div>
            </Contents>
        </ModalFrame>
    );
};

export default ContractProjectModal;
