import React from 'react';

const GoogleTrendsIcon = props => {
    const { width, height, color } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={color}>
            <mask
                id="mask0_2481_16204"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2481_16204)">
                <path
                    d="M3.0625 15.6458L2 14.5833L7.85417 8.72917L11.0208 11.8958L15.4375 7.5H13V6H18V11H16.5V8.5625L11.0208 14.0208L7.85417 10.8542L3.0625 15.6458Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

GoogleTrendsIcon.defaultProps = {
    width: 20,
    height: 20,
    color: 'var(--gray-500)',
};

export default GoogleTrendsIcon;
