import React from 'react';
import styled from 'styled-components';

const MainContainerStyle = styled.main`
    width: 1440px;
    min-height: 720px;
    margin: 0 auto;
    /*background-color: #fbfbfb;*/
`;
const LargeContainerStyle = styled.main`
    min-width: 1440px;
    width: 100%;
    min-height: 720px;
    margin: 0 auto;
    /*background-color: #fbfbfb;*/
`;

const MainContainer = ({ children }) => {
    return <MainContainerStyle id="contents">{children}</MainContainerStyle>;
};

export const LargeContainer = ({ children }) => {
    return <LargeContainerStyle id="contents">{children}</LargeContainerStyle>;
};

export default MainContainer;
