import React from 'react';
import classNames from 'classnames';
import { getMyModelsContractDetail } from '../../queries/GetMyModelsContract';
import { useQuery } from 'react-query';
import Loading from '../../components/contents/Loading';
import Error from '../../components/contents/Error';

function ProjectProgress(props) {
    const { contractId, status, setStatus } = props;
    const { data, isLoading, isError, error } = useQuery(
        ['getMyModelsContractDetail'],
        () => getMyModelsContractDetail({ contractId: contractId }),
        {
            onSuccess: data => {
                if (data) {
                    setStatus(data.status);
                }
            },
        }
    );

    if (isLoading) {
        return <Loading />;
    }
    //if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <div className="process-container pt-10">
            <div className="circle fl">
                <span
                    className={classNames('number', {
                        select: status?.toLowerCase() === 'casting' || !status,
                    })}
                >
                    1
                </span>
                <span className="label select">Casting</span>
            </div>
            <div className="circle fl">
                <span className={classNames('number', { select: status?.toLowerCase() === 'documenting' })}>2</span>
                <span className="label">Documenting</span>
            </div>
            <div className="circle fl">
                <span className={classNames('number', { select: status?.toLowerCase() === 'posting' })}>3</span>
                <span className="label">Posting</span>
            </div>
            <div className="circle fl">
                <span className={classNames('number', { select: status?.toLowerCase() === 'complete' })}>4</span>
                <span className="label">Completed</span>
            </div>
            <div className="border cline" />
        </div>
    );
}

export default ProjectProgress;
