import React from 'react';
import styled from 'styled-components';
import { MaterialIconsOutlined } from '../styled-components/MaterialIcons';
import { useUrl } from '../../hooks/useUrl';
import useLogout from '../../hooks/useLogout';
import { useSelector } from 'react-redux';
import Section from '../layout/Section';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.nav`
    margin: 0 auto;
    width: 1440px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-900);
    /*color: var(--gray-050);*/
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    /*color: var(--white);*/
    /*background-color: transparent;*/
    /*position: fixed;*/
    /*z-index: 100;*/
    /*top: 0;*/
    .menu {
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        cursor: pointer;
        &.selected {
            color: var(--purple-200);
            /*text-shadow: 0px 0px 1px var(--purple-200);*/
        }
        &:hover {
            color: var(--purple-200);
            /*text-shadow: 0px 0px 1px var(--purple-200);*/
        }
    }
    /*.menu {*/
    .logo {
        cursor: pointer;
        width: 90px;
        height: 26px;
        margin-bottom: 4px;
        background-image: url('/svg/celevu_Dark.svg');
        /*background-image: url('/svg/celevu_Light.svg');*/
        background-repeat: no-repeat;
        background-position: center;
    }
    .page-nav {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 48px;
    }
    .user-nav {
        padding: 0 16px;
        display: flex;
        align-items: center;
        position: relative;
        gap: 48px;
        height: 100%;
        justify-content: space-between;
        position: relative;
        .icon:hover {
            .dropdown {
                display: block;
            }
        }
        .icon {
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 8px;
            .not-auth {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .celeb {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--crimson-050);
                color: var(--crimson-500);
                border-radius: 20px;
                width: 20px;
                height: 20px;
            }
            .agency {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--purple-500);
                color: var(--purple-050);
                border-radius: 20px;
                width: 20px;
                height: 20px;
            }
        }
        .dropdown {
            padding-top: 10px;
            top: 38px;
            right: 0px;
            border-radius: 0 0 2px 2px;
            z-index: 10;
            overflow: hidden;
            display: none;
            position: absolute;
            width: 120px;
            background-color: var(--gray-050);
            color: var(--gray-900);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4rem;
            .item {
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    cursor: pointer;
                    background-color: var(--purple-200);
                    color: var(--gray-050);
                    text-shadow: none;
                }
            }
        }
    }
`;

const Nav = () => {
    const { navigateToPage } = useUrl();
    const { Logout } = useLogout();
    const location = useLocation();
    const userData = useSelector(state => state.user);

    const Icon = () => {
        if (!userData.id) {
            return (
                <>
                    <li
                        className={location.pathname.startsWith('/contact') ? 'selected menu' : 'menu'}
                        onClick={() => navigateToPage('/contact')}
                    >
                        Contact us
                    </li>
                    <li className="icon">
                        <span className="not-auth">
                            <MaterialIconsOutlined
                                name="account_circle"
                                cursor="pointer"
                                size="20px"
                                color="var(--gray-900)"
                                onClick={() => navigateToPage('/login')}
                            />
                        </span>
                        <ul className="dropdown">
                            <li className="item" onClick={() => navigateToPage('/login')}>
                                Login
                            </li>
                            <li className="item" onClick={() => navigateToPage('/register')}>
                                Sign up
                            </li>
                        </ul>
                    </li>
                </>
            );
        } else {
            return (
                <>
                    <li className="menu" onClick={() => navigateToPage('/project')}>
                        Project
                    </li>
                    <li className="icon">
                        <span className="agency">A</span>
                        <ul className="dropdown">
                            <li className="item" onClick={() => navigateToPage('/mypage')}>
                                My page
                            </li>
                            <li className="item" onClick={() => navigateToPage('/contact')}>
                                Contact us
                            </li>
                            <li className="item" onClick={() => Logout()}>
                                Logout
                            </li>
                        </ul>
                    </li>
                </>
            );
        }
    };

    return (
        <Section bgColor="var(--gray-050)">
            {/*<Section bgColor="var(--gray-900)">*/}
            <Wrapper>
                <ul className="page-nav">
                    <li className="logo" onClick={() => navigateToPage('/')} />
                    {userData.id ? (
                        <li
                            className={location.pathname.startsWith('/labs') ? 'selected menu' : 'menu'}
                            onClick={() => navigateToPage('/labs')}
                        >
                            Labs
                        </li>
                    ) : (
                        <></>
                    )}
                    <li
                        className={location.pathname.startsWith('/demo') ? 'selected menu' : 'menu'}
                        onClick={() => navigateToPage('/demo')}
                    >
                        Demo
                    </li>
                    <li
                        className={location.pathname.startsWith('/celebs') ? 'selected menu' : ' menu'}
                        onClick={() => navigateToPage('/celebs')}
                    >
                        Celebs
                    </li>
                </ul>
                <ul className="user-nav">
                    <Icon />
                </ul>
            </Wrapper>
        </Section>
    );
};

export default Nav;
