import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const FadeContainer = styled.div`
    & > * {
        @keyframes fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        @keyframes fadeout {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
        animation: ${props => (props.status ? `fadein ${props.time}ms` : `fadeout ${props.time}ms`)};
    }
`;

const Fade = ({ children, status, time }) => {
    const [isVisible, setIsVisible] = useState(status);

    useEffect(() => {
        if (status === true) {
            setIsVisible(true);
        } else {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, time);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [status]);

    return isVisible ? (
        <FadeContainer status={status} time={time}>
            {children}
        </FadeContainer>
    ) : (
        <></>
    );
};

Fade.defaultProps = {
    time: 100,
};

export default Fade;
