import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import Pager from '../../modules/common/Pager';
import SortArea from '../../modules/model/SortArea';
import ProjectModelCard from './ProjectModelCard';
import Feature from '../../modules/agency/Feature';
import {
    getProjectFeatures,
    getProjectModelList,
    getRecommendedCelebs,
    putProjectFeatures,
} from '../../queries/GetProjectList';
import Loading from '../../components/contents/Loading';
import Error from '../../components/contents/Error';
import Advertisement from './Advertisement';

const ProjectModelList = props => {
    const { viewState, projectId, productStatus, contractId } = props;
    const [feature, setFeature] = useState([
        // { id: 1, display: 'Fearture 1', feature: 1, value: 2, project: 12 },
        // { id: 2, display: 'Fearture 2', feature: 2, value: 3, project: 12 },
        // { id: 3, display: 'Fearture 3', feature: 3, value: 4, project: 12 },
        // { id: 4, display: 'Fearture 4', feature: 4, value: 1, project: 12 },
        // { id: 5, display: 'Fearture 5', feature: 5, value: 6, project: 12 },
        // { id: 6, display: 'Fearture 6', feature: 6, value: 2, project: 12 },
        // { id: 7, display: 'Fearture 7', feature: 7, value: 1, project: 12 },
        // { id: 8, display: 'Fearture 8', feature: 8, value: 2, project: 12 },
        // { id: 9, display: 'Fearture 9', feature: 9, value: 1, project: 12 },
    ]);

    const {
        data,
        isError: isFeatureError,
        error: errorFeature,
        isLoading: isFeatureLoading,
        refetch: feartuereRefetch,
    } = useQuery(['getProjectFeatures', projectId], () => getProjectFeatures({ projectId: projectId }), {
        enabled: true,
    });

    useEffect(() => {
        if (data) {
            setFeature(data);
        }
    }, [data]);

    const [modelsResult, setModelsResult] = useState(null);

    const [list, setList] = useState([]);

    const [query, setQuery] = useState({
        page: 1,
        male: false,
        female: false,
        selected: false,
        choices: 'created',
    });
    const {
        data: dataModles,
        isError,
        error,
        isLoading,
        refetch,
        isFetched,
    } = useQuery(['getRecommendedCelebs'], () => getRecommendedCelebs({ projectId: projectId }));

    const modelListPaging = () => {
        // console.log('modelListPaging', query);
        const firstIndex = (query.page - 1) * 6;
        setList(() => dataModles?.slice(firstIndex, firstIndex + 6));
    };

    useEffect(() => {
        setModelsResult(() => dataModles);
        modelListPaging();
        // console.log('dataModles init');
    }, [dataModles]);

    useEffect(() => {
        if (data) {
            setFeature(() => data);
        }
    }, [data]);

    useEffect(() => {
        setModelsResult(() => dataModles);
    }, [feature]);

    useEffect(() => {
        modelListPaging();
    }, [query]);

    if (!data) return <></>;
    if (isFeatureLoading) {
        return <Loading />;
    }
    if (isFeatureError) {
        return <Error errMsg={errorFeature.message} />;
    }

    if (isLoading) {
        return <Loading />;
    }
    if (!dataModles) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    // console.log('ProjectModelList', data, projectId);

    const setQueryPageHandler = pageIndex => {
        if (dataModles.length / 6 + 1 >= pageIndex && pageIndex > 0) {
            setQuery(() => ({ ...query, page: pageIndex }));
        }
    };

    const getModelListHandler = result => {
        const oldFeature = feature;
        const newFeature = oldFeature.map((item, i) => {
            return { ...item, value: Object.values(result)[i].value };
        });
        // console.log('getModelListHandler', result);

        // console.log('getModelListHandler', newFeature);
        setFeature(() => newFeature);

        feartuereRefetch();
        refetch();
        setQueryPageHandler(1);
    };

    return (
        <>
            {viewState ? <Feature featureData={feature} handler={getModelListHandler} /> : <></>}
            {productStatus === 'Posting' ? (
                <Advertisement projectId={projectId} contractId={contractId} />
            ) : (
                <>
                    <div className="model-list">
                        <div className="header">
                            <h3>Recommended Celebrities</h3>
                        </div>
                        <div className="pagination">
                            <Pager data={dataModles} query={query} setQueryPageHandler={setQueryPageHandler} />
                            <div className="sort-area">{/*<SortArea viewSelected={true} />*/}</div>
                        </div>
                    </div>
                    <div className="list-area">
                        {list?.map((model, index) => (
                            <ProjectModelCard key={index} size={50} height={150} item={model} />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default ProjectModelList;
