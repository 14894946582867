import React from 'react';
import MainContainer from '../../components/MainContainer';
import Layout from '../../modules/common/Layout';
import RegisterModel from '../../modules/model/CreateModel';

const CreateModel = () => {
    return (
        <>
            <Layout>
                <MainContainer>
                    <RegisterModel />
                </MainContainer>
            </Layout>
            {/* <ModalCreateProject />
            <LargeModal />
            <ConfirmModal />
            <OfferDetail /> */}
        </>
    );
};

export default CreateModel;
