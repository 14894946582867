import React from 'react';
import Section from '../layout/Section';
import Carousel, { CarouselTimer } from '../styled-components/Carousel';
import styled from 'styled-components';
import AutoSlide from '../styled-components/AutoSlide';
import Mailing from '../Mailing';
import { useGetBanner, useGetReference } from '../../hooks/useLanding';
import ScrollSlide from '../styled-components/ScrollSlide';

const SubTitle = styled.p`
    color: var(--gray-800);
    text-align: center;
    font-family: Larken;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 48px */
    margin-bottom: 40px;
`;

const TopBanner = styled.div`
    width: 100%;
    height: 800px;
    background-color: black;
    .content {
        margin: 0 auto;
        display: grid;
        grid-template-columns: 740px 540px 180px;
        width: 1440px;
        .text-area {
            padding: 128px 48px 128px 106px;
            color: var(--grey-white, #fff);
            font-family: Larken;
            font-size: 60px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 72px */
        }
        .image-area {
            width: 540px;
            height: 800px;
            overflow: hidden;
            img {
                width: 540px;
                height: 800px;
                object-fit: cover;
            }
        }
        .margin {
        }
    }
`;

const CarouselSection = () => {
    const { data } = useGetBanner();

    if (data.length === 0) return <TopBanner />;
    return (
        <CarouselTimer width="100vw" height="800px">
            {/*{data.map((banner, index) => {
                return (
                    <TopBanner key={'banner_' + index}>
                        <div className="content">
                            <div className="text-area">{banner.article}</div>
                            <div className="image-area">
                                <img src={banner.article_image} />
                            </div>
                            <div className="margin"></div>
                        </div>
                    </TopBanner>
                );
            })}*/}
            {/* 배너 2개만 임시 적용 */}
            <TopBanner key={'banner_1'}>
                <div className="content">
                    <div className="text-area">{data[0].article}</div>
                    <div className="image-area">
                        <img src={data[0].article_image} />
                    </div>
                    <div className="margin"></div>
                </div>
            </TopBanner>
            <TopBanner key={'banner_2'}>
                <div className="content">
                    <div className="text-area">{data[1].article}</div>
                    <div className="image-area">
                        <img src={data[1].article_image} />
                    </div>
                    <div className="margin"></div>
                </div>
            </TopBanner>
            {/* 배너 2개만 임시 적용 */}
        </CarouselTimer>
    );
};

const ServiceSection = () => {
    return (
        <Section mt={100} mb={160}>
            <SubTitle>Our Service</SubTitle>
            <ScrollSlide>
                <img src="/images/celeb_contents.png" width="1860" height="516" />
            </ScrollSlide>
        </Section>
    );
};

const PartnerImages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    img {
        height: 140px;
    }
`;

const PartnerSection = () => {
    return (
        <Section bgColor={'var(--white)'} mb={160}>
            {/*<Section bgColor="var(--white)" pt={80} pb={80}>*/}
            <SubTitle>Our Partners</SubTitle>
            <PartnerImages>
                <img src="/images/partners/ann-company.png" />
                <img src="/images/partners/just-ent.png" />
                <img src="/images/partners/dexter-krema.png" />
                <img src="/images/partners/dear-ent.png" />
            </PartnerImages>
        </Section>
    );
};

const Slide = styled.li`
    overflow: hidden;
    img {
        width: 320px;
        height: 320px;
        object-fit: cover;
    }
`;

const ReferenceSection = () => {
    const { data } = useGetReference();

    return (
        <Section mb={160}>
            <SubTitle>References</SubTitle>
            <AutoSlide viewWidth={1440} width={320} height={320} gap={20}>
                {data.map(img => {
                    return (
                        <Slide key={'reference_' + img.id}>
                            <img src={img.reference_image} />
                        </Slide>
                    );
                })}
            </AutoSlide>
        </Section>
    );
};

const ChartImage = styled.div`
    width: 1240px;
    height: 880px;
    overflow: hidden;
    margin: 0 auto;
    img {
        object-fit: cover;
        width: 1240px;
        height: 880px;
    }
`;

const LabsDescription = styled.div`
    .tags {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 40px;
        .tag {
            border-radius: 44px;
            background: var(--white);
            color: var(--purple-500);
            text-align: center;
            font-family: Inter;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.8rem;
            padding: 12px 22px;
            border: 1px solid var(--gray-300);
            /*box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);*/
        }
    }
    .text {
        color: var(--gray-800);
        text-align: center;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        margin-bottom: 60px;
    }
`;

const ChartImageSection = () => {
    return (
        <Section mb={160}>
            <SubTitle>Celevu Labs</SubTitle>
            <LabsDescription>
                <ul className="tags">
                    <li className="tag">#Sophistication</li>
                    <li className="tag">#Ruggedness</li>
                    <li className="tag">#Excitement</li>
                    <li className="tag">#Competence</li>
                    <li className="tag">#Sincerity</li>
                </ul>
                <p className="text">
                    Make your decision meaningful with the richest data analysis tools<br></br>Search, select, and check
                    characteristics of celebs you are interested
                </p>
            </LabsDescription>
            <ChartImage>
                <img className="chart-img" src="/images/chart.png" />
            </ChartImage>
        </Section>
    );
};

const Landing = () => {
    return (
        <>
            <CarouselSection />
            <ServiceSection />
            <PartnerSection />
            <ReferenceSection />
            <ChartImageSection />
            <Mailing />
        </>
    );
};

export default Landing;
