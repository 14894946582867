import React from 'react';
import styled from 'styled-components';

const FlowWrapper = styled.div`
    overflow: hidden;
    display: block;
    margin: 20px auto;
    table {
        margin: 0 auto 20px;
        font: normal normal normal 1.4rem/2rem inter;
        thead th {
            text-align: center;
            vertical-align: bottom;
            padding-bottom: 7px;
            border-bottom: 2px solid #cacbcd;
            width: 100px;
        }
        td {
            border-bottom: 2px solid #cacbcd;
            padding: 15px;
            vertical-align: middle;
            font: normal normal normal 1.4rem/2rem inter;
        }
    }
    .dot-gradation-ad {
        width: 20px;
        height: 20px;
        margin: 0 auto;
        display: block;
        background: transparent linear-gradient(180deg, #fc9620 0%, #f42206 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
    }

    .dot-gradation-ma {
        width: 20px;
        height: 20px;
        margin: 0 auto;
        display: block;
        background: transparent linear-gradient(180deg, #d5eb30 0%, #04d8d0 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
    }
`;

const ServiceFlowContext = () => {
    return (
        //<div className="flow">
        <FlowWrapper>
            <table>
                <colgroup>
                    <col width="170px" />
                    <col width="120px" />
                    <col width="150px" />
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th>Search celebs</th>
                        <th>Select celebs</th>
                        <th>Confirm</th>
                        <th>Create ads</th>
                        <th>Re-confirm</th>
                        <th>Execute ads</th>
                        <th>Review results</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center' }}>
                            Advertiser
                            <br />
                            (Ad agency)
                        </td>
                        <td>
                            <span className="dot-gradation-ad"></span>
                        </td>
                        <td>
                            <span className="dot-gradation-ad"></span>
                        </td>
                        <td></td>
                        <td>
                            <span className="dot-gradation-ad"></span>
                        </td>
                        <td></td>
                        <td>
                            <span className="dot-gradation-ad"></span>
                        </td>
                        <td>
                            <span className="dot-gradation-ad"></span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center' }}>
                            Celeb<br></br>(Celeb agency)
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            <span className="dot-gradation-ma"></span>
                        </td>
                        <td></td>
                        <td>
                            <span className="dot-gradation-ma"></span>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </FlowWrapper>
    );
};

export default ServiceFlowContext;
