import { useQuery } from 'react-query';
import { UserSelf } from '../queries/UserSelf';

export const useUserData = () => {
    const { data, isError, isLoading, refetch } = useQuery(['UserSelf'], () => UserSelf());

    const fetchData = async () => {
        await refetch();
    };

    //if (isLoading || isError) {
    //    return { data: {}, fetchData };
    //}

    return { isLoading, data, fetchData };
};
