import React from 'react';
import MainContainer from '../components/MainContainer';
import ModelList from '../components/models/ModelList';
import Layout from '../modules/common/Layout';
import CelebList from '../components/celebs/CelebList';

const Models = () => {
    return (
        <Layout>
            <MainContainer>
                {/*<ModelList />*/}
                <CelebList />
            </MainContainer>
        </Layout>
    );
};

export default Models;
