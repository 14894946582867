function TermsOfUseDetail() {
    return (
        <div style={{ width: '830px', margin: 'auto' }}>
            <iframe src="terms-of-use.html" width="830px" height="3830px" frameborder="0"></iframe>
        </div>
        //<div className="c15 terms-of-use">
        //    <p className="c10">
        //        <span className="c8 c18">CeleVu </span>
        //        <span className="c8 c9">Site Terms of Use</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">Last Updated: JANUARY 2023</span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            Please read these terms of use carefully. by accessing or using any CeleVu website or mobile
        //            application (or any content, product, service, or feature available through the website or mobile
        //            application, including the embedded viewer) (collectively, the "site"), you agree to abide by and be
        //            bound by the terms described herein and by all terms, policies and guidelines incorporated by
        //            reference, as well as any additional terms and restrictions presented in relation to specific
        //            content or a specific product, service or feature (collectively, the "site terms"). if you do not
        //            agree to all of these terms, do not use the site.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            These Site Terms are not intended to alter the terms or conditions of any content license agreement
        //            you may have with CeleVu, its subsidiaries or affiliates, and to the extent of any conflict, the
        //            terms of your content license agreement will prevail. By using the Site, you represent and warrant
        //            that you are over the age of 18 and are lawfully able to accept these Site Terms. If you are using
        //            the Site on behalf of any entity, you further represent and warrant that you are authorized to
        //            accept these Site Terms on such entity's behalf, and that such entity agrees to indemnify CeleVu for
        //            violations of these Site Terms.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Privacy Policy</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            CeleVu is committed to protecting your privacy. Please refer to our Privacy policy for information
        //            on how we collect, use and disclose personal information.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Ownership of the Site and its Contents</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c7">
        //            The Site is owned by CeleVu. Unless otherwise indicated, all of the content featured or displayed on
        //            the Site, including, but not limited to, text, graphics, data, photographic images, moving images,
        //            sound, illustrations, software,{' '}
        //        </span>
        //        <span className="c7">created advertisement contents </span>
        //        <span className="c7">
        //            and the selection and arrangement thereof ("CeleVu Content"), is owned by Celevu,{' '}
        //        </span>
        //        <span className="c7">its licensors, or its third-party image partners.</span>
        //        <span className="c0">
        //            &nbsp;All elements of the Site, including the Celevu Content, are protected by copyright, trade
        //            dress, moral rights, trademark and other laws relating to the protection of intellectual property.
        //            We also appreciate any feedback or other suggestions about our services that you may submit to us,
        //            but you understand that we may use such feedback or suggestions without any obligation to compensate
        //            you for them.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Use of the Site</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c7">
        //            The Site and the Celevu Content are intended for customers of CeleVu. You may not use the Site or
        //            the CeleVu Content for any purpose not related to your business with CeleVu. You are specifically
        //            prohibited{' '}
        //        </span>
        //        <span className="c7">from: </span>
        //        <span className="c0">
        //            (a) downloading, copying, or re-transmitting any or all of the Site or the CeleVu Content without,
        //            or in violation of, a written license or agreement with CeleVu; (b) using any data mining, robots or
        //            similar data gathering or extraction methods; (c) manipulating or otherwise displaying the Site or
        //            the CeleVu Content by using framing or similar navigational technology; (d) registering,
        //            subscribing, unsubscribing, or attempting to register, subscribe, or unsubscribe any party for any
        //            CeleVu product or service if you are not expressly authorized by such party to do so; (e) reverse
        //            engineering, altering or modifying any part of the Site or the CeleVu Content; (f) circumventing,
        //            disabling or otherwise interfering with security-related features of the Site or any system
        //            resources, services or networks connected to or accessible through the Site; (g) selling, licensing,
        //            leasing, or in any way commercializing the Site or the CeleVu Content without specific written
        //            authorization from CeleVu; and (h) using the Site or the CeleVu Content other than for its intended
        //            purpose. Such unauthorized use may also violate applicable laws including without limitation
        //            copyright and trademark laws, the laws of privacy and publicity, and applicable communications
        //            regulations and statutes. You represent and warrant that you will comply with all applicable laws
        //            and regulations, including, without limitation, those relating to the Internet, data, e-mail,
        //            privacy, and the transmission of technical data exported from the Republic of Korea or the country
        //            in which you reside.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c7">
        //            CeleVu will not be responsible to pay you or any Third Parties fees in connection with billing or
        //            collecting any licensing fees from you. You are responsible for all applicable taxes and any
        //            applicable Third Party fees (including but not limited to credit card fees, foreign exchange fees
        //            and cross border fees.) In the event you reside in New Zealand, Japan, Malaysia, India, United Arab
        //            Emirates, Hong Kong, Singapore or Thailand and choose to pay with a credit card, the payment will be
        //            processed via a European Acquirer and constitutes an agreement between you and CeleVu.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Copyright Infringement Policy</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            In accordance with the Digital Millennium Copyright Act ("DMCA") and other applicable laws, CeleVu
        //            has adopted a policy of terminating, in appropriate circumstances and at CeleVu's sole discretion,
        //            account holders who infringe the intellectual property rights of CeleVu or any third party.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Copyright Complaints</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            If you believe that any material on the Site infringes upon any copyright that you own or control,
        //            you may file a notification of such infringement with our Designated Agent as set forth below:
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Copyright Agent</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c0">CeleVu Legal Department</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c16">KIST H-1 991A</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c16">Hwa-rang ro 14 gil 5, Seong-buk gu</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c17">02792 Seoul, The Republic of Korea</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c0">Phone: +82-2-967-0701</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c0">Fax: +82-2-967-0701</span>
        //    </p>
        //    <p className="c14">
        //        <span className="c0">E-mail: copyright@aligo.ai</span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Trademarks</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            CeleVu Trademarks, the CeleVu logo, and any other product or service name or slogan contained in the
        //            Site are trademarks of CeleVu and its suppliers or licensors, and may not be copied, imitated or
        //            used, in whole or in part, without the prior written permission of CeleVu or the applicable
        //            trademark holder. You may not use metatags or any other "hidden text" utilizing " CeleVu " or any
        //            other name, trademark or product or service name of CeleVu without our prior written permission. In
        //            addition, the look and feel of the Site, including all page headers, custom graphics, button icons
        //            and scripts, is the service mark, trademark and/or trade dress of CeleVu and may not be copied,
        //            imitated or used, in whole or in part, without our prior written permission. All other trademarks,
        //            registered trademarks, product names and company names or logos mentioned in the Site are the
        //            property of their respective owners. Reference to any products, services, processes or other
        //            information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or
        //            imply endorsement, sponsorship or recommendation thereof by us.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Links</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            You may not use a CeleVu logo or other proprietary graphic of CeleVu to link to the Site without the
        //            express written permission of CeleVu. Further, you may not frame any CeleVu trademark, logo or other
        //            proprietary information, including the CeleVu Content, without CeleVu's express written consent.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            CeleVu makes no claim or representation regarding, and accepts no responsibility for, directly or
        //            indirectly, the quality, content, nature or reliability of third-party websites accessible by
        //            hyperlink from the Site, or websites linking to the Site. Such sites are not under the control of
        //            CeleVu and CeleVu is not responsible for the contents of any linked site or any link contained in a
        //            linked site, or any review, changes or updates to such sites. CeleVu provides these links to you
        //            only as a convenience, and the inclusion of any link does not imply affiliation, endorsement or
        //            adoption by CeleVu of any site or any information contained therein. When you leave the Site, you
        //            should be aware that our terms and policies no longer govern. You should review the applicable terms
        //            and policies, including privacy and data gathering practices, of any site to which you navigate from
        //            the Site.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            Your participation, correspondence or business dealings with any third party found on or through the
        //            Site, regarding the payment and delivery of related goods or services, and any other terms,
        //            conditions, warranties or representations associated with such dealings, are solely between you and
        //            such third party. You agree that CeleVu shall not be responsible or liable for any loss, damage or
        //            other matters of any sort incurred as the result of any such dealings.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Registration Data and Account Security</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c7">
        //            In consideration of your use of the Site, you agree to: (a) provide accurate, current and complete
        //            information about you as may be prompted by any registration forms on the Site ("Registration
        //            Data"); (b) maintain and promptly update the Registration Data, and any other information you
        //            provide to Company, to keep it accurate, current and complete; (c) maintain the security of your
        //            password and identification; (d) notify CeleVu immediately of any unauthorized use of your account
        //            or other breach of security; (e) accept all responsibility for any and all activities that occur
        //            under your account, including but not limited to, all applicable taxes and any applicable
        //            third-party fees (including but not limited to{' '}
        //        </span>
        //        <span className="c7">credit card fees</span>
        //        <span className="c0">
        //            , foreign exchange fees and cross border fees); and (f) accept all risks of unauthorized access to
        //            the Registration Data and any other information you provide to Company.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c8">Indemnification</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c7">You agree to defend, indemnify and hold harmless CeleVu</span>
        //        <span className="c7">
        //            , its subsidiaries, affiliates, licensors, employees, agents, third party information providers and
        //            independent contractors against{' '}
        //        </span>
        //        <span className="c0">
        //            any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable
        //            attorneys' fees) arising out of or related to any User Content that you post, store or otherwise
        //            transmit on or through the Site, your conduct, your use or inability to use the Site, your breach or
        //            alleged breach of the Site Terms or of any representation or warranty contained herein, your
        //            unauthorized use of the CeleVu, or your violation of any rights of another.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Disclaimer</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            The site, including without limitation any editing tools and the CeleVu content, are provided "as
        //            is" and CeleVu and its directors, employees, content providers, agents, licensors and affiliates
        //            exclude, to the fullest extent permitted by applicable law, any warranty, express or implied,
        //            including, without limitation, any implied warranties of merchantability, satisfactory quality or
        //            fitness for a particular purpose. CeleVu will not be liable for any damages of any kind arising from
        //            the use of the site or the CeleVu content, or the unavailability of the same, including, but not
        //            limited to lost profits, and direct, indirect, incidental, punitive and consequential damages. the
        //            functions embodied on or in the materials of this site are not warranted to be uninterrupted or
        //            without error. You, not CeleVu, assume the entire cost of all necessary servicing, repair or
        //            correction due to your use of this site or the CeleVu content. We make no warranty that the site or
        //            the CeleVu content is free from infection by viruses or anything else that has contaminating or
        //            destructive properties. CeleVu uses reasonable efforts to ensure the accuracy, correctness and
        //            reliability of the CeleVu Content, but we make no representations or warranties as to the CeleVu
        //            Content's accuracy, correctness or reliability.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            CeleVu offers a search feature within the Site. CeleVu explicitly disclaims any responsibility for
        //            the content or availability of information contained in our search index or directory. CeleVu also
        //            disclaims any responsibility for the completeness or accuracy of any directory or search result.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            Some US states and foreign countries do not permit the exclusion or limitation of implied warranties
        //            or liability for certain categories of damages. Therefore, some or all of the limitations above may
        //            not apply to you to the extent they are prohibited or superseded by state or national provisions.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Limitation of Liability</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            In no event shall Celevu, its directors, members, employees, licensors or agents be liable for any
        //            direct, special, indirect or consequential damages, or any other damages of any kind, including but
        //            not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort
        //            (including but not limited to negligence) or otherwise, arising out of or in any way connected with
        //            the use of the site, the services, the Celevu content or the materials contained in or accessed
        //            through the site, including without limitation any damages caused by or resulting from reliance by
        //            user on any information obtained from Celevu, or that result from mistakes, omissions,
        //            interruptions, deletion of files or email, errors, defects, viruses, delays in operation or
        //            transmission or any failure of performance, whether or not resulting from acts of god,
        //            communications failure, theft, destruction or unauthorised access to celevu’s records, programmes or
        //            services. in no event shall the aggregate liability of CeleVu, whether in contract, warranty, tort
        //            (including negligence, whether active, passive or imputed), product liability, strict liability or
        //            other theory, arising out of or relating to the use of the site exceed any compensation you pay, if
        //            any, to CeleVu for access to or use of the site.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c8">Applicable Law and Venue</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c7">
        //            Any dispute relating in any way to your use of the Site or the CeleVu Content shall be submitted to
        //            confidential arbitration in Seoul
        //        </span>
        //        <span className="c0">&nbsp;Northern District Court.</span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Termination</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            Notwithstanding any of these Site Terms, CeleVu reserves the right, without notice and in its sole
        //            discretion, to terminate your account and/or to block your use of the Site.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Miscellaneous Provisions</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            Any waiver of any provision of the Site Terms will be effective only if in writing and signed by
        //            CeleVu. If any clause in these Site Terms is found to be unenforceable, wherever possible this will
        //            not affect any other clause and each will remain in full force and effect. Any rights not expressly
        //            granted herein are reserved.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2">
        //        <span className="c4">Changes to Site Terms</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c0">
        //            CeleVu reserves the right to change any of the terms and conditions contained in the Site Terms or
        //            any policy or guideline of the Site, at any time and in its sole discretion. When we make changes,
        //            we will revise the "last updated" date at the top of the Site Terms. Any changes will be effective
        //            immediately upon posting on the Site. Your continued use of the Site following the posting of
        //            changes will constitute your acceptance of such changes. We encourage you to review the Site Terms
        //            whenever you visit one of our websites.
        //        </span>
        //    </p>
        //    <p className="c2 c3">
        //        <span className="c0" />
        //    </p>
        //    <p className="c2" id="h.gjdgxs">
        //        <span className="c4">Questions and Contact Information</span>
        //    </p>
        //    <p className="c2">
        //        <span className="c7">Please contact us (</span>
        //        <span className="c7 c12">
        //            <a className="c13" href="mailto:contract@aligo.ai">
        //                contract@aligo.ai
        //            </a>
        //        </span>
        //        <span className="c0">) if you have any questions or comments about the Site or Site Terms.</span>
        //    </p>
        //    <p className="c3 c11">
        //        <span className="c0" />
        //    </p>
        //</div>
    );
}

export default TermsOfUseDetail;
