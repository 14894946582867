import { useDispatch, useSelector } from 'react-redux';
import { setModal } from './api/action';
import styled from 'styled-components';
import { useRef } from 'react';
import Fade from '../../components/styled-components/Fade';
/**
 * 정의된 모달 타입과 data object
 * @param {MODAL_TYPE} type
 * @param {object} data
 * @returns
 */
export const useModal = type => {
    const dispatch = useDispatch();

    const showModal = data => {
        if (data !== undefined) {
            const newData = { ...data, status: true };
            dispatch(
                setModal({
                    type: type,
                    data: newData,
                })
            );
        } else {
            dispatch(
                setModal({
                    type: type,
                    data: {
                        status: true,
                    },
                })
            );
        }
    };

    const hideModal = () => {
        dispatch(
            setModal({
                type: type,
                data: {
                    status: false,
                },
            })
        );
    };

    return { showModal, hideModal };
};

const ModalBackground = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: auto;
    /*background: linear-gradient(181.2deg, #5900e5 2.06%, #860cf9 33.02%, rgba(243, 231, 254, 0) 97.94%);*/
`;

const ModalContent = styled.div`
    border-radius: 15px;
    width: ${props => props.width};
    height: ${props => props.height};
    background-color: #fbfbfb;
    position: absolute;
    padding: 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*color: #f3e7fe;*/
    box-shadow: 0px 0px 15px 0px rgba(37, 37, 37, 0.2);
    overflow: auto;
    i.material-icons {
        position: absolute;
        color: #666666;
        width: 28px;
        height: 28px;
        right: 28px;
        top: 28px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ModalFrame = ({ children, width, height, modalType, modalName }) => {
    const modalState = useSelector(state => state.modal[modalName]);
    const { hideModal } = useModal(modalType);
    const modalRef = useRef(null);

    const handleClickBackground = e => {
        if (e.target === modalRef.current) {
            hideModal();
        }
    };

    return (
        <Fade status={modalState.status}>
            <ModalBackground ref={modalRef} onClick={handleClickBackground}>
                <ModalContent width={width} height={height}>
                    <i onClick={hideModal} className="material-icons">
                        close
                    </i>
                    {children}
                </ModalContent>
            </ModalBackground>
        </Fade>
    );
};
