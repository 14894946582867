import React, { useState } from 'react';
import { ModalFrame, useModal } from './ModalUtils';
import { FORGOT_PASSWORD_MODAL } from './api/action';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../components/input/Input';
import * as yup from 'yup';
import Button from '../../components/styled-components/Button';
import { useMutation } from 'react-query';
import { putForgotPassword } from '../../queries/UserSelf';
import Loading from '../../components/contents/Loading';
import { Circular } from '../../animation/Animation';

const schema = yup
    .object({
        email: yup.string().label('Email').required(),
    })
    .required();

const ModalContents = styled.div`
    margin: 36px auto;
    width: 520px;
    .button-area {
        margin: 20px auto 0;
    }
`;
const PageTitle = styled.div`
    margin: 0 auto 40px;
    .logo {
        width: 164px;
    }
    .title {
        /*margin-left: 12px;*/
        margin-bottom: 20px;
        color: var(--gray-900);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 100% */
    }
    .description {
        /*margin-left: 12px;*/
        color: var(--gray-900);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
    }
`;
const InputGroup = styled.div`
    width: 520px;
    margin: auto;
    label.require {
        ::after {
            content: ' *';
            color: var(--crimson-500);
        }
    }
    label.optional {
        ::after {
            content: ' (optional)';
            font-weight: 350;
        }
    }
`;

const LabelStyle = styled.label`
    display: inline-block;
    color: var(--gray-800);

    margin-left: 1px;
    margin-bottom: 16px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem;
`;

const ErrorLabel = styled.label`
    margin-left: 1px;
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: top;
    justify-content: flex-start;
    color: var(--crimson-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { hideModal } = useModal(FORGOT_PASSWORD_MODAL);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const mutation = useMutation(async data => putForgotPassword(data), {
        onSuccess: res => {
            alert('success');
            setIsLoading(false);
            hideModal();
        },
        onError: err => {
            setError('email', {
                type: 'manual',
                message: err.response.data.data.email[0],
            });
            setIsLoading(false);
        },
    });

    const onSubmit = data => {
        setIsLoading(true);
        mutation.mutate(data);
    };
    //if (isLoading) {
    //    return (
    //        <ModalFrame width="620px" modalType={FORGOT_PASSWORD_MODAL} modalName={'forgotPassword'}>
    //            <ModalContents>
    //                <Loading />
    //            </ModalContents>
    //        </ModalFrame>
    //    );
    //}

    return (
        <ModalFrame width="620px" modalType={FORGOT_PASSWORD_MODAL} modalName={'forgotPassword'}>
            <ModalContents>
                <>
                    <PageTitle>
                        <div className="title">Request a new password</div>
                        <div className="description">
                            Enter your email adress and we will send a password reset link.
                        </div>
                    </PageTitle>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup>
                            <LabelStyle className="require">Email</LabelStyle>
                            <Input
                                type="text"
                                name="email"
                                size="lg"
                                color="primary"
                                register={register}
                                option={{ required: true }}
                                error={errors.email ? errors.email : null}
                            />
                            {errors.email ? <ErrorLabel>{errors.email.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                        <div className="button-area">
                            {isLoading ? (
                                <Button type="button" variant="gray" size="lg" disabled>
                                    Wait..
                                </Button>
                            ) : (
                                <Button type="submit" variant="primary" size="lg">
                                    Confirm
                                </Button>
                            )}
                        </div>
                    </form>
                </>
            </ModalContents>
        </ModalFrame>
    );
};

export default ForgotPassword;
