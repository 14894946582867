import React from 'react';
import MainContainer from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import Labs from '../components/celebs/Labs';

const CelevuLabs = () => {
    return (
        <Layout>
            <MainContainer>
                <Labs />
            </MainContainer>
        </Layout>
    );
};

export default CelevuLabs;
