// CustomCheckbox.js
import React from 'react';
import styled from 'styled-components';
import { useState } from 'react'; // useState 가져오기

export const useCheckbox = (initialValue = []) => {
    const [selectList, setSelectList] = useState(initialValue);

    const SelectListHandler = (data, isChecked) => {
        if (isChecked) {
            setSelectList(prevSelected => [...prevSelected, data]);
        } else {
            setSelectList(prevSelected => prevSelected.filter(item => item.value !== data.value));
        }
    };

    const ResetSelectList = () => {
        setSelectList([]);
    };

    const isChecked = data => {
        const selectListJSON = JSON.stringify(data);
        if (selectList.some(data => JSON.stringify(data) === selectListJSON)) return true;
    };

    return { selectList, SelectListHandler, ResetSelectList, isChecked };
};

const sizeStyles = {
    default: {
        font: 'normal normal 400 1.2rem/1.2rem inter',
        boxWidth: '16px',
        boxHeight: '16px',
    },
    sm: {
        font: 'normal normal 400 1.2rem/1.2rem inter',
        boxWidth: '16px',
        boxHeight: '16px',
    },
    md: {
        font: 'normal normal 500 1.6rem/1.6rem inter',
        boxWidth: '16px',
        boxHeight: '16px',
    },
};

const CheckBoxStyle = styled.label`
    font: ${props => sizeStyles[props.size]?.font || sizeStyles.default.font};
    input[type='checkbox'] {
        display: none;
    }
    input[type='checkbox'] + span {
        margin: 6px;
        display: inline-block;
        cursor: pointer;
        color: #7a7a7a;
        white-space: nowrap;
    }
    input[type='checkbox'] + span:before {
        display: inline-block;
        box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        color: #fbfbfb;
        background-color: #fbfbfb;
        border: 1px solid #c79bfd;
        border-radius: 2px;
        margin-right: 4px;
        width: ${props => sizeStyles[props.size]?.boxWidth || sizeStyles.default.boxWidth};
        height: ${props => sizeStyles[props.size]?.boxHeight || sizeStyles.default.boxHeight};
        line-height: ${props => sizeStyles[props.size]?.boxHeight || sizeStyles.default.boxHeight};
        vertical-align: middle;
        content: '';
    }
    input[type='checkbox']:checked + span:before {
        border: none;
        background-color: #860cf9;
        background-size: cover;
        background-image: url('/svg/check.svg');
    }
`;
const CustomCheckbox = props => {
    const { data, isChecked, size, ...options } = props;

    return (
        <CheckBoxStyle size={size}>
            <input type="checkbox" checked={isChecked || false} {...options} />
            <span>{data.label}</span>
        </CheckBoxStyle>
    );
};

export default CustomCheckbox;
