import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PostAccessToken, PostFacebookAccount } from '../../queries/Facebook';
import Button from '../styled-components/Button';

const Facebook = () => {
    const [accounts, setAccounts] = useState(null);
    const [error, setError] = useState(null);
    const [isSdkLoaded, setIsSdkLoaded] = useState(false);

    useEffect(() => {
        window.fbAsyncInit = function () {
            //console.log('Initializing Facebook SDK...');
            window.FB.init({
                appId: '255686420564393', // Facebook 앱 ID
                cookie: true,
                xfbml: true,
                version: 'v19.0',
            });

            window.FB.AppEvents.logPageView();
            setIsSdkLoaded(true); // SDK 로드 완료
            //console.log('Facebook SDK initialized.');
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                //console.log('Facebook SDK script already loaded. Initializing again...');
                window.fbAsyncInit(); // 초기화 함수 다시 호출
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
            console.log('Facebook SDK script inserted.');
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const getInsights = () => {
        if (!isSdkLoaded) {
            setError(new Error('Facebook SDK가 아직 로드되지 않았습니다.'));
            return;
        }

        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                fetchProfile(response.authResponse.accessToken);
            } else {
                window.FB.login(function (response) {
                    if (response.authResponse) {
                        fetchProfile(response.authResponse.accessToken);
                    }
                });
            }
        });
    };

    const fetchProfile = async accessToken => {
        try {
            const profileResponse = await axios.get(`https://graph.facebook.com/v19.0/me?fields=id,name,email`, {
                params: {
                    access_token: accessToken,
                },
            });
            console.log(profileResponse, 'profile Response');
            fetchAccounts(accessToken, profileResponse.data.id); // USER_ID 사용하여 계정 정보 가져오기
        } catch (error) {
            setError(error);
        }
    };

    const fetchAccounts = async (accessToken, userId) => {
        try {
            const accountsResponse = await axios.get(`https://graph.facebook.com/v19.0/${userId}/accounts`, {
                params: {
                    access_token: accessToken,
                },
            });
            setAccounts(accountsResponse.data);
            if (accountsResponse.data.data.length > 0) {
                PostFacebookAccount(accountsResponse.data.data[1].id, accessToken); // 첫 번째 페이지 ID 사용
            }
        } catch (error) {
            setError(error);
        }
    };

    return (
        <Button onClick={getInsights} size="md" variant="gradient">
            Go to link
        </Button>
        //<div>
        //    {accounts && (
        //        <div>
        //            {accounts.data.map(account => (
        //                <div key={account.id}>
        //                    <p>ID: {account.id}</p>
        //                    <p>Name: {account.name}</p>
        //                </div>
        //            ))}
        //        </div>
        //    )}
        //</div>
    );
};

export default Facebook;
