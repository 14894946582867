import React from 'react';
import MainContainer from '../components/MainContainer';
//import SignIn from '../modules/auth/SignIn';
import Layout from '../modules/common/Layout';
import Login from '../modules/auth/Login';

const LoginPage = () => {
    return (
        <Layout>
            <MainContainer>
                {/*<SignIn />*/}
                <Login />
            </MainContainer>
        </Layout>
    );
};

export default LoginPage;
