import { clearAuthTokens } from 'axios-jwt';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUrl } from './useUrl';

const useLogout = () => {
    const dispatch = useDispatch();
    const { navigateToPage } = useUrl();

    const Logout = () => {
        clearAuthTokens();
        dispatch({ type: 'user/logout' });
        navigateToPage('/');
    };

    return { Logout };
};

export default useLogout;
