import React from 'react';

const Arrow = ({ props }) => {
    return (
        <>
            {props?.level === 'U' ? (
                <button type="button" className="upBtn">
                    <i className="icon-up-small">&#xe800;</i>
                </button>
            ) : (
                <button type="button" className="downBtn">
                    <i className="icon-down-small">&#xe800;</i>
                </button>
            )}
        </>
    );
};

Arrow.defaultProps = {
    level: 'U',
};

export default Arrow;
