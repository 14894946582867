import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heart from '../../modules/common/Heart';
import FeatureTags from './FeatureTags';
import ModelImg from './ModelImg';
import { useAlertModal } from '../../modules/modal/AlertModal';
import numeral from 'numeral';
import styled from 'styled-components';

const CelebName = styled.h5`
    font: italic normal bold 1.8rem/3.4rem inter !important;
    color: #5a607f !important;
    margin: 0;
    display: inline-block;
    cursor: pointer;
`;

const ModelCard = props => {
    const { model, pageIndex, heartList, selectedCountry } = props;
    const { showModal } = useAlertModal({
        title: 'Register and get more information',
        content: "Seems you don't have account yet. Please sign up and enjoy more service!",
        buttonText: 'Go login',
        buttonFunction: () => (window.location.href = '/login'),
    });

    const [imgIndex, setImgIndex] = useState(0);
    const [heartID, setHeartID] = useState(model.photos[imgIndex].heart_id_of_login_user);
    const userData = useSelector(state => state.user);
    const ctr = model.ctr ? model.ctr + '%' : 'N/A';
    const instagram_followers = model.social_media?.instagram_followers
        ? numeral(model.social_media.instagram_followers).format('0.0a')
        : 'none';
    const google_popularity = model.google_popularity ? (
        <p className="pl-5">Popularity : {model.google_popularity?.[selectedCountry].toFixed(2)}</p>
    ) : (
        <p className="pl-5">Popularity : N/A</p>
    );
    const leftArrowClick = () => {
        setImgIndex(imgIndex - 1);
    };

    useEffect(() => {
        setHeartID(model.photos[imgIndex].heart_id_of_login_user);
    }, [imgIndex]);

    const setHeartIDHandler = value => {
        setHeartID(value);
    };

    const rightArrowClick = () => {
        setImgIndex(imgIndex + 1);
    };

    useEffect(() => {
        setImgIndex(0);
    }, [pageIndex]);

    return (
        <div className="model-card">
            <div className="card-content">
                <div className="img">
                    <ModelImg
                        modelPhotos={model.photos}
                        imgIndex={imgIndex}
                        modelId={model.id}
                        showAlertModal={showModal}
                    />
                    <button
                        type="button"
                        onClick={leftArrowClick}
                        className="arrow icon-left-open-1 "
                        disabled={imgIndex === 0}
                    />
                    <button
                        type="button"
                        onClick={rightArrowClick}
                        className="arrow-right icon-right-open-1 "
                        disabled={imgIndex === model.photos.length - 1}
                    />
                </div>
                <div className="card-header">
                    <CelebName
                        onClick={() => {
                            if (userData.id) {
                                window.location.href = `/celebs/${model.id}`;
                            } else {
                                showModal();
                            }
                        }}
                    >
                        {model.pen_name}
                    </CelebName>
                    <Heart
                        userId={userData.id}
                        photoId={model.photos[imgIndex].id}
                        isClick={heartID}
                        setHeartIDHandler={setHeartIDHandler}
                        heartList={heartList}
                        level="U"
                    />
                </div>
                <div className="card-body">
                    <p className="pl-5">Insta follower # {instagram_followers}</p>
                    {/*<span>*/}
                    {/*<b>Instagram follower {instagram_followers}</b>*/}
                    {/*<b>CTR {ctr}</b>*/}
                    {/*<b>CTR {model.photos[imgIndex].click}</b>%*/}
                    {/*</span>*/}
                    {google_popularity}
                    <FeatureTags tags={model.tags} />
                </div>
            </div>
        </div>
    );
};

ModelCard.defaultProps = {
    size: 70,
    height: 185,
};
export default ModelCard;
