import React from 'react';
import Row from '../Row';
import ServiceFlowContext from './ServiceFlowContext';
import styled from 'styled-components';

const PresentationButton = styled.button`
    width: 250px;
    display: block;
    /*background: none;*/
    background-color: #ff4d4d;
    border-radius: 500px;
    padding: 19px 20px;
    margin: 20px auto;
    color: var(--white);
    font-size: 1.6rem;
    text-decoration: none;
`;

const Title = styled.h2`
    /*margin-top: 100px;*/
    margin: 120px auto 40px;
    padding-left: 160px;
    /*text-align: left;*/
    font: italic normal normal 3.2rem/3.2rem inter;
    letter-spacing: 0px;
    color: #202124;
    display: block;
    height: 67px;
    overflow: hidden;
`;

const ServiceFlow = () => {
    const onClick = () => {
        window.open(
            'https://www.figma.com/proto/R5YTa5Di0uIorbcjxZhDf5/***?page-id=1%3A2&type=design&node-id=1-101&viewport=785%2C-143%2C0.5&t=t36IO5JAjNg4oV4C-1&scaling=contain&mode=design',
            '_blank'
        );
    };
    return (
        <Row>
            <Title>Quick, Simple & No Hassle</Title>
            <div className="content">
                {/*<h5>CeleVu Service Flow</h5>*/}
                <ServiceFlowContext />
                <div style={{ width: '800px', margin: 'auto' }}>
                    <img style={{ width: '100%', height: 'auto' }} src="/images/contact_us_image_01.png" />
                    <img style={{ width: '100%', height: 'auto' }} src="/images/presentation.gif" />
                    {/*<video controls width="100%">
                        <source src="/celevu_teaser.mp4" />
                    </video>*/}
                    <img style={{ width: '100%', height: 'auto' }} src="/images/contact_us_image_02.png" />
                    <PresentationButton onClick={onClick}>Checkout our Presentation!</PresentationButton>
                    <div
                        style={{
                            margin: '30px auto',
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <a href="https://www.aligo.ai/" target="_blank">
                            <img width="40px" src="/images/icon/url_icon.png" />
                        </a>
                        <a href="https://www.linkedin.com/company/aligo-ai/?viewAsMember=true" target="_blank">
                            <img width="40px" src="/images/icon/linkedin_icon.png" />
                        </a>
                        <a href="https://www.youtube.com/@CeleVu" target="_blank">
                            <img width="40px" src="/images/icon/youtube_icon.png" />
                        </a>
                    </div>
                </div>
            </div>
        </Row>
    );
};

export default ServiceFlow;
