import React from 'react';
import { LargeContainer } from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import Home from '../components/main/Home';
import { useSelector } from 'react-redux';
import Landing from '../components/main/Landing';

const HomePage = () => {
    const userData = useSelector(state => state.user);
    return (
        <Layout>
            <LargeContainer>
                <Landing/>
                {/* {userData.id ? <Home /> : <Landing />} */}
                {/*<Focus />*/}
                {/*<ModelsNow />*/}
                {/*<FacialAnalysis />*/}
                {/*<Analytics />*/}
            </LargeContainer>
        </Layout>
    );
};

export default HomePage;
