import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { instagramCode } from '../../queries/Instagram';
import Loading from '../contents/Loading';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 1080px;
    margin: auto;
`;

export const useCodedata = ({ celebId }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const instagramMutate = useMutation(async data => instagramCode(data), {
        onSuccess: async res => {
            console.log(res);
        },
        onError: err => {
            console.log(err);
        },
    });

    useEffect(() => {
        if (code) {
            instagramMutate.mutate({ code: code });
        }
    }, []);
};

export const InstagramRedirect = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const instagramMutate = useMutation(async data => instagramCode(data), {
        onSuccess: async res => {
            console.log(res);
        },
        onError: err => {
            console.log(err);
        },
    });

    useEffect(() => {
        if (code) {
            instagramMutate.mutate({ code: code });
        }
    }, []);
};

const Redirect = () => {
    return (
        <Wrapper>
            <Loading />
        </Wrapper>
    );
};

export default Redirect;
