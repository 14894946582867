import React from 'react';
import styled from 'styled-components';
import { BLACK_COLOR, BORDER_COLOR, RADIUS, ERROR_COLOR, FOCUS_COLOR, WHITE_COLOR } from '../../utils/color';

const sizeStyles = {
    default: {
        width: '524px',
        height: '60px',
        fontSize: '1.5rem',
        padding: '15px 22px',
        fontWeight: '500',
    },
    sm: {
        fontSize: '1.2rem',
        padding: '6px 10px',
        fontWeight: '500',
    },
    md: {
        fontSize: '1.4rem',
        padding: '14px 16px',
        fontWeight: '600',
    },
    lg: {
        width: '524px',
        height: '60px',
        fontSize: '1.5rem',
        padding: '15px 22px',
        font: 'normal normal 400 1.5rem/1.5rem inter',
    },
};

const colorStyles = {
    default: {
        border: '1px solid ' + BORDER_COLOR,
        backgroundColor: WHITE_COLOR,
        color: BLACK_COLOR,
        '&:focus': {
            border: '1px solid ' + FOCUS_COLOR,
            outline: 'none',
        },
    },
    error: {
        border: '2px solid ' + ERROR_COLOR,
        backgroundColor: WHITE_COLOR,
        color: BLACK_COLOR,
        '&:focus': {
            //border: '1px solid ' + FOCUS_COLOR,
            outline: 'none',
        },
    },
};

const InputGroupStyle = styled.div`
    margin: 0;
    ${props => (props.width ? `width: ${props.width}` : '100%')};
`;

const LabelGroupStyle = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: ${props => sizeStyles[props.size] || sizeStyles.default.width};
`;

const TitleLabelStyle = styled.label`
    font: normal normal 700 1.8rem/1.8rem inter;
    color: #666666;
    margin-bottom: 12px;
`;
const HelpLabelStyle = styled.label`
    ${props => (props.errors ? 'color: red;' : 'color: black;')}
    font: normal normal bold 1.3rem/2rem inter;
`;

const InputStyle = styled.input`
    ${props => sizeStyles[props.size] || sizeStyles.default}
    ${props => (props.errorMsg ? colorStyles.error : colorStyles.default)}
	border-radius: ${props => props.borderRadius || RADIUS};
    margin: 0 auto;
`;

function InputGroup(props) {
    const { children, register, name, text, title, type, size, errorMsg, width, value, setValue, ...options } = props;
    const ErrorLabel = <HelpLabelStyle errors={errorMsg}>{errorMsg}</HelpLabelStyle>;
    const TextLabel = <HelpLabelStyle>{text}</HelpLabelStyle>;

    if (register) {
        return (
            <InputGroupStyle size={size}>
                <LabelGroupStyle>
                    <TitleLabelStyle htmlFor={name}>{title}</TitleLabelStyle>
                    {errorMsg ? ErrorLabel : TextLabel}
                </LabelGroupStyle>
                <InputStyle {...register(name)} id={name} width={width} errorMsg={errorMsg} size={size} {...options}>
                    {children}
                </InputStyle>
            </InputGroupStyle>
        );
    } else {
        return (
            <InputGroupStyle>
                <LabelGroupStyle size={size} width={width}>
                    <TitleLabelStyle htmlFor={name}>{title}</TitleLabelStyle>
                    {errorMsg ? ErrorLabel : TextLabel}
                </LabelGroupStyle>
                <InputStyle id={name} errorMsg={errorMsg} size={size} {...options}>
                    {children}
                </InputStyle>
            </InputGroupStyle>
        );
    }
}

export default InputGroup;
