import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import Progressbar from '../../components/progressbar/Progressbar';
import { getRecommendedCelebs, putProjectFeatures } from '../../queries/GetProjectList';

const Feature = prop => {
    const { featureData, handler } = prop;
    const projectId = useParams().id;
    const { register, handleSubmit } = useForm({});
    const mutation = useMutation(async feature => putProjectFeatures({ projectId: projectId, data: feature }), {
        onSuccess: res => {},
        onError: err => {
            switch (err.response.status) {
                default:
                    console.log('default');
                    return;
            }
        },
    });

    const onSubmit = result => {
        let postData = [];
        const keys = Object.keys(result);

        for (let i = 0; i < keys.length; i++) {
            const featureValue = featureData.filter(function (item) {
                return item.display === keys[i];
            });

            const obj = {
                project: parseInt(featureData[0].project),
                feature: parseInt(featureValue[0].feature),
                value: parseInt(result[keys[i]]),
            };
            postData.push(obj);
        }
        mutation.mutate(postData);
        handler(postData);
    };

    return (
        <div className="feature">
            <form onSubmit={handleSubmit(onSubmit)}>
                <ul>
                    {featureData.map((feature, index) => {
                        if (index < 6)
                            return (
                                <li key={index}>
                                    <Progressbar
                                        register={register}
                                        name={feature.display}
                                        title={feature.display}
                                        value={feature.value}
                                    />
                                </li>
                            );
                    })}
                </ul>
                <div className="btn-group clearfix">
                    <button className="btn btn-outline-primary" type="submit">
                        Save & Request Recommendation
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Feature;
