import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CREATE_PROJECT_MODAL, setModal } from '../modal/api/action';
import classNames from 'classnames';

const LeftMenu = props => {
    const { list, selectedId } = props;
    const [show, setShow] = useState({
        view: false,
        id: null,
    });

    const dispatch = useDispatch();
    const showCreateProjectModal = () => {
        dispatch(
            setModal({
                type: CREATE_PROJECT_MODAL,
                data: {
                    status: true,
                },
            })
        );
    };

    const emptyList = (
        <ul className="mt-20">
            <li className="border-bottom">
                <h3>Current Project</h3>
            </li>
            <li className="border-bottom">No Project</li>
        </ul>
    );

    let projectList = (
        <ul className="mt-20">
            {/*<li className="border-bottom">
                <h3>Active Project</h3>
            </li>*/}
            {list.map((project, index) => (
                <li
                    className={classNames('border-bottom', { select: project.id == selectedId })}
                    key={index}
                    onMouseOver={() => setShow({ view: true, projectId: project.id })}
                    onMouseLeave={() => setShow({ view: false, projectId: null })}
                >
                    <a href={'/project/' + project.id}>{project.label}</a>
                    <DeleteButton projectId={project.id} show={show} />
                </li>
            ))}
        </ul>
    );

    return (
        <div className="side-bar pt-60 pb-60">
            <button
                type="button"
                onClick={showCreateProjectModal}
                className="btn btn-sidebar ml-20 mb-20 border-bottom"
            >
                Create Project
            </button>
            {list[0] ? projectList : emptyList}
        </div>
    );
};

const DeleteButton = props => {
    const { projectId, show } = props;

    const deleteProject = () => {
        console.log('delete project : ', projectId);
    };

    return (
        <img
            src="/images/close-icon-black.svg"
            alt="delete"
            className={classNames('delete', { selected: show.view && show.projectId == projectId })}
            onClick={deleteProject}
        />
    );
};

LeftMenu.defaultProps = {
    list: [],
};
export default LeftMenu;
