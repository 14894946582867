import React from 'react';
import Layout from '../modules/common/Layout';
import { LargeContainer } from '../components/MainContainer';
import Demo from '../components/Demo/Demo';

const DemoPage = () => {
    return (
        <Layout>
            <LargeContainer>
                <Demo />
            </LargeContainer>
        </Layout>
    );
};

export default DemoPage;
