import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/input/Input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelect from '../../components/styled-components/CustomSelect';
import { useSelect } from '../../components/styled-components/Select';
import { useProjectBudget, useProjectCountries, useProjectSchedule } from '../../hooks/getCode';
import { useMutation } from 'react-query';
import { createProjects } from '../../queries/Project';
import Button from '../../components/styled-components/Button';
import { useUrl } from '../../hooks/useUrl';
import { useUserData } from '../../hooks/useUserData';
import countryCode from '../../utils/countryCode.json';
//import RegisterSelect from '../../components/styled-components/RegisterSelect';
//import CustomSelect from '../../components/styled-components/control/CustomSelect';

const schema = yup
    .object({
        title: yup.string().label('ID').required(),
        detail: yup.string().label('Detail').required(),
        webpage: yup.string().label('Webpage'),
        budget: yup.string().label('Webpage'),
        countries: yup.string().label('Countries'),
        first_name: yup.string().label('First name').required(),
        last_name: yup.string().label('Last name').required(),
        country_code: yup.string().label('Country code').required(),
        mobile: yup.string().label('Mobile').required(),
        //budget: yup.number().label('Budget').required(),
    })
    .required();

const PageTitle = styled.div`
    width: 620px;
    margin: 100px auto 40px;
    .logo {
        width: 164px;
    }
    .title {
        margin-left: 12px;
        margin-bottom: 20px;
        color: var(--gray-900);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 100% */
    }
    .description {
        margin-left: 12px;
        color: var(--gray-900);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
    }
`;

const ProjectFormWrapper = styled.div`
    width: 616px;
    border-radius: 15px;
    background-color: var(--white);
    margin: 40px auto 200px;
    padding: 48px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    .flex {
        display: flex;
        justify-content: space-between;
    }
    .country-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 32px;
        flex-wrap: wrap;
    }
    .border {
        margin: 32px auto;
        border-top: 1px solid var(--gray-400);
        height: 1px;
    }
`;

const InputGroup = styled.div`
    /*.flex {
        display: flex;
        justify-content: space-between;
    }*/
`;

const LabelStyle = styled.label`
    &.require {
        ::after {
            content: ' *';
            color: var(--crimson-500);
        }
    }
    &.blank {
        ::after {
            content: 'blank';
            color: transparent;
        }
    }
    &.optional {
        ::after {
            content: ' (optional)';
            font-weight: 350;
        }
    }
    display: inline-block;
    color: var(--gray-800);

    margin-left: 1px;
    margin-bottom: 16px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem;
`;

const ErrorLabel = styled.label`
    margin-left: 1px;
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: top;
    justify-content: flex-start;
    color: var(--crimson-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const ButtonArea = styled.div`
    display: flex;
    margin-top: 160px;
`;

const SubmitButton = styled.input`
    width: 180px;
    height: 60px;
    border-radius: 15px;
    border: none;
    background: var(--purple-500);
    margin-left: auto;

    color: var(--gray-050);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 100% */
    cursor: pointer;
    /*position: absolute;
    right: 48px;
    bottom: 48px;*/
`;

const CreateProject = () => {
    const { data: countriesList } = useProjectCountries();
    const { data: budgetList } = useProjectBudget();
    const { data: scheduleList } = useProjectSchedule();
    const { state: budget, setState: setBudget } = useSelect();
    const { data: userData, isLoading } = useUserData();
    const { state: schedule, setState: setSchedule } = useSelect();
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [errorState, setErrorState] = useState({
        schedule: false,
        budget: false,
        ids: false,
    });

    const { navigateToPage } = useUrl();
    const mutation = useMutation(async data => createProjects(data), {
        onSuccess: response => {
            navigateToPage('/project/');
        },
        onError: err => {
            console.log(err);
        },
    });

    useEffect(() => {}, [countriesList]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    if (isLoading) return <></>;
    const onSubmit = data => {
        const ids = selectedCountries.map(country => country.id);
        setErrorState(prevState => ({
            ...prevState,
            schedule: !schedule.id,
            budget: !budget.id,
            ids: !ids.length,
        }));
        //if (!schedule.id) {
        //    console.log(schedule.id);
        //    setErrorState({ ...errorState, schedule: true });
        //}
        //if (!budget.id) {
        //    console.log(budget);
        //    setErrorState({ ...errorState, budget: true });
        //}
        //if (!ids.length) {
        //    setErrorState({ ...errorState, ids: true });
        //}

        if (errorState.schedule || errorState.budget || errorState.ids) {
            return;
        }
        const params = {
            title: data.title,
            detail: data.detail,
            webpage: data.webpage,
            countries: ids,
            schedule: schedule.id,
            budget: budget.id,
            first_name: data.first_name,
            last_name: data.last_name,
            mobile: data.country_code + data.mobile,
        };
        mutation.mutate(params);
    };

    const handleButtonClick = country => {
        if (selectedCountries.includes(country)) {
            setSelectedCountries(selectedCountries.filter(item => item.value !== country.value));
        } else {
            setSelectedCountries([...selectedCountries, country]);
        }
    };

    return (
        <>
            <PageTitle>
                <div className="title">Create Project</div>
            </PageTitle>
            <ProjectFormWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                        <LabelStyle className="require">Project title</LabelStyle>
                        <Input
                            type="text"
                            name="title"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.title ? errors.title : null}
                        />
                        {errors.title ? <ErrorLabel>{errors.title.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <InputGroup>
                        <LabelStyle className="require">Project Description</LabelStyle>
                        <Input
                            type="text"
                            name="detail"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.detail ? errors.detail : undefined}
                        />
                        {errors.detail ? <ErrorLabel>{errors.detail.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <InputGroup>
                        <LabelStyle>Product Website</LabelStyle>
                        <Input
                            type="text"
                            name="webpage"
                            size="lg"
                            color="primary"
                            placeholder="Optional"
                            register={register}
                            option={{ required: false }}
                            error={errors.webpage ? errors.webpage : undefined}
                        />
                        {errors.webpage ? <ErrorLabel>{errors.webpage.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <InputGroup>
                        <LabelStyle className="require">Target Country</LabelStyle>
                        <div className="country-wrapper">
                            {countriesList.map((country, index) => {
                                const variant = selectedCountries.some(item => item.value === country.value)
                                    ? 'country'
                                    : 'selected';
                                return (
                                    <Button
                                        key={country.id + country.value + index}
                                        type="button"
                                        variant={variant}
                                        size="country"
                                        isError={errorState.ids}
                                        onClick={() => handleButtonClick(country)}
                                    >
                                        {country.label}
                                    </Button>
                                );
                            })}
                        </div>
                    </InputGroup>
                    <div className="flex">
                        <InputGroup>
                            <LabelStyle className="require">Campaign Schedule</LabelStyle>
                            <CustomSelect
                                optionsList={scheduleList}
                                state={schedule}
                                setState={setSchedule}
                                size="lgHalf"
                                variant="gray2"
                                iconName="schedule"
                                placeholder="Select period"
                                isError={errorState.schedule}
                                //register={register}
                                //option={{ required: true }}
                                //name="schedule"
                            />
                            {errors.schedule ? <ErrorLabel>{errors.schedule.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                        <InputGroup>
                            <LabelStyle className="require">Budget</LabelStyle>
                            <CustomSelect
                                optionsList={budgetList}
                                state={budget}
                                setState={setBudget}
                                size="lgHalf"
                                variant="gray2"
                                iconName="toll"
                                placeholder="Select amount"
                                isError={errorState.budget}
                                //register={register}
                                //option={{ required: true }}
                                //name="budget"
                            />
                            {errors.budget ? <ErrorLabel>{errors.budget.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                    </div>
                    <div className="border" />
                    <div className="flex">
                        <InputGroup>
                            <LabelStyle className="require">Name</LabelStyle>
                            <Input
                                type="text"
                                name="first_name"
                                size="lg_half"
                                color="primary"
                                placeholder="First name"
                                register={register}
                                option={{ required: true }}
                                error={errors.first_name ? errors.first_name : undefined}
                                defaultValue={userData?.first_name}
                            />
                            {errors.first_name ? <ErrorLabel>{errors.first_name.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                        <InputGroup>
                            <LabelStyle className="blank" />
                            <Input
                                type="text"
                                name="last_name"
                                size="lg_half"
                                color="primary"
                                placeholder="Last name"
                                register={register}
                                option={{ required: true }}
                                error={errors.last_name ? errors.last_name : undefined}
                                defaultValue={userData?.last_name}
                            />
                            {errors.last_name ? <ErrorLabel>{errors.last_name.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                    </div>
                    <div className="flex">
                        <InputGroup>
                            <LabelStyle className="require">Mobile</LabelStyle>
                            <Input
                                type="text"
                                name="country_code"
                                size="lg_half"
                                color="primary"
                                placeholder="Country code"
                                register={register}
                                option={{ required: true }}
                                error={errors.country_code ? errors.country_code : undefined}
                            />
                            {errors.country_code ? (
                                <ErrorLabel>{errors.country_code.message}</ErrorLabel>
                            ) : (
                                <ErrorLabel />
                            )}
                        </InputGroup>
                        <InputGroup>
                            <LabelStyle className="blank" />
                            <Input
                                type="text"
                                name="mobile"
                                size="lg_half"
                                color="primary"
                                register={register}
                                option={{ required: true }}
                                error={errors.mobile ? errors.mobile : undefined}
                                defaultValue={userData?.mobile}
                            />
                            {errors.mobile ? <ErrorLabel>{errors.mobile.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                    </div>
                    <Button type="submit" size="lg" variant="primary" style={{ marginTop: '28px' }}>
                        Confirm
                    </Button>
                    {/*<ButtonArea>
                        <SubmitButton type="submit" value="Confirm" />
                    </ButtonArea>*/}
                </form>
            </ProjectFormWrapper>
        </>
    );
};

export default CreateProject;
