import React from 'react';
import FilmographyForm from './FilmographyForm';

function Filmography(props) {
    const { register, filmographys } = props;

    return (
        <>
            <FilmographyForm index={0} register={register} filmography={filmographys ? filmographys[0] : null} />
            <FilmographyForm index={1} register={register} filmography={filmographys ? filmographys[1] : null} />
            <FilmographyForm index={2} register={register} filmography={filmographys ? filmographys[2] : null} />
            <FilmographyForm index={3} register={register} filmography={filmographys ? filmographys[3] : null} />
            <FilmographyForm index={4} register={register} filmography={filmographys ? filmographys[4] : null} />
        </>
    );
}

export default Filmography;
