//import axios from 'axios';
//import { BASE_URL } from '../configs/common';
import { privateClient, publicClient } from '../utils/client';

export const getCelebList = async query => {
    try {
        const response = await privateClient.get('/api/v1/celeb/', {
            params: query,
        });

        return response.data;
    } catch (error) {}
};
export const getPublicCelebList = async query => {
    try {
        const response = await privateClient.get('/api/v1/celeb/profile/', {
            params: query,
        });

        return response.data;
    } catch (error) {}
};

export const getCelebDetail = async ({ celebId }) => {
    try {
        const response = await privateClient.get(`/api/v1/celeb/${celebId}/`);
        return response.data;
    } catch (error) {
        // 여기서 에러를 처리하거나 적절한 방식으로 반환합니다.
        // 예를 들어, 특정 에러 메시지를 반환하거나 다시 던질 수 있습니다.
        if (error.response && error.response.status === 404) {
            throw new Error('존재하지 않는 셀럽입니다.');
        } else if (error.response && error.response.status === 403) {
            throw new Error('권한이 없습니다.');
        } else if (error.response && error.response.status === 401) {
            throw new Error('로그인이 필요합니다.');
        } else {
            // 다른 예상치 못한 에러가 발생한 경우
            throw error;
        }
    }
};
export const getPublicCelebDetail = async ({ celebId }) => {
    try {
        const response = await privateClient.get(`/api/v1/celeb/profile/${celebId}/`);
        return response.data;
    } catch (error) {
        // 여기서 에러를 처리하거나 적절한 방식으로 반환합니다.
        // 예를 들어, 특정 에러 메시지를 반환하거나 다시 던질 수 있습니다.
        if (error.response && error.response.status === 404) {
            throw new Error('존재하지 않는 셀럽입니다.');
        } else if (error.response && error.response.status === 403) {
            throw new Error('권한이 없습니다.');
        } else if (error.response && error.response.status === 401) {
            throw new Error('로그인이 필요합니다.');
        } else {
            // 다른 예상치 못한 에러가 발생한 경우
            throw error;
        }
    }
};

export const getCelebsNow = async () => {
    const response = await privateClient.get('/api/v1/main/celebs/now/');

    return response.data;
};

export const getRecommendCeleb = async query => {
    const response = await privateClient.get(`/api/v1//celeb/recommendation/`, { params: query });

    return response.data.data;
};
