import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getHeartList } from '../../queries/Portfolio';
import Error from '../contents/Error';
import Loading from '../contents/Loading';
import ModelCard from './ModelCard';

function CardList(props) {
    const { models, page, selectedCountry } = props;
    const userData = useSelector(state => state.user);
    const { data, isLoading, isError, error } = useQuery(['getHeartList'], () => getHeartList({ userId: userData.id }));

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    const heartList = data.map(value => value.portfolio);
    return (
        <div className="list-area">
            {models.results.map((model, index) => (
                <ModelCard
                    key={index}
                    model={model}
                    pageIndex={page}
                    heartList={heartList}
                    selectedCountry={selectedCountry}
                />
            ))}
        </div>
    );
}

export default CardList;
