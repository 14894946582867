import React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AddHeart, RemoveHeart } from '../../queries/AddHeart';
import { ALERT_MODAL, setAlertModal } from '../modal/api/action';

const Heart = props => {
    const { isClick, photoId, userId, setHeartIDHandler, heartList } = props;
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user);

    const showAlertModal = () => {
        dispatch(
            setAlertModal({
                type: ALERT_MODAL,
                data: {
                    status: true,
                    title: 'Register and get more information',
                    content: "Seems you don't have account yet. Please sign up and enjoy more service!",
                    buttonText: 'Go Login',
                    buttonFunction: () => {
                        window.location.href = '/login';
                    },
                },
            })
        );
    };

    const mutation = useMutation(
        async () =>
            isClick
                ? RemoveHeart({ userId: userId, portfolioId: isClick })
                : AddHeart({ photoId: photoId, userId: userId }),
        {
            onSuccess: async res => {
                //setHeartStatus(!heartStatus);
                console.log(res);
                //setHeartIDHandler(res.data.id);
            },
            onError: err => {
                switch (err.response.status) {
                    case 401:
                        console.log('401');
                        if (err.response.data.detail) {
                            console.log(err.response.data);
                        }
                        return;
                    case 301:
                        console.log('301');
                        return;
                    default:
                        console.log(err.response.data.detail);
                        console.log('default');
                        return;
                }
            },
        }
    );
    return (
        <button
            onClick={() => {
                console.log('click', userData);
                if (userData.isAuthenticated == false) {
                    showAlertModal();
                } else {
                    mutation.mutate();
                }
            }}
            type="button"
            className="heart"
        >
            {/*{heartList.includes(photoId) ? <i className="icon-heart"></i> : <i className="icon-heart-empty-thin"></i>}*/}
        </button>
    );
};

//Heart.defaultProps = {
//    isClick: true,
//};

export default Heart;
