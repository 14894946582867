import React from 'react';
import ReactDOM from 'react-dom';
import Script from 'react-load-script';

export default function GoogleTrendsMap(props) {
    const { type, keyword, url, startDate, endDate } = props;
    const handleScriptLoad = _ => {
        window.trends.embed.renderExploreWidgetTo(
            document.getElementById('widget'),
            type,
            {
                comparisonItem: [{ keyword, geo: '', time: startDate + ' ' + endDate }],
                category: 0,
                property: '',
            },
            {
                exploreQuery: `date=${startDate + ' ' + endDate}&q=${keyword}`,
                guestPath: 'https://trends.google.com:443/trends/embed/',
            }
        );
    };

    const renderGoogleTrend = _ => {
        return <Script url={url} onLoad={handleScriptLoad} />;
    };

    return <div className="googleTrend">{renderGoogleTrend()}</div>;
}
