import { useNavigate } from 'react-router-dom';

export const useUrl = () => {
    const navigate = useNavigate();

    const navigateToPage = url => {
        navigate(url);
    };

    return { navigateToPage };
};
