import React, { useState } from 'react';
import styled from 'styled-components';

const SelectStyle = styled.div`
    position: relative;
    display: inline-block;
    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        pointer-events: none;
    }
`;

const SelectTag = styled.select`
    width: 152px;
    height: 36px;
    padding: 10px 17px;
    color: #7a7a7a;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    ::ms-expand {
        display: none;
    }
    font: normal normal 500 1.5rem/1.5rem inter;
    :focus {
        font: normal normal 700 1.5rem/1.5rem inter;
        color: #474747;
    }
    border-radius: 2px;
    border: 1px solid var(--secondary-secondary-500-secondary, #860cf9);
    background: var(--gray-gray-050, #fbfbfb);
    option {
        pointer-events: none;
    }
`;

const Select = props => {
    const { options, setValue, value } = props;

    const handleChange = event => {
        setValue(event.target.value);
    };

    return (
        <SelectStyle>
            <SelectTag onChange={event => handleChange(event)} value={value}>
                <option value="" disabled>
                    {value ? options.find(item => item.value === value)?.label : ''}
                </option>
                {options.map(option => (
                    <option key={option?.id} value={option?.value}>
                        {option?.label}
                    </option>
                ))}
            </SelectTag>
            <span className="arrow material-icons">arrow_drop_down</span>
        </SelectStyle>
    );
};
Select.defaultProps = {
    options: [],
    setValue: null,
    value: '',
};
export default Select;
