import React, { useEffect, useState, useRef } from 'react';

const Carousel = props => {
    const { images, imageCount, mainWidth, mainHeight } = props;
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [leftArrow, setLeftArrow] = useState(false);
    const [rightArrow, setRightArrow] = useState(true);
    const listRef = useRef(null);
    const width = mainWidth / imageCount;
    const height = mainWidth / imageCount;
    //const height = mainHeight / imageCount;

    useEffect(() => {
        if (images.length > 0) {
            listRef.current.style.transition = 'all 0.5s ease-in-out';
            listRef.current.style.transform = `translateX(${currentImageIndex * -width}px)`;
            if (currentImageIndex === 0) {
                setLeftArrow(false);
            } else {
                setLeftArrow(true);
            }
            if (currentImageIndex >= images.length - imageCount - 1) {
                setRightArrow(false);
            } else {
                setRightArrow(true);
            }
        }
    }, [currentImageIndex]);
    if (!images.length || !images) return <></>;

    const leftButtonIndex = () => {
        //if (leftArrow) {
        const nextIndex = currentImageIndex - 1;

        if (nextIndex >= 0) {
            setCurrentImageIndex(nextIndex);
        }
        //}
    };

    const rightButtonIndex = () => {
        //if (rightArrow) {
        const nextIndex = currentImageIndex + 1;

        if (nextIndex >= 0 && nextIndex < images.length - imageCount) {
            setCurrentImageIndex(nextIndex);
        }
        //}
    };

    return (
        <>
            <img
                width={mainWidth}
                height={mainHeight}
                src={images[selectedImageIndex].portfolio?.file}
                style={{ objectFit: 'contain' }}
            />
            {images.length > 0 ? (
                <div style={{ overflow: 'hidden', width: `${mainWidth}px`, position: 'relative' }}>
                    <div className="nav-arrow-box">
                        <i
                            onClick={leftButtonIndex}
                            className="icon-left-open"
                            style={{
                                position: 'absolute',
                                left: '0',
                                top: `${height / 2}px`,
                                zIndex: '1',
                                cursor: 'pointer',
                                display: leftArrow ? 'block' : 'none',
                            }}
                        />
                        <i
                            onClick={rightButtonIndex}
                            className="icon-right-open"
                            style={{
                                position: 'absolute',
                                right: '0',
                                top: `${height / 2}px`,
                                zIndex: '1',
                                cursor: 'pointer',
                                display: rightArrow ? 'block' : 'none',
                            }}
                        />
                    </div>
                    <div
                        className="img-container"
                        ref={listRef}
                        style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            position: 'relative',
                            width: `${width * images.length}px`,
                        }}
                    >
                        {images.map((image, index) => {
                            //if (selectedImageIndex !== index) {
                            return (
                                <img
                                    key={index}
                                    onClick={() => setSelectedImageIndex(index)}
                                    style={{ width: `${width}px`, height: `${height}px`, objectFit: 'cover' }}
                                    src={image.portfolio.file}
                                    alt={index}
                                />
                            );
                            //}
                        })}
                    </div>
                </div>
            ) : (
                <></>
            )}
            {/*<img src={images[selectedImageIndex]?.portfolio.file} />*/}
        </>
    );
};

export default Carousel;
