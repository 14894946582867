import axios from 'axios';
import { privateClient, publicClient } from '../utils/client';

export const getCountriesCode = async () => {
    const response = await publicClient.get('/api/v1/code/celeb/countries/');

    response.data.data.push({ id: 99, label: 'Followers', order: 99, value: 'followers_count' });
    return response.data.data;
};

export const getGenderCode = async () => {
    const result = [
        {
            id: 1,
            value: 'male',
            label: 'Male',
            order: 1,
        },
        {
            id: 2,
            value: 'female',
            label: 'Female',
            order: 2,
        },
        {
            id: 3,
            value: 'nonbinary',
            label: 'Nonbinary',
            order: 3,
        },
    ];
    const response = await publicClient.get('/api/v1/code/gender/');
    return result;

    //return response.data.data;
};

export const getFeatureCode = async () => {
    const response = await publicClient.get('/api/v1/code/celeb/features/');
    return response.data.data;
};

export const getCelebQuery = async () => {
    const response = await publicClient.get('/api/v1/code/celeb/');

    return response.data.data;
};

export const getProjectCode = async ({ id }) => {
    const response = await privateClient.get(`/api/v1/projects/`);

    return response.data;
};

export const getProjectBudgetCode = async () => {
    const response = await privateClient.get('/api/v1/code/projects/budget/');

    return response.data.data;
};

export const getProjectCountries = async () => {
    const response = await privateClient.get(`/api/v1/code/projects/countries/`);

    return response.data.data;
};

export const getProjectSchedule = async () => {
    const response = await privateClient.get(`/api/v1/code/projects/schedule/`);

    return response.data.data;
};
