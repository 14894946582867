import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    text-align: center;
    margin: 92px auto 0;
    ${props => sizeStyles[props.size] || sizeStyles.default};
`;

const sizeStyles = {
    default: {
        width: '198px',
    },
};

const Logo = props => {
    const { text, size } = props;
    return (
        <Wrapper size={size}>
            <img src="/svg/CeleVu_logo_b.svg" alt="logo" />
            {text}
        </Wrapper>
    );
};

export default Logo;
