import axios from 'axios';
import { privateClient } from '../utils/client';

export const getMessageList = async ({ userId, contractId }) => {
    const response = await privateClient.get(`/api/v1/contracts/${contractId}/messages/`);
    //const response = await privateClient.get(`/user/${userId}/contract/${contractId}/message/`);

    return response.data.data;
};

export const sendMessage = async ({ userId, contractId, data }) => {
    const response = await privateClient.post(`/api/v1/contracts/${contractId}/messages/`, data);
    //const response = await privateClient.post(`/user/${userId}/contract/${contractId}/message/`, data);

    return response.data.data;
};
