import {
    ALERT_MODAL,
    CONFIRM_MODAL,
    CREATE_OFFER_MODAL,
    CREATE_OFFER_DETAIL_MODAL,
    FIND_PASSWORD_MODAL,
    LARGE_MODAL,
    CREATE_PROJECT_MODAL,
    OFFER_DETAIL_MODAL,
    ALERT_MODAL_OFF,
    EXPOSURE_MEDIAS_CODE,
    REGISTER_MODAL,
    CONTRACT_OFFER_MODAL,
    CONTRACT_PROJECT_MODAL,
    FORGOT_PASSWORD_MODAL,
} from './action';

export const modalInitialState = {
    alert: {
        status: false,
        title: '',
        content: '',
        buttonText: '',
        buttonFunction: null,
    },
    confirm: {
        status: false,
    },
    createOffer: {
        status: false,
        projectId: 0,
        portfolio: {},
    },
    createOfferDetail: {
        status: false,
        body: {},
        data: {},
    },
    findPassword: {
        status: false,
    },
    large: {
        status: false,
        title: '',
        content: '',
        buttonText: '',
        buttonFunction: null,
    },
    offerDetail: {
        status: false,
        contract_id: 0,
    },
    register: {
        status: false,
    },
    contractProjectModal: {
        status: false,
        photo: '',
        celebId: 0,
    },
    contractOfferModal: {
        status: false,
        celebId: 0,
        project: {},
    },
    createProject: {
        status: false,
    },
    exposureMediasCode: [],
    forgotPassword: {
        status: false,
    },
};

const modal = (state = modalInitialState, action) => {
    switch (action.type) {
        case ALERT_MODAL:
            return {
                ...state,
                alert: action.data,
            };
        case ALERT_MODAL_OFF:
            return {
                ...state,
                alert: {
                    status: false,
                },
            };
        case CONFIRM_MODAL:
            return {
                ...state,
                confirm: action.data,
            };
        case CREATE_OFFER_MODAL:
            return {
                ...state,
                createOffer: action.data,
            };
        case CREATE_OFFER_DETAIL_MODAL:
            return {
                ...state,
                createOfferDetail: action.data,
            };
        case FIND_PASSWORD_MODAL:
            return {
                ...state,
                findPassword: action.data,
            };
        case LARGE_MODAL:
            return {
                ...state,
                large: action.data,
            };
        case CREATE_PROJECT_MODAL:
            return {
                ...state,
                createProject: action.data,
            };
        case OFFER_DETAIL_MODAL:
            return {
                ...state,
                offerDetail: action.data,
            };
        case EXPOSURE_MEDIAS_CODE:
            return {
                ...state,
                exposureMediasCode: action.data,
            };
        case REGISTER_MODAL:
            return {
                ...state,
                register: action.data,
            };
        case CONTRACT_PROJECT_MODAL:
            return {
                ...state,
                contractProjectModal: action.data,
            };
        case CONTRACT_OFFER_MODAL:
            return {
                ...state,
                contractOfferModal: action.data,
            };
        case FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                forgotPassword: action.data,
            };
        default:
            return state;
    }
};

export default modal;
