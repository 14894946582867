import React from 'react';

const SortArea = prop => {
    const { viewSelected, query, setCheckedEvent, setSeletedCountry, selectedCountry } = prop;

    const seleted = viewSelected ? (
        <>
            <input type="checkbox" value="F" id="chkselected" /> <label htmlFor="chkselected">Selected</label>
        </>
    ) : (
        <></>
    );

    return (
        <>
            {seleted}
            <input
                type="checkbox"
                value="F"
                id="chkGenderFemale"
                defaultChecked={query.female}
                onChange={() => {
                    setCheckedEvent('chkGenderFemale');
                }}
            />{' '}
            <label htmlFor="chkGenderFemale">Female</label>
            <input
                type="checkbox"
                value="M"
                id="chkGenderMale"
                defaultChecked={query.male}
                onChange={() => {
                    setCheckedEvent('chkGenderMale');
                }}
            />{' '}
            <label htmlFor="chkGenderMale">Male</label>
            <select onChange={e => setCheckedEvent(e.target.value)} defaultValue={query.o}>
                <option value="popularity">Popularity</option>
                <option value="created">Created</option>
                <option value="instagram_followers">Followers</option>
                {/*<option value="age">Age</option>*/}
            </select>{' '}
            <select className="mr-10" onChange={e => setSeletedCountry(e.target.value)} defaultValue={selectedCountry}>
                <option value="world">Worldwide</option>
                <option value="canada">Canada</option>
                <option value="france">France</option>
                <option value="germany">Germany</option>
                <option value="italy">Italy</option>
                <option value="japan">Japan</option>
                <option value="netherlands">Netherlands</option>
                {/*<option value="south_korea">South Korea</option>*/}
                <option value="spain">Spain</option>
                {/*<option value="sweden">Sweden</option>*/}
                <option value="united_kingdom">United Kingdom</option>
                <option value="united_states">United States</option>
            </select>
        </>
    );
};

SortArea.defaultProps = {
    viewSelected: false,
    setCheckedEvent: function () {
        return false;
    },
    query: {
        page: 1,
        male: false,
        female: false,
        choices: 'created',
    },
};

export default SortArea;
