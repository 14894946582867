import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const LeftMenu = () => {
    const userData = useSelector(state => state.user);
    const onRedirect = e => {
        e.preventDefault();
        window.location.href = '/my-celebs/create-celeb';
    };

    let location = useLocation();

    return (
        <div className="side-bar pt-60 pb-60">
            {/*<button className="btn btn-sidebar ml-20" onClick={onRedirect}>
                Register Celeb
            </button>*/}
            <h4 className="p-10 ml-10">My page</h4>
            <ul className="border-top">
                {/*<li
                    className={classNames('border-bottom', {
                        select: location.pathname === '/mypage',
                    })}
                >
                    <a href="/mypage">Complated project</a>
                </li>
                {userData.adagency ? (
                    <li
                        className={classNames('border-bottom', {
                            select: location.pathname === '/hearts',
                        })}
                    >
                        <a href="/hearts">Selected heart</a>
                    </li>
                ) : (
                    <></>
                )}*/}

                <li
                    className={classNames('border-bottom', {
                        select: location.pathname === '/setting',
                    })}
                >
                    <a href="/setting">Setting</a>
                </li>
            </ul>
        </div>
    );
};

export default LeftMenu;
