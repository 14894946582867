import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCelebDetail } from '../../queries/CelebList';
import Loading from '../contents/Loading';
import { useQuery } from 'react-query';
import Error from '../contents/Error';
import styled from 'styled-components';
import ApplyButton from '../buttons/ApplyButton';
import ImageSlider from './ImageSlider';
import { ReactComponent as InstagramLogo } from '../../svg/instagram.svg';
import numeral from 'numeral';
import Icon from '../models/Icon';
import CustomScrollbar from '../styled-components/Scrollbar';
import InstagramStat from './InstagramStat';
import GoogleTrends from './GoogleTrends';
import Highlights from './Highlights';
import { useModal } from '../../modules/modal/ModalUtils';
import { CONTRACT_PROJECT_MODAL } from '../../modules/modal/api/action';
import { LikeButton } from './CelebProfile';
import Button from '../styled-components/Button';
import Facebook from '../api/Facebook';

const useCelebDetail = celebId => {
    const { data, isLoading, isError, error } = useQuery(
        ['getCelebDetail'],
        () => getCelebDetail({ celebId: celebId }),
        {
            onError: err => {
                switch (err.response.status) {
                    case 401:
                        alert('로그인이 필요합니다.');
                        window.location.href = 'http://celevu.ai/';
                        break;
                    case 403:
                        alert('권한이 없습니다.');
                        window.location.href = 'http://celevu.ai/';
                        break;
                    case 404:
                        alert('존재하지 않습니다.');
                        window.location.href = 'http://celevu.ai/';
                        break;
                    default:
                        break;
                }
            },
        }
    );

    return { data, isLoading, isError, error };
};

const CelebDetailWrapper = styled.div`
    display: inline-block;
`;

const MainImageContainer = styled.div`
    .image-cover-bottom {
        /*position: relative;
        margin-top: -526px;
        width: 1440px;
        height: 528px;
        background: linear-gradient(0deg, #fbfbfb 20%, rgba(255, 255, 255, 0) 100%);*/
        h1 {
            font: normal normal 700 10rem/10rem inter;
            color: #202124;
            /*margin-top: 128px;*/
            margin-left: 100px;
            bottom: 0;
        }
    }
`;

const CelebProfile = styled.div`
    color: black;
    padding: 0 100px;
    width: 1440px;
    height: 300px;
    background: linear-gradient(
        0deg,
        rgba(251, 251, 251, 1) 50%,
        rgba(251, 251, 251, 0.5) 75%,
        rgba(255, 255, 255, 0) 100%
    );
    margin-top: -280px;
    position: relative;
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        overflow: visible;
        * {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .border {
            border-top: 1px solid #a4a4a4;
        }
    }
    h1 {
        font: normal normal 700 10rem/10rem inter;
        color: #202124;
        /*margin-top: 128px;*/
        bottom: 0;
    }
    h2 {
        font: normal normal 700 5rem/5rem inter;
        color: #2039b3;
    }
    .celeb-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .celeb-data-container {
        display: flex;
        /*width: 309px;*/
        padding: 28px 0px;
        /*align-items: center;*/
        align-items: flex-start;
        align-content: flex-start;
        gap: 8px 34px;
        flex-wrap: wrap;
        color: #7a7a7a;
        .celeb-data {
            width: 88px;
            .top {
                display: flex;
                /*justify-content: space-between;*/
                align-items: center;
            }
            .bottom {
                /*display: flex;*/
                /*justify-content: center;*/
                /*align-items: center;*/
            }
            .numbers {
                color: #7a7a7a;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 125% */
                margin-left: 5px;
            }
            .description {
                color: #7a7a7a;
                font-family: Inter;
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: 14px; /* 100% */
            }
        }
    }
`;

const SocialContainer = styled.div`
    width: 480px;
    .title {
        color: var(--gray-600, #7a7a7a);
        font-family: Inter;
        font-size: 1.43rem;
        font-style: italic;
        font-weight: 400;
        line-height: 1.4rem; /* 100% */
        margin-bottom: 12px;
    }
`;

const FavoriteButton = styled.button`
    width: 78px;
    height: 78px;
    border-radius: 10px;
    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
    margin-left: 28px;
    background-color: #fbfbfb;
`;

const CelebPhotoContainer = styled.div`
    display: flex;
    margin: 28px 0;
`;

const CelebPhoto = styled.div`
    margin: 16px;
    width: 286px;
    height: 286px;
    background-color: #fbfbfb;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.3);
    /*padding: 16px;*/
    overflow: hidden;
    &:hover {
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px #af6efd;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const InstagramArea = styled.div`
    /*width: 1440px;*/
    /*height: 1024px;*/
    /*background-color: #f3e7fe;*/
    display: inline-block;
`;

const CelebDetail = () => {
    const userData = useSelector(state => state.user);
    const celebId = parseInt(useParams().id);
    const { data, isLoading, isError, error } = useCelebDetail(celebId);
    const { showModal } = useModal(CONTRACT_PROJECT_MODAL);

    if (isLoading) return <Loading />;
    if (isError) return <Error errMsg={error.message} />;
    if (!data) return <></>;

    const onClickRequest = () => {
        const photo = {
            photo: data.photos[0].file,
            celebId: celebId,
        };
        showModal(photo);
    };

    const instagram = data.social_media?.instagram_followers
        ? numeral(data.social_media.instagram_followers).format('0.0a')
        : null;
    const popularity = data.google_popularity ? data.google_popularity['world'].toFixed(2) : null;
    const popularityTrends = popularity ? (
        <div className="celeb-data">
            <div className="top">
                <span className="material-icons">trending_up</span>
                <span className="numbers">{popularity}</span>
            </div>
            <div className="bottom">
                <span className="description">Google trend</span>
            </div>
        </div>
    ) : (
        <></>
    );
    const instagramTrends = instagram ? (
        <div className="celeb-data">
            <div className="top">
                <InstagramLogo style={{ width: '21px', height: '21px', fill: '#7A7A7A' }} />
                <span className="numbers">{instagram}</span>
            </div>
            <div className="bottom">
                <span className="description">Instagram</span>
            </div>
        </div>
    ) : (
        <></>
    );

    //const SocialNetwork =
    //    data.social_media.instagram ||
    //    data.social_media.youtube ||
    //    data.social_media.tiktok ||
    //    data.social_media.twitter ? (
    //        <SocialContainer>
    //            <p className="title">You can post on</p>
    //            <Icon url={data.social_media?.instagram} height="40px" />
    //            <Icon url={data.social_media?.facebook} height="40px" />
    //            <Icon url={data.social_media?.youtube} height="40px" />
    //            <Icon url={data.social_media?.tiktok} height="40px" />
    //            <Icon url={data.social_media?.twitter} height="40px" />
    //            <Icon url={data.social_media?.pinterest} height="40px" />
    //        </SocialContainer>
    //    ) : (
    //        <></>
    //    );

    const images = data.photos.map(obj => obj.file);
    const ImageList = (
        <CelebPhotoContainer>
            {data.photos.map((image, index) => (
                <CelebPhoto key={index}>
                    <img src={image.file} />
                </CelebPhoto>
            ))}
        </CelebPhotoContainer>
    );
    return (
        <CelebDetailWrapper>
            <MainImageContainer>
                <ImageSlider images={images} width="1440" height="1024" />
            </MainImageContainer>
            <CelebProfile>
                {/*<h1>{data.pen_name}</h1>*/}
                <div className="row">
                    <h1>{data.pen_name}</h1>
                    {/* ^ 이거 지우고 주석 풀기*/}
                    {/*<h2>K-pop idol</h2>*/}
                    <div>
                        <ApplyButton onClick={onClickRequest} size="lg">
                            Request Project
                        </ApplyButton>
                        <FavoriteButton>
                            <LikeButton celebId={data.id} defaultValue={data.is_like} />
                        </FavoriteButton>
                    </div>
                </div>
                <span className="row border" />
                <div className="celeb-info">
                    <div className="celeb-data-container">
                        {popularityTrends}
                        {instagramTrends}
                    </div>
                    {/*{SocialNetwork}*/}
                    {/*<Facebook />*/}
                </div>
            </CelebProfile>
            <CustomScrollbar width={1280} style={{ marginLeft: '100px' }}>
                {ImageList}
            </CustomScrollbar>
            {/*<InstagramArea>
                <InstagramStat />
            </InstagramArea>*/}
            {data.highlights ? <Highlights highlightsData={data.highlights} /> : <></>}
            {data.google_trends ? <GoogleTrends trendsData={data.google_trends} /> : <></>}
            {/*{data.instagram ? <InstagramStat /> : <></>}*/}
            {/*</InstagramArea>*/}
        </CelebDetailWrapper>
    );
};

export default CelebDetail;
