import axios from 'axios';
import { privateClient } from '../utils/client';

export const createProject = async ({ userID, data }) => {
    const response = await privateClient.post(`/api/v1/user/${userID}/project/`, data);

    return response.data;
};

export const advertisementResult = async ({ projectId }) => {
    const response = await privateClient.post(`/api/v1/project/${projectId}/result/`);

    return response.data.data;
};

export const getProjects = async () => {
    const response = await privateClient.get('/api/v1/projects/');

    return response.data.data;
};

export const removeProjects = async ({ id }) => {
    const response = await privateClient.delete(`/api/v1/projects/${id}`);

    return response.data.data;
};

export const patchProjects = async ({ id, params }) => {
    const response = await privateClient.delete(`/api/v1/projects/${id}`, params);

    return response.data.data;
};

export const getProject = async id => {
    const response = await privateClient.get(`/api/v1/projects/${id}`);

    return response.data.data;
};

export const createProjects = async data => {
    const response = await privateClient.post('/api/v1/projects/', data);

    return response.data.data;
};
