import React, { useState } from 'react';
import { useEffect } from 'react';
import Pagination from './Pagination';

const ListPagination = props => {
    const { data, postsPerPage, pageCount, returnData, number, setNumber } = props;
    const [page, setPage] = useState(1);
    const [blockNum, setBlockNum] = useState(0);

    useEffect(() => {
        const lastPostIndex = page * postsPerPage;
        const firstPostIndex = lastPostIndex - postsPerPage;
        const currentPosts = data?.slice(firstPostIndex, lastPostIndex);

        const currentDataNumber = data.length - (page - 1) * postsPerPage;

        setNumber(currentDataNumber);
        returnData(currentPosts);
    }, [page]);

    return (
        <Pagination
            data={data}
            postsPerPage={postsPerPage}
            pageCount={pageCount}
            setPage={setPage}
            page={page}
            blockNum={blockNum}
            setBlockNum={setBlockNum}
        />
    );
};

export default ListPagination;
