import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Favorite } from '../../svg/favorite.svg';
import ReactApexChart from 'react-apexcharts';
//import SimpleBarChart, { SimpleLineChart } from './Test';
import MaterialIcons, { MaterialIconsOutlined } from '../styled-components/MaterialIcons';
import { Bar, BarChart, CartesianGrid, Cell, Tooltip } from 'recharts';

const data = [
    {
        name: 'Page A',
        followers: 4000,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page A',
        followers: 4000,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page A',
        followers: 4000,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page A',
        followers: 4000,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page A',
        followers: 4000,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page A',
        followers: 4000,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page B',
        followers: 3000,
        date: '20/10/11',
        pv: 1300,
    },
    {
        name: 'Page C',
        followers: 2000,
        date: '20/10/11',
        pv: 1500,
    },
    {
        name: 'Page D',
        followers: 2780,
        date: '20/10/11',
        pv: 1400,
    },
    {
        name: 'Page E',
        followers: 1890,
        date: '20/10/11',
        pv: 100,
    },
    {
        name: 'Page F',
        followers: 2390,
        date: '20/10/11',
        pv: 10,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
    {
        name: 'Page G',
        followers: 3490,
        date: '20/10/11',
        pv: 1000,
    },
];

const InstagramStatWrapper = styled.div`
    width: 1440px;
    height: 1024px;
    background-color: #f3e7fe;
    display: inline-block;
    padding: 0 140px;
    .page-title {
        color: #860cf9;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 100% */
        margin-top: 70px;
        margin-left: -40px;
    }
    .cards {
        margin: 50px auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        .card {
            width: 272px;
            height: 160px;
            padding: 16px;
            border-radius: 15px;
            background: #fbfbfb;
            box-shadow: 0px 0px 10px 0px #d9d9d9;
            .title {
                margin-top: 8px;
                padding-bottom: 12px;
                gap: 4px;
                display: flex;
                align-items: center;
                color: #252525;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 100% */
                border-bottom: 1px solid #c2c2c2;
                /*.material-icons {
                    width: 24px;
                    height: 24px;
                    margin-right: 4px;
                }*/
            }
            .score {
                margin-top: 12px;
                color: #252525;
                font-family: Inter;
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px; /* 100% */
            }
        }
    }
    .charts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        .chart-wrapper {
            .title {
                margin-top: 4px;
                padding-bottom: 12px;
                display: flex;
                align-items: center;
                gap: 4px;
                color: #252525;
                font-family: Inter;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 100% */
            }
            .chart {
                width: 568px;
                height: 580px;
                flex-shrink: 0;
                border-radius: 15px;
                background: #fbfbfb;
                box-shadow: 0px 0px 10px 0px #d9d9d9;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }
        }
    }
`;

const FansAnalysisArea = styled.div`
    /*display: block;*/
    width: 1440px;
    /*height: 1024px;*/
    /*background-color: #f3e7fe;*/
    display: inline-block;
    padding: 0 140px;
    .page-title {
        font: normal normal 400 2.5rem/2.5rem inter;
        color: #252525;
        margin-top: 70px;
        margin-left: -40px;
    }
    .statistics {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        div {
            height: 536px;
            background-color: red;
            border-radius: 15px;
            background: #fbfbfb;
            box-shadow: 0px 0px 10px 0px #d9d9d9;
        }
        .top3 {
            display: grid;
            gap: 28px;
            grid-template-rows: repeat(3, 1fr);
            .card {
                width: 392px;
                height: 160px;
                padding: 16px;
                border-radius: 15px;
                background: #fbfbfb;
                box-shadow: 0px 0px 10px 0px #d9d9d9;
                .title {
                    /*margin-top: 8px;*/
                    padding-bottom: 12px;
                    display: flex;
                    align-items: center;
                    color: #252525;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 100% */
                    border-bottom: 1px solid #c2c2c2;
                    .material-icons {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                    }
                }
                .score {
                    margin-top: 12px;
                    color: #252525;
                    font-family: Inter;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 40px; /* 100% */
                }
            }
        }
    }
`;

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    width: '120px',
                    height: '60px',
                    padding: '7px',
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '15px',
                    filter: 'drop-shadow(0px 2px 4px #C2C2C2)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <span>{payload[0].payload.date}</span>
            </div>
        );
    }
};

const SimpleBarChart = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleMouseEnter = index => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };
    return (
        <BarChart width={500} height={500} data={data} margin={{ top: 100, left: 20, right: 20 }}>
            {/*<BarChart width={500} height={500} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>*/}
            <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} strokeOpacity={0.5} />
            {/*<XAxis dataKey="name" />*/}
            {/*<YAxis />*/}
            {/*<Tooltip />*/}
            <Tooltip content={CustomTooltip} position={{ y: -10 }} />
            {/*<Legend />*/}
            <Bar
                dataKey="followers"
                onMouseEnter={(event, index) => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                radius={[10, 10, 0, 0]}
                barSize={10}
                isAnimationActive={false}
            >
                {data.map((entry, index) =>
                    index === activeIndex ? (
                        <Cell fill={'#AF6EFD'} stroke={'#AF6EFD'} key={index} />
                    ) : (
                        <Cell fill={'#DDC4FD'} stroke={'#DDC4FD'} key={index} />
                    )
                )}
            </Bar>
        </BarChart>
    );
};

const InstagramStat = () => {
    return (
        <div>
            <InstagramStatWrapper>
                <p className="page-title">Instagram Overview</p>
                <div className="cards">
                    <div className="card">
                        <div className="title">
                            <MaterialIcons name="favorite" color="var(--crimson-500)" size="24px" />
                            <span>like</span>
                        </div>
                        <div className="score">100,000,000</div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <MaterialIconsOutlined name="chat_burble" color="#F59E0B" size="24px" />
                            <span>comments</span>
                        </div>
                        <div className="score">10,000</div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <MaterialIconsOutlined name="monitoring" color="#860CF9" size="24px" />
                            {/*<span className="material-icons" style={{ color: '#860CF9' }}>
                                monitoring
                            </span>*/}
                            <span>EGR</span>
                        </div>
                        <div className="score">10,000</div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <MaterialIcons name="visibility" color="var(--blue-500)" size="24px" />
                            <span>impressions</span>
                        </div>
                        <div className="score">10,000</div>
                    </div>
                </div>
                <div className="charts">
                    <div className="chart-wrapper">
                        <div className="title">
                            <MaterialIconsOutlined name="monitoring" color="#860CF9" size="24px" />
                            <span>EGR 비교</span>
                        </div>
                        <div className="chart">
                            <SimpleBarChart />
                        </div>
                    </div>
                    <div className="chart-wrapper">
                        <div className="title">
                            <MaterialIcons name="tag" color="#EC00CD" size="24px" />
                            <span>HASHTAG</span>
                        </div>
                        <div className="chart"></div>
                    </div>
                </div>
            </InstagramStatWrapper>
        </div>
    );
    //<FansAnalysisArea>
    //    <h1 className="page-title">Fans Analysis</h1>
    //    <div className="statistics">
    //        <div className="top3">
    //            <div className="card">
    //                <div className="title">FRANCE</div>
    //            </div>
    //            <div className="card">
    //                {/*<Donut />*/}
    //                <div className="title">FRANCE</div>
    //            </div>
    //            <div className="card">a</div>
    //        </div>
    //        <div>a</div>
    //        <div>a</div>
    //    </div>
    //</FansAnalysisArea>
};

export default InstagramStat;
