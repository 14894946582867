import React from 'react';
import { useMutation } from 'react-query';
import { createPortfolio } from '../../queries/CreatePortfolio';

function UploadFile(props) {
    const formData = new FormData();
    const { addList, isMain, setMain, title, id } = props;

    const uploadFile = event => {
        formData.set('file', event.target.files[0]);
        formData.set('is_main', isMain);
        mutation.mutate(formData);
    };

    const mutation = useMutation(async data => createPortfolio(data), {
        onSuccess: async res => {
            if (isMain) {
                setMain(res);
            } else {
                addList(res);
            }
        },
        onError: err => {
            switch (err.response.status) {
                case 401:
                    console.log('401');
                    return;
                case 301:
                    console.log('301');
                    return;
                default:
                    console.log(err.response.data.detail);
                    console.log('default');
                    return;
            }
        },
    });

    return (
        <>
            <input
                type="file"
                id={id}
                //accept=".jpeg, .jpg, .png"
                onChange={uploadFile}
            />
            {/*<button type="button" htmlFor={id} className="btn-inner-upload">
                {title}
            </button>*/}
            <label type="label" htmlFor={id}>
                {title}
            </label>
        </>
    );
}

export default UploadFile;
