import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import ListPagination from '../../components/nav/ListPagination';
import { getHeartList, removeHeartList } from '../../queries/Portfolio';
import Breadcrumb from '../header/Breadcrumb';
import LeftMenu from './LeftMenu';

const SelectedHeart = () => {
    const userData = useSelector(state => state.user);
    const [number, setNumber] = useState(0);
    const [currentData, setCurrentData] = useState([]);
    const { data, isLoading, error, isError, refetch } = useQuery(['getHeartList'], () => getHeartList());

    const removeHeart = portfolioId => {
        mutation.mutate(portfolioId);
    };

    const mutation = useMutation(
        async portfolioId => removeHeartList({ userId: userData.id, portfolioId: portfolioId }),
        {
            onSuccess: res => {
                refetch();
            },
            onError: err => {
                switch (err.response.status) {
                    default:
                        console.log('default');
                        return;
                }
            },
        }
    );

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <div className="page-container">
            <LeftMenu />
            <div className="row px-60">
                <Breadcrumb value={['My page', 'Select Heart']} />
                <h1 className="fl mb-20">Selected Heart</h1>
                <div className="grid-container mt-50 mb-100 p-0">
                    <ListPagination
                        data={data}
                        postsPerPage={15}
                        pageCount={10}
                        returnData={setCurrentData}
                        number={number}
                        setNumber={setNumber}
                    />
                    {/*<div className="pagination right mr-65">
                        <ul>
                            <li>
                                <a href="#">
                                    <i className="icon-left-open"></i>
                                </a>
                            </li>
                            <li className="selected">1</li>
                            <li>
                                <a href="#">2</a>
                            </li>
                            <li>
                                <a href="#">3</a>
                            </li>
                            <li>
                                <a href="#">4</a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="icon-right-open"></i>
                                </a>
                            </li>
                        </ul>
                    </div>*/}
                    <div className="selected-list">
                        {currentData.map((item, index) => {
                            return (
                                <div className="selected-item" key={index}>
                                    <div className="selected-item-img">
                                        <img src={item.portfolio.file} alt="" />
                                    </div>
                                    <div className="selected-item-info">
                                        <span className="click">Click {item.portfolio.click}</span>
                                        <button
                                            onClick={() => removeHeart(item.portfolio.id)}
                                            type="button"
                                            className="heart"
                                        >
                                            <span className="click">{item.portfolio.heart_count}</span>
                                            <i className="icon-heart" style={{ color: 'red' }}></i>
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectedHeart;
