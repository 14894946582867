import React from 'react';
import Section from '../layout/Section';
import styled from 'styled-components';
import Button from '../styled-components/Button';
import MaterialIcons from '../styled-components/MaterialIcons';
import { useQuery } from 'react-query';
import { getContact } from '../../queries/Contact';
import { useUrl } from '../../hooks/useUrl';

const Contents = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 100px;
    h2.title {
        color: var(--gray-800, #474747);
        font-family: Larken;
        font-size: 6rem;
        font-style: normal;
        font-weight: 500;
        line-height: 7.2rem; /* 72px */
        margin-bottom: 18px;
        white-space: pre-wrap;
    }
    .description {
        color: var(--gray-800);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.9rem;
        margin-bottom: 60px;
    }
    .button-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: -2px;
    }
    img {
        display: block;
        width: 480px;
        height: 480px;
    }
    .detail {
        margin-top: 16px;
        padding: 80px;
        width: 576px;
        height: 678px;
        border-radius: 16px;
        border: 1px solid var(--purple-100);
        background: var(--gray-050);
        color: var(--gray-800);

        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.9rem; /* 19.2px */
        .category {
            color: var(--gray-800);
            /*text-align: center;*/
            font-size: 2.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3.3rem; /* 33.6px */
            margin-bottom: 28px;
        }
        .middle-category {
            margin-top: 28px;
            color: var(--purple-300);
            margin-bottom: 12px;
            /*text-align: center;*/
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.4rem; /* 24px */
        }
        .link {
            color: var(--gray-800);
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.9rem; /* 19.2px */
            text-decoration-line: underline;
            margin-bottom: 80px;
            cursor: pointer;
        }
    }
`;

const Contact = () => {
    const { navigateToPage } = useUrl();
    const { data, isError, error, isLoading } = useQuery(['getContact'], () => getContact(), {});

    if (!data) return <></>;

    return (
        <Section bgUrl="/images/bg.png">
            <Contents>
                <div>
                    <h2 className="title">Need support?{'\n'}We’re here to help.</h2>
                    <h3 className="description">If you are interested but don't know how to proceed, let's chat!</h3>
                    <Button size="md" variant="contact" onClick={() => navigateToPage('/contact/schedule/')}>
                        <span className="button-text">
                            <MaterialIcons name="calendar_month" size="24px" color="white" /> Schedule Online Meeting
                        </span>
                    </Button>
                    <img src="/images/contact-us02.png"></img>
                </div>
                <div>
                    <ul className="detail">
                        <h4 className="category">Contact</h4>
                        <li className="middle-category">Email</li>
                        <li>{data.email}</li>
                        <li className="middle-category">Social media</li>
                        <li className="link">
                            <a href={data.social_media} target="_blank">
                                LinkedIn
                                <MaterialIcons name="arrow_outward" size="16px" />
                            </a>
                        </li>
                        <h4 className="category">OFFICE</h4>
                        <li className="middle-category">SEOUL</li>
                        <li>{data.office.seoul}</li>
                        <li className="middle-category">PARIS</li>
                        <li>{data.office.paris}</li>
                    </ul>
                </div>
            </Contents>
        </Section>
    );
};

export default Contact;
