import axios from 'axios';
import { privateClient } from '../utils/client';

//export const getOfferImage = async ({ contractId }) => {
//    const response = await privateClient.get(`contract/${contractId}/media/`);

//    return response.data;
//};

export const getOfferImage = async ({ contractId }) => {
    const response = await privateClient.get(`/api/v1/contracts/${contractId}/contents/`);

    return response.data.data;
};

export const GetContract = async ({ contractId }) => {
    const response = await privateClient.get(`/api/v1/contracts/${contractId}/`);

    return response.data;
};

export const confirmOffer = async ({ mediaId, body }) => {
    const response = await privateClient.patch(`/api/v1/contract/media/${mediaId}/`, body);

    return response.data;
};

export const offerAgencyConfirm = async ({ contractId }) => {
    const response = await privateClient.patch(`/api/v1/contract/${contractId}/`);

    return response.data;
};

export const saveSendOffer = async ({ userId, contractId }) => {
    const response = await privateClient.post(`/api/v1/user/${userId}/contract/${contractId}/send/`);
    return response.data;
};

export const patchContract = async (contractId, data) => {
    if (contractId && data) {
        const response = await privateClient.patch(`/api/v1/contracts/${contractId}/`, data);
        return response.data;
    }
    return new Error();
};
