import React from 'react';
import styled from 'styled-components';

const sizeStyles = {
    default: {
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        padding: '20px 24px',
        fontWeight: '400',
        width: '520px',
        height: '60px',
    },
    sm: {
        fontSize: '1.2rem',
        padding: '6px 10px',
        fontWeight: '500',
    },
    md: {
        fontSize: '1.4rem',
        padding: '14px 16px',
        fontWeight: '600',
    },
    lg: {
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        padding: '20px 24px',
        fontWeight: '400',
        width: '520px',
        height: '60px',
    },
    lg_half: {
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        padding: '20px 24px',
        fontWeight: '400',
        width: '248px',
        height: '60px',
    },
    base: {
        fontSize: '1.6rem',
        lineHeight: '1.8rem',
        fontWeight: '400',
        fontStyle: 'normal',
        padding: '16px 20px',
        width: '420px',
        height: '60px',
    },
};

const colorStyles = {
    default: {
        borderRadius: '2px',
        border: '1px solid var(--gray-400)',
        background: 'var(--white)',
        color: 'var(--gray-800)',
        '&:focus': {
            border: 'none',
            //border: '1px solid var(--purple-400)',
            boxShadow: '0px 0px 10px 0px var(--purple-100)',
        },
    },
    primary: {
        borderRadius: '2px',
        border: '1px solid var(--gray-400)',
        background: 'var(--white)',
        color: 'var(--gray-800)',
        '&:focus': {
            border: 'none',
            //border: '1px solid var(--purple-400)',
            boxShadow: '0px 0px 10px 0px var(--purple-100)',
        },
    },
    error: {
        borderRadius: '2px',
        border: '2px solid var(--crimson-500)',
        background: 'var(--white)',
        color: 'var(--gray-800)',
    },
};

const InputStyle = styled.input`
    display: block;
    ::placeholder {
        color: var(--gray-500);
    }
    ${props => sizeStyles[props.size] || sizeStyles.default}
    ${props => colorStyles[props.color] || colorStyles.default}
    ${props => (props.error ? colorStyles.error : '')}
`;

const LabelStyle = styled.label`
    display: inline-block;
    &.require {
        ::after {
            content: ' *';
            color: var(--crimson-500);
        }
    }
    &.blank {
        ::after {
            content: 'blank';
            color: transparent;
        }
    }
    &.optional {
        ::after {
            content: ' (optional)';
            font-weight: 350;
        }
    }
    color: var(--gray-800);

    margin-left: 1px;
    margin-bottom: 12px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem;
`;

const ErrorLabel = styled.label`
    margin-left: 1px;
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: top;
    justify-content: flex-start;
    color: var(--crimson-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const Input = props => {
    const { type, name, size, color, disabled, register, option, error, children, ...options } = props;

    return (
        <InputStyle
            {...register(name, option)}
            type={type}
            disabled={disabled}
            error={error}
            color={color}
            size={size}
            {...options}
        >
            {children}
        </InputStyle>
    );
};

Input.defaultProps = {
    type: 'text',
};

export const InputGroup = props => {
    const { type, name, size, color, disabled, register, option, error, children, label, ...options } = props;

    return (
        <div>
            <LabelStyle>{label}</LabelStyle>
            <InputStyle
                {...register(name, option)}
                type={type}
                disabled={disabled}
                error={error}
                color={color}
                size={size}
                {...options}
            >
                {children}
            </InputStyle>
            <ErrorLabel>{error?.message}</ErrorLabel>
        </div>
    );
};

export default Input;
