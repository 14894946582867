import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Breadcrumb from '../header/Breadcrumb';
import { RegisterInput } from '../auth/RegisterInput';
import LeftMenu from './LeftMenu';
import { useMutation } from 'react-query';
import { PatchUserSelf } from '../../queries/UserSelf';

const schema = yup
    .object({
        username: yup.string(),
        company_name: yup.string(),
        web_site: yup.string(),
        password: yup.string(),
        password2: yup.string().oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다'),
        //.matches(/(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi, '정상적인 도메인이 아닙니다.'),
        //.required('Web_site는(은) 필수 입니다'),
        last_name: yup.string(),
        //.min(2, '2자 이상 입력해 주세요.'),
        mobile: yup.string(),
        //.min(10),
        first_name: yup.string(),
        //.min(2, '2자 이상 입력해 주세요.'),
        email: yup.string().email(),
        //referee_name: yup.string(),
        //referee_email: yup.string().email(),
        bank_name: yup.string(),
        holder_name: yup.string(),
        number: yup.string(),
        bic: yup.string(),
        bank_mobile: yup.string(),
        address: yup.string(),
    })
    .required();

function CelebSetting(props) {
    const { data } = props;
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const mutation = useMutation(async data => PatchUserSelf(data), {
        onSuccess: res => {
            alert('success');
            console.log(res);
        },
    });

    const onSubmit = result => {
        const registerBody = {
            company: {
                name: result.company_name,
                website: result.web_site,
            },
            account_manager: {
                last_name: result.last_name,
                first_name: result.first_name,
                email: result.email,
                mobile: result.mobile,
            },
            bank_account: {
                bank_name: result.bank_name,
                number: result.number,
                holder_name: result.holder_name,
                bic: result.bic,
                address: result.bank_address,
                mobile: result.bank_mobile,
            },
            password: result.password ? result.password : null,
            password2: result.password2 ? result.password2 : null,
        };
        //console.log(registerBody);
        mutation.mutate(registerBody);
    };

    return (
        <div className="page-container">
            <LeftMenu />
            <div className="row px-60">
                <Breadcrumb value={['My page', 'Setting']} />
                <h1 className="fl mb-20">Setting</h1>
                <div className="setting">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h5 className="f-14 pt-10 pl-10 border-top2">Agency information</h5>
                        <div className="pr-30 pl-30 f-row justify-content-center">
                            <div className="col-5">
                                <div className="input-group">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="ID (Email)"
                                        name="username"
                                        isRequire={true}
                                        defaultValue={data.username}
                                        errorMsg={errors.username?.message}
                                        defaultMsg=""
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="input-group mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Company name"
                                        name="company_name"
                                        defaultValue={data.company?.name}
                                        errorMsg={errors.company_name?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group  mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Web site"
                                        name="web_site"
                                        defaultValue={data.company?.website}
                                        errorMsg={errors.web_site?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="input-group">
                                    <RegisterInput
                                        register={register}
                                        type="password"
                                        label="Password"
                                        name="password"
                                        //defaultValue={data.modelagency}
                                        errorMsg={errors.password?.message}
                                        defaultMsg="Minimum 8 characters including Capital letter1, numbers"
                                    />
                                </div>
                                <div className="input-group mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="password"
                                        label="Re-enter password"
                                        name="password2"
                                        //defaultValue={data.modelagency}
                                        errorMsg={errors.password2?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                        </div>
                        <h5 className="f-14 mt-50 pt-10 pl-10 border-top2">Account manager</h5>
                        <div className="pr-30 pl-30 f-row justify-content-center">
                            <div className="col-5">
                                <div className="input-group">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Last name"
                                        name="last_name"
                                        defaultValue={data.account_manager?.last_name}
                                        errorMsg={errors.last_name?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Mobile"
                                        name="mobile"
                                        defaultValue={data.account_manager?.mobile}
                                        errorMsg={errors.mobile?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="input-group">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="First name"
                                        name="first_name"
                                        //defaultValue={data.modelagency}
                                        defaultValue={data.account_manager?.first_name}
                                        errorMsg={errors.first_name?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Email"
                                        name="email"
                                        defaultValue={data.account_manager?.email}
                                        //defaultValue={data.modelagency}
                                        errorMsg={errors.email?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                        </div>
                        <h5 className="f-14 mt-50 pt-10 pl-10 border-top2">Account information</h5>
                        <div className="pr-30 pl-30 f-row justify-content-center">
                            <div className="col-5">
                                <div className="input-group">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Bank name"
                                        name="bank_name"
                                        defaultValue={data.bank_account?.bank_name}
                                        errorMsg={errors.bank_name?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group  mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Bank account holder name (last, first)"
                                        name="holder_name"
                                        defaultValue={data.bank_account?.holder_name}
                                        errorMsg={errors.holder_name?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group  mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Account number"
                                        name="number"
                                        defaultValue={data.bank_account?.number}
                                        errorMsg={errors.number?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="input-group">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="BIC (SWIFT)"
                                        name="bic"
                                        defaultValue={data.bank_account?.bic}
                                        errorMsg={errors.bic?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group  mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Bank account holder mobile"
                                        name="bank_mobile"
                                        defaultValue={data.bank_account?.mobile}
                                        errorMsg={errors.bank_mobile?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group  mt-30">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Bank branch address"
                                        name="address"
                                        defaultValue={data.bank_account?.address}
                                        //defaultValue={data.bank_account?.}
                                        errorMsg={errors.address?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                        </div>
                        {/*<h5 className="f-14 mt-50 pt-10 pl-10 border-top2">Who referred you?</h5>
                    <div className="pr-30 pl-30 f-row justify-content-center">
                        <div className="col-5">
                            <div className="input-group">
                                <RegisterInput
                                    register={register}
                                    type="text"
                                    label="Name"
                                    name="referee_name"
                                    defaultValue={data.referee?.name}
                                    errorMsg={errors.referee_name?.message}
                                    defaultMsg=""
                                />
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="input-group">
                                <RegisterInput
                                    register={register}
                                    type="text"
                                    label="Email"
                                    name="referee_email"
                                    defaultValue={data.referee?.mobile}
                                    errorMsg={errors.referee_email?.message}
                                    defaultMsg=""
                                />
                            </div>
                        </div>
                    </div>*/}
                        <div className="btn-group pt-20">
                            <button className="btn btn-primary w-400">Done</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CelebSetting;
