import React, { useEffect } from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import Loading from '../../components/contents/Loading';
import { getOfferImage } from '../../queries/ConfirmOffer';
import { createPortfolio } from '../../queries/CreatePortfolio';
import { removeAd, uploadAd } from '../../queries/UploadAd';
import { showAlertModal } from '../modal/AlertModal';
import { ALERT_MODAL, setAlertModal } from '../modal/api/action';
import Carousel from '../model/Carousel';
import CarouseNonSelect from '../model/CarouselNonSelect';
import OfferImageList from './OfferImageList';
import OfferImages from './OfferImages';

function UploadFile(props) {
    const formData = new FormData();
    const { contractId } = props;
    const [selectedId, setSelectedId] = useState(0);
    const [imgList, setImgList] = useState([]);
    const { isLoading, data, error, isError, refetch } = useQuery(['getContentList', contractId], () =>
        getOfferImage({ contractId: contractId })
    );

    const handleSelectedId = id => {
        setSelectedId(id);
    };

    const uploadFile = event => {
        formData.set('file', event.target.files[0]);
        formData.set('is_main', false);
        uploadMutation.mutate(formData);
    };

    const deletePortfolio = () => {
        removeMutation.mutate();
    };

    const uploadMutation = useMutation(async formData => createPortfolio(formData), {
        onSuccess: async res => {
            const currentImgList = [...imgList];

            currentImgList.push(res);
            setImgList(currentImgList);
            //setImgUrl(res.file);
            uploadAd({ contractId: contractId, portfolioId: res.id });
            refetch();
        },
        onError: err => {
            switch (err.response.status) {
                case 401:
                    console.log('401');
                    return;
                case 301:
                    console.log('301');
                    return;
                default:
                    console.log(err.response.data.detail);
                    console.log('default');
                    return;
            }
        },
    });

    const removeMutation = useMutation(async () => removeAd({ contractId: contractId, contentId: selectedId }), {
        onSuccess: async res => {
            refetch();
        },
        onError: err => {
            switch (err.response.status) {
                case 401:
                    console.log('401');
                    return;
                case 301:
                    console.log('301');
                    return;
                default:
                    console.log(err.response.data.detail);
                    console.log('default');
                    return;
            }
        },
    });

    //const mutation = useMutation(async formData => createPortfolio(formData), {
    //    onSuccess: async res => {
    //        const currentImgList = [...imgList];

    //        currentImgList.push(res);
    //        setImgList(currentImgList);
    //        //setImgUrl(res.file);
    //        uploadAd({ contractId: contractId, portfolioId: res.id });
    //        refetch();
    //    },
    //    onError: err => {
    //        switch (err.response.status) {
    //            case 401:
    //                console.log('401');
    //                return;
    //            case 301:
    //                console.log('301');
    //                return;
    //            default:
    //                console.log(err.response.data.detail);
    //                console.log('default');
    //                return;
    //        }
    //    },
    //});

    //const image = imgUrl ? <img src={imgUrl} /> : <i className="icon-picture" />;
    if (isLoading) return <Loading />;
    if (isError) return <div>{error.message}</div>;

    return (
        <>
            <div className="modal-tab-container">
                <div className="modal-tab-content" id="confirmTab">
                    <CarouseNonSelect
                        images={data}
                        imageCount={1}
                        width={590}
                        height={450}
                        idHandler={handleSelectedId}
                        children={
                            <label type="label" onClick={deletePortfolio} className="btn btn-outline-primary right">
                                Delete file
                            </label>
                        }
                    />
                </div>
                <div className="upload-img-button">
                    <input
                        type="file"
                        id="uploadAd"
                        //accept=".jpeg, .jpg, .png"
                        onChange={uploadFile}
                    />
                    <label type="label" htmlFor="uploadAd" className="btn btn-outline-primary right">
                        Upload file
                    </label>
                </div>
                {/*<button type="button">click</button>*/}
                {/*<OfferImages imgList={imgList} />*/}
                {/*<div className="upload-img-container">*/}
                {/* <OfferImageList imgList={imgList} /> */}
                {/*{imgList.map((img, index) => (
                    <>
                        <i
                            onClick={deletePortfolio(index)}
                            className="icon-cancel"
                            style={{ fontSize: '10px', color: 'black' }}
                        />
                        <img key={index} src={img.file} />
                    </>
                ))}*/}
                {/*</div>*/}
                {/*<div className="upload-img-container">{image}</div>*/}
            </div>
        </>
    );
}

export default UploadFile;
