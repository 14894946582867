import React from 'react';
import { useProjectList } from '../../hooks/getCode';
import styled from 'styled-components';
import { useUrl } from '../../hooks/useUrl';
import MaterialIcons from '../styled-components/MaterialIcons';
import { useMutation } from 'react-query';
import { removeProjects } from '../../queries/Project';

const ProjectsListWrapper = styled.div`
    width: 240px;
    min-height: 720px;
    height: auto;
    /*float: left;*/
    background-color: var(--white);
    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #a6adb4;
        padding: 80px 20px 28px 32px;
        /*padding-bottom: 28px;*/
        /*padding-left: 24px;*/
        h1 {
            font: normal normal 700 3.2rem/3.2rem inter;
            color: #202124;
        }
        .material-icons {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 32px;
            height: 32px;
        }
    }
    ul {
        li:first-child {
        }
        li {
            padding: 12px 12px 12px 28px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--gray-500);
            align-items: center;
            height: 60px;
            color: var(--gray-800);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 100% */
            .material-icons {
                display: none;
            }
            cursor: pointer;
            &:hover {
                .material-icons {
                    display: flex;
                    &:hover {
                        /*color: red;*/
                        border-radius: 5px;
                        background-color: rgba(255, 77, 77, 0.2);
                    }
                }
                /*background-color: rgba(255, 77, 77, 0.2);*/
                /*border-bottom: 1px solid var(--purple-200);*/
                background-color: var(--purple-050);
            }
        }
        li:last-child {
            border-bottom: none;
        }
        .selected {
            background-color: var(--purple-050);
            color: var(--gray-800);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 100% */
        }
    }
`;

const ProjectsList = props => {
    const { refetch, data, selectHandler } = props;
    const { navigateToPage } = useUrl();
    const mutation = useMutation(async data => removeProjects({ id: data }), {
        onSuccess: response => {
            refetch();
        },
    });

    const onClick = project => {
        selectHandler(project);
        navigateToPage(`/project/${project.id}`);
    };

    const removeProject = id => {
        mutation.mutate(id);
    };

    //if (!data.length)
    //    return (
    //        <ProjectsListWrapper>
    //            <div className="title">
    //                <h1>Project</h1>
    //                <MaterialIcons
    //                    name="add"
    //                    color="var(--gray-800)"
    //                    size="30px"
    //                    onClick={() => navigateToPage('/project/create/')}
    //                />
    //            </div>
    //            <ul>
    //                <li>No project</li>
    //            </ul>
    //        </ProjectsListWrapper>
    //    );

    return (
        <ProjectsListWrapper>
            <div className="title">
                <h1>Project</h1>
                <MaterialIcons
                    name="add"
                    color="var(--gray-800)"
                    size="30px"
                    onClick={() => navigateToPage('/project/create/')}
                />
            </div>
            <ul>
                {data.map((project, index) => (
                    <li key={index + project.id} onClick={() => onClick(project)}>
                        {project.title}
                        <MaterialIcons
                            name="close"
                            color="var(--gray-800)"
                            size="16px"
                            onClick={() => removeProject(project.id)}
                        />
                    </li>
                ))}
            </ul>
        </ProjectsListWrapper>
    );
};

export default ProjectsList;
