export const ALERT_MODAL = 'MODAL/ALERT';
export const ALERT_MODAL_OFF = 'MODAL/ALERT_OFF';
export const CONFIRM_MODAL = 'MODAL/CONFIRM';
export const CREATE_OFFER_MODAL = 'MODAL/CREATE_OFFER';
export const CREATE_OFFER_DETAIL_MODAL = 'MODAL/CREATE_OFFER_DETAIL';
export const FIND_PASSWORD_MODAL = 'MODAL/FIND_PASSWORD';
export const LARGE_MODAL = 'MODAL/LARGE';
export const CREATE_PROJECT_MODAL = 'MODAL/CREATE_PROJECT';
export const OFFER_DETAIL_MODAL = 'MODAL/OFFER_DETAIL';
export const EXPOSURE_MEDIAS_CODE = 'CODE/EXPOSURE_MEDIAS';
export const REGISTER_MODAL = 'MODAL/REGISTER';
export const CONTRACT_PROJECT_MODAL = 'MODAL/CONTRACT_PROJECT';
export const CONTRACT_OFFER_MODAL = 'MODAL/CONTRACT_OFFER';
export const FORGOT_PASSWORD_MODAL = 'MODAL/FORGOT_PASSWORD';

export function setModal({ type, data }) {
    return dispatch => {
        dispatch({ type: type, data: data });
    };
}

export function setOfferModal({ type, data }) {
    return dispatch => {
        dispatch({ type: type, data: data });
    };
}

export function setAlertModal({ type, data }) {
    return dispatch => {
        dispatch({ type: type, data: data });
    };
}
