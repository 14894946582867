import React from 'react';
import MainContainer from '../components/MainContainer';
import Detail from '../components/models/Detail';
import Layout from '../modules/common/Layout';
import CelebDetail from '../components/celebs/CelebDetail';

const ModelDetail = () => {
    return (
        <Layout>
            <MainContainer>
                {/*<Detail></Detail>*/}
                <CelebDetail />
            </MainContainer>
        </Layout>
    );
};

export default ModelDetail;
