import React from 'react';
import UploadFile from './UploadFile';

function UploadContents(props) {
    const { userId, contractId } = props;
    return (
        <div className="modal-tab-container">
            <div className="modal-tab-content" id="confirmTab">
                <div className="upload-content" style={{ textAlign: 'center' }}>
                    {/*<label className="btn btn-outline-primary right">Upload file</label>*/}
                    <UploadFile userId={userId} contractId={contractId} />
                    {/*<img src=" style={{ height: '100%' }} />*/}
                </div>
            </div>
        </div>
    );
}

export default UploadContents;
