import React from 'react';

const ConfirmModal = prop => {
    const { content, title, successText, cancelText } = prop;
    return (
        <div className="modal hide">
            <div className="modal-dialog modal-sm">
                <h3>{title}</h3>
                <i className="icon-cancel"></i>
                <div className="modal-content mb-50">{content}</div>
                <div className="btn-group clearfix">
                    <button type="button" className="btn btn-alt-primary left">
                        {successText}
                    </button>
                    <button type="button" className="btn btn-outline-primary left ml-20">
                        {cancelText}
                    </button>
                </div>
            </div>
        </div>
    );
};

ConfirmModal.defaultProps = {
    title: 'Congratulations!',
    content: 'Successfully sent the offer to the model.',
    successText: 'Yes, delete project',
    cancelText: 'Cancel',
};

export default ConfirmModal;
