import React from 'react';
import styled from 'styled-components';
import { useRecommend } from '../../hooks/useRecommend';
import Ai, { AiRecommendTitle } from '../../icon/Ai';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.length}, 1fr);
    width: ${props => props.length * 160}px;
    gap: 20px;
    /*margin: 0 auto;*/
`;

const CelebCard = styled.div`
    line-height: 0;
    width: 160px;
    height: 200px;
    img {
        border-radius: 2px;
        width: 160px;
        height: 160px;
        object-fit: cover;
        margin: 0;
        cursor: pointer;
    }
    .description {
        padding-top: 8px;
        .top {
            color: var(--gray-800);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.2rem; /* 100% */
            margin-bottom: 4px;
        }
        .bottom {
            color: var(--purple-500);
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem; /* 100% */
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
`;

const RecommendCelebs = props => {
    const { celebId, recommendLength } = props;
    const { data } = useRecommend({ celeb_id: celebId, n_recommend: recommendLength });

    return (
        <>
            <AiRecommendTitle />
            <Container length={data.length}>
                {data.map((celeb, index) => {
                    return (
                        <CelebCard key={'recommend_celeb_' + celeb.id + index}>
                            <a href={'/celebs/' + celeb.id}>
                                <img src={celeb.portfolio_main.thumbnail} />
                            </a>
                            <div className="description">
                                <div className="top">
                                    <a href={'/celebs/' + celeb.id}>{celeb.pen_name}</a>
                                </div>
                                <div className="bottom">
                                    {celeb.company[0]
                                        ? celeb.company[0].label
                                        : '' + ', ' + celeb.type[0]
                                        ? celeb.type[0]?.label
                                        : ''}
                                </div>
                            </div>
                        </CelebCard>
                    );
                })}
            </Container>
        </>
    );
};

RecommendCelebs.defaultProps = {};

export default RecommendCelebs;
