import React, { useState } from 'react';

const Progressbar = props => {
    const { register, name, title, value } = props;
    const [progress, setProgress] = useState(value);

    return (
        <div className="progress-box sm">
            <label className="fearture-sm">{title}</label>
            <div className="progress">
                <div className="irs">
                    <span>0</span>
                    {/*<span className="center-number">5</span>*/}
                    <span className="cnum-7" style={{ left: progress + '0%' }}>
                        {parseInt(progress)}
                    </span>
                    <span>10</span>
                </div>
                {/* <progress value="70" max="100" /> */}
                <input
                    {...register(name, { valueAsNumber: true })}
                    type="range"
                    min="0"
                    max="10"
                    defaultValue={progress}
                    className="slider"
                    id="myRange"
                    onChange={event => setProgress(event.target.value)}
                ></input>
                <div className="track" id="track" style={{ width: progress + '3%' }}></div>
            </div>
        </div>
    );
};

export default Progressbar;
