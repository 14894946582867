import React, { useState } from 'react';
import MaterialIcons from '../styled-components/MaterialIcons';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import Loading from '../contents/Loading';
import Error from '../contents/Error';
import CustomCheckbox from '../checkbox/CustomCheckbox';
import Button from '../buttons/Buttons';
import { getCelebQuery } from '../../queries/GetList';

const CelebFilterContainer = styled.div`
    margin-bottom: 68px;
    .button-area {
        display: flex;
        justify-content: center;
        gap: 12px;
    }
`;

const FilterBar = styled.div`
    display: flex;
    align-items: center;
    width: 1240px;
    border-top: 1px solid var(--gray-300);
    border-bottom: 1px solid var(--gray-300);
    margin: auto;
    gap: 80px;
    padding: 20px 40px;
    .category {
        display: flex;
        align-items: center;
        color: var(--gray-800, #474747);
        font-family: Inter;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
        cursor: pointer;
    }
    .active {
        color: #860cf9;
    }
`;

const FilterListStyle = styled.div`
    border: 1px solid var(--gray-gray-300, #e5e5e5);
    border-top: none;
    background: var(--gray-gray-200, #f1f1f1);
    width: 1240px;
    margin: auto;
    transition: display 3s ease-in-out;
    padding: 20px 40px;
    label > span {
        width: 120px;
        height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const FilterList = props => {
    const { keyString, isChecked, list, setValue } = props;
    return (
        <FilterListStyle>
            {list?.map(data => {
                const keyValue = 'FilterCheckbox' + data.id;
                const keyData = { ...data, keyString: keyString };
                const chekedStatus = isChecked(keyData);
                const onChange = e => {
                    const { checked } = e.target;
                    setValue(keyData, checked);
                };
                return (
                    <CustomCheckbox
                        key={keyValue}
                        data={data}
                        isChecked={chekedStatus}
                        onChange={e => onChange(e)}
                        //
                    />
                );
            })}
        </FilterListStyle>
    );
};

const categoryList = ['gender', 'company', 'agency', 'group', 'type'];

const initialCategoryState = {};

categoryList.forEach(category => {
    initialCategoryState[category] = undefined;
});

const useCelebFilter = () => {
    const { data, isLoading, isError, error } = useQuery(['getCelebQuery'], () => getCelebQuery(), {});

    return { data, isLoading, isError, error };
};

const CelebFilter = props => {
    const { query, setQuery, selectList, SelectListHandler, ResetSelectList, isChecked } = props;

    const { data, isLoading, isError, error } = useCelebFilter();
    const [isVisible, setIsVisible] = useState(false);
    const [selected, setSelected] = useState('');

    if (isLoading) return <Loading />;
    if (isError) return <Error errMsg={error.message} />;
    if (!data) return <></>;

    const filter = {};

    const onApply = () => {
        const result = selectList.reduce((acc, item) => {
            acc[item.keyString] = acc[item.keyString] || [];
            acc[item.keyString].push(item.id);
            return acc;
        }, {});
        for (const key in result) {
            if (Array.isArray(result[key])) {
                result[key] = result[key].join(',');
            }
        }

        const updatedQuery = {
            ...query,
            ...initialCategoryState,
            page: 1,
        };
        setQuery({ ...updatedQuery, ...result });
    };

    categoryList.forEach(category => {
        filter[category] = (
            <FilterList
                keyString={category}
                isChecked={isChecked}
                list={data[category]}
                selectedValue={selectList}
                setValue={SelectListHandler}
            />
        );
    });

    const setStatus = () => {
        if (isVisible) setSelected('');
        setIsVisible(!isVisible);
        if (!isVisible) setSelected('agency');
    };

    const updateFilterState = value => {
        setSelected(value);
        if (!isVisible) setIsVisible(!isVisible);
    };

    const FilterIcon = isVisible ? (
        <MaterialIcons
            style={{ cursor: 'pointer' }}
            onClick={setStatus}
            name="remove"
            color="var(--gray-900)"
            size="16px"
        ></MaterialIcons>
    ) : (
        <MaterialIcons
            style={{ cursor: 'pointer' }}
            onClick={setStatus}
            name="add"
            color="var(--gray-900)"
            size="16px"
        ></MaterialIcons>
    );
    return (
        <CelebFilterContainer>
            <FilterBar>
                <span className="category" onClick={setStatus}>
                    Filter {FilterIcon}
                </span>
                {categoryList.map(category => (
                    <span
                        key={category}
                        className={`category ${selected === category ? 'active' : ''}`}
                        onClick={() => updateFilterState(category)}
                    >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </span>
                ))}
            </FilterBar>
            {isVisible ? filter[selected] : null}
            <SelectedFilter data={selectList} setValue={SelectListHandler} />
            {isVisible ? (
                <div className="button-area">
                    <Button text="Reset" size="sm" variant="gray" borderRadius="2px" onClick={ResetSelectList}></Button>
                    <Button text="Apply" size="sm" variant="default" borderRadius="2px" onClick={onApply}></Button>
                </div>
            ) : (
                <></>
            )}
        </CelebFilterContainer>
    );
};

const SelectedValueList = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 1240px;
    margin: 20px auto;
    padding: 8px 42px;
    .select-item {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 20px;
        border: 1px solid var(--gray-300, #e5e5e5);
        background: var(--gray-050, #fbfbfb);
        padding: 4px 8px;
        color: var(--gray-800, #474747);
        font-family: Inter;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2rem; /* 100% */
    }
`;

const SelectedFilter = props => {
    const { data, setValue } = props;

    return (
        <SelectedValueList>
            {data?.map(item => {
                const onClick = () => {
                    setValue(item, false);
                };
                return (
                    <span className="select-item" onClick={onClick} key={'SelectedItem' + item.value + item.id}>
                        {item.label}
                        <MaterialIcons name="close" size="12px" />
                    </span>
                );
            })}
        </SelectedValueList>
    );
};

export default CelebFilter;
