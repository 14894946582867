import axios from 'axios';
import { privateClient } from '../utils/client';

export const createContract = async ({ data }) => {
    const response = await privateClient.post(`/api/v1/contracts/concise/`, data);

    return response;
};

export const GetContractInvoice = async ({ contractId }) => {
    const response = await privateClient.get(`/api/v1/contracts/${contractId}/invoice/`);

    return response.data;
};
