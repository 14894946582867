import React, { useState } from 'react';
import styled from 'styled-components';
import MaterialIcons from '../styled-components/MaterialIcons';
import FeatureTags from '../models/FeatureTags';
import numeral from 'numeral';
import { ALERT_MODAL } from '../../modules/modal/api/action';
import { useModal } from '../../modules/modal/ModalUtils';
import { useSelector } from 'react-redux';
import { ReactComponent as Instagram } from '../../svg/instagram.svg';
import { useMutation, useQuery } from 'react-query';
import { addLike, getLike, removeLike } from '../../queries/Like';
import ImageSlider from '../celebs/ImageSlider';

const CelebProfileCard = styled.div`
    margin: auto;
    width: 392px;
    height: 512px;
    box-shadow: 0px 0px 4px 0px rgba(102, 102, 102, 0.3);
    border-radius: 4px;
    overflow: hidden;
    .celeb-info {
        width: 392px;
        height: 120px;
        padding: 20px 36px;
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 4px;
            .name {
                font: normal normal 700 2.5rem/2.5rem inter;
                color: #474747;
                cursor: pointer;
            }
            .favorite {
            }
        }
        .detail {
            display: flex;
            align-items: center;
            margin: 16px 0;
            font: normal normal 500 2rem/2rem inter;
            min-height: 2rem;
            .filmography {
                color: #7a7a7a;
            }
            .numbers {
                display: flex;
                align-items: center;
                color: #a4a4a4;
                margin-right: 28px;
                gap: 4px;
            }
        }
    }
`;

const useGetLike = () => {
    const { data, isLoading, isError, error } = useQuery(['getLike'], () => getLike(), {});

    return { data, isLoading, isError, error };
};

const useLike = () => {
    const postMutate = useMutation(async celebId => addLike(celebId));
    const deleteMutate = useMutation(async celebId => removeLike(celebId));

    return { postMutate, deleteMutate };
};

export const LikeButton = props => {
    const { celebId, defaultValue } = props;
    const { postMutate, deleteMutate } = useLike();
    const [isLike, setIsLike] = useState(defaultValue);

    const onClickLike = celebId => {
        postMutate
            .mutateAsync(celebId)
            .then(response => {
                // 성공적으로 좋아요를 추가한 경우, 여기에서 UI를 업데이트하거나 필요한 동작을 수행합니다.
                setIsLike(true);
            })
            .catch(error => {
                // 에러가 발생한 경우, 에러 처리를 수행합니다.
            });
    };

    const onClickUnlike = celebId => {
        deleteMutate
            .mutateAsync(celebId)
            .then(response => {
                // 성공적으로 좋아요를 제거한 경우, 여기에서 UI를 업데이트하거나 필요한 동작을 수행합니다.
                setIsLike(false);
            })
            .catch(error => {
                // 에러가 발생한 경우, 에러 처리를 수행합니다.
            });
    };
    return isLike ? (
        <MaterialIcons
            name="favorite"
            size="32px"
            color="var(--pink-300)"
            style={{ cursor: 'pointer' }}
            onClick={() => onClickUnlike(celebId)}
        />
    ) : (
        <MaterialIcons
            name="favorite_border"
            size="32px"
            style={{ cursor: 'pointer' }}
            onClick={() => onClickLike(celebId)}
        />
    );
};

const PublicCelebProfile = props => {
    const { celeb, selectedCountry } = props;
    const { showModal } = useModal(ALERT_MODAL);
    const userData = useSelector(state => state.user);
    const redirect = () => {
        window.location.href = `/celebs/${celeb.id}`;
    };
    const images = celeb.photos.map(celeb => celeb.thumbnail);
    const instagram = celeb.social_media?.instagram_followers
        ? numeral(celeb.social_media.instagram_followers).format('0.0a')
        : null;
    const popularity = celeb.google_trends ? celeb.google_trends.regional[selectedCountry]?.toFixed(2) : null;
    const popularityTrends = popularity ? (
        <div className="numbers">
            <span className="material-icons">trending_up</span>
            <span>{popularity}</span>
        </div>
    ) : (
        <div className="numbers">
            <span className="material-icons">trending_up</span>
            <span>N/A</span>
        </div>
    );
    const instagramTrends = instagram ? (
        <div className="numbers">
            <Instagram style={{ width: '1.6rem', height: '1.6rem', fill: '#a4a4a4' }} />
            {/*<span className="material-icons">person</span>*/}
            <span>{instagram}</span>
        </div>
    ) : (
        <></>
    );

    return (
        <CelebProfileCard>
            <ImageSlider
                onClick={() => redirect()}
                style={{ cursor: 'pointer' }}
                images={images}
                width={392}
                height={392}
            />
            <div className="celeb-info">
                <div className="top">
                    <p className="name" onClick={() => redirect()}>
                        {celeb.pen_name}
                    </p>
                    {/*<LikeButton celebId={celeb.id} defaultValue={celeb.is_like} />*/}
                </div>
                <div className="detail">
                    {instagramTrends}
                    {popularityTrends}
                </div>
                {/*<FeatureTags tags={celeb.tags}></FeatureTags>*/}
            </div>
        </CelebProfileCard>
    );
};

export default PublicCelebProfile;
