import { useQuery } from 'react-query';
import { getBanner, getReference } from '../queries/Landing';

export const useGetBanner = () => {
    const { data, isError, isLoading, refetch } = useQuery(['getBanner'], () => getBanner());

    const fetchData = async () => {
        await refetch();
    };

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data, fetchData };
};

export const useGetReference = () => {
    const { data, isError, isLoading, refetch } = useQuery(['getReference'], () => getReference());

    const fetchData = async () => {
        await refetch();
    };

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data, fetchData };
};
