import React, { useState } from 'react';
import Chart from 'react-apexcharts';

class ModelChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: 'Series 1',
                    data: [6.2, 5.1, 6.8, 7.5, 8],
                },
            ],
            options: {
                xaxis: {
                    categories: ['January', 'February', 'March', 'April', 'five'],
                    labels: {
                        style: {
                            colors: ['#000', '#000', '#000', '#000', '#000'],
                            fontSize: props.fontSize,
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        formatter: function (val, i) {
                            return '';
                        },
                    },
                },
                plotOptions: {
                    radar: {
                        size: this.props.size,
                        polygons: {
                            strokeWidth: this.props.strokeWidth,
                            strokeColors: '#202124',
                            connectorColors: '#202124',
                            fill: {
                                colors: ['#fff', '#fff'],
                            },
                        },
                    },
                },
                colors: this.props.fillColor,
                stroke: {
                    show: true,
                    curve: 'stepline',
                    lineCap: 'butt',
                    colors: this.props.strokeColor,
                    width: 1,
                    dashArray: 0,
                },
                markers: {
                    size: 0,
                },
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [],
                        },
                    },
                },
            },
        };
    }

    render() {
        return (
            <div className="chart">
                <Chart options={this.state.options} series={this.state.series} type="radar" width={'100%'} height={this.props.height} />
            </div>
        );
    }
}

ModelChart.defaultProps = {
    height: 180,
    size: 50,
    fontSize: '12px',
    strokeWidth: 1,
    strokeColor: ['#D349A9'],
    fillColor: ['#F1D2FCD5'],
};
export default ModelChart;
