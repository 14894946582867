import React, { useEffect } from 'react';
import styled from 'styled-components';

const a = styled.span`
    color: #ff0000;
`;

const Label = styled.label`
    font: normal normal 700 1.5rem/2.6rem Roboto;
    color: #a6adb4;
`;

const BackgroundContainer = styled.div`
    width: 100%;
`;

const BackgroundImg = styled.img`
    background: 'linear-gradient(to bottom, black 100%)';
    width: 390px;
    height: 280px;
    filter: blur(4px);
    object-fit: cover;
    z-index: 100;
`;

const MainImg = styled.img`
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-top: -90px;
    margin-left: 16px;
    width: 150px;
    height: 150px;
    z-index: 10;
    position: relative;
`;

const MobileContainer = styled.div`
    padding: 16px;
`;

function ContractView() {
    if (window.innerWidth < 768) {
        return (
            <div>
                <BackgroundContainer>
                    <BackgroundImg src="/images/focus.jpg" />
                    <MainImg src="/images/focus.jpg"></MainImg>
                    <h2>mobile</h2>
                </BackgroundContainer>
                <Label>Product title</Label>
            </div>
        );
    }
    return (
        <div>
            <BackgroundContainer>
                <BackgroundImg src="/images/focus.jpg" />
                <MainImg src="/images/focus.jpg"></MainImg>
                <h2>desktop</h2>
            </BackgroundContainer>
            <Label>Product title</Label>
        </div>
    );
}

export default ContractView;
