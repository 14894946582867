import React from 'react';
import styled from 'styled-components';

const TitleContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 16px;
    h3 {
        color: var(--gray-800);
        font-size: 1.6;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6rem; /* 100% */
    }
`;

export const AiRecommendTitle = () => {
    return (
        <TitleContainer>
            <Ai />
            <h3>Recommend</h3>
        </TitleContainer>
    );
};

const Ai = props => {
    const { size } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
                fill="#474747"
            />
            <g clipPath="url(#clip0_2263_5768)">
                <path
                    d="M6.46871 3.41651C6.34247 3.16403 6.12758 3.01898 5.87779 3.01898C5.62799 3.01898 5.41311 3.16403 5.28687 3.4192L1.09401 11.9983C1.02955 12.1245 1 12.2427 1 12.3555C1 12.7101 1.2874 12.9975 1.64196 12.9975C1.88907 12.9975 2.12544 12.8551 2.23288 12.6403L2.98227 11.1173H8.77062L9.5227 12.6429C9.62745 12.8551 9.86651 12.9975 10.1136 12.9975C10.4682 12.9975 10.7556 12.7101 10.7556 12.3555C10.7556 12.24 10.726 12.1245 10.6616 12.001L6.46871 3.41651ZM3.61348 9.83338L5.87779 5.08989L8.14209 9.83338H3.61348Z"
                    fill="white"
                />
                <path
                    d="M13.6053 11.7134C12.9231 11.6516 12.8506 10.7975 12.8506 10.4322V6.07281C12.8506 5.70482 12.5632 5.41473 12.1925 5.41473C11.8218 5.41473 11.5344 5.70214 11.5344 6.07281V10.4349C11.5344 11.7134 12.12 12.6455 13.1004 12.9275C13.2347 12.9705 13.4227 13 13.5838 13C13.9867 13 14.2258 12.6723 14.2258 12.3581C14.2258 12.0089 13.9465 11.7242 13.6 11.7161L13.6053 11.7134Z"
                    fill="white"
                />
                <path
                    d="M13.1622 3.96965C13.1622 3.43513 12.7271 3 12.1925 3C11.658 3 11.2229 3.43513 11.2229 3.96965C11.2229 4.50416 11.658 4.9393 12.1925 4.9393C12.7271 4.9393 13.1622 4.50416 13.1622 3.96965Z"
                    fill="#F200D6"
                />
            </g>
            <defs>
                <clipPath id="clip0_2263_5768">
                    <rect width="13.2313" height="10" fill="white" transform="translate(1 3)" />
                </clipPath>
            </defs>
        </svg>
    );
};

Ai.defaultProps = {
    size: 16,
};

export default Ai;
