import Section from '../layout/Section';
import React from 'react';
import styled from 'styled-components';
import { LikeButton } from '../celebs/CelebProfile';
import InstagramIcon from '../../icon/instagram';
import GoogleTrendsIcon from '../../icon/GoogleTrends';
import numeral from 'numeral';
import { useGetRanking } from '../../hooks/useGetRanking';
import { useGetNews } from '../../hooks/useGetNews';
import Carousel from '../styled-components/Carousel';
import Labs, { CustomLabs } from '../celebs/Labs';
import { useUrl } from '../../hooks/useUrl';
import { useGetLabs } from '../../hooks/useGetLabs';
import RecommendCelebs from '../celebs/RecommendCelebs';
import { AiRecommendTitle } from '../../icon/Ai';
import Button from '../styled-components/Button';
import { useGetHottest } from '../../hooks/useGetHottest';
import ViewMore from '../styled-components/ViewMore';

const Container = styled.div``;

const TopBanner = styled.div`
    display: flex;
    width: 1440px;
    background-color: black;
    background-color: ${props => props.bgColor};
    /*background-color: var(--gray-900);*/
    .image-wrapper {
        width: 916px;
        height: 774px;
        img {
            width: 774px;
            height: 774px;
            object-fit: cover;
        }
    }
    .description {
        color: var(--gray-050);
        margin-left: -160px;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem; /* 125% */
        .celeb-name {
            margin-top: 100px;
            margin-bottom: 28px;
            font-family: Larken;
            font-size: 6rem;
            font-style: normal;
            font-weight: 500;
            line-height: 6.6rem; /* 110% */
            letter-spacing: 3px;
        }
        .article {
            margin-top: 88px;
            margin-bottom: 28px;
            color: var(--gray-050);
            font-family: larken;
            font-size: 5.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 6rem; /* 110% */
        }
        padding: 30px 50px;
        width: 524px;
        height: 774px;
        background-color: ${props => props.articleBgColor};
    }
`;

const TitleArea = styled.div`
    .title-area {
        display: flex;
        gap: 14px;
        margin-bottom: 24px;
        .view-more {
            margin-top: 18px;
            color: var(--gray-800);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2rem;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
    .title {
        color: var(--gray-900);
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem;
        margin-bottom: 16px;
    }
    .sub-title {
        color: var(--gray-800);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        margin-bottom: 24px;
    }
`;

//const NewCelebArea = styled.div`
//    padding: 0 100px;
//    .cards-area {
//        display: flex;
//        gap: 32px;
//    }
//`;

const CelebRanking = styled.div`
    padding: 0 100px;
    display: flex;
    gap: 32px;
    .left {
        width: 922px;
        .cards-area {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 32px;
        }
    }
    .right {
        margin-top: 56px;
        .ranking-area {
            ul {
                width: 286px;
                height: 720px;
                border-top: 1px solid var(--gray-500);
                li {
                    color: var(--gray-400);
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.6rem; /* 100% */
                    .rank {
                        width: 24px;
                    }
                    .name {
                        width: 180px;
                        color: var(--gray-800);
                        margin-left: 16px;
                    }
                    .point {
                        color: var(--gray-400);
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 1.2rem; /* 100% */
                        margin-left: 24px;
                    }
                    display: flex;
                    align-items: center;
                    padding: 20px 8px;
                    border-bottom: 1px solid var(--gray-300);
                }
            }
        }
    }
`;

const CelebCardSmall = styled.div`
    width: 286px;
    height: 380px;
    .image-wrapper {
        height: 286px;
        img {
            width: 286px;
            height: 286px;
            border-radius: 4px;
            object-fit: cover;
            cursor: pointer;
        }
    }
    .celeb-info {
        padding: 14px 2px;
        .like {
            padding: 6px;
            float: right;
        }
        .first-row {
            display: flex;
            align-items: baseline;
            gap: 8px;
            margin-bottom: 12px;
            .name {
                cursor: pointer;
                color: var(--gray-800);
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 100% */
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            /*@media (min-width: 180px) {
                .name {
                    font-size: 1.6rem;
                }
            }*/
            .agency {
                color: var(--purple-500);
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.2rem; /* 100% */
            }
        }
        .second-row {
            display: flex;
            gap: 12px;
            color: var(--gray-500);
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.6rem; /* 100% */
            .group {
                display: flex;
                align-items: center;
                gap: 4px;
                .score {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100px;
                }
            }
        }
    }
`;

const NewCelebList = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 28px;
    column-gap: 36px;
    width: 604px;
    height: 452px;
    grid-template-rows: repeat(2, 1fr);
    .celeb {
        width: 284px;
        height: 212px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        img {
            width: 284px;
            height: 212px;
            object-fit: cover;
        }
        .name {
            position: absolute;
            display: none;
            left: 0;
            top: 0;
            width: 284px;
            height: 212px;
            background-color: rgba(251, 251, 251, 0.6);
            backdrop-filter: blur(2.5px);
            color: var(--gray-800);
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 100% */
        }
        &:hover {
            .name {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
`;

const CelebCardLarge = styled.div`
    width: 604px;
    height: 556px;
    .image-wrapper {
        height: 452px;
        img {
            border-radius: 4px;
            width: 604px;
            height: 452px;
            object-fit: cover;
        }
    }
    .celeb-info {
        padding: 20px 2px;
        .like {
            padding: 6px;
            float: right;
        }
        .first-row {
            width: 540px;
            display: flex;
            align-items: baseline;
            gap: 8px;
            margin-bottom: 12px;
            .name {
                color: var(--gray-800);
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem; /* 100% */
            }
            .agency {
                color: var(--purple-500);
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.2rem; /* 100% */
            }
        }
        .second-row {
            color: var(--gray-800);
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.6rem; /* 114.286% */
        }
    }
`;

const Home = () => {
    const { data: rankingList } = useGetRanking();
    const { data: newsData } = useGetNews();
    const { data: labsCelebs } = useGetLabs();
    const { data: hottestCelebs } = useGetHottest();
    const { navigateToPage } = useUrl();

    const celebArray = labsCelebs.map(item => item.celeb);

    return (
        <Container>
            {/*<Section color="var(--gray-900)" bottom={160}>*/}
            <Section mb={160}>
                <Carousel width={'1440px'} height={'774px'}>
                    {newsData.map(news => {
                        return (
                            <TopBanner
                                key={'celeb_news' + news.celeb.id}
                                bgColor={news.image_bg_color}
                                articleBgColor={news.article_bg_color}
                            >
                                <div className="image-wrapper">
                                    {news.article_image ? (
                                        <img src={news.article_image}></img>
                                    ) : (
                                        <img src={news.default_bg}></img>
                                    )}
                                    {/*<img src={news.celeb.portfolio_main.thumbnail}></img>*/}
                                </div>
                                <div className="description">
                                    {/*<h2 className="celeb-name">{news.celeb.pen_name}</h2>*/}
                                    <h3 className="article">{news.article}</h3>
                                    <ViewMore url={`/celebs/${news.celeb.id}`} />
                                    {/*<ViewMore url={news.link} />*/}
                                    {/*{news.article}
                                {news.date}*/}
                                </div>
                            </TopBanner>
                        );
                    })}
                </Carousel>
            </Section>
            {/*<Section bottom={160}>
                <NewCelebArea>
                    <TitleArea>
                        <div className="title-area">
                            <h2 className="title">New Celebrity</h2>
                            <h4 className="view-more">View more</h4>
                        </div>
                    </TitleArea>
                    <div className="cards-area">
                        <CelebCardLarge key={'celeb_card_large' + rankingList[0]?.celeb.id}>
                            <div className="image-wrapper">
                                <img src={rankingList[0]?.celeb.portfolio_main.thumbnail} />
                            </div>
                            <div className="celeb-info">
                                <div className="like">
                                    <LikeButton
                                        celebId={rankingList[0]?.celeb.id}
                                        defaultValue={rankingList[0]?.celeb.is_like}
                                    />
                                </div>
                                <div className="first-row">
                                    <span className="name">{rankingList[0]?.celeb.pen_name}</span>
                                    <span className="agency">{rankingList[0]?.celeb.company?.label}</span>
                                </div>
                                <div className="second-row">{rankingList[0]?.celeb.description}</div>
                            </div>
                        </CelebCardLarge>
                        <NewCelebList>
                            {rankingList.map((ranking, index) => {
                                if (index < 4)
                                    return (
                                        <div className="celeb" key={'celeb_card_xsmall' + ranking.celeb.id}>
                                            <img src={ranking.celeb.portfolio_main.thumbnail} />
                                            <div
                                                className="name"
                                                onClick={() => navigateToPage(`celebs/${ranking.celeb.id}`)}
                                            >
                                                {ranking.celeb.pen_name}
                                                <MaterialIcons name="arrow_outward" size="20px" />
                                            </div>
                                        </div>
                                    );
                            })}
                        </NewCelebList>
                    </div>
                </NewCelebArea>
            </Section>*/}
            <Section mb={160}>
                <CelebRanking>
                    <div className="left">
                        <TitleArea>
                            <h2 className="title">Celebrity</h2>
                            <h3 className="sub-title">Hottest Celebrity</h3>
                        </TitleArea>
                        <div className="cards-area">
                            {hottestCelebs.map((ranking, index) => {
                                const instagram = ranking.celeb.instagram?.followers_count
                                    ? numeral(ranking.celeb.instagram.followers_count).format('0.0a')
                                    : 'N/A';
                                const popularity = ranking.celeb.google_trends
                                    ? ranking.celeb.google_trends.regional.world.toFixed(2)
                                    : 'N/A';
                                if (index < 9) {
                                    return (
                                        <CelebCardSmall key={'celeb_card' + ranking.celeb.id}>
                                            <div
                                                className="image-wrapper"
                                                //onClick={() => navigateToPage('/celebs/' + ranking.celeb.id)}
                                            >
                                                <a href={'/celebs/' + ranking.celeb.id}>
                                                    <img src={ranking.celeb.portfolio_main.thumbnail} />
                                                </a>
                                            </div>
                                            <div className="celeb-info">
                                                <div className="like">
                                                    <LikeButton
                                                        celebId={ranking.celeb.id}
                                                        defaultValue={ranking.celeb.is_like}
                                                    />
                                                </div>
                                                <div className="first-row">
                                                    <span
                                                        className="name"
                                                        //onClick={() => navigateToPage('/celebs/' + ranking.celeb.id)}
                                                    >
                                                        <a href={'/celebs/' + ranking.celeb.id}>
                                                            {ranking.celeb.pen_name}
                                                        </a>
                                                    </span>
                                                    <span className="agency">{ranking.celeb.company?.label}</span>
                                                </div>
                                                <div className="second-row">
                                                    <div className="group">
                                                        <InstagramIcon />
                                                        <span className="score">{instagram}</span>
                                                    </div>
                                                    <div className="group">
                                                        <GoogleTrendsIcon />
                                                        <span className="score">{popularity}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CelebCardSmall>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="right">
                        <TitleArea>
                            <h3 className="sub-title">Trend ranking</h3>
                        </TitleArea>
                        <div className="ranking-area">
                            <ul>
                                {rankingList.map(ranking => {
                                    return (
                                        <li key={'celeb_ranking_' + ranking.order + '_' + ranking.celeb.id}>
                                            <span className="rank">{ranking.order}</span>
                                            {/*<span className="rank">{index + 1}</span>*/}
                                            <span className="name">{ranking.celeb.pen_name}</span>
                                            {/*<span className="point">{ranking.celeb.instagram?.followers_count}</span>*/}
                                            <span className="point">
                                                {parseInt(ranking.celeb.google_trends?.regional.world * 1000)}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </CelebRanking>
            </Section>
            <Section bgColor="var(--purple-050)">
                {/*<Labs />*/}
                {/*asdf*/}
                <CustomLabs celebList={celebArray} />
                <div style={{ marginTop: '80px', padding: '0 100px' }}>
                    <RecommendCelebs />
                    <Button
                        variant="gradient"
                        size="md"
                        onClick={() => navigateToPage('/celebs')}
                        style={{ display: 'block', margin: '80px auto 160px' }}
                    >
                        View more
                    </Button>
                </div>
            </Section>
            {/*asdf*/}
        </Container>
    );
};

export default Home;
