import React from 'react';
import MainContainer from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import UserPage from '../components/main/UserPage';

const MyPage = () => {
    return (
        <Layout>
            <MainContainer>
                <UserPage />
            </MainContainer>
        </Layout>
    );
};

export default MyPage;
