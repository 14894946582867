import { privateClient } from '../utils/client';

export const CreateCeleb = async data => {
    if (!data) {
        throw new Error('no data');
    }
    //const response = await privateClient.post(`/user/${userId}/celeb/`, data);
    const response = await privateClient.post(`/api/v1/celebs/`, data);
    return response.data;
};

export const PatchCeleb = async ({ celebId, data }) => {
    if (!data) {
        throw new Error('no data');
    }
    const response = await privateClient.patch(`/api/v1/celeb/${celebId}/`, data);
    return response.data;
};
