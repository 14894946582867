import React from 'react';
import MainContainer from '../../components/MainContainer';
import MyProject from '../../modules/agency/ProjectList';
import Layout from '../../modules/common/Layout';
import ConfirmModal from '../../modules/modal/ConfirmModal';
import LargeModal from '../../modules/modal/LargeModal';
import ModalCreateProject from '../../modules/modal/ModalCreateProject';
import Projects from '../../components/agency/Projects';

const Project = () => {
    return (
        <>
            <Layout>
                <MainContainer>
                    {/*<MyProject />*/}
                    <Projects />
                </MainContainer>
            </Layout>
            {/*<ModalCreateProject />*/}
            <LargeModal />
            {/*<ConfirmModal />*/}
        </>
    );
};

export default Project;
