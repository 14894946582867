import React from 'react';
import styled from 'styled-components';
import MaterialIcons from './MaterialIcons';

const ProgressBarWrapper = styled.div`
    margin: 20px auto;
    position: relative;
    width: 404px;
    height: 20px;
    .background {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background-color: var(--gray-300);
        overflow: hidden;
        .progress {
            width: ${props => props.width};
            height: 100%;
            background-color: var(--purple-200);
        }
    }
    ul.progress-number {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background-color: white;
            border: 1px solid black;
            border-radius: 50%;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem; /* 100% */
            &.finish {
                color: var(--purple-050);
                background-color: var(--purple-200);
                border: none;
                background-size: cover;
                background-image: url('/svg/check.svg');
                ::after {
                    position: absolute;
                    content: '';
                }
            }
            &.in-progress {
                color: var(--purple-400);
                border: 1px solid var(--purple-200);
            }
            &.stand-by {
                background-color: var(--gray-300);
                color: var(--gray-500);
                border: none;
            }
            ::before {
                position: absolute;
            }
        }
    }
    ul.progress-description {
        position: absolute;
        top: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            color: var(--gray-500);
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem; /* 100% */
        }
    }
`;

const progressDescription = ['Planning', 'Design', 'Development', 'Testing'];

const ProgressDetailStyle = styled.div`
    /*display: inline-block;*/
    padding: 0 26px;
    width: 372px;
    margin-right: 20px;
    border-radius: 15px;
    border: 1px solid var(--gray-300);
    background: var(--white, #fff);
    height: 100px;
    .top {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-top: 16px;
        color: var(--grey-grey-600, #7a7a7a);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 100% */
        border-bottom: 1px solid var(--gray-300);
        padding-bottom: 8px;
    }
    .bottom {
        margin-top: 8px;
        color: var(--grey-grey-800, #474747);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.8rem; /* 112.5% */
    }
`;

const ProgressAreaStyle = styled.div`
    display: flex;
    .progress-bar {
        /*display: inline-block;*/
        padding: 16px 26px;
        width: 472px;
        border-radius: 15px;
        border: 1px solid var(--gray-300);
        background: var(--white, #fff);
        height: 100px;
    }
`;

export const ProgressArea = props => {
    const { status } = props;

    return (
        <>
            <ProgressAreaStyle>
                <ProgressDetailStyle>{progressComponent[status]}</ProgressDetailStyle>
                <div className="progress-bar">
                    <ProgressBar status={status} />
                </div>
            </ProgressAreaStyle>
        </>
    );
};

const progressComponent = {
    'Waiting Response': (
        <>
            <div className="top">
                <MaterialIcons name="edit" size="20px" color="var(--gray-300)" />
                <span>Planning</span>
            </div>
            <div className="bottom">
                Plan a product design goals
                <br />
                design
            </div>
        </>
    ),
    completed: (
        <>
            <div className="top">
                <MaterialIcons name="design_services" size="20px" color="var(--purple-200)" />
                <span>Design</span>
            </div>
            <div className="bottom">
                Plan a product design goals
                <br />
                design
            </div>
        </>
    ),
    deleted: (
        <>
            <div className="top">
                <MaterialIcons name="construction" size="20px" color="var(--gray-300)" />
                <span>Development</span>
            </div>
            <div className="bottom">
                Plan a product design goals
                <br />
                design
            </div>
        </>
    ),
    rejected: (
        <>
            <div className="top">
                <MaterialIcons name="slow_motion_video" size="20px" color="var(--gray-300)" />
                <span>Testing</span>
            </div>
            <div className="bottom">
                Plan a product design goals
                <br />
                design
            </div>
        </>
    ),
};
export const ProgressDetail = props => {
    const { status } = props;

    return <ProgressDetailStyle>{progressComponent[status]}</ProgressDetailStyle>;
};

const progressStatus = {
    'Waiting Response': {
        state: ['in-progress', 'stand-by', 'stand-by', 'stand-by'],
        progressWidth: '0%',
    },
    'Accepted: WIP': {
        state: ['finish', 'in-progress', 'stand-by', 'stand-by'],
        progressWidth: '33%',
    },
    Deleted: {
        state: ['finish', 'finish', 'in-progress', 'stand-by'],
        progressWidth: '66%',
    },
    Rejected: {
        state: ['finish', 'finish', 'finish', 'in-progress'],
        progressWidth: '99%',
    },
    Completed: {
        state: ['finish', 'finish', 'finish', 'finish'],
        progressWidth: '99%',
    },
};

ProgressDetail.defaultProps = {
    status: 'Waiting Response',
};

const ProgressBar = props => {
    const { status } = props;
    const width = progressStatus[status].progressWidth;

    return (
        <>
            <ProgressBarWrapper width={width}>
                <div className="background">
                    <div className="progress"></div>
                </div>
                <ul className="progress-number">
                    {progressStatus[status].state.map((data, index) => (
                        <li key={'progress_circle_' + index} className={data}>
                            {data === 'finish' ? '' : '0' + (index + 1)}
                        </li>
                    ))}
                </ul>
                <ul className="progress-description">
                    {progressDescription.map((description, index) => (
                        <li key={'progress_description_' + index}>{description}</li>
                    ))}
                </ul>
            </ProgressBarWrapper>
        </>
    );
};

ProgressBar.defaultProps = {
    status: 'status2',
};

export default ProgressBar;
