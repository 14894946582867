import React, { useEffect } from 'react';
import { useRef } from 'react';

function ImageList(props) {
    const { list, currentIndex, removeItem } = props;
    const listRef = useRef(null);

    useEffect(() => {
        listRef.current.style.transition = 'all 0.5s ease-in-out';
        listRef.current.style.transform = `translateX(${currentIndex * 168}px)`;
    }, [currentIndex]);

    return (
        <div style={{ overflow: 'hidden' }}>
            <div
                ref={listRef}
                style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    position: 'relative',
                    width: '5544px',
                }}
            >
                {/*컨테이너*/}
                {list.map((file, index) => (
                    <div
                        key={index}
                        className="image-box"
                        style={{
                            width: '156px',
                            height: '152px',
                            border: '1px solid #5a607f',
                            marginLeft: '5px',
                            marginRight: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <img src={file.file} style={{ float: 'left' }}></img>
                        <i onClick={() => removeItem(index)} className="icon-cancel"></i>
                    </div>
                ))}
                <div className="image-box" style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {/*<i className="icon-cancel"></i>*/}
                </div>
                <div className="image-box" style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {/*<i className="icon-cancel"></i>*/}
                </div>
                <div className="image-box" style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {/*<i className="icon-cancel"></i>*/}
                </div>
                <div className="image-box" style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {/*<i className="icon-cancel"></i>*/}
                </div>
            </div>
        </div>
    );
}

export default ImageList;
