import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Input from '../../components/input/Input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { registerUser } from '../../queries/Register';
import WelcomePage from './WelcomePage';
import Button from '../../components/styled-components/Button';

const schema = yup
    .object({
        //password: yup.string().min(3, '세글자 이상이어야합니다').required('필수 값입니다'),
        username: yup.string().label('ID').required(),
        password: yup.string().label('Password').required(),
        confirm_password: yup
            .string()
            .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다')
            .label('Confirm password')
            .required(),
        //first_name: yup.string().label('First name').required(),
        //last_name: yup.string().label('Last name').required(),
        website: yup.string().label('Website'),
        //email: yup.string().label('Email').required(),
        //mobile: yup.string().label('Mobile').required(),
        //age: yup.number().positive().integer().required(),
    })
    .required();

const PageTitle = styled.div`
    margin: 100px auto 40px;
    text-align: center;
    .logo {
        display: inline-block;
        width: 200px;
    }
    .description {
        margin-left: 12px;
        color: var(--gray-900);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 100% */
    }
`;

const ProjectFormWrapper = styled.div`
    width: 616px;
    border-radius: 15px;
    background-color: var(--white);
    margin: 0 auto 200px;
    padding: 48px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    .flex {
        display: flex;
        justify-content: space-between;
    }
`;

const InputGroup = styled.div`
    /*.flex {
        display: flex;
        justify-content: space-between;
    }*/
`;

const LabelStyle = styled.label`
    display: inline-block;
    &.require {
        ::after {
            content: ' *';
            color: var(--crimson-500);
        }
    }
    &.optional {
        ::after {
            content: ' (optional)';
            font-weight: 350;
        }
    }
    color: var(--gray-800);

    margin-left: 1px;
    margin-bottom: 16px;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8rem;
`;

const ErrorLabel = styled.label`
    margin-left: 1px;
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: top;
    justify-content: flex-start;
    color: var(--crimson-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
`;

const Register = () => {
    const [finish, setFinish] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const mutate = useMutation(async params => registerUser(params), {
        onSuccess: res => {
            setFinish(true);
        },
        onError: err => {
            console.log(err);
            console.log(err.response.data.data);
        },
    });

    const onSubmit = data => {
        const params = {};

        for (const key in data) {
            if (data[key]) {
                params[key] = data[key];
            }
        }
        mutate.mutate(params);
    };

    if (finish) {
        return (
            <>
                <WelcomePage />
            </>
        );
    }

    return (
        <>
            <PageTitle>
                <div className="logo">
                    <a href="/">
                        <img src="/svg/CeleVu_logo_b.svg" alt="logo" />
                    </a>
                </div>
                <div className="description">Sign up for CeleVu account.</div>
            </PageTitle>
            <ProjectFormWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                        <LabelStyle className="require">Email</LabelStyle>
                        <Input
                            type="text"
                            name="username"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.username ? errors.username : null}
                        />
                        {errors.username ? <ErrorLabel>{errors.username.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <InputGroup>
                        <LabelStyle className="require">Password</LabelStyle>
                        <Input
                            type="password"
                            name="password"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.password ? errors.password : undefined}
                        />
                        {errors.password ? <ErrorLabel>{errors.password.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    <InputGroup>
                        <LabelStyle className="require">Confirm password</LabelStyle>
                        <Input
                            type="password"
                            name="confirm_password"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.confirm_password ? errors.confirm_password : undefined}
                        />
                        {errors.confirm_password ? (
                            <ErrorLabel>{errors.confirm_password.message}</ErrorLabel>
                        ) : (
                            <ErrorLabel />
                        )}
                    </InputGroup>
                    {/*<LabelStyle className="require">Name</LabelStyle>
                    <div className="flex">
                        <InputGroup>
                            <Input
                                type="text"
                                name="first_name"
                                size="lg_half"
                                color="primary"
                                placeholder="First name"
                                register={register}
                                option={{ required: true }}
                                error={errors.first_name ? errors.first_name : undefined}
                            />
                            {errors.first_name ? <ErrorLabel>{errors.first_name.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                        <InputGroup>
                            <Input
                                type="text"
                                name="last_name"
                                size="lg_half"
                                color="primary"
                                placeholder="Last name"
                                register={register}
                                option={{ required: true }}
                                error={errors.last_name ? errors.last_name : undefined}
                            />
                            {errors.last_name ? <ErrorLabel>{errors.last_name.message}</ErrorLabel> : <ErrorLabel />}
                        </InputGroup>
                    </div>*/}
                    <InputGroup>
                        <LabelStyle>Website</LabelStyle>
                        <Input
                            type="text"
                            name="website"
                            size="lg"
                            color="primary"
                            placeholder="Optional"
                            register={register}
                            option={{ required: false }}
                            error={errors.website ? errors.website : undefined}
                        />
                        {errors.website ? <ErrorLabel>{errors.website.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>
                    {/*<InputGroup>
                        <LabelStyle className="require">Email</LabelStyle>
                        <Input
                            type="text"
                            name="email"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.email ? errors.email : undefined}
                        />
                        {errors.email ? <ErrorLabel>{errors.email.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>*/}
                    {/*<InputGroup>
                        <LabelStyle className="require">Mobile</LabelStyle>
                        <Input
                            type="text"
                            name="mobile"
                            size="lg"
                            color="primary"
                            register={register}
                            option={{ required: true }}
                            error={errors.mobile ? errors.mobile : undefined}
                        />
                        {errors.mobile ? <ErrorLabel>{errors.mobile.message}</ErrorLabel> : <ErrorLabel />}
                    </InputGroup>*/}
                    <Button type="submit" size="lg" variant="primary" style={{ marginTop: '28px' }}>
                        Sign up
                    </Button>
                    {/*<SubmitButton type="submit" value="Sign up" />*/}
                </form>
            </ProjectFormWrapper>
        </>
    );
};

export default Register;
