import React from 'react';
import styled from 'styled-components';
import { useUrl } from '../../hooks/useUrl';

const FooterWrapper = styled.footer`
    width: 100%;
    border-top: 1px solid var(--gray-300);
    background-color: var(--gray-050);
    /*background-color: var(--gray-900);*/
    .content {
        padding: 52px;
        width: 1440px;
        margin: 0 auto;
        display: grid;
        /*color: var(--purple-050);*/
        color: var(--gray-800);
        /*color: var(--white);*/
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.4rem; /* 100% */
        font-style: normal;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 20px;
        grid-row-gap: 20px;
        grid-template-areas:
            'logo first second third '
            'logo copyright copyright third';
        .logo {
            grid-area: logo;
            /*background-image: url('/svg/celevu_Light.svg');*/
            background-image: url('/svg/celevu_Dark.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: 200px;
            height: 56px;
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .first {
            margin-left: 40px;
            width: 200px;
            display: flex;
            gap: 20px;
            grid-area: first;
            li {
                cursor: pointer;
            }
        }
        .second {
            width: 200px;
            grid-area: second;
            li {
                cursor: pointer;
            }
        }
        .third {
            margin-left: 120px;
            grid-area: third;
            width: 448px;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
            .bold {
                font-weight: 700;
            }
        }
        .copyright {
            margin-top: 24px;
            margin-left: 40px;
            grid-area: copyright;
            font-size: 1.4rem;
            font-style: italic;
            font-weight: 400;
            line-height: 1.4rem; /* 100% */
        }
    }
`;

const Footer = () => {
    const { navigateToPage } = useUrl();
    return (
        <FooterWrapper>
            <div className="content">
                <div className="logo"></div>
                <div className="copyright">@2024 CeleVu All rights reserved</div>
                <div className="first">
                    <ul>
                        <li onClick={() => navigateToPage('/services')}>Service</li>
                        <li onClick={() => navigateToPage('/celebs')}>Celebs</li>
                    </ul>
                </div>
                <div className="second">
                    <ul>
                        <li onClick={() => navigateToPage('/terms-of-use')}>Team of Service</li>
                        <li onClick={() => navigateToPage('/contact')}>Contact us</li>
                    </ul>
                </div>
                <div className="third">
                    <ul>
                        <li>
                            <span className="bold">Registration Number:</span> 509-88-01732
                        </li>
                        <li>
                            Room 212, 84, Madeul-ro 13-gil,
                            <br />
                            Dobong-gu, Seoul, Republic of Korea, 01411
                        </li>
                        <li>
                            <span className="bold">Tel:</span> +82-2967-0701 / <span className="bold">E-mail:</span>{' '}
                            support@aligo.ai
                        </li>
                    </ul>
                </div>
            </div>
        </FooterWrapper>
    );
};

export default Footer;
