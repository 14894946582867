export const alertMessage = msg => {
    alert(msg);
    return;
};

export const getMBTIString = mbti => {
    const mbtiString = mbti.mbti0 + mbti.mbti1 + mbti.mbti2 + mbti.mbti3;

    return mbtiString.toUpperCase();
};

export const getAdPostingCountries = adPostingCountriesArray => {
    let ret = '';

    adPostingCountriesArray.forEach(element => {
        ret += element.label + ', ';
    });

    return ret.substring(0, ret.length - 2);
};

export const getDisplayNameString = array => {
    let ret = '';

    array.forEach(element => {
        ret += element.label + ', ';
    });

    return ret.substring(0, ret.length - 2);
};

export const getYears = () => {
    const years = [];
    const year = new Date().getFullYear();

    for (let index = year - 100; index <= year; index++) {
        years.push(index);
    }
    return years;
};

export const getDataSlice = (data, currentPage, postsPerPage) => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    return currentPosts;
};
export const pagination = (data, currentPage, postsPerPage, pageCount) => {
    const totalData = data.length; //전체 데이터의 갯수

    const totalPages = Math.ceil(totalData / postsPerPage); //전체 페이지의 갯수
    // const pageGroup = Math.ceil(currentPage / pageCount); //페이지 그룹

    // const start = (currentPage - 1) * postsPerPage; //시작 페이지 인덱스
    // const last = pageGroup * postsPerPage; //마지막 페이지 인덱스

    // if (totalPages < last) {
    //     last = totalPages;
    // }

    // const next = last + 1; //다음 페이지
    // const prev = start - 1; //이전 페이지

    // if (totalPages < 1) {
    //     start = last;
    // }

    return (
        <div className="pagination right">
            <ul>
                <li>
                    <a href="#">
                        <i className="icon-left-open"></i>
                    </a>
                </li>
                <li className="selected">1</li>
                <li>
                    <a href="#">2</a>
                </li>
                <li>
                    <a href="#">3</a>
                </li>
                <li>
                    <a href="#">4</a>
                </li>
                <li>
                    <a href="#">
                        <i className="icon-right-open"></i>
                    </a>
                </li>
            </ul>
            <select className="sort-select">
                <option>Latest</option>
                <option>Popularity</option>
            </select>
        </div>
    );
};
