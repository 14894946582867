import axios from 'axios';
import { BASE_URL } from '../configs/common';
import { privateClient } from '../utils/client';

const axiosInstance = axios.create({ baseURL: BASE_URL });

export const AddHeart = async ({ photoId, userId }) => {
    console.log(photoId, userId);
    if (!userId) {
        console.log('로그인이 필요합니다');
        return;
    }
    const formData = new FormData();
    formData.append('portfolio', photoId);
    const response = await privateClient.post(`/user/${userId}/heart/portfolio/`, formData);
    return response;
};

export const RemoveHeart = async ({ userId, portfolioId }) => {
    if (!userId) {
        console.log('로그인이 필요합니다');
        return;
    }
    const response = await privateClient.delete(`/user/${userId}/heart/portfolio/${portfolioId}`);
    return response;
};
