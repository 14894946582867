import React from 'react';
import Service from '../components/contents/Service';
import ServiceFlow from '../components/contents/ServiceFlow';
import MainContainer from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import Breadcrumb from '../modules/header/Breadcrumb';

const Services = ({ children }) => {
    return (
        <Layout>
            <MainContainer>
                {/*<Breadcrumb></Breadcrumb>*/}
                {/*<Service></Service>*/}
                <ServiceFlow />
            </MainContainer>
        </Layout>
    );
};

export default Services;
