import axios from 'axios';
import { privateClient } from '../utils/client';

export const uploadAd = async ({ contractId, portfolioId }) => {
    const body = {
        portfolio: portfolioId,
    };
    const response = await privateClient.post(`/api/v1/contracts/${contractId}/contents/`, body);

    return response.data;
};

export const removeAd = async ({ contractId, contentId }) => {
    const response = await privateClient.delete(`/api/v1/contracts/${contractId}/contents/${contentId}/`);

    return response.data;
};
