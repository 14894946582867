import React from 'react';
import GradientText from '../styled-components/GradientText';
import Loading from '../contents/Loading';
import Error from '../contents/Error';
import LabsChart from '../Charts/LabsChart';
import { useCountries, useFeatureList } from '../../hooks/getCode';
import { useLikeList } from '../../hooks/useLike';
import styled from 'styled-components';
import Select, { useSelect } from '../styled-components/Select';
import MaterialIcons from '../styled-components/MaterialIcons';
import { useUrl } from '../../hooks/useUrl';

//function transformArray(arr) {
//    const transformedArray = [];

//    arr.forEach(originalObject => {
//        const transformedObject = {
//            id: originalObject.id,
//            pen_name: originalObject.pen_name,
//            gender: originalObject.gender.id,
//            followers_count: originalObject.instagram?.followers_count,
//            ...originalObject.google_trends.regional,
//        };

//        originalObject.feature_score.forEach(item => {
//            let lowerString = item.feature.toLowerCase();

//            transformedObject[lowerString] = parseFloat(item.score) * 100;
//        });

//        transformedArray.push(transformedObject);
//    });

//    return transformedArray;
//}

function transformArray(arr) {
    // 변환된 배열을 담을 새 배열
    const transformedArray = [];

    // 배열의 각 항목을 순회하면서 변환
    arr.forEach(originalObject => {
        const transformedObject = {
            id: originalObject.id,
            pen_name: originalObject.pen_name,
            gender: originalObject.gender.id,
            followers_count: originalObject.instagram ? originalObject.instagram.followers_count : 0,
            ...originalObject.google_trends?.regional,
        };

        originalObject.feature_score.forEach(item => {
            let lowerString = item.feature.toLowerCase();

            transformedObject[lowerString] = parseFloat(item.score) * 100;
        });

        transformedArray.push(transformedObject);
    });

    return transformedArray;
}

const SelectArea = styled.div`
    display: flex;
    align-items: center;
    gap: 28px;
    margin: 40px 60px;
    .select-group {
        display: flex;
        align-items: center;
        gap: 4px;
        .select-label {
            color: var(--grey-grey-800, #474747);
            text-align: center;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 10px; /* 100% */
        }
    }
`;
const ChartArea = styled.div`
    width: 1240px;
    padding: 24px 28px;
    background-color: var(--white);
    border-radius: 2px;
    margin: 0 auto;
    border: 1px solid var(--gray-200);
`;

const TitleArea = styled.div`
    margin: 100px 100px 40px;
    border-bottom: 1px solid var(--gray-300);
    padding-bottom: 40px;
    .sub-text {
        margin-top: 20px;
        color: var(--gray-800);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.9rem; /* 126.667% */
    }
`;

const Labs = () => {
    const { data, isLoading, isError, error } = useLikeList();
    const { data: countriesList } = useCountries();
    const { data: featuresList } = useFeatureList();
    const { state: xFeatureState, setState: setXFeatureState } = useSelect();
    const { state: yFeatureState, setState: setYFeatureState } = useSelect();
    const { state: zFeatureState, setState: setZFeatureState } = useSelect();

    if (isLoading) return <Loading />;
    if (isError) return <Error errMsg={error.message} />;
    if (!data) return <></>;

    const chartData = transformArray(data);
    return (
        <>
            <TitleArea>
                <GradientText text="CeleVu Labs" fontSize="md" />
                <p className="sub-text">
                    Explore, pick, and compare celebs to find out best for your ads
                    <br />
                    {/*Choose your celebs for BlackPink.*/}
                </p>
            </TitleArea>
            <ChartArea>
                <SelectArea>
                    <div className="select-group">
                        <p className="select-label">X Value:</p>
                        <Select
                            optionsList={featuresList}
                            state={xFeatureState}
                            setState={setXFeatureState}
                            initValue="sophistication"
                            size="sm"
                            color="gray"
                        />
                    </div>
                    <div className="select-group">
                        <p className="select-label">Y Value:</p>
                        <Select
                            optionsList={featuresList}
                            state={yFeatureState}
                            setState={setYFeatureState}
                            initValue="excitement"
                            size="sm"
                            color="gray"
                        />
                    </div>
                    <div className="select-group">
                        <p className="select-label">Size Value:</p>
                        <Select
                            optionsList={countriesList}
                            state={zFeatureState}
                            setState={setZFeatureState}
                            initValue="world"
                            size="sm"
                            color="gray"
                        />
                    </div>
                </SelectArea>
                <LabsChart chartData={chartData} xState={xFeatureState} yState={yFeatureState} zState={zFeatureState} />
            </ChartArea>
        </>
    );
};

export const CustomLabs = props => {
    const { celebList } = props;
    const { data: countriesList } = useCountries();
    const { data: featuresList } = useFeatureList();
    const { state: xFeatureState, setState: setXFeatureState } = useSelect();
    const { state: yFeatureState, setState: setYFeatureState } = useSelect();
    const { state: zFeatureState, setState: setZFeatureState } = useSelect();
    const { navigateToPage } = useUrl();
    if (celebList.length === 0) return <></>;

    const chartData = transformArray(celebList);
    return (
        <>
            <TitleArea>
                <GradientText text="CeleVu Labs" fontSize="md" />
                <MaterialIcons
                    name="arrow_outward"
                    color="var(--purple-500)"
                    cursor="pointer"
                    onClick={() => navigateToPage('/labs/')}
                    style={{ marginLeft: '12px' }}
                />
                <p className="sub-text">
                    Explore, pick, and compare celebs to find out best for your ads
                    <br />
                    {/*Choose your celebs for BlackPink.*/}
                </p>
            </TitleArea>
            <ChartArea>
                <SelectArea>
                    <div className="select-group">
                        <p className="select-label">X Value:</p>
                        <Select
                            optionsList={featuresList}
                            state={xFeatureState}
                            setState={setXFeatureState}
                            initValue="sophistication"
                            size="sm"
                            color="gray"
                        />
                    </div>
                    <div className="select-group">
                        <p className="select-label">Y Value:</p>
                        <Select
                            optionsList={featuresList}
                            state={yFeatureState}
                            setState={setYFeatureState}
                            initValue="excitement"
                            size="sm"
                            color="gray"
                        />
                    </div>
                    <div className="select-group">
                        <p className="select-label">Size Value:</p>
                        <Select
                            optionsList={countriesList}
                            state={zFeatureState}
                            setState={setZFeatureState}
                            initValue="world"
                            size="sm"
                            color="gray"
                        />
                    </div>
                </SelectArea>
                <LabsChart chartData={chartData} xState={xFeatureState} yState={yFeatureState} zState={zFeatureState} />
            </ChartArea>
        </>
    );
};

export default Labs;
