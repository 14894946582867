import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PaginationLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px auto;
    .page-number {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        font: normal normal 500 1.5rem/1.5rem inter;
        width: 32px;
        height: 32px;
        color: #474747;
        &:hover {
            font: normal normal 700 1.5rem/1.5rem inter;
            border-radius: 16px;
            background-color: #474747;
            color: #fbfbfb;
        }
        &.active {
            font: normal normal 900 1.5rem/1.5rem inter;
            /*border-radius: 16px;
            background-color: #474747;
            color: #fbfbfb;*/
        }
    }
`;

const Pagination = ({ setPage, activePage, lastPage }) => {
    const [visiblePages, setVisiblePages] = useState([]);

    const generateVisiblePages = () => {
        const maxVisiblePages = 5; // 페이지네이션에 최대로 표시할 페이지 개수 (현재 페이지 포함)
        const pageNeighbours = Math.floor((maxVisiblePages - 1) / 2);
        let startPage = Math.max(1, activePage - pageNeighbours);
        let endPage = Math.min(lastPage, activePage + pageNeighbours);

        const totalPagesToShow = endPage - startPage + 1;

        if (totalPagesToShow < maxVisiblePages - 1) {
            // 페이지가 maxVisiblePages보다 작은 경우 중간에 Ellipsis 추가
            const diff = maxVisiblePages - totalPagesToShow - 1;
            if (startPage > 1) {
                startPage = Math.max(1, startPage - diff);
            }
            if (endPage < lastPage) {
                endPage = Math.min(lastPage, endPage + diff);
            }
        }

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
        setVisiblePages(pages);
    };

    const handlePageChange = pageNumber => {
        setPage(pageNumber);
    };

    useEffect(() => {
        generateVisiblePages();
    }, [activePage, lastPage]);

    return (
        <PaginationLayout>
            <span className="material-icons">navigate_before</span>
            {visiblePages[0] !== 1 && (
                <span className="page-number" onClick={() => handlePageChange(1)}>
                    1
                </span>
            )}
            {visiblePages[0] > 2 && (
                <span className="page-number" onClick={() => handlePageChange(visiblePages[0] - 1)}>
                    ...
                </span>
            )}
            {visiblePages.map(page => (
                <span
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={activePage === page ? 'page-number active' : 'page-number'}
                >
                    {page}
                </span>
            ))}
            {visiblePages[visiblePages.length - 1] < lastPage - 1 && (
                <span
                    className="page-number"
                    onClick={() => handlePageChange(visiblePages[visiblePages.length - 1] + 1)}
                >
                    ...
                </span>
            )}
            {visiblePages[visiblePages.length - 1] !== lastPage && (
                <span className="page-number" onClick={() => handlePageChange(lastPage)}>
                    {lastPage}
                </span>
            )}
            <span className="material-icons">navigate_next</span>
        </PaginationLayout>
    );
};

export default Pagination;
