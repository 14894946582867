import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Detail from '../offer/Detail';
import Message from '../offer/Message';
import { OFFER_DETAIL_MODAL, setOfferModal } from './api/action';
import UploadContents from '../offer/UploadContents';
import ConfirmContents from '../offer/ConfirmContents';
import ContractDetail from '../offer/ContractDetail';

const OfferDetail = () => {
    const OfferDetailState = useSelector(state => state.modal.offerDetail);
    const userData = useSelector(state => state.user);
    const [detailTab, setDetailTab] = useState(true);
    const [messageTab, setMessageTab] = useState(false);
    const [uploadContentTab, setUploadContentTab] = useState(false);

    const selectDetailTab = () => {
        setDetailTab(true);
        setMessageTab(false);
        setUploadContentTab(false);
    };

    const selectMessageTab = () => {
        setDetailTab(false);
        setMessageTab(true);
        setUploadContentTab(false);
    };

    const selectUploadContentTab = () => {
        setDetailTab(false);
        setMessageTab(false);
        setUploadContentTab(true);
    };
    const Contents =
        userData.role === 'ad_agency' ? (
            <UploadContents userId={userData.id} contractId={OfferDetailState.contract_id} />
        ) : (
            <ConfirmContents userId={userData.id} contractId={OfferDetailState.contract_id} />
        );

    const dispatch = useDispatch();
    const hideModal = () => {
        selectDetailTab();
        dispatch(
            setOfferModal({
                type: OFFER_DETAIL_MODAL,
                data: {
                    status: false,
                    contract_id: 0,
                },
            })
        );
    };

    return OfferDetailState.status ? (
        <div className="modal">
            <div className="modal-dialog modal-xl">
                <i onClick={hideModal} className="icon-cancel"></i>
                <div className="modal-tab ">
                    <ul>
                        <li onClick={selectDetailTab} className={detailTab ? 'selected' : ''}>
                            Offer Detail
                        </li>
                        <li onClick={selectMessageTab} className={messageTab ? 'selected' : ''}>
                            Message
                        </li>
                        <li onClick={selectUploadContentTab} className={uploadContentTab ? 'selected' : ''}>
                            Confirm Content
                        </li>
                    </ul>
                </div>
                {detailTab ? <ContractDetail userId={userData.id} contractId={OfferDetailState.contract_id} /> : <></>}
                {messageTab ? <Message userId={userData.id} contractId={OfferDetailState.contract_id} /> : <></>}
                {uploadContentTab ? Contents : <></>}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default OfferDetail;
