import { useQuery } from 'react-query';
import { getDemoCelebList, getDemoResult, getDemoProductList } from '../queries/Demo';

export const useGetProductList = () => {
    const { data, isError, isLoading, refetch } = useQuery(['getProductList'], () => getDemoProductList());

    const fetchData = async () => {
        await refetch();
    };

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data, fetchData };
};

export const useGetDemoCelebList = productId => {
    const { data, isError, isLoading, refetch } = useQuery(['getDemoCelebList', productId], () =>
        getDemoCelebList(productId)
    );

    const fetchData = async productId => {
        await refetch(productId);
    };

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data, fetchData };
};

export const useGetDemoResult = (productId, celebId) => {
    const { data, isError, isLoading, refetch } = useQuery(['getDemoResult', productId, celebId], () =>
        getDemoResult(productId, celebId)
    );

    const fetchData = async (productId, celebId) => {
        await refetch(productId, celebId);
    };

    if (isLoading || isError) {
        return { data: {} };
    }
    return { data, fetchData };
};
