import React, { useEffect, useState } from 'react';
import { ModalFrame, useModal } from './ModalUtils';
import { ALERT_MODAL, CONTRACT_OFFER_MODAL, CONTRACT_PROJECT_MODAL } from './api/action';
import styled from 'styled-components';
import Button from '../../components/styled-components/Button';
import { useSelector } from 'react-redux';
import { getLabelString } from './ContractProjectModal';
import { useMutation } from 'react-query';
import { createContract } from '../../queries/CreateContract';

const Contents = styled.div`
    padding: 32px 24px;
    height: 100%;
    .modal-title {
        padding: 0;
        color: var(--gray-900);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem;
    }
    .celeb-info {
        display: flex;
        gap: 20px;
        padding: 28px 0;
        border-bottom: 1px solid var(--gray-300);
        .image {
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
        .project-detail {
            .detail {
                margin-bottom: 20px;
                .title {
                    color: var(--gray-500, #a4a4a4);
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.5rem; /* 100% */
                    margin-bottom: 8px;
                }
                .info {
                    color: var(--gray-900, #252525);
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem; /* 100% */
                }
            }
        }
    }
    .message {
        overflow: hidden;
        .message-title {
            padding: 20px 0;
            color: var(--gray-500);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 100% */
            ::after {
                content: ' (Optional)';
                color: var(--gray-500);
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
            }
        }
        textarea {
            font-family: inter;
            font-size: 1.5rem;
            font-weight: 400;
            font-style: normal;
            line-height: 1.5rem;
            resize: none;
            border-radius: 2px;
            padding: 12px;
            border: 1px solid var(--gray-500);
            background: var(--white);
            width: 760px;
            height: 200px;
            :focus {
                border: 1px solid black;
            }
            margin-bottom: 28px;
        }
        .button-area {
            float: right;
            display: flex;
            gap: 16px;
        }
    }
`;

const ContractOfferModal = () => {
    const contractOffer = useSelector(state => state.modal.contractOfferModal);
    const [text, setText] = useState('');
    const { hideModal: hideOfferModal } = useModal(CONTRACT_OFFER_MODAL);
    const { showModal: showContractModal } = useModal(CONTRACT_PROJECT_MODAL);
    const { showModal, hideModal } = useModal(ALERT_MODAL);
    const mutation = useMutation(async data => createContract({ data }), {
        onSuccess: response => {
            if (response && response.data.status === 'success') {
                showModal({
                    title: 'Congratulations!',
                    content: 'Your project has been successfully created.\nOur manager will contact you via email',
                    buttonText: 'Confirm',
                    buttonFunction: () => hideModal(),
                });
                hideOfferModal();
            }
        },
        onError: error => {
            if (error.response && error.response.status === 400) {
                showModal({
                    title: 'Already exist!',
                    content:
                        'You already sent offer to another celeb for this project.\nPlease make offer with fresh project.',
                    buttonText: 'Confirm',
                    buttonFunction: () => hideModal(),
                });
            }
        },
    });

    const handleChange = event => {
        setText(event.target.value);
    };

    const onClickBack = () => {
        hideOfferModal();
        showContractModal();
    };

    const onClickSend = () => {
        const data = {
            project: contractOffer.id,
            celeb: contractOffer.celebId,
            special_condition: text,
        };
        mutation.mutate(data);
    };

    useEffect(() => {
        setText('');
    }, [contractOffer.status]);

    return (
        <ModalFrame width="864px" modalType={CONTRACT_OFFER_MODAL} modalName="contractOfferModal">
            <Contents>
                <p className="modal-title">Contract</p>
                <div className="celeb-info">
                    <img className="image" src={contractOffer.photo} />
                    <div className="project-detail">
                        <div className="detail">
                            <p className="title">Project Title</p>
                            <p className="info">{contractOffer.title}</p>
                        </div>
                        <div className="detail">
                            <p className="title">Project Detail</p>
                            <p className="info">{contractOffer.detail}</p>
                        </div>
                        <div className="detail">
                            <p className="title">Ad Posting country</p>
                            <p className="info">{getLabelString(contractOffer.countries)}</p>
                        </div>
                    </div>
                </div>
                <div className="message">
                    <p className="message-title">Special Condition</p>
                    <textarea value={text} onChange={e => handleChange(e)} />
                    <div className="button-area">
                        <Button size="sm" type="button" variant="gray" onClick={onClickBack}>
                            Back to project
                        </Button>
                        <Button size="sm" type="button" variant="primary" onClick={onClickSend}>
                            Send Offer
                        </Button>
                    </div>
                </div>
            </Contents>
        </ModalFrame>
    );
};

export default ContractOfferModal;
