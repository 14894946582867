import React from 'react';
import styled from 'styled-components';

const sizeStyles = {
    default: {
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        padding: '20px 24px',
        fontWeight: '400',
        width: '520px',
        height: '60px',
    },
    sm: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
        padding: '6px 10px',
        fontWeight: '500',
        width: '180px',
        height: '60px',
    },
    md: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        padding: '20px 24px',
        fontWeight: '500',
        width: '420px',
        height: '60px',
    },
    lg: {
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        padding: '20px 24px',
        fontWeight: '400',
        width: '520px',
        height: '60px',
    },
    lg_half: {
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        padding: '20px 24px',
        fontWeight: '400',
        width: '248px',
        height: '60px',
    },
    country: {
        fontSize: '1.2rem',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '1.2rem',
        whiteSpace: 'nowrap',
        padding: '8px',
    },
};

const colorStyles = {
    default: {
        backgroundColor: 'var(--purple-500)',
        fontFamily: 'Inter',
        color: 'var(--white)',
        borderRadius: '2px',
        '&:hover': {},
    },
    gradient: {
        background: 'var(--gradient-06)',
        fontFamily: 'Inter',
        color: 'var(--gray-050)',
        borderRadius: '4px',
    },
    contact: {
        background: 'var(--gradient-06)',
        fontFamily: 'Inter',
        color: 'var(--gray-050)',
        borderRadius: '30px',
    },
    primary: {
        backgroundColor: 'var(--purple-500)',
        fontFamily: 'Inter',
        color: 'var(--white)',
        borderRadius: '2px',
        '&:hover': {},
    },
    secondary: {
        border: '1px solid var(--purple-500)',
        fontFamily: 'Inter',
        color: 'var(--purple-500)',
        backgroundColor: 'var(--white)',
        borderRadius: '2px',
        '&:hover': {},
    },
    gray: {
        backgroundColor: 'var(--gray-500)',
        fontFamily: 'Inter',
        color: 'var(--white)',
        borderRadius: '2px',
    },
    country: {
        color: 'var(--gray-050)',
        fontFamily: 'Inter',
        borderRadius: '20px',
        border: '1px solid var(--purple-500)',
        backgroundColor: 'var(--purple-400)',
    },
    error: {
        color: 'var(--gray-500)',
        fontFamily: 'Inter',
        borderRadius: '20px',
        border: '1px solid var(--error)',
        backgroundColor: 'var(--white)',
    },
    selected: {
        color: 'var(--gray-500)',
        fontFamily: 'Inter',
        borderRadius: '20px',
        border: '1px solid var(--gray-500)',
        backgroundColor: 'var(--gray-100)',
    },
};

const ButtonStyle = styled.button`
    cursor: pointer;
    display: inline-block;
    outline: none;
    ${props => colorStyles[props.variant] || colorStyles.default};
    ${props => (props.isError ? colorStyles.error : '')};
    ${props => sizeStyles[props.size] || sizeStyles.default}/*box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);*/
`;

const Button = props => {
    const { children, type, size, variant, isError, ...options } = props;
    return (
        <ButtonStyle type={type} variant={variant} size={size} isError={isError} {...options}>
            {children}
        </ButtonStyle>
    );
};

Button.defaultProps = {
    type: 'button',
    isError: false,
};

export default Button;
