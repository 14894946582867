import React from 'react';
import styled from 'styled-components';

const HashTags = styled.ul`
    display: flex;
    flex-wrap: wrap;
    /*align-items: center;*/
    gap: 8px 6px;
    overflow: hidden;
    li {
        padding: 10px 6px;
        border-radius: 10px;
        font: normal normal 500 1.2rem/1.2rem inter;
    }
    .sincerity {
        color: #009200;
        background-color: #d0fdc7;
        border: 1px dashed #59f64f;
    }
    .excitement {
        color: #860cf9;
        background-color: #f3e7fe;
        border: 1px dashed #c79bfd;
    }
    .competence {
        color: #fd8700;
        background-color: #fafcc0;
        border: 1px dashed #f6e500;
    }
    .sophistications {
        color: #008dff;
        background-color: #e2f1ff;
        border: 1px dashed #8bc6ff;
    }
    .ruggedness {
        color: #ec0e5d;
        background-color: #fde4eb;
        border: 1px dashed #f78dae;
    }
`;

function FeatureTags(props) {
    const { tags } = props;
    const features = {
        honest: 'sincerity',
        cheerful: 'sincerity',
        trendy: 'excitement',
        young: 'excitement',
        successful: 'competence',
        technical: 'competence',
        charming: 'sophistications',
        feminine: 'sophistications',
        'upper class': 'sophistications', //elegant
        glamorous: 'sophistications',
        smooth: 'sophistications',
        tough: 'ruggedness',
        masculine: 'ruggedness',
        outdoorsy: 'ruggedness',
        rugged: 'ruggedness',
    };

    return (
        <HashTags>
            {tags.map((tag, index) => {
                if (index < 6)
                    return (
                        <li className={features[tag.toLowerCase()]} key={index}>
                            {'#' + tag}
                        </li>
                    );
            })}
        </HashTags>
    );
}

export default FeatureTags;
