import MainContainer from '../components/MainContainer';
import Register from '../modules/auth/Register';
import Layout from '../modules/common/Layout';

const RegisterPage = () => {
    return (
        <Layout>
            <MainContainer>
                <Register />
            </MainContainer>
        </Layout>
    );
};

export default RegisterPage;
