import React from 'react';
import MainContainer from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import ContractView from '../mobile/ContractView';

function ContractMobile() {
    return (
        // <Layout>
        // <MainContainer>
        <ContractView />
        // </MainContainer>
        // </Layout>
    );
}

export default ContractMobile;
