import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getCelebList } from '../../queries/MyCelebCodeList';
import Error from '../../components/contents/Error';
import Loading from '../../components/contents/Loading';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

const LeftMenu = () => {
    const celebId = useParams().id;
    const { data, isLoading, isError, error } = useQuery(['getCelebList'], () => getCelebList());
    const [show, setShow] = useState({
        view: false,
        id: null,
    });

    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    const RegisterModelPage = () => {
        window.location.href = '/my-celebs/create-celeb';
    };

    const modelList = data.map((model, index) => (
        <li
            key={index}
            className={'border-bottom ' + (celebId == model.id ? 'select' : '')}
            onMouseOver={() => setShow({ view: true, id: model.id })}
            onMouseLeave={() => setShow({ view: false, id: null })}
        >
            <a href={`/my-celebs/${model.id}`}>{model.pen_name}</a>
            {/*<Link to={`/my-celebs/${model.id}`}>{model.pen_name}</Link>*/}
            <DeleteButton modelId={model.id} show={show} />
            {/*{model.messageCount ? <span>{model.messageCount}</span> : <></>}*/}
        </li>
    ));

    return (
        <div className="side-bar pt-60 pb-60">
            <button type="button" className="btn btn-sidebar ml-20 mb-20 border-bottom" onClick={RegisterModelPage}>
                Register Celeb
            </button>
            <ul>
                <li className="border-bottom">{data.length ? '' : 'No Celeb'}</li>
                {modelList}
            </ul>
        </div>
    );
};

const DeleteButton = props => {
    const { modelId, show } = props;

    const deleteProject = () => {
        console.log('modelId project : ', modelId);
    };

    return (
        <img
            src="/images/close-icon-black.svg"
            alt="delete"
            className={classNames('delete', { selected: show.view && show.id == modelId })}
            onClick={deleteProject}
        />
    );
};

export default React.memo(LeftMenu);
