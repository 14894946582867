import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterInput } from '../auth/RegisterInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CREATE_PROJECT_MODAL, LARGE_MODAL, setAlertModal, setModal } from './api/action';
import { useMutation, useQuery } from 'react-query';
import { createProject } from '../../queries/Project';
import { GetADPostingCountryCode } from '../../queries/GetADPostingCountryCode';
import Loading from '../../components/contents/Loading';
import Error from '../../components/contents/Error';
import classNames from 'classnames';
import Progressbar from '../../components/progressbar/Progressbar';
import styled from 'styled-components';
import Fade from '../../components/styled-components/Fade';
import { useModal } from './ModalUtils';

const ModalBackground = styled.div`
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    animation: ${props => (props.mount ? 'fadein 400ms' : 'fadeout 400ms')};
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
`;

const schema = yup
    .object({
        title: yup.string().required('Please enter a Title'),
        product: yup.string().required('Please enter a Product'),
        advertisement_client: yup.string().required('Please enter a Advertisement Client'),
        ad_posting_countries: yup.string().required('Please select a Ad Posting Countries'),
        brand_name: yup.string().required('Please enter a Brand Name'),
        brand_webpage: yup.string(),
    })
    .required();

const ModalCreateProject = prop => {
    const { data, error, isError, isLoading } = useQuery(['GetADPostingCountryCode'], GetADPostingCountryCode);
    const [postingCountriesArray, setPostingCountriesArray] = useState([]);
    const { content, title } = prop;
    const userData = useSelector(state => state.user);
    const createProjectModalState = useSelector(state => state.modal.createProject);
    const temp = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const { hideModal } = useModal(CREATE_PROJECT_MODAL);

    const showAlertModal = () => {
        dispatch(
            setAlertModal({
                type: LARGE_MODAL,
                data: {
                    status: true,
                    title: 'Congratulations!',
                    content:
                        'Successfully created your project. You can get recommended models based on your references. Use feature weight bars.',
                    buttonText: 'Send',
                    buttonFunction: () => {
                        dispatch(
                            setAlertModal({
                                type: LARGE_MODAL,
                                data: {
                                    status: false,
                                },
                            })
                        );
                        window.location.href = '/project';
                    },
                },
            })
        );
    };

    const mutation = useMutation(async data => createProject({ userID: userData.id, data: data }), {
        onSuccess: async res => {
            hideModal();
            window.location.href = '/project';
            //showAlertModal();
            // window.location.reload();
        },
        onError: err => {
            switch (err.response.status) {
                case 401:
                    console.log(err);
                    return;
                case 301:
                    console.log(err);
                    return;
                default:
                    console.log(err);
                    return;
            }
        },
    });
    const {
        register,
        setValue,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const appendCountry = event => {
        let postingCountries = [...postingCountriesArray];

        if (!postingCountries.find(element => element === event.target.value) && event.target.value !== '') {
            postingCountries.push(event.target.value);
            setPostingCountriesArray(() => postingCountries);
        }
    };

    const removeCountry = event => {
        let postingCountries = postingCountriesArray;
        let filtered = postingCountries.filter(element => element !== event.target.value);

        setPostingCountriesArray(filtered);
    };

    const onSubmit = data => {
        if (postingCountriesArray.length === 0) {
            alert('Please select a Ad Posting Countries');
            return false;
        }

        data.ad_posting_countries = postingCountriesArray.map(value => Number(value));

        delete data.select;
        mutation.mutate(data);
    };
    if (isLoading) return <Loading />;
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <Fade status={createProjectModalState.status}>
            <div className="modal">
                <div className="modal-dialog create-project">
                    <h3 className="mb-40">{title}</h3>
                    <i onClick={hideModal} className="icon-cancel"></i>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-content">
                            <div className="modal-content-left">
                                <div className="input-group mt-40">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Project title"
                                        name="title"
                                        errorMsg={errors.title?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group mt-40">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Client of advertisement"
                                        name="advertisement_client"
                                        errorMsg={errors.advertisement_client?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group mt-40">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Brand name"
                                        name="brand_name"
                                        errorMsg={errors.brand_name?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group mt-40">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Brand webpage (optional)"
                                        name="brand_webpage"
                                        errorMsg={errors.brand_webpage?.message}
                                        defaultMsg=""
                                    />
                                </div>
                                <div className="input-group mt-40">
                                    <RegisterInput
                                        register={register}
                                        type="text"
                                        label="Product name"
                                        name="product"
                                        errorMsg={errors.product?.message}
                                        defaultMsg=""
                                    />
                                </div>
                            </div>
                            <div className="modal-content-right">
                                <div className="input-group mt-40">
                                    <label
                                        htmlFor="agencyName"
                                        className={classNames('h6', {
                                            'text-error': errors.ad_posting_countries,
                                        })}
                                    >
                                        Ad posting country
                                        {errors.ad_posting_countries && (
                                            <span className="text-error">{errors.ad_posting_countries.message}</span>
                                        )}
                                    </label>
                                    <div className="selectGroup">
                                        <select
                                            {...register('ad_posting_countries')}
                                            onChange={e => {
                                                setValue('select', postingCountriesArray.length !== 0, {
                                                    shouldValidate: true,
                                                });
                                                appendCountry(e);
                                            }}
                                            defaultValue=""
                                            //onChange={appendCountry}
                                            className={classNames('sel-locale', {
                                                'select-error': errors.ad_posting_countries,
                                            })}
                                        >
                                            <option value="" hidden disabled>
                                                Select
                                            </option>
                                            {data.map((country, index) => (
                                                <option value={country.id} key={index}>
                                                    {country.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="input-group selected mt-40">
                                    <label className="h6">Selected :</label>
                                    {postingCountriesArray.map((country, index) => (
                                        <button
                                            className="pl-10 pr-10"
                                            type="button"
                                            onClick={removeCountry}
                                            key={index}
                                            value={country}
                                            style={{ width: 'auto' }}
                                        >
                                            {data[country - 1].label}
                                        </button>
                                    ))}
                                </div>
                                {/*<div className="input-group">
                                <Progressbar register={register} name="feature.exciting" title="Exciting" value={5} />
                            </div>
                            <div className="input-group mt-20">
                                <Progressbar register={register} name="feature.luxurious" title="Luxurious" value={5} />
                            </div>
                            <div className="input-group mt-20">
                                <Progressbar register={register} name="feature.reliable" title="Reliable" value={5} />
                            </div>
                            <div className="input-group mt-20">
                                <Progressbar register={register} name="feature.sincere" title="Sincere" value={5} />
                            </div>
                            <div className="input-group mt-20">
                                <Progressbar register={register} name="feature.tough" title="Tough" value={5} />
                            </div>*/}
                                {/* <div className="input-group mt-20">
                                <label className="h6">Product MBTI: Brand Identity (optional)</label>
                                <div className="selectGroup">
                                    <select {...register('mbti0')} className="selMBTI">
                                        <option value="E">E</option>
                                        <option value="I">I</option>
                                    </select>
                                    <select {...register('mbti1')} className="selMBTI">
                                        <option value="S">S</option>
                                        <option value="N">N</option>
                                    </select>
                                    <select {...register('mbti2')} className="selMBTI">
                                        <option value="T">T</option>
                                        <option value="F">F</option>
                                    </select>
                                    <select {...register('mbti3')} className="selMBTI">
                                        <option value="J">J</option>
                                        <option value="P">P</option>
                                    </select>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className="modal-footer mt-80">
                            <div className="btn-group">
                                <button type="submit" className="btn btn-primary">
                                    Done
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fade>
    );
};

ModalCreateProject.defaultProps = {
    title: 'Create Project',
    content: 'Successfully sent the offer to the model.',
};

export default ModalCreateProject;
