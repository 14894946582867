import React from 'react';
import { useUrl } from '../../hooks/useUrl';
import styled from 'styled-components';
import MaterialIcons from './MaterialIcons';

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0.7;
    .text {
        cursor: pointer;
        text-decoration: underline;
        color: var(--gray-200);
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6rem;
    }
`;

const ViewMore = props => {
    const { url } = props;
    const { navigateToPage } = useUrl();

    return (
        <Wrapper onClick={() => navigateToPage(url)}>
            <span className="text">ViewMore</span>
            <MaterialIcons name="arrow_forward" color="var(--gray-200)" size="16px" cursor="pointer" />
        </Wrapper>
    );
};

export default ViewMore;
