import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { getOfferImage } from '../../queries/ConfirmOffer';
import { advertisementResult } from '../../queries/Project';
import Error from '../contents/Error';
import Loading from '../contents/Loading';

const Advertisement = props => {
    const { projectId, contractId } = props;
    const { data, isLoading, isError, error } = useQuery(['getOfferImage'], () =>
        getOfferImage({ contractId: contractId })
    );
    const mutation = useMutation(async () => advertisementResult({ projectId: projectId }), {
        onSuccess: res => {},
        onError: err => {
            switch (err.response.status) {
                default:
                    console.log('default');
                    return;
            }
        },
    });

    const saveAdvertisement = () => {
        if (!projectId) return;
        mutation.mutate();
    };

    if (isLoading) {
        return <Loading />;
    }
    if (!data) return <></>;
    if (isError) return <Error errMsg={error.message} />;

    return (
        <>
            {data.map((portfolio, index) => (
                <div className="advertisement" key={index}>
                    <h5>Advertisement</h5>
                    <div className="inner-box">
                        <div className="fl">
                            <img src={portfolio.portfolio.file} style={{ width: '320px', height: '320px' }} />
                            {/*<img src="https://via.placeholder.com/320x320" alt="advertisement" />*/}
                        </div>
                        <div className="result fl">
                            <h5>Advertisement Result</h5>
                            <div className="result-box">
                                <span>Impressions : </span>
                                <input type="number" placeholder="0" name="impression" />
                            </div>
                            <div className="result-box">
                                <span>Click Rate : </span>
                                <input type="number" placeholder="0" name="rate" />
                            </div>
                            <div className="button-group" onClick={saveAdvertisement}>
                                <button type="submit" className="btn-outline-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Advertisement;
