import axios from 'axios';
import { privateClient } from '../utils/client';

export const createPortfolio = async data => {
    const response = await privateClient.post('/api/v1/celeb/portfolio/', data);

    return response.data;
};

export const deletePortfolio = async portfolioId => {
    const response = await privateClient.delete(`/api/v1/celeb/portfolio/${portfolioId}`);

    return response.data;
};
