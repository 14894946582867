import React, { useState } from 'react';
import styled from 'styled-components';
import Pagination from '../styled-components/Pagination';
import CelebProfile from '../celebs/CelebProfile';
import SortArea from '../celebs/SortArea';
import Section from '../layout/Section';
import { useGetCelebList } from '../../hooks/getCelebList';
import PublicCelebProfile from './PublicCelebProfile';
import Loading from '../contents/Loading';

const defaultCelebQueryState = {
    page: 1,
    //o: 'instagram_followers',
    //country: 'world',
    //option: 'instagram_followers',
};

const CelebCards = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 52px 32px;
    margin: 0px 100px 50px;
`;

const Title = styled.h2`
    color: var(--purple-500);
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
    margin: 100px 100px 40px;
`;

const PublicCelebList = () => {
    const [query, setQuery] = useState(defaultCelebQueryState);
    const { data, isLoading, fetchData } = useGetCelebList(query);

    const selectPage = page => {
        setQuery({ ...query, page: page });
    };

    if (isLoading) return <Loading />;

    return (
        <>
            <Section>
                <Title>Popular Celebs</Title>
                {/*<SortArea query={query} setQuery={setQuery} />*/}
            </Section>
            <Section>
                <CelebCards>
                    {data.results?.map(celeb => (
                        <PublicCelebProfile key={celeb.id} celeb={celeb} selectedCountry={query.country} />
                    ))}
                </CelebCards>
            </Section>
            <Section>
                <Pagination setPage={selectPage} activePage={query.page} lastPage={Math.ceil(data.count / 9)} />
            </Section>
        </>
    );
};

export default PublicCelebList;
