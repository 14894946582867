import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import MaterialIcons, { MaterialIconsOutlined } from '../styled-components/MaterialIcons';
import CelebDetailSection from './CelebDetailSection';
import GoogleTrendsMap from './GoogleTrendsMap';

const GoogleTrendsContents = styled.div`
    .page-subtitle {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--gray-900, #252525);
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem; /* 100% */
        margin-top: 72px;
        margin-bottom: 24px;
    }
    .trends-data {
        display: grid;
        grid-template-columns: 1fr 3fr;
        .cards {
            display: grid;
            gap: 28px;
            grid-template-rows: repeat(3, 1fr);
        }
        .chart {
            position: relative;
            .title {
                color: var(--gray-900, #252525);
                font-family: Inter;
                font-size: 2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2rem; /* 100% */
                margin-left: 20px;
                /*margin-top*/
            }
            .legend {
                margin-top: 4px;
                display: flex;
                justify-content: flex-end;
                color: var(--gray-gray-600, #7a7a7a);
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1rem; /* 100% */
                gap: 32px;
                .group {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                }
            }
            width: 922px;
            height: 560px;
            padding: 28px;
            border-radius: 15px;
            background-color: var(--white);
            .last-update {
                position: absolute;
                right: 30px;
                bottom: 30px;
                color: var(--gray-600, #7a7a7a);
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1rem; /* 100% */
            }
        }
    }
    #widget {
        border-radius: 15px;
        overflow: hidden;
        margin-top: 40px;
    }
`;

const CardStyle = styled.div`
    width: 284px;
    height: 168px;
    padding: 24px 20px;
    border-radius: 15px;
    background-color: var(--white);
    .card-header {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--gray-400);
        .text {
            margin-left: 4px;
            color: var(--gray-900, #252525);
            font-family: Inter;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2rem; /* 100% */
        }
        .sub-text {
            color: var(--gray-900, #252525);
            font-family: Inter;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 133.333% */
            margin-left: auto;
            margin-right: 4px;
        }
    }
    .card-contents {
        margin-top: 12px;
        .score {
            color: var(--gray-900, #252525);
            font-family: Inter;
            font-size: 4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 4rem;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .diff {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: end;
            color: var(--gray-900, #252525);
            .text {
                font-family: Inter;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2rem;
                text-align: right;
                margin-left: 4px;
            }
        }
    }
`;

const Card = props => {
    const { score, text, subText, diff, icon } = props;
    return (
        <CardStyle>
            <div className="card-header">
                {icon}
                <span className="text">{text}</span>
                <span className="sub-text">{subText}</span>
            </div>
            <div className="card-contents">
                <p className="score">{score}</p>
                <span className="diff">
                    <MaterialIcons name="arrow_circle_up" size="14px" color="var(--gray-900)"></MaterialIcons>
                    <span className="text">{diff}%</span>
                </span>
            </div>
        </CardStyle>
    );
};

const GoogleTrendsChart = props => {
    const { data } = props;

    const combinedData = data.graph_for_celeb.data.map((celebDataPoint, index) => ({
        date: celebDataPoint.date,
        celebValue: celebDataPoint.value * 10 + 10,
        anchorValue: data.graph_for_anchor.data[index].value * 10 + 10,
    }));

    return (
        <LineChart
            width={880}
            data={combinedData}
            layout="horizontal"
            height={480}
            margin={{ top: 50, right: 50, left: 50, bottom: 50 }}
        >
            <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} strokeOpacity={0.5} />
            <XAxis hide={true} dataKey="date" />
            <YAxis tick={{ fontSize: 10, fill: '#C2C2C2' }} axisLine={false} tickLine={false} />
            <Tooltip />
            {/*<Legend />*/}
            <Line dot={false} strokeWidth={3} type="monotone" dataKey="anchorValue" stroke="#C2C2C2" />
            <Line dot={false} strokeWidth={3} type="monotone" dataKey="celebValue" stroke="#EF3A74" />
            {/*<Line
                dot={false}
                strokeWidth={3}
                //type="monotone"
                data={data.graph_for_celeb.data}
                dataKey="value"
                stroke="#C2C2C2"
                name={data.graph_for_celeb.name}
                />
                <Line
                dot={false}
                strokeWidth={3}
                //type="monotone"
                data={data.graph_for_anchor.data}
                dataKey="value"
                stroke="#EF3A74"
                name={data.graph_for_anchor.name}
            />*/}
        </LineChart>
    );
};

const GoogleTrends = props => {
    const { trendsData } = props;
    const currentDate = new Date();

    // 오늘 날짜를 문자열로 변환 (예: "2023-09-09")
    const todayDateString = currentDate.toISOString().slice(0, 10);

    // 3년 전의 날짜 계산
    const threeYearsAgoDate = new Date(currentDate);
    threeYearsAgoDate.setFullYear(currentDate.getFullYear() - 3);

    // 3년 전의 날짜를 문자열로 변환 (예: "2020-09-09")
    const threeYearsAgoDateString = threeYearsAgoDate.toISOString().slice(0, 10);

    const DataExploration = <MaterialIcons name="data_exploration" color="var(--crimson-500)" size="24px" />;
    const Public = <MaterialIcons name="public" color="var(--blue-400)" size="24px" />;

    return (
        <CelebDetailSection backgroundColor="var(--gray-200)" fontColor="var(--gray-900)" title="Fans Analysis">
            <GoogleTrendsContents>
                <p className="page-subtitle">
                    <MaterialIcons name="insights" color="var(--crimson-500)" size="24px"></MaterialIcons>Google Trends
                </p>
                <div className="trends-data">
                    <div className="cards">
                        <Card
                            score={Math.floor(trendsData.score.recent_1w.value * 10 + 10)}
                            diff={trendsData.score.recent_1w.diff}
                            text="Score"
                            icon={DataExploration}
                        />
                        <Card
                            score={Math.floor(trendsData.score.recent_1y.value * 10 + 10)}
                            diff={trendsData.score.recent_1y.diff}
                            text="Average"
                            subText="Past 12 months"
                            icon={Public}
                        />
                        <Card
                            score={Math.floor(trendsData.score.recent_3m.value * 10 + 10)}
                            diff={trendsData.score.recent_3m.diff}
                            text="Average"
                            subText="Past 3 months"
                            icon={Public}
                        />
                    </div>
                    <div className="chart">
                        <p className="title">Interest over time</p>
                        <div className="legend">
                            <span className="group">
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: '#EC0E5D',
                                    }}
                                />
                                {trendsData.graph_for_celeb.name}
                            </span>
                            <span className="group">
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: '#C2C2C2',
                                    }}
                                />
                                {trendsData.graph_for_anchor.name}
                            </span>
                        </div>
                        <GoogleTrendsChart data={trendsData} />
                        <span className="last-update">last updated : </span>
                    </div>
                </div>
                <div id="widget">
                    <GoogleTrendsMap
                        type="GEO_MAP"
                        keyword={decodeURIComponent(trendsData.key)}
                        url="https://ssl.gstatic.com/trends_nrtr/2051_RC11/embed_loader.js"
                        startDate={threeYearsAgoDateString}
                        endDate={todayDateString}
                    />
                </div>
            </GoogleTrendsContents>
        </CelebDetailSection>
    );
};

export default GoogleTrends;
