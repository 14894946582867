import axios from 'axios';
import { publicClient } from '../utils/client';

export const getDemoProductList = async () => {
    const response = await publicClient.get('/api/v1/samples/product/');

    return response.data.data;
};

export const getDemoCelebList = async productId => {
    if (productId) {
        const response = await publicClient.get(`/api/v1/samples/celeb/?product=${productId}`);

        return response.data.data;
    } else {
        return;
    }
};

export const getDemoResult = async (productId, celebId) => {
    if (productId && celebId) {
        const response = await publicClient.get(`/api/v1/samples/result/?product=${productId}&celeb=${celebId}`);

        return response.data.data;
    } else {
        return;
    }
};
