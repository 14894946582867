import React from 'react';
import styled from 'styled-components';

const IconStyle = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.size};
    width: ${props => props.size};
    height: ${props => props.size};
    cursor: ${props => props.cursor};
`;
/**
 * @name: icon name (ex:'favorite');
 * @color: icon color (ex:'red');
 * @size: icon size (ex:'24px');
 */
const MaterialIcons = props => {
    const { name, color, size, cursor, ...options } = props;
    return (
        <IconStyle className="material-icons" color={color} size={size} cursor={cursor} {...options}>
            {name}
        </IconStyle>
    );
};

/**
 * @name: icon name (ex:'favorite');
 * @color: icon color (ex:'red');
 * @size: icon size (ex:'24px');
 */
export const MaterialIconsOutlined = props => {
    const { name, color, size, cursor, ...options } = props;
    return (
        <IconStyle className="material-icons-outlined" color={color} size={size} cursor={cursor} {...options}>
            {name}
        </IconStyle>
    );
};

MaterialIcons.defaultProps = {
    size: '24px',
    color: '#474747',
    cursor: 'default',
};
MaterialIconsOutlined.defaultProps = {
    size: '24px',
    color: '#474747',
    cursor: 'default',
};

export default MaterialIcons;
