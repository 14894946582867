import axios from 'axios';
import { privateClient } from '../utils/client';

const result = {
    id: 2,
    title: 'stirng',
    product: 'stirng',
    contract_id: 2,
    new_message_count: 2,
    advertisement_client: 'stirng',
    ad_posting_countries: [
        { id: 1, label: 'korea' },
        { id: 2, label: 'japan' },
    ],
    brand_name: 'stirng',
    brand_webpage: 'stirng',
    mbti: 'ENFJ',
    features: [
        { id: 1, display: 'Fearture 1', value: 60 },
        { id: 2, display: 'Fearture 2', value: 40 },
        { id: 3, display: 'Fearture 3', value: 30 },
        { id: 4, display: 'Fearture 4', value: 10 },
        { id: 5, display: 'Fearture 5', value: 20 },
        { id: 6, display: 'Fearture 6', value: 20 },
        { id: 7, display: 'Fearture 7', value: 30 },
        { id: 8, display: 'Fearture 8', value: 80 },
        { id: 9, display: 'Fearture 9', value: 90 },
    ],
    model: {
        id: 1,
        selected_photo: {
            id: 1,
            photo: 'stirng',
        },
        impression: 350,
        click_rate: 540,
    },
    status: 0, //0:Casting, 1:Documenting, 2:Posting, 3:Completed
};

// export const getProjectList = async ({ userID }) => {
//     //const response = await privateClient.get(`/user/${userID}/project/${projectId}`);

//     return result;
//     // return response.data;
// };

export const getProjectDetail = async ({ userId, projectId }) => {
    const response = await privateClient.get(`/api/v1/user/${userId}/project/${projectId}/`);

    return response.data;
};

export const getRecommendedCelebs = async ({ projectId }) => {
    const response = await privateClient.get(`/api/v1/project/${projectId}/recommended-celebs/`);

    return response.data;
};

export const getProjectFeatures = async ({ projectId }) => {
    const response = await privateClient.get(`/api/v1/project/${projectId}/feature/`);

    return response.data;
};

export const putProjectFeatures = async ({ projectId, data }) => {
    //const temp = [
    //    { project: projectId, feature: 17, value: 5 },
    //    { project: projectId, feature: 16, value: 5 },
    //    { project: projectId, feature: 15, value: 5 },
    //    { project: projectId, feature: 14, value: 5 },
    //    { project: projectId, feature: 5, value: 5 },
    //    { project: projectId, feature: 4, value: 5 },
    //    { project: projectId, feature: 3, value: 5 },
    //    { project: projectId, feature: 2, value: 5 },
    //    { project: projectId, feature: 1, value: 5 },
    //];
    //await privateClient.post(`/project/${projectId}/feature/`, temp);
    if (!data) {
        throw new Error('Not user credentials');
    }

    await privateClient.put(`/api/v1/project/${projectId}/feature/`, data);
};

export const getProjectModelList = async ({ features }) => {
    let gender = { id: 1, label: 'Male' };

    const modelListResult = [];
    for (let index = 1; index < 101; index++) {
        const rand = Math.floor(Math.random() * 100);
        const ctr = Math.floor(Math.random() * 100);
        const age = Math.floor(Math.random() * 65);
        gender = gender.id == 1 ? { id: 2, label: 'Female' } : { id: 1, label: 'Male' };

        const arr = {
            id: index,
            selected: true,
            created: '2022-11-14T13:34:11.532591+09:00',
            pen_name: 'Woo-min ' + index,
            family_name: 'BYUN',
            given_name: 'Woo-min',
            gender: gender,
            birth_year: 2022 - age,
            age: age,
            photos: [
                {
                    id: 1688,
                    is_main: true,
                    file: 'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%EB%B3%80%EC%9A%B0%EB%AF%BC.png',
                    file_w256:
                        'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%EB%B3%80%EC%9A%B0%EB%AF%BC_BhjMFMt.png',
                    file_w512:
                        'https://aligo-acp.s3.amazonaws.com/deploy/celeb/portfolio/%EB%B3%80%EC%9A%B0%EB%AF%BC_5oMOzTt.png',
                    type: 'photo',
                    heart_count: 0,
                    heart_id_of_login_user: null,
                    click: 0,
                    celeb: 275,
                    ctr: ctr,
                    cpm: 100.0,
                    features: [
                        {
                            id: 6580,
                            name: 'smart',
                            value: 5.8,
                        },
                        {
                            id: 6579,
                            name: 'cool',
                            value: 4.4,
                        },
                        {
                            id: 6578,
                            name: 'cute',
                            value: 3.3,
                        },
                        {
                            id: 6577,
                            name: 'gorgeous',
                            value: 2.8,
                        },
                        {
                            id: 6576,
                            name: 'luxury',
                            value: 7.1,
                        },
                    ],
                },
            ],
            popularity: rand,
        };
        modelListResult.push(arr);
    }

    return modelListResult;
};
