import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import comp from './comp.json';
import circular from './circular.json';

export const LoadingBar = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: containerRef.current,
            animationData: comp,
            loop: true,
            autoplay: true,
        });
    }, []);

    return <div ref={containerRef} />;
};

export const Circular = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: containerRef.current,
            animationData: circular,
            loop: true,
            autoplay: true,
        });
    }, []);

    return <div ref={containerRef} />;
};
