import React, { useState } from 'react';
import styled from 'styled-components';
import ProjectsList from './ProjectsList';
import { useProjectList } from '../../hooks/getCode';
import Button from '../styled-components/Button';
import { useUrl } from '../../hooks/useUrl';
import ProgressBar, { ProgressArea, ProgressDetail } from '../styled-components/ProgressBar';

const PageWrapper = styled.div`
    display: flex;
`;

const ProjectDetail = styled.div`
    width: 864px;
    /*padding-top: 100px;*/
    margin: 100px auto 0;
    .center-area {
        img {
            margin: 0 auto;
        }
        button {
            margin: 0 auto;
        }
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 40px;
        .text {
            margin: 8px auto 40px;
            /*margin-top: 8px;
            margin-bottom: 40px;*/
            color: var(--gray-800);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 100% */
        }
    }
    .title {
        color: var(--gray-900);
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 100% */
        margin-bottom: 40px;
    }

    .progress-area {
        margin-top: 20px;
        .left {
            /*padding: 16px 26px;
            width: 372px;
            margin-right: 20px;
            border-radius: 15px;
            border: 1px solid var(--gray-300);
            background: var(--white, #fff);
            height: 100px;*/
        }
        .right {
            display: inline-block;
            padding: 16px 26px;
            width: 472px;
            border-radius: 15px;
            border: 1px solid var(--gray-300);
            background: var(--white, #fff);
            height: 100px;
        }
    }
    /*margin-top: 100px;*/
`;

const ProjectTable = styled.div`
    margin: 0 auto;
    padding: 32px 36px;
    border-radius: 15px;
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    display: flex;
    flex-direction: column;
    gap: 32px;
    .row {
        display: flex;
        gap: 20px;
        .field-name {
            width: 180px;
            color: var(--gray-800);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.8rem; /* 120% */
        }
        .description {
            color: var(--gray-800);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.8rem; /* 120% */
        }
    }
`;

const Projects = () => {
    const { data, refetch } = useProjectList();
    const [selectedProject, setSelectedProject] = useState(undefined);
    const { navigateToPage } = useUrl();

    const handleSelectedProject = project => {
        setSelectedProject(project);
    };

    return (
        <PageWrapper>
            <ProjectsList refetch={refetch} data={data} selectHandler={handleSelectedProject} />
            {selectedProject ? (
                <ProjectDetail>
                    <h4 className="title">CeleVu - advertisement</h4>
                    <ProjectTable>
                        <div className="row">
                            <span className="field-name">Project Detail</span>
                            <span className="description">{selectedProject?.detail}</span>
                        </div>
                        <div className="row">
                            <span className="field-name">Ad posting country</span>
                            <span className="description">
                                {selectedProject.countries?.map((country, index) => {
                                    if (index < selectedProject.countries.length - 1) {
                                        return country.label + ', ';
                                    }
                                    return country.label;
                                })}
                            </span>
                        </div>
                        <div className="row">
                            <span className="field-name">Posting schedule</span>
                            <span className="description">{selectedProject.schedule?.label}</span>
                        </div>
                        <div className="row">
                            <span className="field-name">Ad posting period</span>
                            <span className="description">{selectedProject.budget?.label}</span>
                        </div>
                        <div className="row">
                            <span className="field-name">Product webpage</span>
                            <span className="description">
                                {selectedProject.webpage ? selectedProject.webpage : 'N/A'}
                            </span>
                        </div>
                    </ProjectTable>
                    <div className="progress-area">
                        {/*<ProgressArea status={selectedProject.status} />*/}
                        <ProgressArea status={selectedProject.status} />
                        {/*<ProgressDetail status={'status2'} />*/}
                        {/*<ProgressDetail status={selectedProject.status} />*/}
                        {/*<div className="right">*/}
                        {/*<ProgressBar status={'status2'} />*/}
                        {/*<ProgressBar status={selectedProject.status} />*/}
                        {/*</div>*/}
                    </div>
                </ProjectDetail>
            ) : (
                <ProjectDetail>
                    <div className="center-area">
                        <img width="80" src="/images/empty-project.png" />
                        <span className="text">Getting started your project!</span>
                        <Button size="md" variant="gradient" onClick={() => navigateToPage('/project/create/')}>
                            Create Project
                        </Button>
                    </div>
                </ProjectDetail>
            )}
        </PageWrapper>
    );
};

export default Projects;
