import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getProjects } from '../../queries/Project';

const ProjectListWrapper = () => {
    const userData = useSelector(state => state.user);
    const { param } = useParams();

    const { data, isError, error, isLoading } = useQuery(['getProjects'], () => getProjects());
    if (!data) return <></>;

    return <Sidebar list={data} selectedId={param} />;
};

const SidebarLayout = styled.div`
    width: 240px;
    min-height: 1024px;
    float: left;
    background-color: #fff;
    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #a6adb4;
        padding: 80px 0 28px 24px;
        /*padding-bottom: 28px;*/
        /*padding-left: 24px;*/
        h1 {
            font: normal normal 700 3.2rem/3.2rem inter;
            color: #202124;
        }
        .material-icons {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 32px;
            height: 32px;
        }
    }
    ul {
        li:first-child {
        }
        li {
            padding: 12px 32px;
            display: flex;
            border-bottom: 1px solid var(--gray-500);
            align-items: center;
            height: 60px;
            color: var(--gray-800);
            font-family: Inter;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 100% */
            cursor: pointer;
            &:hover {
                /*background-color: rgba(255, 77, 77, 0.2);*/
                /*border-bottom: 1px solid var(--purple-200);*/
                background-color: var(--purple-050);
            }
        }
        li:last-child {
            border-bottom: none;
        }
        .selected {
            background-color: var(--purple-050);
            color: var(--gray-800);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 100% */
        }
    }
`;

const Sidebar = ({ list, selectedId }) => {
    const navigate = useNavigate();
    const onClick = projectId => {
        navigate('/project/' + projectId); // 페이지 이동
    };

    return (
        <SidebarLayout>
            <div className="title">
                <h1>Project</h1>
                <i className="material-icons">add</i>
            </div>
            <ul>
                {list.map(project => (
                    //<a href={'/project/' + project.id}>
                    <li
                        onClick={() => onClick(project.id)}
                        key={project.id}
                        className={project.id == selectedId ? 'selected' : ''}
                    >
                        {project.label}
                    </li>
                    //</a>
                ))}
            </ul>
        </SidebarLayout>
    );
};

export default Sidebar;
