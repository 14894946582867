import axios from 'axios';
import { privateClient } from '../utils/client';

//const result = [
//    { id: 1, modelName: '1', messageCount: 0 },
//    { id: 2, modelName: 'kim gil dong2', messageCount: 0 },
//    { id: 3, modelName: 'kim gil dong3', messageCount: 0 },
//    { id: 4, modelName: 'kim gil dong4', messageCount: 0 },
//    { id: 5, modelName: 'kim gil dong5', messageCount: 0 },
//    { id: 6, modelName: 'kim gil dong6', messageCount: 0 },
//    { id: 7, modelName: 'kim gil dong7', messageCount: 0 },
//    { id: 8, modelName: 'kim gil dong8', messageCount: 0 },
//    { id: 9, modelName: 'kim gil dong9', messageCount: 0 },
//    { id: 10, modelName: 'kim gil dong10', messageCount: 3 },
//];
//export const getCelebCodeList = async ({ userID }) => {
//const response = await privateClient.get(`/user/${userID}/celeb/code/`);
//return result;
//return response.data;
//};

export const getCelebList = async () => {
    const response = await privateClient.get('/api/v1/celeb/user/');

    return response.data;
};
