import React from 'react';
import styled from 'styled-components';
import { LoadingBar } from '../../animation/Animation';

const LoadingStyle = styled.div`
    width: 100%;
    height: 100%;
    div {
        margin: auto;
        width: 100%;
        height: 100%;
    }
`;

function Loading({ children }) {
    return <LoadingStyle>{children}</LoadingStyle>;
}

Loading.defaultProps = {
    children: <LoadingBar />,
};
export default Loading;
