import React from 'react';
import Arrow from '../../modules/common/Arrow';
import Heart from '../../modules/common/Heart';
import Charts from './Charts';
import ModelChart from './ModelChart';
import styled from 'styled-components';

const CursorPointer = styled.div`
    cursor: pointer;
`;

const ProjectModelCard = props => {
    const { size, height, item } = props;

    const onClick = () => {
        window.location.href = `/celebs/${item.id}`;
    };

    return (
        <div className="model-card">
            <div className="card-content">
                <CursorPointer className="img" onClick={onClick}>
                    <img src={item.photos[0].file} />
                </CursorPointer>
                <div className="card-header" onClick={onClick}>
                    <h5>{item.pen_name}</h5>
                    <Heart level="U" />
                </div>
                <div className="card-body">
                    <span>
                        Popularity <b>{item.popularity}</b>
                    </span>
                    <span>
                        CPM <b>134</b>
                    </span>
                    <span>
                        CTR <b>7%</b>
                    </span>
                    <div className="card-chart mt-20">
                        <Charts
                            data={item.photos[0].features}
                            height={height}
                            size={size}
                            fontSize={'10px'}
                            strokeWidth={1}
                            strokeColor={['#5A607F']}
                            fillColor={['#5A607F43']}
                        />
                        {/*<ModelChart height={height} size={size} fontSize={'10px'} strokeWidth={1} strokeColor={['#5A607F']} fillColor={['#5A607F43']} />*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

ProjectModelCard.defaultProps = {
    size: 55,
    height: 130,
};
export default ProjectModelCard;
