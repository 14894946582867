import React, { useEffect, useRef, useState } from 'react';
import MaterialIcons from './MaterialIcons';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    width: ${props => sizeStyles[props.size].width || sizeStyles.default.width};
`;

const SelectBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${props => colorStyles[props.variant] || colorStyles.default}
    ${props => sizeStyles[props.size] || sizeStyles.default}
    ${props => (props.isError ? colorStyles.error : '')}
    cursor: pointer;
    .selected-option {
        display: flex;
        align-items: center;
        gap: 8px;
        .selected-label {
            color: var(--gray-800);
        }
        .placeholder {
            color: var(--gray-400);
            font-style: normal;
            font-weight: 400;
        }
    }
`;

const OptionList = styled.ul`
    cursor: pointer;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    top: 110%;
    max-height: ${props => optionHeight[props.size]?.maxHeight || optionHeight.default.maxHeight};
    width: ${props => sizeStyles[props.size].width || sizeStyles.default.width};
    ${props => colorStyles[props.variant] || colorStyles.default}
    z-index: 10;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: var(--gray-200);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--purple-100);
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: var(--purple-500);
    }
    li {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        ${props => sizeStyles[props.size] || sizeStyles.default}
        height: ${props => optionHeight[props.size]?.height || optionHeight.default.height};
        &:hover {
            background-color: var(--purple-100);
            color: var(--gray-050);
        }
    }
`;

const iconSize = {
    default: '4px',
    sm: '16px',
    md: '20px',
    lg: '24px',
    lgHalf: '24px',
};

const optionHeight = {
    default: {
        height: '32px',
        maxHeight: '192px',
    },
    sm: {
        height: '10px',
        maxHeight: '60px',
    },
    md: {
        height: '20px',
        maxHeight: '120px',
    },
    lg: {
        height: '32px',
        maxHeight: '192px',
    },
    lgHalf: {
        height: '32px',
        maxHeight: '192px',
    },
};

const sizeStyles = {
    default: {
        fontSize: '1.2rem',
        fontWeight: '500',
    },
    sm: {
        fontWeight: '500',
        fontSize: '1rem',
        lineHeight: '1rem',
        width: '100px',
        height: '16px',
        padding: '2px 6px',
    },
    md: {
        fontWeight: '500',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        width: '152px',
        height: '36px',
        padding: '10px 16px',
    },
    lg: {
        fontWeight: '400',
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        width: '520px',
        height: '60px',
        padding: '10px 16px',
    },
    lgHalf: {
        fontWeight: '400',
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        width: '248px',
        height: '60px',
        padding: '10px 16px',
    },
};
const colorStyles = {
    default: {
        backgroundColor: 'var(--gray-200)',
        border: 'none',
        borderRadius: '2px',
        color: 'var(--gray-800)',
    },
    primary: {
        backgroundColor: 'var(--gray-050)',
        border: '1px solid var(--purple-500)',
        borderRadius: '2px',
    },
    gray: {
        backgroundColor: 'var(--gray-200)',
        border: 'none',
        borderRadius: '2px',
        color: 'var(--gray-800)',
    },
    gray2: {
        borderRadius: '2px',
        border: '1px solid var(--gray-400, #C2C2C2)',
        backgroundColor: 'var(--white)',
    },
    error: {
        borderRadius: '2px',
        border: '2px solid var(--crimson-500)',
        background: 'var(--white)',
        color: 'var(--gray-800)',
    },
};

const CustomSelect = props => {
    const { optionsList, state, setState, size, variant, initValue, iconName, isError, placeholder } = props;
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);

    const isOpenHandler = () => {
        setIsOpen(!isOpen);
    };

    const onOptionClick = option => {
        //옵션 클릭시 함수
        const selectedValue = optionsList.find(item => item.value === option.value);
        setState(selectedValue);
        setIsOpen(false);
    };

    useEffect(() => {
        //select list 외부 클릭시 닫힘
        const closeSelectOnOutsideClick = event => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', closeSelectOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSelectOnOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (initValue) {
            const selectedValue = optionsList.find(item => item.value === initValue);
            setState(selectedValue);
        }
    }, [initValue, optionsList]);

    return (
        <Wrapper size={size}>
            <SelectBox size={size} variant={variant} isError={isError} onClick={isOpenHandler} ref={selectRef}>
                {state.value ? (
                    <>
                        <span className="selected-option">
                            <MaterialIcons name={iconName} size={iconSize[size]} color="var(--deactivated)" />
                            <span className="selected-label">{state.label}</span>
                        </span>
                    </>
                ) : (
                    <>
                        <span className="selected-option">
                            <MaterialIcons name={iconName} size={iconSize[size]} color="var(--deactivated)" />
                            <span className="placeholder">{placeholder}</span>
                        </span>
                    </>
                )}
                {isOpen ? (
                    <MaterialIcons name="arrow_drop_up" size={iconSize[size]} />
                ) : (
                    <MaterialIcons name="arrow_drop_down" size={iconSize[size]} />
                )}
            </SelectBox>
            {isOpen ? (
                <OptionList variant={variant} size={size}>
                    {optionsList.map((option, index) => (
                        <li key={'option' + option.value + option.id + index} onClick={() => onOptionClick(option)}>
                            {option.label}
                        </li>
                    ))}
                </OptionList>
            ) : (
                <></>
            )}
        </Wrapper>
    );
};

export default CustomSelect;
