import React from 'react';
import styled, { keyframes } from 'styled-components';

const slideAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const ViewContainer = styled.div`
    width: ${props => props.viewWidth}px;
    height: ${props => props.height}px;
    overflow: hidden;
    /*background: linear-gradient(
        90deg,
        rgba(71, 71, 71, 0.5) 0%,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0) 80%,
        rgba(71, 71, 71, 0.5) 100%
    );*/
`;

const SlideWrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: start;
    width: ${props => (props.width * props.length + props.gap * props.length) * 2}px;
    height: ${props => props.height}px;
    animation: ${slideAnimation} 30s linear infinite;
    li {
        margin-right: ${props => props.gap}px;
        width: ${props => props.width}px;
        height: ${props => props.height}px;
    }
`;

const AutoSlide = ({ viewWidth = 1440, width = 320, height = 320, gap = 32, children }) => {
    return (
        <ViewContainer viewWidth={viewWidth} height={height}>
            <SlideWrapper width={width} height={height} gap={gap} length={children.length}>
                {children}
                {children}
            </SlideWrapper>
        </ViewContainer>
    );
};

export default AutoSlide;
