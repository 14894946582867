import React from 'react';
import { CartesianGrid, Cell, Label, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import styled from 'styled-components';

const CustomizedShapeStyle = styled.g`
    circle {
        fill: ${props => props.fill};
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
    image {
        width: 100%;
        height: 100%;
    }
    text {
        fill: ${props => props.fontColor};
        z-index: 100;
    }
`;

const CustomizedShape = props => {
    const { cx, cy, size, name, celebId, gender } = props;
    const fill = gender === 1 ? '#C79BFD' : '#FBB1ED';
    const fontColor = gender === 1 ? '#860CF9' : '#F200D6';

    const onClick = () => {
        window.location.href = `/celebs/${celebId}`;
    };

    return (
        <CustomizedShapeStyle onClick={onClick} fill={fill} fontColor={fontColor}>
            <circle cx={cx} cy={cy} r={size} />
            <text x={cx} y={cy} textAnchor="middle" dy=".3em">
                {name}
            </text>
        </CustomizedShapeStyle>
    );
};

const LabsChart = props => {
    const { xState, yState, zState, chartData } = props;
    const xTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const yTicks = [0, 20, 40, 60, 80, 100];

    if (xState === undefined || yState === undefined || zState === undefined || !chartData.length) return <></>;
    return (
        <ScatterChart width={1120} height={780} margin={{ top: 0, right: 0, left: 0, bottom: 40 }}>
            <CartesianGrid stroke="#F1F1F1" fillOpacity={0} />
            <XAxis
                stroke="#A4A4A4"
                ticks={xTicks}
                tick={{ display: 'none' }}
                tickLine={false}
                type="number"
                dataKey={xState.value}
                domain={[0, 100]}
                name={xState.label}
                padding={{ left: 100, right: 100 }}
            >
                <Label value={xState.label} position="bottom" />
            </XAxis>
            <YAxis
                stroke="#A4A4A4"
                ticks={yTicks}
                tick={{ display: 'none' }}
                tickLine={false}
                type="number"
                dataKey={yState.value}
                domain={[0, 100]}
                name={yState.label}
                padding={{ top: 100, bottom: 100 }}
            >
                <Label value={yState.label} angle={-90} />
            </YAxis>
            <ZAxis
                dataKey={zState.value}
                label={{ value: zState.label, position: 'insideBottomRight' }}
                type="number"
                name={zState.label}
                range={[30, 80]}
            />
            <Tooltip filterNull={false} />
            <Scatter data={chartData} shape={<CustomizedShape />}>
                {chartData.map((celeb, index) => (
                    <Cell key={index} name={celeb.pen_name} celebId={celeb.id} />
                ))}
            </Scatter>
        </ScatterChart>
    );
};

export default LabsChart;
