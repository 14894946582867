import React from 'react';
import CreateOffer from '../modal/CreateOffer';
import CreateOfferDetail from '../modal/CreateOfferDetail';
import OfferDetail from '../modal/OfferDetail';
import CookieNotice from '../modal/CookieNotice';
import RegisterModal from '../modal/RegisterModal';
import Modals from './Modals';
import Footer from '../../components/layout/Footer';
import Nav from '../../components/nav/Nav';

const Layout = ({ children }) => {
    return (
        <>
            <Nav />
            {children}
            <Footer />
            <CookieNotice />
            <CreateOffer />
            <CreateOfferDetail />
            <OfferDetail />
            <RegisterModal />
            <Modals />
        </>
    );
};

export default Layout;
