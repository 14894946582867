import React from 'react';
import styled from 'styled-components';

const GradientTextStyle = styled.p`
    display: inline-block;
    background: linear-gradient(240.64deg, #fef3c7 0%, #860cf9 62.4%, #5900e5 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${props => fontStyle[props.fontSize] || fontStyle.default}
`;

const fontStyle = {
    default: {
        fontWeight: '700',
        fontSize: '4rem',
        lineHeight: '4rem',
    },
    sm: {
        fontWeight: '500',
        fontSize: '2rem',
        lineHeight: '2rem',
    },
    md: {
        fontWeight: '700',
        fontSize: '4rem',
        lineHeight: '4rem',
    },
    lg: {
        fontWeight: '700',
        fontSize: '8rem',
        lineHeight: '8rem',
    },
};

const GradientText = props => {
    const { children, text, fontSize, ...options } = props;
    return (
        <GradientTextStyle fontSize={fontSize} {...options}>
            {text}
            {children}
        </GradientTextStyle>
    );
};

export default GradientText;
