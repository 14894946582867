import React from 'react';

export const RegisterInput = props => {
    const { register, label, name, type, defaultMsg, errorMsg, defaultValue, isDisabled, isRequire, placeholder } =
        props;
    return (
        <>
            <label htmlFor={name} className={`h6 ${errorMsg && 'text-error'}`}>
                <span style={{ float: 'left', color: 'red', marginRight: '2px' }}>{isRequire ? '*' : ''}</span>
                {label}
            </label>
            {errorMsg ? <span className="text-error">{errorMsg}</span> : <span>{defaultMsg}</span>}
            <input
                {...register(name)}
                autoComplete="off"
                id={name}
                type={type}
                className={`input-text input-bold ${errorMsg && 'input-error'}`}
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={isDisabled}
            />
        </>
    );
};
