import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

const LargeModal = prop => {
    const { content, title } = prop;
    const AlertState = useSelector(state => state.modal.large);

    return AlertState.status ? (
        <div className="modal">
            <div className="modal-dialog modal-lg">
                <h3 className="mb-40">{AlertState.title}</h3>
                <i className="icon-cancel"></i>
                <div className="modal-content">{AlertState.content}</div>
                <div className="progress-box">
                    <label className="fearture">Featuer 1</label>
                    <span className="tooltip tips t9">9</span>
                    <div className="progress">
                        <div className="irs">
                            <span>0</span>
                            <span className="center-number">5</span>
                            <span>10</span>
                        </div>
                        <progress value="90" max="100" />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="btn-group">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                AlertState.buttonFunction();
                            }}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

LargeModal.defaultProps = {
    title: 'Create Project',
    content:
        'Successfully created your project. You can get recommended models based on your references. Use feature weight bars.',
};

export default LargeModal;
