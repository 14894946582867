import { useQuery } from 'react-query';
import { getCountriesCode, getProjectBudgetCode, getProjectCountries, getProjectSchedule } from '../queries/GetList';
import { getFeatureCode } from '../queries/GetList';
import { getProjects } from '../queries/Project';

export const useCountries = () => {
    const { data, isLoading, isError } = useQuery(['getCountriesCode'], () => getCountriesCode(), {});

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data };
};

export const useFeatureList = () => {
    const { data, isLoading, isError } = useQuery(['getFeatureCode'], () => getFeatureCode(), {});

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data };
};

export const useProjectList = () => {
    const { data, isError, isLoading, refetch } = useQuery(['getProjects'], () => getProjects());

    const fetchData = async () => {
        await refetch();
    };

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data, fetchData };
};

export const useProjectBudget = () => {
    const { data, isError, isLoading } = useQuery(['getProjectBudgetCode'], () => getProjectBudgetCode());

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data };
};

export const useProjectCountries = () => {
    const { data, isError, isLoading } = useQuery(['getProjectCountries'], () => getProjectCountries());

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data };
};

export const useProjectSchedule = () => {
    const { data, isError, isLoading } = useQuery(['getProjectSchedule'], () => getProjectSchedule());

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data };
};
