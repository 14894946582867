import { Route, Routes } from 'react-router-dom';
import CreateModel from './pages/model/CreateModel';
import Project from './pages/agency/Project';
import MyModels from './pages/model/MyModels';
import ModelDetail from './pages/ModelDetail';
import Models from './pages/Models';
import Services from './pages/Services';
import MyPage from './pages/MyPage';
import MyModelsDetail from './pages/model/MyModelsDetailPage';
import Hearts from './pages/mypage/Hearts';
import Setting from './pages/mypage/Setting';
import ContactUs from './pages/ContactUs';
import TermsOfUse from './pages/TermsOfUse';
import RegisterPage from './pages/RegisterPage';
import ContractMobile from './pages/ContractMobile';
//import SampleServiceMain from './pages/samplepage/SampleServiceMain';
import { InstagramRedirect } from './components/celebs/RedirectPage';
import FavoriteCeleb from './pages/FavoriteCeleb';
import CelevuLabs from './pages/CelevuLabs';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import CreateProjectPage from './pages/CreateProjectPage';
import LoginPage from './pages/Login';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import SchedulePage from './pages/SchedulePage';
import RedirectPage from './pages/RedirectPage';
import InstagramPage from './pages/InstagramPage';
import DemoPage from './pages/DemoPage';
import PublicCelebPage from './pages/PublicCelebPage';
import PublicCelebDetailPage from './pages/PublicCelebDetailPage';

function App() {
    return (
        <RecoilRoot>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/services" element={<Services />} />
                <Route path="/demo" element={<DemoPage />} />
                {/*<Route path="/celebs" element={<PublicCelebPage />} />*/}
                {/*<Route path="/celebs/:id" element={<PublicCelebDetailPage />} />*/}
                <Route path="/celebs" element={<Models />} />
                <Route path="/celebs/:id" element={<ModelDetail />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/contract" element={<ContractMobile />} />
                <Route path="/project" element={<Project />} />
                <Route path="/project/:id" element={<Project />} />
                <Route path="/project/create" element={<CreateProjectPage />} />
                <Route path="/my-celebs" element={<MyModels />} />
                <Route path="/favorite" element={<FavoriteCeleb />} />;
                <Route path="/labs" element={<CelevuLabs />} />;
                <Route path="/my-celebs/:id" element={<MyModelsDetail />} />
                <Route path="/my-celebs/create-celeb" element={<CreateModel />} />
                <Route path="/my-celebs/redirect" element={<InstagramRedirect />} />
                <Route path="/mypage" element={<MyPage />} />
                <Route path="/hearts" element={<Hearts />} />
                <Route path="/setting" element={<Setting />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/contact/schedule" element={<SchedulePage />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/mypage" element={<MyPage />} />
                <Route path="/redirect" element={<RedirectPage />} />
                <Route path="/instagram" element={<InstagramPage />} />
            </Routes>
        </RecoilRoot>
    );
}

export default App;
