import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CelebProfile from './CelebProfile';
import SortArea from './SortArea';
import { getCelebList } from '../../queries/CelebList';
import { useQuery } from 'react-query';
import Loading from '../contents/Loading';
import Error from '../contents/Error';
import Pagination from '../styled-components/Pagination';
import CelebFilter from './CelebFilter';
import { useCheckbox } from '../checkbox/CustomCheckbox';

const defaultCelebQueryState = {
    page: 1,
    o: 'latest',
    country: 'world',
    option: 'instagram_followers',
};

const useCelebList = query => {
    const { data, isLoading, isError, error } = useQuery(['celeb_list', query], () => getCelebList(query));

    return {
        data,
        isLoading,
        isError,
        error,
    };
};

const CelebCards = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 52px 32px;
    margin: 0px 100px 50px;
`;

const Title = styled.h2`
    color: var(--purple-500);
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
    margin: 100px 100px 40px;
`;

const CelebList = () => {
    const { selectList, SelectListHandler, ResetSelectList, isChecked } = useCheckbox();
    const [query, setQuery] = useState(defaultCelebQueryState);
    const { data, isLoading, isError, error } = useCelebList(query);

    if (isLoading) return <Loading />;
    if (isError) return <Error errMsg={error.message} />;
    if (!data) return <></>;

    const selectPage = page => {
        setQuery({ ...query, page: page });
    };

    return (
        <>
            <Title>Popular Celebs</Title>
            <CelebFilter
                query={query}
                setQuery={setQuery}
                selectList={selectList}
                SelectListHandler={SelectListHandler}
                ResetSelectList={ResetSelectList}
                isChecked={isChecked}
                //addQuery={addQuery}
            />
            <SortArea
                query={query}
                setQuery={setQuery}
                //setFemale={checkFemale}
                //setMale={checkMale}
                //selectCountryHandler={selectCountry}
                //selectOptionHandler={selectOption}
            />
            <CelebCards>
                {data.results.map(celeb => (
                    <CelebProfile key={celeb.id} celeb={celeb} selectedCountry={query.country} />
                ))}
            </CelebCards>
            <Pagination setPage={selectPage} activePage={query.page} lastPage={Math.ceil(data.count / 9)}></Pagination>
        </>
    );
};

export default CelebList;
