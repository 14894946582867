import React from 'react';

import { privateClient } from '../utils/client';

export const getLike = async query => {
    const response = await privateClient.get(`/api/v1/celeb/like/`, {
        params: query,
    });
    return response.data;
};

export const getLikeList = async () => {
    const response = await privateClient.get(`/api/v1/celeb/like/all/`);
    return response.data;
};

export const addLike = async celebId => {
    const response = await privateClient.post(`/api/v1/celeb/${celebId}/like/`);
    return response.data;
};

export const removeLike = async celebId => {
    const response = await privateClient.delete(`/api/v1/celeb/${celebId}/like/`);
    return response.data;
};
