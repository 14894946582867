import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import { Provider } from 'react-redux';

import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
      /* <color template> */
      --focus: #f00;
      --black: #000;
      --white: #fff;
      --deactivated: var(--gray-500);
      --error: var(--crimson-500);
      --gradient-02: linear-gradient(240.64deg, #fef3c7 0%, #860cf9 62.4%, #5900e5 100%);
      --gradient-06: linear-gradient(241deg, #A200B4 0%, #6A00EA 50%, #3800E0 100%);
      --crimson-900: #8C004C;
      --crimson-800: #B10052;
      --crimson-700: #C50656;
      --crimson-600: #DB0A5B;
      --crimson-500: #EC0E5D;
      --crimson-400: #EF3A74;
      --crimson-300: #F35F8D;
      --crimson-200: #F78DAE;
      --crimson-100: #FABACE;
      --crimson-050: #FDE4EB;

      --gray-900: #252525;
      --gray-800: #474747;
      --gray-700: #666666;
      --gray-600: #7A7A7A;
      --gray-500: #A4A4A4;
      --gray-400: #C2C2C2;
      --gray-300: #E5E5E5;
      --gray-200: #F1F1F1;
      --gray-100: #F6F6F6;
      --gray-050: #FBFBFB;

      --blue-900: #2039B3;
      --blue-800: #1D5AD2;
      --blue-700: #186CE5;
      --blue-600: #0C7FF9;
      --blue-500: #008DFF;
      --blue-400: #2D9EFF;
      --blue-300: #58AFFF;
      --blue-200: #8BC6FF;
      --blue-100: #B9DBFF;
      --blue-050: #E2F1FF;

      --pink-900: #7C00AC;
      --pink-800: #A200B4;
      --pink-700: #B600B8;
      --pink-600: #CC00BB;
      --pink-500: #DC00BE;
      --pink-400: #EC00CD;
      --pink-300: #F200D6;
      --pink-200: #F876E1;
      --pink-100: #FBB1ED;
      --pink-050: #FFE0F8;
      
      --purple-900: #3800E0;
      --purple-800: #5900E5;
      --purple-700: #6A00EA;
      --purple-600: #7B01F2;
      --purple-500: #860CF9;
      --purple-400: #9B47FC;
      --purple-300: #AF6EFD;
      --purple-200: #C79BFD;
      --purple-100: #DDC4FD;
      --purple-050: #F3E7FE;
      /* <color template> */
  }

  * {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 0;
  }

  html {
    font-family: Inter;
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-variant: normal;
    font-family: Inter;
    user-select: none;
    -webkit-user-drag: none;
  }

  a {
    text-decoration: none;
    color: var(--gray-900)
  }
  button {
    cursor: pointer;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
    background: none;
  }
  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    border: none;
}
input[type='file'] + label {
    cursor: pointer;
}
  ul,ol,li {
    list-style: none outside;
  }

  input {
    outline: none;
  }

  .wrapper {
    margin: 0 auto;
    /*background-color: var(--gray-050);*/
    height: 100%;
  }
`;
const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <CookiesProvider>
                        <BrowserRouter>
                            <GlobalStyle />
                            <App />
                        </BrowserRouter>
                    </CookiesProvider>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
);

//reportWebVitals();
