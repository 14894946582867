import React from 'react';
import styled from 'styled-components';
import { RADIUS, MAIN_COLOR, WHITE_COLOR, GRAY_COLOR, BLACK_COLOR } from '../../utils/color';

const sizeStyles = {
    default: {
        width: '164px',
        height: '40px',
        font: 'normal normal 500 1.5rem/1.5rem inter',
    },
    sm: {
        width: '120px',
        height: '36px',
        font: 'normal normal 500 1.5rem/1.5rem inter',
    },
    md: {
        width: '164px',
        height: '40px',
        font: 'normal normal 700 1.5rem/1.8rem Roboto',
    },
    lg: {
        width: '360px',
        height: '40px',
        font: 'normal normal 700 1.5rem/1.8rem Roboto',
    },
};

const colorStyles = {
    default: {
        backgroundColor: 'var(--purple-500)',
        color: 'var(--white)',
        '&:hover': {
            border: '1px solid var(--purple-500)',
        },
    },
    contained: {
        backgroundColor: MAIN_COLOR,
        color: WHITE_COLOR,
        '&:hover': {
            border: '1px solid ' + MAIN_COLOR,
            backgroundColor: 'transparent',
        },
    },
    outlined: {
        backgroundColor: 'transparent',
        color: MAIN_COLOR,
        border: '1px solid ' + MAIN_COLOR,
        '&:hover': {},
    },
    gray: {
        backgroundColor: 'transparent',
        color: GRAY_COLOR,
        border: '1px solid var(--gray-500)',
        '&:hover': {
            backgroundColor: GRAY_COLOR,
            color: WHITE_COLOR,
        },
    },
    black: {
        backgroundColor: BLACK_COLOR,
        color: WHITE_COLOR,
        '&:hover': {
            border: '1px solid ' + BLACK_COLOR,
            backgroundColor: WHITE_COLOR,
            color: BLACK_COLOR,
        },
    },
};

const ButtonStyle = styled.button`
    color: ${props => props.color || WHITE_COLOR};
    ${props => colorStyles[props.variant] || colorStyles.default}
    ${props => sizeStyles[props.size] || sizeStyles.default}
    border-radius: ${props => props.borderRadius || RADIUS};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    /*margin: auto;*/
    cursor: pointer;
`;

function Button(props) {
    const { children, text, type, size, variant, color, backgroundColor, borderRadius, ...options } = props;
    return (
        <ButtonStyle
            type={type ? type : 'button'}
            //onClick={onClick}
            //disabled={disabled}
            {...options}
            variant={variant}
            color={color}
            size={size}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
        >
            {text}
            {children}
        </ButtonStyle>
    );
}

export default Button;
