import React from 'react';
import Layout from '../modules/common/Layout';
import MainContainer from '../components/MainContainer';
import CreateProject from '../modules/agency/CreateProjectPage';

const CreateProjectPage = () => {
    return (
        <Layout>
            <MainContainer>
                <CreateProject />
            </MainContainer>
        </Layout>
    );
};

export default CreateProjectPage;
