import { useQuery } from 'react-query';
import { getRecommendCeleb } from '../queries/CelebList';

export const useRecommend = params => {
    const { data, isError, isLoading, refetch } = useQuery(['getRecommendCeleb'], () => getRecommendCeleb(params));

    const fetchData = async () => {
        await refetch();
    };

    if (isLoading || isError) {
        return { data: [] };
    }
    return { data, fetchData };
};
