import React from 'react';
import { LargeContainer } from '../components/MainContainer';
import Layout from '../modules/common/Layout';
import ContactSchedule from '../components/contact/ContactSchedule';

const SchedulePage = () => {
    return (
        <Layout>
            <LargeContainer>
                <ContactSchedule />
            </LargeContainer>
        </Layout>
    );
};

export default SchedulePage;
